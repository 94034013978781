import { useContext } from 'react';
import Context from '../context/index';
import { useGetCurrentUserRole } from './useGetCurrentUserRole';
import { ActivityDifferentiator, Answer, Installation } from '../schemas';
import { updateInstallationDataWithMerge } from '../helpers/updateMergedData';

type updateAnswerInContextFunction = (
  newAnswer: Answer,
  questionSetIdParam: string | null,
  questionSequenceNumber: number | null
) => void;

export const useUpdateInstallationTaskAnswer = (): [updateAnswerInContextFunction] => {
  const { installationData, updateInstallationData } = useContext(Context);
  const [userRole] = useGetCurrentUserRole();

  const updateInstallationTaskAnswer = (
    newAnswer: Answer,
    questionSetIdParam: string | null,
    questionSequenceNumber: number | null
  ) => {
    if (newAnswer && questionSequenceNumber !== null && questionSetIdParam) {
      switch (userRole) {
        case ActivityDifferentiator.INST: {
          const newAnswerSets = installationData?.installationPlanAnswers?.map(
            (answerSet) => {
              if (answerSet.questionSetId === questionSetIdParam) {
                const newAnswers = answerSet.answers.map((ans, i) =>
                  i === questionSequenceNumber ? newAnswer : ans
                );
                return {
                  questionSetId: answerSet.questionSetId,
                  answers: newAnswers,
                };
              }
              return answerSet;
            }
          );
          updateInstallationDataWithMerge(
            {
              ...installationData,
              installationPlanAnswers: newAnswerSets,
            } as Installation,
            updateInstallationData
          );
          break;
        }
      }
    }
  };
  return [updateInstallationTaskAnswer];
};
