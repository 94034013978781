import React, { JSX, useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NaviClose } from '@konecorp/ui-library';

import Context from '../../context';
import { VersionInfo as VersionInfoSchema } from '../../schemas';
import { useGetToken } from '../../hooks/useGetToken';
import { get } from '../../helpers/fetch';
import { formatDate } from '../../helpers/formating';

const ReleaseNoteBox = styled(Box)(() => ({
  border: '1px solid',
  background: 'rgb(244,244,244)',
  padding: '15px',
  borderColor: 'rgb(240,240,240)',
}));

const NaviCloseIcon = styled(NaviClose)(() => ({
  position: 'absolute',
  right: '10px',
  width: 40,
  height: 40,
}));

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    height: '80%',
  },
}));

export type VersionInfoContentProps = {
  versionInfo: VersionInfoSchema | undefined;
};

export type SettingsProps = {
  open: boolean;
  onClose: () => void;
};

export const VersionInfoContent = ({
  versionInfo,
}: VersionInfoContentProps): React.ReactElement => {
  const { t } = useTranslation();

  if (!versionInfo) return <></>;

  return (
    <Box>
      <Typography variant="body1">
        {t('versionInfo.iesVersion', { version: versionInfo?.version })}
      </Typography>
      <Typography variant="body1">
        {t('versionInfo.releaseDate', {
          releaseDate: formatDate(versionInfo?.releaseDate),
        })}
      </Typography>

      <Box pt={3}>
        <Typography variant="body1">{t('versionInfo.releaseNote')}</Typography>

        {versionInfo && (
          <ReleaseNoteBox>
            {versionInfo.newFeatures.map((newFeature, index) => (
              <Box key={`${index}`} marginBottom={4}>
                <Typography>{`${newFeature.title}:`}</Typography>

                {newFeature.description.map((detail, index) => (
                  <Typography
                    key={`${newFeature.title}-detail-${index}`}
                  >{`* ${detail}`}</Typography>
                ))}
              </Box>
            ))}
          </ReleaseNoteBox>
        )}
      </Box>
    </Box>
  );
};

const VersionInfo = (props: SettingsProps): JSX.Element => {
  const { open, onClose } = props;

  const [versionInfo, setVersionInfo] = useState<VersionInfoSchema | undefined>(
    undefined
  );
  const { updateIsLoading } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [getToken] = useGetToken();

  useEffect(() => {
    (async () => {
      updateIsLoading(true);
      if (window.navigator.serviceWorker?.controller) {
        const registration = await window.navigator.serviceWorker.ready;

        // manually trigger to tell browser to check for new FE version
        await registration.update();
      }

      const token = await getToken();
      const versionInfo = (await get(
        `v1/applications/ies-app/versions/${i18n.language}`, //ex: en-US
        token
      )) as VersionInfoSchema;

      setVersionInfo(versionInfo);

      updateIsLoading(false);
    })();
  }, []);

  return (
    <StyledDialog open={open} fullWidth>
      <DialogTitle>
        {t('versionInfo.title')}
        <NaviCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <VersionInfoContent versionInfo={versionInfo} />
      </DialogContent>
      <DialogActions>
        <Box width="100%" p={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            data-testid="save-button"
            onClick={onClose}
          >
            {t('versionInfo.ok')}
          </Button>
        </Box>
      </DialogActions>
    </StyledDialog>
  );
};

export default VersionInfo;
