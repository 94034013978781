import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import { startOfDay, isValid } from 'date-fns';
import { Box } from '@mui/system';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const DateRangePickerComponent = ({ state, handleSelect }: any) => {
  // Get the current date (without time)
  const today = startOfDay(new Date());
  const [focusedRange, setFocusedRange] = useState<[number, 0 | 1]>([0, 0]);

  // Ensure the state has valid dates
  const validatedState = state.map((range: any) => ({
    ...range,
    startDate: isValid(new Date(range.startDate)) ? new Date(range.startDate) : today,
    endDate: isValid(new Date(range.endDate)) ? new Date(range.endDate) : today,
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '16px',
        width: '100%',
        boxSizing: 'border-box',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', sm: '600px' },
          minWidth: '300px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            width: '100%',
            '& .rdrCalendarWrapper': {
              width: '100%', // Ensure the calendar fits within the container
            },
            '& .rdrMonth': {
              width: '100%', // Adjust the month width to fit the container
            },
          }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={validatedState}
            showDateDisplay={false}
            minDate={today}
            focusedRange={focusedRange} // Explicitly setting focused range
            onRangeFocusChange={(range) => setFocusedRange(range)} // Update focus state
            rangeColors={['#4caf50', '#4caf50', '#a5d6a7']}
          />
        </Box>
      </Box>
    </Box>
  );
};
