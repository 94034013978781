import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid2, TextField, Typography } from '@mui/material';
import { theme } from '@konecorp/ui-library';
import Context from '../../context';
import { Attachment, SiteInfo, SiteInfoType } from '../../schemas';
import UploadWidget from '../UploadWidget';
import { FileType } from '../../helpers/upload-download';
import { useFormik } from 'formik';
export type SiteInfoFormProps = {
  siteInfoToEdit: any | undefined;
  onSubmit: (siteInfo: SiteInfo) => void;
  onClose: () => void;
};

const SiteInfoForm = (props: PropsWithChildren<SiteInfoFormProps>): JSX.Element => {
  const { siteInfoToEdit, onSubmit, onClose } = props;
  const { isLoading } = useContext(Context);
  const [selectedFiles, setSelectedFiles] = useState<(File | Attachment)[]>([]);
  const { t } = useTranslation();
  const handleSubmit = (values: any) => {
    values.attachments = [...selectedFiles];
    onSubmit(values);
  };
  useEffect(() => {
    if (siteInfoToEdit?.attachments) {
      setSelectedFiles(siteInfoToEdit.attachments);
    }
  }, [siteInfoToEdit]);
  const handleUploadButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (selectedFiles.length + 1 <= 5) {
        setSelectedFiles((prevFiles) => [...prevFiles, file]); // Update selected files
      } else {
        alert('Total number of files should not exceed 5');
      }
    }
  };
  const deleteAttachmentsLocally = (file: File | Attachment) => {
    setSelectedFiles(selectedFiles.filter((f) => f !== file));
  };
  const initialSiteInfo: SiteInfo = {
    sk: '',
    note: '',
    type: 'siteInfo',
    attachments: [],
    name: SiteInfoType.SITEACCESSINFO,
  };
  const formik = useFormik<SiteInfo>({
    initialValues: siteInfoToEdit ?? initialSiteInfo,
    onSubmit: handleSubmit,
  });
  return (
    <Box
      padding={theme.spacing(1)}
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}
    >
      <Typography
        align="center"
        sx={{
          padding: theme.spacing(2),
          textTransform: 'uppercase',
          borderBottom: '1px solid lightgrey',
        }}
      >
        {!siteInfoToEdit?.sk
          ? siteInfoToEdit?.name === SiteInfoType.SITEACCESSINFO
            ? t('teamList.siteInfo.addSiteAccsess')
            : siteInfoToEdit?.name === SiteInfoType.BATHROOMSINFO
            ? t('teamList.siteInfo.addBathrooms')
            : t('teamList.siteInfo.addOtherNote')
          : siteInfoToEdit?.name === SiteInfoType.SITEACCESSINFO
          ? t('teamList.siteInfo.addSiteAccsess')
          : siteInfoToEdit?.name === SiteInfoType.BATHROOMSINFO
          ? t('teamList.siteInfo.addBathrooms')
          : t('teamList.siteInfo.addOtherNote')}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          padding: theme.spacing(1, 1, 1),
          borderBottom: '1px solid lightgrey',
          '& .MuiFormControl-root': {
            margin: theme.spacing(1, 0),
          },
        }}
      >
        <Typography sx={{ paddingLeft: theme.spacing(1) }}>
          {t('teamList.siteInfo.description')}
        </Typography>
        <Grid2 size={{ xs: 12 }}>
          <TextField
            inputProps={{
              'data-testid': 'description-field',
            }}
            id="description-field"
            name="note"
            value={formik.values.note}
            onChange={formik.handleChange}
            variant="outlined"
            required
            fullWidth
            multiline
            rows={5}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <UploadWidget
            handleUploadButton={handleUploadButton}
            selectedFiles={selectedFiles}
            fileType={FileType.ATTACHMENT}
            deleteAttachmentsLocally={deleteAttachmentsLocally}
            label={t('deviationForm.addFile')}
            disabled={selectedFiles.length > 5}
          />
        </Grid2>
      </Box>
      <Box display="flex" justifyContent="space-between" padding={theme.spacing(2)}>
        <Button
          color="primary"
          variant="outlined"
          disabled={isLoading}
          data-testid="clear-button"
          onClick={onClose}
          fullWidth
          sx={{ margin: theme.spacing(1) }}
        >
          {t('deviationForm.cancel')}
        </Button>

        <Button
          color="primary"
          variant="contained"
          data-testid="send-button"
          type="submit"
          disableElevation
          fullWidth
          sx={{ margin: theme.spacing(1) }}
        >
          {t('deviationForm.save')}
        </Button>
      </Box>
    </Box>
  );
};
export default SiteInfoForm;
