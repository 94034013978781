import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';

import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { styled } from '@mui/material';
import { Calendar } from '@konecorp/ui-library';
import { useState } from 'react';
interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  calendarIcon: boolean;
  planDate?: boolean;
}

const StyledButton = styled(Button)(() => ({
  border: 'none',
  padding: '0px',
  fontSize: 'medium',
  margin: '0px !important',
}));

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
    calendarIcon,
    planDate,
  } = props;
  return (
    <StyledButton
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {planDate ? (
        <Calendar />
      ) : label ? (
        `${label}`
      ) : calendarIcon ? (
        <Calendar />
      ) : (
        '+ Add Date'
      )}
      {/* {label ? `${label}` : calendarIcon ? <Calendar />:'+ Add Date'} */}
    </StyledButton>
  );
}

function ButtonDatePicker({
  calendarIcon,
  planDate,
  ...props
}: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> & {
  calendarIcon: boolean;
  planDate?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState<Dayjs | null>(props.value || null);

  const handleDateChange = (newValue: Dayjs | null) => {
    setCurrentDate(newValue);
  };

  const handleAccept = () => {
    props.onChange?.(currentDate, { validationError: null });
    setOpen(false);
  };
  return (
    <>
      <DatePicker
        slots={{ ...props.slots, field: ButtonField }}
        slotProps={{
          ...props.slotProps,
          field: { setOpen, calendarIcon, planDate } as any,
          actionBar: {
            actions: ['cancel', 'accept'],
            onAccept: handleAccept,
          },
        }}
        {...props}
        value={currentDate}
        open={open}
        disableHighlightToday
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        closeOnSelect={false}
        onChange={handleDateChange}
        minDate={dayjs()}
      />
    </>
  );
}

export const PickerWithButtonField = ({
  inspectDate,
  handleDateChange,
  calendarIcon,
  planDate,
}: {
  inspectDate: any;
  handleDateChange: (newValue: Dayjs | null) => void;
  calendarIcon: boolean;
  planDate?: boolean;
}) => {
  const handleChange = (newValue: Dayjs | null) => {
    if (newValue !== null) {
      handleDateChange(newValue);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        label={!dayjs(inspectDate).isValid() ? null : inspectDate.format('DD.MM.YYYY')}
        value={inspectDate}
        onChange={handleChange}
        calendarIcon={calendarIcon}
        planDate={planDate}
      />
    </LocalizationProvider>
  );
};
