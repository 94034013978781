import React, { JSX, useContext } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowForward, CheckCircleOutlineRounded, Warning } from '@mui/icons-material';
import { theme, CircleCancel } from '@konecorp/ui-library';
import Context from '../../context';
import { useNavigate } from 'react-router-dom';
import Empty from '../Empty';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

const FormSummaryText = styled(Typography)(() => ({
  marginBottom: '10px',
}));

const ArrowForwardIcon = styled(ArrowForward)(() => ({
  marginLeft: '20px',
  color: theme.palette.primary.main,
}));

const FormPassStatus = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginLeft: '16px',
  color: theme.palette.success.main,
}));

const FormFailStatus = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginLeft: '16px',
  color: theme.palette.error.main,
}));

const QuestionText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200PX',
}));

const QuestionTextContainer = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
  marginBottom: '10px',
}));

interface Question {
  pk: string;
  sk: string;
  value: string;
  mustScore: boolean;
  totalPoints: number;
  maxPoints: number;
}

interface FailedCriticalQuestions {
  questionSetId: string;
  questionIndex: number;
  questionText: string | undefined | null;
}

interface ScoreData {
  failedCriticalQuestions?: Question[];
  failedQuestions?: Question[];
  passQuestions?: Question[];
  unscoredQuestions?: Question[];
  totalPoints?: number;
  maxPoints?: number;
  passPercentage?: number;
  currentPercentage?: number;
  pass?: boolean;
  isScoreForm?: boolean;
}

interface FormScoreProps {
  scoreData: ScoreData;
  failedCriticalQuestions: FailedCriticalQuestions[];
  unAnsweredQuestionsCount: number;
  loadFlag: boolean;
}

const FormScore = (props: FormScoreProps): JSX.Element => {
  const { t } = useTranslation();
  const { networkNumber } = useContext(Context);
  const navigate = useNavigate();
  const [isSubcontractor] = useIfSubcontractor();

  const handleClick = (question: FailedCriticalQuestions) => {
    if (isSubcontractor)
      navigate(
        `/subcontractor/${networkNumber}/execution?questionSetId=${question?.questionSetId}&questionNumber=${question?.questionIndex}`
      );
    else
      navigate(
        `/${networkNumber}/execution?questionSetId=${question?.questionSetId}&questionNumber=${question?.questionIndex}`
      );
  };

  return (
    <>
      {props?.loadFlag && Object.keys(props?.scoreData).length > 0 ? (
        <>
          <FormSummaryText variant="h6">
            {t('formScore.formScoreSummary')}
          </FormSummaryText>
          {props?.scoreData?.pass ? (
            <>
              <Box display="flex" alignItems="center" marginBottom="10px">
                <CheckCircleOutlineRounded
                  data-testid="pass-icon"
                  htmlColor={theme.palette.success.main}
                />
                <FormPassStatus variant="h6">{t('formScore.passed')}</FormPassStatus>
              </Box>
              <Typography>
                <Trans
                  i18nKey="formScore.passedMessage"
                  values={{ percentage: props?.scoreData?.currentPercentage }}
                  components={{ b: <strong /> }}
                />
              </Typography>
              <FormSummaryText>
                <Trans
                  i18nKey="formScore.passedSecondMessage"
                  values={{ failPercentage: props?.scoreData?.passPercentage }}
                  components={{ b: <strong /> }}
                />
              </FormSummaryText>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <CircleCancel
                  data-testid="fail-icon"
                  htmlColor={theme.palette.error.main}
                />
                <FormFailStatus variant="h6">{t('formScore.failed')}</FormFailStatus>
              </Box>
              <Typography>
                <Trans
                  i18nKey="formScore.failedMessage"
                  values={{ percentage: props?.scoreData?.currentPercentage }}
                  components={{ b: <strong /> }}
                />
              </Typography>
              <FormSummaryText>
                <Trans
                  i18nKey="formScore.failedSecondMessage"
                  values={{ passPercentage: props?.scoreData?.passPercentage }}
                  components={{ b: <strong /> }}
                />
              </FormSummaryText>
            </>
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={3}
            height="auto"
            width="40%"
            minWidth="200px"
            style={{ backgroundColor: theme.palette.grey[200], padding: '30px' }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography>{t('formScore.totalQuestions')}:</Typography>
              <Typography>
                {(props?.scoreData?.failedCriticalQuestions?.length || 0) +
                  (props?.scoreData?.failedQuestions?.length || 0) +
                  (props?.scoreData?.passQuestions?.length || 0) +
                  (props?.scoreData?.unscoredQuestions?.length || 0)}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.answered')}:</Typography>
              <Typography>
                {(props?.scoreData?.failedCriticalQuestions?.length || 0) +
                  (props?.scoreData?.failedQuestions?.length || 0) +
                  (props?.scoreData?.passQuestions?.length || 0) +
                  (props?.scoreData?.unscoredQuestions?.length || 0) -
                  props?.unAnsweredQuestionsCount}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.unAnswered')}:</Typography>
              <Typography>{props?.unAnsweredQuestionsCount}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.passedQuestions')}:</Typography>
              <Typography fontWeight="bold" color={theme.palette.success.main}>
                {props?.scoreData?.passQuestions?.length}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mt={1}
            >
              <Typography>{t('formScore.failedQuestions')}:</Typography>
              <Typography fontWeight="bold" color={theme.palette.error.main}>
                {props?.scoreData?.failedQuestions?.length}
              </Typography>
            </Box>
          </Box>
          {props.failedCriticalQuestions.length > 0 && (
            <>
              <Box display="flex" alignItems="center" mt={3}>
                <Warning htmlColor={theme.palette.error.main} />
                <FormFailStatus variant="h6">
                  {t('formScore.criticalQuestionsMessage', {
                    criticalQuestions: props?.scoreData?.failedCriticalQuestions?.length,
                  })}
                </FormFailStatus>
              </Box>
              <FormSummaryText>
                {t('formScore.criticalQuestionsSecondMessage')}
              </FormSummaryText>
              {props?.failedCriticalQuestions?.map(
                (question: FailedCriticalQuestions, index: number) => (
                  <QuestionTextContainer
                    key={index}
                    display="flex"
                    alignItems="center"
                    onClick={() => handleClick(question)}
                    data-testid="questionText"
                  >
                    <QuestionText variant="body1">
                      {question.questionText || 'No text available'}
                    </QuestionText>
                    <ArrowForwardIcon />
                  </QuestionTextContainer>
                )
              )}
            </>
          )}
        </>
      ) : (
        <Empty displayIcon={false} message={t('notifications.fetchingFormScore')}></Empty>
      )}
    </>
  );
};

export default FormScore;
