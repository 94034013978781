import { mergeData } from './formUtils';
import { Installation } from '../schemas';

export const updateInstallationDataWithMerge = (
  data: Installation | null,
  updateInstallationData: (data: Installation | null) => void
) => {
  if (data && data.forms) {
    const {
      mergedQuestions,
      mergedAnswers,
      mergedQuestionSequences,
      mergedQuestionSetInfo,
    } = mergeData(data);

    updateInstallationData({
      ...data,
      mergedQuestions,
      mergedAnswers,
      mergedQuestionSequences,
      mergedQuestionSetInfo,
    });
  } else if (data && !data.forms) {
    updateInstallationData(data);
  } else {
    updateInstallationData(null);
  }
};
