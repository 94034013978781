import React, { useState, ReactNode, JSX } from 'react';
import { AuthStateProps, AcquireTokenFunction } from '@konecorp/ui-library';

import Context, { ContextState, ErrorMessage } from './index';
import { Installation } from '../schemas';

type ReactProps = {
  children: ReactNode;
};

export default ({ children }: ReactProps): JSX.Element => {
  const authData = {
    isAuthenticated: false,
  } as AuthStateProps;

  const [authState, updateAuthState] = useState<AuthStateProps>(authData);
  const [installationData, updateInstallationData] = useState<Installation | null>(null);
  const [networkNumber, updateNetworkNumber] = useState<string>('');
  const [isScoreForm, updateScoreForm] = useState<boolean>(false);
  const [ongoingInstallations, updateOngoingInstallations] = useState<null | string[]>(
    null
  );
  const [toBeStartedInstallations, updateToBeStartedInstallations] = useState<
    null | string[]
  >(null);
  const [completedInstallations, updateCompletedInstallations] = useState<
    null | string[]
  >(null);
  const [isLoading, updateIsLoading] = useState<boolean>(false);
  const [errorMessage, updateErrorMessage] = useState<ErrorMessage | null>(null);

  const [acquireToken, updateAcquireToken] = useState<AcquireTokenFunction | undefined>();

  const state: ContextState = {
    authState,
    networkNumber,
    isScoreForm,
    installationData,
    ongoingInstallations,
    toBeStartedInstallations,
    completedInstallations,
    isLoading,
    acquireToken,
    errorMessage,
    updateInstallationData,
    updateAuthState,
    updateNetworkNumber,
    updateScoreForm,
    updateOngoingInstallations,
    updateToBeStartedInstallations,
    updateCompletedInstallations,
    updateIsLoading,
    updateAcquireToken,
    updateErrorMessage,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
