import React, { useEffect, useState, useContext, JSX } from 'react';
import {
  PlayArrow,
  HomeOutlined,
  CheckBoxOutlined,
  PersonAddOutlined,
  Search,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TabMyNetworks } from '@konecorp/ui-library';
import { useNavigate } from 'react-router-dom';

import Widget, { WidgetButtonProps, WidgetGroupProps } from '../../components/Widget';
import HeaderBar from '../../components/HeaderBar';
import Context from '../../context';
import logo from '../../logo_inverted.svg';
import WarningRaccoonSign from '../../components/Warning';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';

type InstallationsProp = {
  onSignOut: () => void;
};

const Installations = (props: InstallationsProp): JSX.Element => {
  const [widgetGroups, setWidgetGroups] = useState<WidgetGroupProps[]>([]);
  const {
    authState: { user, isAuthenticated },
    ongoingInstallations,
    toBeStartedInstallations,
    completedInstallations,
    updateIsLoading,
  } = useContext(Context);

  const env =
    '(' + (process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT || 'DEV').toUpperCase() + ')';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();

  useEffect(() => {
    (async () => {
      if (!ongoingInstallations || !toBeStartedInstallations || !completedInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    const selfAssignWidgetData = {
      icon: <PersonAddOutlined />,
      title: t('selfAssignPage.title'),
      action: () => navigate('/selfassign'),
    } as WidgetButtonProps;

    const onGoingWidgetData = {
      icon: <TabMyNetworks />,
      title: t('myInstallation.noOpenInstallations'),
      countLabel:
        ongoingInstallations?.length !== 0 ? ongoingInstallations?.length : undefined,
    } as WidgetButtonProps;

    const toBeStartedWidgetData = {
      icon: <PlayArrow sx={{ display: 'flex' }} />,
      title: t('myInstallation.noToBeStartedInstallations'),
      countLabel:
        toBeStartedInstallations?.length !== 0
          ? toBeStartedInstallations?.length
          : undefined,
    } as WidgetButtonProps;

    const completedInstallationsWidgetData = {
      icon: <CheckBoxOutlined sx={{ display: 'flex' }} />,
      title: t('myInstallation.completedInstallations'),
      countLabel:
        completedInstallations?.length !== 0 ? completedInstallations?.length : undefined,
      action: () => navigate('/completed'),
    } as WidgetButtonProps;

    if (ongoingInstallations && ongoingInstallations.length > 0) {
      onGoingWidgetData.title = t('myInstallation.ongoingInstallations');
      onGoingWidgetData.action = () => {
        navigate('/ongoing');
      };
    }

    if (toBeStartedInstallations && toBeStartedInstallations.length > 0) {
      toBeStartedWidgetData.title = t('myInstallation.toBeStartedInstallations');
      toBeStartedWidgetData.action = () => {
        navigate('/tobestarted');
      };
    }

    const inspectionWidgetData = {
      icon: <Search />,
      title: t('myInstallation.maintenanceInspection'),
      action: () => navigate('/maintenanceassign'),
    } as WidgetButtonProps;

    const myGroup = t('myInstallation.myInstallationGroupTitle', {
      ...(env !== '(PROD)' ? { env } : undefined),
    });
    const sebGroup = t('myInstallation.sebInstallationGroupTitle');

    setWidgetGroups([
      {
        name: myGroup,
        buttons: [
          toBeStartedWidgetData,
          onGoingWidgetData,
          selfAssignWidgetData,
          completedInstallationsWidgetData,
        ],
      },
      {
        name: sebGroup,
        buttons: [inspectionWidgetData],
      },
    ]);
  }, [ongoingInstallations, toBeStartedInstallations, completedInstallations]);

  return (
    <>
      <HeaderBar
        handleSidebarToggle={() => undefined}
        isAuthenticated={isAuthenticated}
        onSignOut={props.onSignOut}
        sidemenuWidth={0}
        title={t('applicationName')}
        titleIcon={HomeOutlined}
        userMenuTitle={user?.username}
        logo={logo}
      />
      <Widget widgetGroups={widgetGroups} />
      {/* TODO: Need to fix how the env variable is conducted and used */}
      {env === '(ACC)' && <WarningRaccoonSign message={t('myInstallation.QAWarning')} />}
    </>
  );
};

export default Installations;
