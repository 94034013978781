import React, { JSX, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { uniqBy } from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  styled,
  Typography,
} from '@mui/material';
import {
  DeviationBlocker,
  DeviationMinor,
  InfoModal,
  NaviClose,
  theme,
} from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import Context from '../../context';
import { API_TYPE, fetchEmployeeFullName, get, put } from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Deviation,
  DeviationStatus,
  Handover,
  Installation,
  InstallationStatus,
  SpecialUserIds,
} from '../../schemas';
import { NetworkInstallationPaths } from '../../containers/NetworkInstallation';
import HandoverSummaryPreview from '../HandoverSummaryPreview';
import { useGetToken } from '../../hooks/useGetToken';
import { updateInstallationDataWithMerge } from '../../helpers/updateMergedData';

enum DialogType {
  NONE,
  HANDOVER,
  INFO,
}

const StyledContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: theme.shape.borderRadius,
  margin: '10px auto',
  padding: theme.spacing(2),
  textTransform: 'uppercase',
}));

const StyledDialog = styled(Dialog)(() => ({
  height: '80%',
}));

const CloseIcon = styled(NaviClose)(() => ({
  position: 'absolute',
  right: '10px',
  width: 40,
  height: 40,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  margin: theme.spacing(1),
}));

type VerifyInstallationDialogProps = {
  isOpen: boolean;
  handover: Handover | null;
  onVerify: () => void;
  onClose: () => void;
};

const VerifyInstallationDialog = ({
  isOpen,
  handover,
  onClose,
  onVerify,
}: VerifyInstallationDialogProps) => {
  const { t } = useTranslation();
  return (
    <StyledDialog data-testid="handover-dialog" open={isOpen} fullWidth>
      <DialogTitle>
        {t('installationActions.verifyHandover')}
        <CloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" sx={{ margin: theme.spacing(1, 0) }}>
          {t('installationActions.verifyHandoverFromInstaller')}
        </Typography>
        <HandoverSummaryPreview handover={handover} />
      </DialogContent>
      <StyledDialogActions>
        <Button
          color="primary"
          variant="outlined"
          data-testid="cancel-button"
          onClick={onClose}
          fullWidth
        >
          {t('installationActions.cancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid="verify-button"
          onClick={onVerify}
          fullWidth
        >
          {t('installationActions.acceptButton')}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export type InstallationActionsProps = {
  deviations: Deviation[];
  networkNumber: string;
};

const InstallationActions = ({
  deviations,
  networkNumber,
}: InstallationActionsProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    installationData,
    updateErrorMessage,
    updateIsLoading,
    updateInstallationData,
  } = useContext(Context);
  const [getTokenFunction] = useGetToken();

  const [openDialog, setOpenDialog] = useState(DialogType.NONE);
  const [handoverCreatorName, setHandoverCreatorName] = useState<string>('');
  const [handover, setHandover] = useState<Handover | null>(null);
  const [newStatus, setNewStatus] = useState<InstallationStatus | null>(null);

  useEffect(() => {
    (async (): Promise<Handover | null> => {
      try {
        updateIsLoading(true);
        const accessToken = await getTokenFunction();
        const handoverData: Handover = await get(
          `v1/installations/${networkNumber}/handovers/latest`,
          accessToken
        );
        setHandover(handoverData);

        const subcontractors = uniqBy(
          installationData?.subcontractors || [],
          'activityDifferentiator'
        );

        const getSubcontractorName = (role: ActivityDifferentiator): string =>
          subcontractors.find(
            (subcontractor) => subcontractor.activityDifferentiator === role
          )?.subcontractor.name || t('installationActions.subcontractor');

        const creatorName = await (async () => {
          switch (handoverData.creator) {
            case SpecialUserIds.SUBCONTRACTOR_INSTALLER:
              return getSubcontractorName(ActivityDifferentiator.INST);
            case SpecialUserIds.SUBCONTRACTOR_TESTER:
              return getSubcontractorName(ActivityDifferentiator.CMSN);
            default:
              return await fetchEmployeeFullName(handoverData.creator, accessToken);
          }
        })();

        setHandoverCreatorName(creatorName);
      } catch (error) {
        updateErrorMessage({ message: 'Error while fetching handover data', error });
      } finally {
        updateIsLoading(false);
      }
      return null;
    })();
  }, []);

  const byBlockers = (deviation: Deviation) => deviation.blocker || deviation.compliance;
  const byNonBlockers = (deviation: Deviation) => !byBlockers(deviation);

  const openDeviations = deviations.filter(
    ({ status }) => status === DeviationStatus.OPEN
  );

  const approveInstallationInspection = async () => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();
      const updatedInstallation: Installation | null = await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        { status: InstallationStatus.FOR_TESTER_ACCEPTANCE }
      );
      if (updatedInstallation) {
        setNewStatus(updatedInstallation.status);
        setOpenDialog(DialogType.INFO);
      }
    } catch (error) {
      updateErrorMessage({ message: 'Error while updating installation status', error });
    } finally {
      updateIsLoading(false);
    }
  };

  const handleCloseInfoModal = () => {
    setOpenDialog(DialogType.NONE);
    if (newStatus)
      updateInstallationDataWithMerge(
        { ...installationData, status: newStatus } as Installation,
        updateInstallationData
      );
  };

  return (
    <StyledContainer>
      <Typography variant="body1">
        {t('installationActions.handoverFrom', { creator: handoverCreatorName })}
      </Typography>
      <Box my={2} display="flex">
        <Link
          variant="body1"
          onClick={() =>
            navigate(generatePath(NetworkInstallationPaths.DEVIATIONS, { networkNumber }))
          }
        >
          {t('installationActions.openDeviations')}
        </Link>
        <Box pl={3} display="flex">
          <DeviationBlocker sx={{ margin: theme.spacing(0, 1) }} />
          <Typography variant="body1" style={{ marginRight: 10 }}>
            {openDeviations.filter(byBlockers).length}
          </Typography>
          <DeviationMinor sx={{ margin: theme.spacing(0, 1) }} />
          <Typography variant="body1">
            {openDeviations.filter(byNonBlockers).length}
          </Typography>
        </Box>
      </Box>

      <Button
        color="primary"
        variant="outlined"
        data-testid="open-handover-dialog-button"
        onClick={() => setOpenDialog(() => DialogType.HANDOVER)}
        fullWidth
      >
        {t('installationActions.openHandoverDialog')}
      </Button>
      <VerifyInstallationDialog
        isOpen={openDialog === DialogType.HANDOVER}
        handover={handover}
        onVerify={async () => await approveInstallationInspection()}
        onClose={() => setOpenDialog(() => DialogType.NONE)}
      />
      <InfoModal
        open={openDialog === DialogType.INFO}
        message={t('installationActions.updateSuccessful')}
        onClose={handleCloseInfoModal}
        closeButtonText={t('installationActions.okButton')}
        isCenteredMessage
      />
    </StyledContainer>
  );
};

export default InstallationActions;
