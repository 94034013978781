import React, { JSX } from 'react';
import { useContext, useEffect, useState } from 'react';
import Context, { InstallationContext } from '../../context';
import { useTranslation } from 'react-i18next';
import { List, Stack, styled, Paper, Box } from '@mui/material';
import Empty from '../../components/Empty';
import {
  AnswerSet,
  Attachment,
  InstallationStatus,
  isNewDeviation,
  PutAnswerPayload,
  QuestionSet,
} from '../../schemas';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import {
  createDeviation,
  editDeviation,
  getDeviationsData,
} from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { useGetToken } from '../../hooks/useGetToken';
import { uploadAttachments } from '../../helpers/upload-download';
import { API_TYPE, post } from '../../helpers/fetch';
import { useUpdateInstallationTaskAnswer } from '../../hooks/useInstallationTaskAnswer';
import dayjs, { Dayjs } from 'dayjs';
import CustomizedLocalizedQuestionText from '../../components/CustomizedLocalizedQuestionText';
import { getLocalizedText } from '../../helpers/custom-i18n';
import AnswerValueWithDeviation from '../AnswerValueWithDeviation';

const QuestionText = styled(Paper)<{ hasAnswer: boolean }>(({ hasAnswer }) => ({
  flexGrow: 1,
  backgroundColor: hasAnswer ? '#FFFFFF' : '#F3F6FE',
  padding: '8px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  border: hasAnswer ? '1px solid #E2E2E2' : 'none',
}));

const AnswerContainer = styled(Box)(({ theme }) => ({
  flexWrap: 'wrap',
  justifyContent: 'end',
  paddingRight: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(5),
  },
}));
// Styled components for the Date UI Box
const DateBoxContainer = styled(Box)<{ hasAnswer: boolean }>(({ hasAnswer }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${hasAnswer ? '#C6CACC' : '#000000'}`,
  borderRadius: '8px',
  overflow: 'hidden',
  width: '60px',
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  flexShrink: 0,
}));
const DateBoxTop = styled(Box)(() => ({
  padding: '4px',
  fontSize: '12px',
  color: '#3D3D3D',
}));
const DateBoxBottom = styled(Box)<{ hasAnswer: boolean }>(({ hasAnswer }) => ({
  backgroundColor: hasAnswer ? '#707070' : '#3D3D3D',
  padding: '2px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#FFFFFF',
  borderRadius: '4px',
  width: '95%',
  marginBottom: '2px',
}));

type QuestionListProps = {
  questionSets?: QuestionSet[];
  answers?: AnswerSet[];
  startDate?: string | null;
  targetDate?: string | null;
  networkNumber: string;
  inspectDate: Dayjs | null;
  handleDateChange: (newValue: Dayjs | null) => void;
};

const InstallationPlanQuestionsList = (props: QuestionListProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { updateIsLoading, updateErrorMessage } = useContext(Context);
  const [updateInstallationTaskAnswer] = useUpdateInstallationTaskAnswer();
  const { dispatch } = useContext(InstallationContext);
  const [getTokenFunction] = useGetToken();
  const [deviationsLoaded, setDeviationsLoaded] = useState(false); // State to track loading state
  const { questionSets, answers, networkNumber } = props;

  const questionSetToShow = questionSets?.find(
    (questionSet) => questionSet.questionSetId === 'PLAN'
  );

  // Filter questions to include only those with deleted = false
  const filteredQuestions = questionSetToShow?.questions
    .map((question, index) => ({ question, actualIndex: index }))
    .filter(({ question }) => !question.deleted);

  useEffect(() => {
    const fetchDeviations = async () => {
      // Clear the deviations state before fetching new deviations
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: [],
      });
      const accessToken = await getTokenFunction();
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      const filteredDeviations = fetchedDeviations?.filter(
        (deviation) =>
          deviation.installationWorkflowStatus === InstallationStatus.INSTALLER_ACCEPTED
      );
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: filteredDeviations,
      });
      setDeviationsLoaded(true); // Update the loading state
    };
    fetchDeviations();
  }, []);

  const onSaveAnswer = async (
    newAnswer: PutAnswerPayload,
    questionSequenceNumber: number
  ) => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();
    const files = newAnswer.value || [];
    const uploadFiles: File[] = [];
    const dbFiles: Attachment[] = [];
    if (Array.isArray(files)) {
      files.forEach((file) => {
        if (file instanceof File) {
          uploadFiles.push(file);
        } else {
          dbFiles.push(file);
        }
      });
    }

    try {
      const actualQuestionSequenceNumber =
        questionSequenceNumber < 10
          ? `0${questionSequenceNumber}`
          : questionSequenceNumber;

      newAnswer.value = true;

      if (Array.isArray(uploadFiles) && uploadFiles.length) {
        const attachments = await uploadAttachments({
          files: uploadFiles,
          networkNumber,
          questionSequenceNumber,
          jwtToken: accessToken,
        });
        newAnswer.value = [...dbFiles, ...(attachments || [])];
      }

      const updatedAnswer = await post(
        `v1/installations/${networkNumber}/answers/PLAN/${actualQuestionSequenceNumber}`,
        accessToken,
        API_TYPE.APPLICATION,
        newAnswer
      );
      updateInstallationTaskAnswer(updatedAnswer, 'PLAN', questionSequenceNumber);
    } catch (e) {
      console.error(e);
    }
    updateIsLoading(false);
  };

  const handleCreateDeviation = async (deviation: CreateDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);

    try {
      const createdDeviation = await createDeviation(
        accessToken,
        networkNumber,
        deviation
      );
      dispatch({
        type: InstallationActionName.ADD_DEVIATION,
        deviation: createdDeviation,
      });
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      const filteredDeviations = fetchedDeviations.filter(
        (deviation) =>
          deviation.installationWorkflowStatus === InstallationStatus.INSTALLER_ACCEPTED
      );
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: filteredDeviations,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotCreateDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const handleEditDeviation = async (deviation: EditDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);
    try {
      const updatedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: updatedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotEditDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const answerSets = answers?.find((answerSet) => answerSet.questionSetId === 'PLAN');
  const answerValues = answerSets?.answers || [];

  return deviationsLoaded && filteredQuestions ? (
    <List sx={{ width: '100%', margin: 'auto', padding: 0 }}>
      {filteredQuestions.map(({ question, actualIndex }, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%', padding: '8px 0' }}
        >
          <QuestionText hasAnswer={!!answerValues[actualIndex]?.value}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {question.startDate && question.endDate && (
                <DateBoxContainer hasAnswer={!!answerValues[actualIndex]?.value}>
                  <DateBoxTop>{dayjs(question.startDate).format('DD.MM')}. -</DateBoxTop>
                  <DateBoxBottom hasAnswer={!!answerValues[actualIndex]?.value}>
                    {dayjs(question.endDate).format('DD.MM')}.
                  </DateBoxBottom>
                </DateBoxContainer>
              )}
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  marginLeft: '8px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                }}
              >
                <CustomizedLocalizedQuestionText
                  localaizedQuestionText={
                    getLocalizedText(i18n, question.text)?.text || ''
                  }
                  hasAnswer={!!answerValues[actualIndex]?.value}
                />
              </Box>
              <AnswerContainer display="flex" alignItems="center">
                {question.valueType === 'yes_no' && (
                  <AnswerValueWithDeviation
                    answer={answerValues[actualIndex]?.value || null}
                    question={question}
                    questionSequence={actualIndex} // Pass the actual index
                    questionSetId="PLAN"
                    isReadOnly={false}
                    onAnswer={async (newAnswer, deviation) => {
                      if (deviation) {
                        if (isNewDeviation(deviation)) {
                          await handleCreateDeviation(
                            deviation as CreateDeviationPayload
                          );
                        } else {
                          await handleEditDeviation(deviation as EditDeviationPayload);
                        }
                      }
                      await onSaveAnswer(newAnswer, actualIndex); // Use the actual index
                    }}
                  />
                )}
              </AnswerContainer>
            </Box>
          </QuestionText>
        </Stack>
      ))}
    </List>
  ) : (
    <Empty displayIcon={false} message={t('')}></Empty>
  );
};

export default InstallationPlanQuestionsList;
