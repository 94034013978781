import React, { JSX, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import UploadWidget from '../UploadWidget';
import { SendHandoverParams } from '../../containers/Handover';
import { emailValidator } from '../../helpers/validators';
import {
  AnswerSet,
  Attachment,
  Contact,
  Deviation,
  isDeviationBlocker,
  isDeviationCompliance,
  isDeviationOpen,
  QuestionSet,
} from '../../schemas';
import { getUnansweredQuestionsCount } from '../../helpers/question';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { InfoModal } from '@konecorp/ui-library';

enum DeviationsWarning {
  COMPLIANCE,
  BLOCKER,
  COMPLIANCE_AND_BLOCKER,
  NONE,
}

const HandoverContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const TextBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.error.main,
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const RemoveButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: 0,
}));

const SebContactsBox = styled(Box)(({ theme }) => ({
  border: '1px solid lightgray',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
}));

const StyledTextfield = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  backgroundColor: theme.palette.common.white,
  border: 'solid 1px',
  borderColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  padding: '2px',
  width: 'fit-content',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(1),
}));

export type NebSupervisorSignoffProps = {
  deviations?: Deviation[] | undefined;
  questionsAndAnswers: { questions: QuestionSet[]; answers: AnswerSet[] };
  selectedFiles: File[];
  sebContacts: Contact[];
  deleteAttachmentsLocally: (file: File | Attachment) => void;
  handleUploadButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendToSeb: (params: SendHandoverParams) => Promise<void>;
  isReadOnly?: boolean;
  createSebContact: (email: string) => Promise<void>;
  commentText: string;
  setCommentText: (comment: string) => void;
};

const NebSupervisorSignoff = (props: NebSupervisorSignoffProps): JSX.Element => {
  const {
    deleteAttachmentsLocally,
    deviations = [],
    sebContacts,
    handleUploadButton,
    questionsAndAnswers,
    selectedFiles,
    sendToSeb,
    isReadOnly,
    createSebContact,
    commentText,
    setCommentText,
  } = props;

  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailAddressError, setEmailAddressError] = useState<string>('');
  const [addedEmails, setAddedEmails] = useState<string[]>([]);
  const [offlineHandover, setOfflineHandover] = useState<boolean>(false);

  const openDeviations = deviations.filter(isDeviationOpen);
  const openDeviationsComplianceCount =
    openDeviations.filter(isDeviationCompliance).length;
  const openDeviationsBlockerCount = openDeviations.filter(isDeviationBlocker).length;

  const deviationsWarning = (() => {
    if (openDeviationsComplianceCount > 0 && openDeviationsBlockerCount > 0) {
      return DeviationsWarning.COMPLIANCE_AND_BLOCKER;
    } else if (openDeviationsComplianceCount > 0) {
      return DeviationsWarning.COMPLIANCE;
    } else if (openDeviationsBlockerCount > 0) {
      return DeviationsWarning.BLOCKER;
    }
    return DeviationsWarning.NONE;
  })();

  const unansweredQuestionsCount = getUnansweredQuestionsCount(questionsAndAnswers);
  const isWarning =
    deviationsWarning !== DeviationsWarning.NONE || unansweredQuestionsCount > 0;
  const isHandoverAllowed = deviationsWarning === DeviationsWarning.NONE && !isReadOnly;
  const anyEmailPresent = sebContacts.length > 0 || addedEmails.length > 0;

  const handleAddEmail = () => {
    const email = emailAddress.trim();

    if (!email) {
      setEmailAddressError(t('nebSupervisorSignoff.emailBlank'));
      return;
    }
    if (!emailValidator(email)) {
      setEmailAddressError(t('nebSupervisorSignoff.emailInvalid'));
      return;
    }
    if (
      sebContacts.some((contact) => contact.email === email) ||
      addedEmails.includes(email)
    ) {
      setEmailAddressError(t('nebSupervisorSignoff.emailExists'));
      return;
    }

    setAddedEmails([...addedEmails, email]);
    setEmailAddress('');
    setEmailAddressError('');
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setAddedEmails(addedEmails.filter((email) => email !== emailToRemove));
  };

  const handleSendToSeb = async () => {
    if (isOnline) {
      const createContactPromises = addedEmails.map((email) => createSebContact(email));
      await Promise.all(createContactPromises);

      await sendToSeb({
        comment: commentText,
        files: selectedFiles,
        newRecipientEmails: addedEmails,
      });
    } else {
      setOfflineHandover(true);
    }
  };

  return (
    <HandoverContainer as="section" data-testid="neb-supervisor-signoff">
      <Box pb={2}>
        <Typography>{t('nebSupervisorSignoff.complete')}</Typography>
      </Box>
      {isWarning && (
        <TextBox role="alert">
          {deviationsWarning === DeviationsWarning.COMPLIANCE && (
            <Typography>
              {t('handoverSignoffPage.openDeviationsCompliance', {
                openDeviationsComplianceCount,
              })}
            </Typography>
          )}
          {deviationsWarning === DeviationsWarning.BLOCKER && (
            <Typography>
              {t('handoverSignoffPage.openDeviationsBlocker', {
                openDeviationsBlockerCount,
              })}
            </Typography>
          )}
          {deviationsWarning === DeviationsWarning.COMPLIANCE_AND_BLOCKER && (
            <Typography>
              {t('handoverSignoffPage.openDeviationsComplianceAndBlocker', {
                openDeviationsComplianceCount,
                openDeviationsBlockerCount,
              })}
            </Typography>
          )}
          {unansweredQuestionsCount > 0 && (
            <Typography>
              {t('handoverSignoffPage.unansweredQuestions', {
                unansweredQuestionsCount,
              })}
            </Typography>
          )}
        </TextBox>
      )}
      <Box pb={2}>
        <Typography>
          {isHandoverAllowed ? t('nebSupervisorSignoff.canHandOver') : ''}
        </Typography>
      </Box>
      <FormLabel>{t('nebSupervisorSignoff.sebRecipients')}</FormLabel>
      <SebContactsBox>
        {sebContacts.length === 0 && addedEmails.length === 0 && (
          <Typography>{t('nebSupervisorSignoff.addSebRecipientsWarning')}</Typography>
        )}
        {sebContacts.map((contact) => (
          <Typography key={contact.email}>{contact.email}</Typography>
        ))}
        {addedEmails.map((email) => (
          <Box display="flex" key={email}>
            <Typography>{email}</Typography>
            <RemoveButton
              data-testid="remove-email-button"
              onClick={() => handleRemoveEmail(email)}
            >
              <RemoveCircle />
            </RemoveButton>
          </Box>
        ))}
      </SebContactsBox>
      <FormLabel required={sebContacts.length === 0}>
        {t('nebSupervisorSignoff.addSebRecipient')}
      </FormLabel>
      <StyledTextfield
        id="email-input"
        disabled={!isHandoverAllowed}
        error={!!emailAddressError}
        helperText={emailAddressError}
        type="email"
        value={emailAddress}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setEmailAddress(event.target.value)
        }
        placeholder={t('nebSupervisorSignoff.emailAddresses')}
        rows={1}
        inputProps={{ 'data-testid': 'email-input' }}
        InputProps={{
          endAdornment: (
            <IconButton data-testid="add-email-button" onClick={handleAddEmail}>
              <AddCircle htmlColor={'green'} />
            </IconButton>
          ),
        }}
        variant="outlined"
      />
      <label htmlFor="additional-comment-input">
        {t('nebSupervisorSignoff.additonalComments')}
      </label>
      <StyledTextfield
        id="additional-comment-input"
        variant="outlined"
        placeholder={t('nebSupervisorSignoff.typeText')}
        disabled={!isHandoverAllowed}
        value={commentText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCommentText(event.target.value)
        }
        multiline
        rows={4}
        inputProps={{
          'data-testid': 'comment-input',
        }}
      />
      <UploadWidget
        handleUploadButton={handleUploadButton}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        selectedFiles={selectedFiles}
        label={t('handoverSignoffPage.uploadButtonInfoText')}
        disabled={!isHandoverAllowed}
      />
      <ButtonContainer>
        <StyledButton
          variant="contained"
          color="primary"
          disabled={!isHandoverAllowed || !anyEmailPresent}
          onClick={handleSendToSeb}
          data-testid="send-to-seb-button"
        >
          {t('nebSupervisorSignoff.sendToSeb')}
        </StyledButton>
      </ButtonContainer>
      {offlineHandover && (
        <InfoModal
          open={true}
          message={t('connection.offlineHandover')}
          onClose={(): void => setOfflineHandover(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </HandoverContainer>
  );
};

export default NebSupervisorSignoff;
