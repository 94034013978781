import {
  InstallationFrm,
  QuestionSet,
  AnswerSet,
  QuestionSetInfo,
  Installation,
  MergedQuestionSequences,
  MergedQuestions,
  MergedAnswers,
} from '../schemas';

export const mergeData = (installationData: Installation) => {
  const forms = installationData.forms || [];
  const allQuestions: MergedQuestions = initializeMergedQuestions();
  const allAnswers: MergedAnswers = initializeMergedAnswers();
  const allSequences: MergedQuestionSequences = initializeMergedQuestionSequences();
  const allQuestionSetInfo: QuestionSetInfo[] = [];

  forms.forEach((form) => {
    Object.entries(allQuestions).forEach(([key, value]) => {
      const formQuestions = form[key as keyof InstallationFrm] as
        | QuestionSet[]
        | undefined;
      if (formQuestions) {
        value.push(...formQuestions);
      }
    });

    Object.entries(allAnswers).forEach(([key, value]) => {
      const formAnswers = form[key as keyof InstallationFrm] as AnswerSet[] | undefined;
      if (formAnswers) {
        value.push(...formAnswers);
      }
    });

    Object.entries(allSequences).forEach(([key, value]) => {
      const formSequences = form[key as keyof InstallationFrm] as string[] | undefined;
      if (formSequences) {
        (value as string[]).push(...formSequences);
      }
    });

    if (form.questionSetInfo) {
      allQuestionSetInfo.push(...form.questionSetInfo);
    }
  });

  /**
   * Merges questions, answers, and sequences, ensuring uniqueness and moving "HANDOVER" to the end.
   */
  const mergedQuestions = Object.keys(allQuestions).reduce((acc, key) => {
    const questionsArray = Array.from(
      new Map(
        allQuestions[key as keyof MergedQuestions].map((q) => [q.questionSetId, q])
      ).values()
    );
    const handoverIndex = questionsArray.findIndex((q) => q.questionSetId === 'HANDOVER');

    if (handoverIndex !== -1) {
      const handoverQuestion = questionsArray.splice(handoverIndex, 1)[0]; // Remove "HANDOVER" from its current position
      questionsArray.push(handoverQuestion); // Add "HANDOVER" to the end of the array
    }

    acc[key as keyof MergedQuestions] = questionsArray;
    return acc;
  }, {} as MergedQuestions);

  const mergedAnswers = Object.keys(allAnswers).reduce((acc, key) => {
    acc[key as keyof MergedAnswers] = allAnswers[key as keyof MergedAnswers].map(
      (answerSet) => {
        const nonNullAnswers = answerSet.answers.filter(
          (a) => a !== null && a !== undefined
        );
        const uniqueNonNullAnswers = Array.from(
          new Map(nonNullAnswers.map((a) => [a!.gsi1, a])).values()
        );
        const mergedAnswers = answerSet.answers.map((a) => {
          if (a === null) {
            return null;
          }
          const uniqueAnswer = uniqueNonNullAnswers.find((u) => u && u.gsi1 === a.gsi1);
          return uniqueAnswer || null;
        });
        return {
          questionSetId: answerSet.questionSetId,
          answers: mergedAnswers,
        };
      }
    );
    return acc;
  }, {} as MergedAnswers);

  const mergedQuestionSequences = Object.keys(allSequences).reduce((acc, key) => {
    const sequenceArray = Array.from(
      new Set(allSequences[key as keyof MergedQuestionSequences])
    );
    const handoverIndex = sequenceArray.indexOf('HANDOVER');

    if (handoverIndex !== -1) {
      sequenceArray.splice(handoverIndex, 1); // Remove "HANDOVER" from its current position
      sequenceArray.push('HANDOVER'); // Add "HANDOVER" to the end of the array
    }

    acc[key as keyof MergedQuestionSequences] = sequenceArray;
    return acc;
  }, {} as MergedQuestionSequences);

  const mergedQuestionSetInfo = Array.from(
    new Map(allQuestionSetInfo.map((info) => [info.setId, info])).values()
  );

  return {
    mergedQuestions,
    mergedAnswers,
    mergedQuestionSequences,
    mergedQuestionSetInfo,
  };
};

export const initializeMergedQuestions = (): MergedQuestions => {
  return {
    installerQuestions: [],
    testerQuestions: [],
    sebSupervisorQuestions: [],
    nebSupervisorQuestions: [],
  };
};
export const initializeMergedAnswers = (): MergedAnswers => {
  return {
    installerAnswers: [],
    testerAnswers: [],
    sebSupervisorAnswers: [],
    nebSupervisorAnswers: [],
  };
};
export const initializeMergedQuestionSequences = (): MergedQuestionSequences => {
  return {
    installerQuestionSetSequence: [],
    testerQuestionSetSequence: [],
    sebSupervisorQuestionSetSequence: [],
    nebSupervisorQuestionSetSequence: [],
  };
};
