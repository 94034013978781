import React, { JSX, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import QDQuestion from '../QDQuestion';
import Context, { InstallationContext } from '../../context';
import {
  Question,
  QuestionSet,
  Answer,
  AnswerSet,
  QuestionValueType,
  PutAnswerPayload,
} from '../../schemas';
import { CONTAINER_HEIGHT } from '../NetworkInstallation';
import { API_TYPE, post } from '../../helpers/fetch';
import PlumbingSummaryPage from '../../components/PlumbingSummaryPage';
import { skipQuestion } from '../../helpers/question';
import Empty from '../../components/Empty';
import Handover from '../Handover';
import { useUpdateAnswerInContext } from '../../hooks/useUpdateAnswerInContext';
import PreviewHandover from '../../components/PreviewHandover';
import { Box, styled } from '@mui/material';
import PlumbingNominalValueCorrection from '../../components/PlumbingNominalValueCorrection';
import { useGetToken } from '../../hooks/useGetToken';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import { createDeviation, editDeviation } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';

export const PLUMBING_QUESTIONSET_ID = 'TACO01001';

export type QuestionNavigationInformation = {
  questionSetId?: string;
  questionNumber?: number;
  question: Question;
};

enum QuestionNavigationAction {
  FORWARD,
  BACKWARD,
}

const StyledQDQuestion = styled(QDQuestion)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  height: '100%',
  paddingBottom: theme.spacing(5),
}));

export type QuestionsSequenceProps = {
  questionSets?: QuestionSet[];
  answers?: AnswerSet[];
};

const QuestionsSequence = (props: QuestionsSequenceProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [getTokenFunction] = useGetToken();
  const { installationData, networkNumber, updateIsLoading, updateErrorMessage } =
    useContext(Context);
  const { dispatch } = useContext(InstallationContext);
  const [updateAnswerInContext] = useUpdateAnswerInContext();

  const [question, setQuestion] = useState<Question>();
  const [nextQuestion, setNextQuestion] = useState<
    QuestionNavigationInformation | undefined
  >(undefined);
  const [prevQuestion, setPrevQuestion] = useState<
    QuestionNavigationInformation | undefined
  >(undefined);
  const [lastQuestionNavigationAction, setLastQuestionNavigationAction] =
    useState<QuestionNavigationAction | null>(null);

  const { questionSets, answers } = props;

  const searchQuery = new URLSearchParams(location.search);
  const questionSetIdParam = searchQuery.get('questionSetId');
  const questionSequenceNumberParam = searchQuery.get('questionNumber');

  const getNavigationInfo = (
    questions: QuestionSet[]
  ): QuestionNavigationInformation[] => {
    return questions.reduce(
      (navigationInfo: QuestionNavigationInformation[], questionSet: QuestionSet) => {
        const indexes = Array.from(Array(questionSet.questions.length).keys());
        navigationInfo.push(
          ...indexes.map((i: number) => {
            return {
              questionSetId: questionSet.questionSetId,
              questionNumber: i,
              question: questionSet.questions[i],
            };
          })
        );
        return navigationInfo;
      },
      []
    );
  };

  const getQuestionOrRedirect = useCallback(
    (
      questionSets: QuestionSet[]
    ): {
      isRedirected: boolean;
      question?: Question;
      prev?: QuestionNavigationInformation;
      next?: QuestionNavigationInformation;
    } => {
      if (questionSetIdParam && questionSequenceNumberParam) {
        const questionSet = questionSets.find(
          (q) => q.questionSetId === questionSetIdParam
        );
        if (questionSet) {
          const questionNavigationInfo = getNavigationInfo(questionSets);

          const indexForCurrentQuestion = questionNavigationInfo.findIndex(
            (x) =>
              x.questionNumber === Number(questionSequenceNumberParam) &&
              x.questionSetId === questionSetIdParam
          );
          const nextQuestion = questionNavigationInfo[indexForCurrentQuestion + 1];
          const previousQuestion = questionNavigationInfo[indexForCurrentQuestion - 1];
          return {
            question: questionSet.questions[Number(questionSequenceNumberParam)],
            isRedirected: false,
            prev: previousQuestion,
            next: nextQuestion,
          };
        }
      }
      if (!questionSequenceNumberParam && questionSetIdParam) {
        navigate(`?questionSetId=${questionSetIdParam}&questionNumber=0`);

        return {
          isRedirected: true,
        };
      }

      return { isRedirected: false };
    },
    [location, history]
  );

  useEffect(() => {
    if (answers && questionSets) {
      const { question, isRedirected, prev, next } = getQuestionOrRedirect(questionSets);
      if (isRedirected) {
        return;
      }
      if (question) {
        setPrevQuestion(prev);
        setQuestion(question);
        setNextQuestion(next);
      }
    }
  }, [getQuestionOrRedirect, setPrevQuestion, setQuestion, setNextQuestion]);

  // To check the condition of the question and decide to skip or not
  useEffect(() => {
    if (question && questionSets && answers) {
      const { condition } = question;

      let shouldSkipQuestion = skipQuestion(condition, answers);
      if (!shouldSkipQuestion) return;

      const questionNavigationInfo = getNavigationInfo(questionSets);

      let indexForCurrentQuestion = questionNavigationInfo.findIndex(
        (question) =>
          question.questionNumber === Number(questionSequenceNumberParam) &&
          question.questionSetId === questionSetIdParam
      );

      let newQuestionToShow = null;

      while (
        shouldSkipQuestion &&
        indexForCurrentQuestion >= 0 &&
        indexForCurrentQuestion < questionNavigationInfo.length
      ) {
        const checkingQuestion =
          lastQuestionNavigationAction === QuestionNavigationAction.FORWARD
            ? questionNavigationInfo[++indexForCurrentQuestion]
            : questionNavigationInfo[--indexForCurrentQuestion];

        shouldSkipQuestion = skipQuestion(checkingQuestion.question.condition, answers);
        if (!shouldSkipQuestion) newQuestionToShow = checkingQuestion;
      }

      if (newQuestionToShow) {
        navigate(
          `?questionSetId=${newQuestionToShow.questionSetId}&questionNumber=${newQuestionToShow.questionNumber}`
        );
      }
    }
  }, [question]);

  const handleOnQuestionNavigate = async (
    originalAnswer: Answer | undefined | null,
    newAnswer: PutAnswerPayload,
    goesForward: boolean
  ) => {
    try {
      const shouldSubmitAnswer =
        (!originalAnswer && newAnswer.value !== null) ||
        (originalAnswer && originalAnswer.value !== newAnswer.value);

      if (shouldSubmitAnswer) {
        const accessToken = await getTokenFunction();

        const submittedAnswer = await post(
          `v1/installations/${networkNumber}/answers/${questionSetIdParam}/${Number(
            questionSequenceNumberParam
          )}`,
          accessToken,
          API_TYPE.APPLICATION,
          newAnswer
        );

        updateAnswerInContext(
          submittedAnswer,
          questionSetIdParam,
          Number(questionSequenceNumberParam)
        );
      }
      if (goesForward && nextQuestion) {
        setLastQuestionNavigationAction(QuestionNavigationAction.FORWARD);
        navigate(
          `?questionSetId=${nextQuestion.questionSetId}&questionNumber=${nextQuestion.questionNumber}`
        );
      } else if (!goesForward && prevQuestion) {
        setLastQuestionNavigationAction(QuestionNavigationAction.BACKWARD);
        navigate(
          `?questionSetId=${prevQuestion.questionSetId}&questionNumber=${prevQuestion.questionNumber}`
        );
      }
    } catch (e) {
      console.error('Error when submitting number question, error:', e);
    }
  };

  const answer = answers?.find(
    (answerSet) => answerSet.questionSetId === questionSetIdParam
  )?.answers[Number(questionSequenceNumberParam)];
  const showLaserPlumbingSummary =
    questionSetIdParam === PLUMBING_QUESTIONSET_ID &&
    question?.tag === 'tag_plumbing_summary' &&
    question.valueType?.toLowerCase() === QuestionValueType.OK;

  const showPlumbingNominalValueCorrection =
    questionSetIdParam === PLUMBING_QUESTIONSET_ID &&
    question?.tag &&
    question.tag.includes('tag_plumbing_nominal') &&
    question.valueType?.toLowerCase() === QuestionValueType.OK;
  const onNavigateFromSummary = (toNextQuestion: boolean) => {
    handleOnQuestionNavigate(
      answer,
      {
        value: answer?.value || null,
        tag: question?.tag || '',
        position: question?.position || '',
        timestamp: Date.now(),
      },
      toNextQuestion
    );
  };

  const onSaveAnswer = async (
    newAnswer: PutAnswerPayload,
    questionSequenceNumber: number
  ) => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();

    try {
      const updatedAnswer = await post(
        `v1/installations/${networkNumber}/answers/${questionSetIdParam}/${questionSequenceNumberParam}`,
        accessToken,
        API_TYPE.APPLICATION,
        newAnswer
      );
      updateAnswerInContext(updatedAnswer, questionSetIdParam, questionSequenceNumber);
    } catch (e) {
      console.error(e);
    }
    updateIsLoading(false);
  };

  const handleCreateDeviation = async (deviation: CreateDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);

    try {
      const createdDeviation = await createDeviation(
        accessToken,
        networkNumber,
        deviation
      );

      dispatch({
        type: InstallationActionName.ADD_DEVIATION,
        deviation: createdDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('qdPage.cannotCreateDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const handleEditDeviation = async (deviation: EditDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);
    try {
      const updatedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: updatedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('qdPage.cannotEditDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const renderQuestionData = (questionToRender: Question) => {
    let onNavigateForwardFromHandover;
    if (nextQuestion) {
      onNavigateForwardFromHandover = () => {
        setLastQuestionNavigationAction(QuestionNavigationAction.FORWARD);
        navigate(
          `?questionSetId=${nextQuestion.questionSetId}&questionNumber=${nextQuestion.questionNumber}`
        );
      };
    }
    if (questionSetIdParam === 'PREVIEW_HANDOVER') {
      return <PreviewHandover onNavigateForward={onNavigateForwardFromHandover} />;
    }
    if (questionSetIdParam === 'HANDOVER' && questionSequenceNumberParam) {
      return (
        <Handover
          onNavigateBackward={() => {
            setLastQuestionNavigationAction(QuestionNavigationAction.BACKWARD);
            navigate(
              `?questionSetId=${prevQuestion?.questionSetId}&questionNumber=${prevQuestion?.questionNumber}`
            );
          }}
          onNavigateForward={onNavigateForwardFromHandover}
          networkNumber={networkNumber}
          questionSequenceNumber={questionSequenceNumberParam}
        />
      );
    }
    if (showPlumbingNominalValueCorrection && installationData) {
      return (
        <PlumbingNominalValueCorrection
          installationData={installationData}
          onNavigateBack={() => onNavigateFromSummary(false)}
          onClickForward={() => onNavigateFromSummary(true)}
          imageLink={questionToRender.imageLink}
        />
      );
    }
    if (showLaserPlumbingSummary && installationData) {
      return (
        <PlumbingSummaryPage
          imageLink={questionToRender.imageLink}
          installationData={installationData}
          onNavigateBack={() => onNavigateFromSummary(false)}
          onCompletePlumbing={() => onNavigateFromSummary(true)}
        />
      );
    }
    return (
      <StyledQDQuestion
        question={questionToRender}
        originalAnswer={answer}
        hasNextQuestion={nextQuestion !== undefined}
        hasPrevQuestion={prevQuestion !== undefined}
        onNavigate={handleOnQuestionNavigate}
        questionSetId={`${questionSetIdParam}`}
        saveAnswer={(newAnswer) =>
          onSaveAnswer(newAnswer, Number(questionSequenceNumberParam))
        }
        onCreateDeviation={handleCreateDeviation}
        onEditDeviation={handleEditDeviation}
      />
    );
  };

  return (
    <Box height={CONTAINER_HEIGHT} mt={11} overflow="auto" data-testid="qd-main-content">
      {question ? (
        renderQuestionData(question)
      ) : (
        <Empty displayIcon={false} message={t('qdPage.noQuestion')} />
      )}
    </Box>
  );
};

export default QuestionsSequence;
