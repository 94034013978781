import React, { JSX } from 'react';
import { Backdrop, CircularProgress, SxProps, Theme } from '@mui/material';

export interface LoadingProps {
  invisible?: boolean;
  backDropSx?: SxProps<Theme>;
}

const Loading = (props: LoadingProps): JSX.Element => {
  return (
    <Backdrop
      open={true}
      invisible={props.invisible}
      data-testid="app-loading-spinner"
      sx={props.backDropSx}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loading;
