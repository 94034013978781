import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { Step, StepButton, StepConnector, Stepper, styled } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import {
  AnswerSet,
  DeviationStatus,
  DeviationVariation,
  QuestionSet,
} from '../../schemas';
import { InstallationContext } from '../../context';
import { isQuestionSetAnswered, isQuestionSetStarted } from '../../helpers/question';
import { theme } from '@konecorp/ui-library';

const getStepButtonSx = (state: NavigationStepperState) => {
  const baseStyles = {
    '& .MuiStepIcon-text': { display: 'none' },
    '& .MuiSvgIcon-root': {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5),
      '& path': { display: 'none' },
    },
    '& .MuiStepLabel-label': {
      width: theme.spacing(12),
      marginTop: theme.spacing(1),
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 1,
    },
  };

  switch (state) {
    case NavigationStepperState.CURRENT:
      return {
        ...baseStyles,
        '& .MuiStepLabel-iconContainer': {
          // border: '2px solid',
          borderRadius: '50%',
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
        },
        '& circle': { fill: theme.palette.primary.main },
        '& .MuiStepLabel-label.Mui-active': {
          color: theme.palette.primary.main, // ✅ Change label color to primary blue
          fontWeight: 500, // Maintain font weight
          textTransform: 'uppercase', // Optional: Make it uppercase like in the image
        },
      };

    case NavigationStepperState.COMPLETED:
      return {
        ...baseStyles,
        '& .MuiStepIcon-root': {
          color: `${green[500]} !important`, // ✅ Ensure the circle is green
        },
        '& .MuiStepIcon-completed': {
          color: `${green[500]} !important`, // ✅ Override default MUI styling
        },
        '& .MuiSvgIcon-root': {
          color: `${green[500]} !important`, // ✅ Ensure the icon stays green
          height: theme.spacing(2),
          width: theme.spacing(2),
        },
        '& .MuiStepIcon-root .MuiStepIcon-text': {
          display: 'none !important',
        },
        '& circle': { fill: `${green[500]} !important` },
        '& .MuiStepLabel-iconContainer': { border: 'none' },
        '& .MuiStepLabel-label.Mui-completed': {
          marginTop: '8px',
          color: theme.palette.primary.main, // ✅ Change label color to primary blue
          fontWeight: 500, // Maintain font weight
          textTransform: 'uppercase', // Optional: Make it uppercase like in the image
        },
      };

    case NavigationStepperState.COMPLETED_WITH_REJECTIONS:
      return {
        ...baseStyles,
        '& .MuiStepIcon-root': {
          color: `${yellow[700]} !important`, // ✅ Ensure the circle is green
        },
        '& .MuiStepIcon-completed': {
          color: `${yellow[700]} !important`, // ✅ Override default MUI styling
        },
        '& .MuiSvgIcon-root': {
          color: `${yellow[700]} !important`, // ✅ Ensure the icon stays green
          height: theme.spacing(2),
          width: theme.spacing(2),
        },
        '& .MuiStepIcon-root .MuiStepIcon-text': {
          display: 'none !important',
        },
        '& circle': { fill: `${yellow[700]} !important` },
        '& .MuiStepLabel-iconContainer': { border: 'none' },
        '& .MuiStepLabel-label.Mui-completed': {
          marginTop: '8px',
          color: theme.palette.primary.main, // ✅ Change label color to primary blue
          fontWeight: 500, // Maintain font weight
          textTransform: 'uppercase', // Optional: Make it uppercase like in the image
        },
      };

    case NavigationStepperState.INCOMPLETE:
      return {
        ...baseStyles,
        '& .MuiStepLabel-iconContainer': {
          // border: '1px solid',
          borderRadius: '50%',
          borderColor: red[500],
          backgroundColor: red[500],
        },
        '& circle': { fill: red[500] },
      };

    default:
      return {
        ...baseStyles,
        '& .MuiStepLabel-iconContainer': {
          border: '1px solid',
          borderRadius: '50%',
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default,
        },
        '& circle': { fill: theme.palette.background.default },
      };
  }
};

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  paddingTop: '18px',
  position: 'absolute',
  overflowX: 'auto',
  overflowY: 'hidden',
  marginTop: '10px',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  zIndex: 1,
  height: theme.spacing(10.5),
  boxShadow: '0 1px 5px rgba(0,0,0,.3)',
}));

enum NavigationStepperState {
  NOT_STARTED = 'Default',
  CURRENT = 'Active',
  COMPLETED = 'Completed',
  COMPLETED_WITH_REJECTIONS = 'CompletedWithRejections',
  INCOMPLETE = 'Incomplete',
}

export type NavigationStep = {
  displayText?: string;
  id: string;
};

export type NavigationStepperProps = {
  navigationSteps: NavigationStep[];
  activeStep: number; // index on the navigationSteps array
  questions?: QuestionSet[];
  answers?: AnswerSet[];
  onClickStep: (selectedStep: NavigationStep) => void;
};

const CustomStepperConnectorLine = styled(StepConnector)(({ theme }) => ({
  '&.MuiStepConnector-alternativeLabel': {
    top: 5,
    left: 'calc(-50% + 6px)',
    right: 'calc(50% + 6px)',
  },
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Default color
    borderTopWidth: 1, // Thin line by default
    borderRadius: 1,
  },
  '&.Mui-completed .MuiStepConnector-line, &.Mui-active .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Completed and Active step color
    borderTopWidth: 4, // Bold line for completed steps
  },
  '&.Mui-completed ~ .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Future steps should remain thin
    borderTopWidth: 1,
  },
}));

const NavigationStepper = (
  props: PropsWithChildren<NavigationStepperProps>
): JSX.Element => {
  const { navigationSteps, activeStep, questions, answers, onClickStep } = props;
  const { deviations } = useContext(InstallationContext);

  const stepRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const getStepKey = (stepIndex: number): string => {
    if (navigationSteps[stepIndex]) {
      const { id } = navigationSteps[stepIndex];
      return `questionset-step-${id}`;
    }
    return '';
  };

  const onChangeStep = (step: NavigationStep) => {
    scrollNavigationStepperToActiveStep();
    onClickStep(step);
  };

  const scrollNavigationStepperToActiveStep = () => {
    if (stepRef && stepRef.current && navigationSteps) {
      const uniqueQuestionsetId = getStepKey(activeStep);
      const activeStepElement = stepRef.current.querySelector(
        `div[data-testid="${uniqueQuestionsetId}"]`
      );
      if (activeStepElement && activeStepElement.scrollIntoView)
        /* there is a bug with chromium implementation, ex: Google Chrome for scrollIntoView
           wrapping it in a setTimeout is a walk around
           https://github.com/facebook/react/issues/23396#issuecomment-1557694564
        */
        setTimeout(() => {
          activeStepElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 200);
    }
  };
  useEffect(scrollNavigationStepperToActiveStep, [activeStep]);

  const getNavigationStepperState = (setId: string, index: number) => {
    if (index === activeStep) return NavigationStepperState.CURRENT;

    if (setId === 'PREVIEW_HANDOVER') return NavigationStepperState.COMPLETED;

    if (setId === 'HANDOVER' || !questions || !answers)
      return NavigationStepperState.NOT_STARTED;

    if (isQuestionSetAnswered(setId, questions, answers)) {
      const openRejectedDeviations = deviations.filter(
        ({ questionSetId, status, variation }) =>
          questionSetId === setId &&
          status === DeviationStatus.OPEN &&
          variation === DeviationVariation.REJECT
      );

      if (openRejectedDeviations.length > 0) {
        return NavigationStepperState.COMPLETED_WITH_REJECTIONS;
      }
      return NavigationStepperState.COMPLETED;
    }

    if (isQuestionSetStarted(setId, questions, answers)) {
      return NavigationStepperState.INCOMPLETE;
    }

    return NavigationStepperState.NOT_STARTED;
  };

  return (
    <>
      <Root data-testid="navigation-stepper" ref={stepRef}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomStepperConnectorLine />}
        >
          {navigationSteps.map((step: NavigationStep, index: number) => {
            const state = getNavigationStepperState(step.id, index);

            // const completedStates = [
            //   NavigationStepperState.COMPLETED,
            //   NavigationStepperState.COMPLETED_WITH_REJECTIONS,
            // ];
            // const isComplete = completedStates.includes(state);
            const scrollKey = getStepKey(index);
            const { displayText, id } = step;
            return (
              <Step
                completed={index <= activeStep}
                key={scrollKey}
                data-testid={scrollKey}
              >
                <StepButton
                  data-testid={`${scrollKey}-button`}
                  onClick={() => onChangeStep(step)}
                  sx={getStepButtonSx(state)}
                >
                  {displayText || id}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Root>
    </>
  );
};

NavigationStepper.defaultProps = {
  navigationSteps: [],
  activeStep: 0,
};

export default NavigationStepper;
