import React, { JSX, PropsWithChildren } from 'react';
import { kebabCase } from 'lodash';
import { Box, ButtonBase, styled, Typography, TypographyProps } from '@mui/material';
import { theme, ArrowRight } from '@konecorp/ui-library';
import backgroundImage from './background.jpg';

export interface WidgetButtonProps {
  icon: JSX.Element;
  title: string;
  countLabel?: number;
  action?: () => void;
}

export interface WidgetGroupProps {
  name: string;
  buttons: WidgetButtonProps[];
}

export interface WidgetProps {
  widgetGroups: WidgetGroupProps[];
}

const StyledButton = styled(ButtonBase)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  border: '1px solid grey',
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  width: '100%',
}));

const Icon = styled('div')(() => ({
  '& path': {
    fill: theme.palette.common.white,
  },
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const Amount = styled('div')(() => ({
  display: 'flex',
  marginLeft: 'auto',
  paddingRight: 3,
}));

const StyledBox = styled(Box)(() => ({
  paddingBottom: theme.spacing(1),
  fontSize: theme.spacing(2),
}));

const ArrowRightIcon = styled('div')(() => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  marginLeft: theme.spacing(10),
  '& svg': {
    fill: theme.palette.text.secondary,
  },
  padding: 3,
}));

const StyledTypography = styled(Typography)<TypographyProps>(() => ({
  padding: theme.spacing(3),
  width: '100%',
  color: theme.palette.primary.contrastText,
  textShadow: `1px 1px ${theme.palette.text.secondary}`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% 16%',
  backgroundImage: `linear-gradient(
        rgba(0, 89, 148, 0.6),
        rgba(0, 89, 148, 0.6)
      ),url(${backgroundImage})`,
}));
const Widget = (props: PropsWithChildren<WidgetProps>): JSX.Element => {
  const Button = (props: { button: WidgetButtonProps }): JSX.Element => {
    const { button } = props;
    const id = kebabCase(button.title);

    return (
      <StyledButton data-testid={`widget-button-${id}`} onClick={button.action}>
        <Typography component="div" alignItems="center" display="flex" width="100%">
          <Icon>{button.icon}</Icon>
          <div style={{ color: theme.palette.common.white }}>{button.title}</div>
          <Amount aria-label={`widget-amount-${id}`}>{button.countLabel}</Amount>
          {button.action && (
            <ArrowRightIcon>
              <ArrowRight />
            </ArrowRightIcon>
          )}
        </Typography>
      </StyledButton>
    );
  };

  return (
    <StyledTypography display="block" gutterBottom>
      {props.widgetGroups.map((group) => (
        <StyledBox key={kebabCase(group.name)}>
          <Typography variant="h6" component="h6">
            {group.name}
          </Typography>
          {group.buttons.map((button, index) => (
            <Button
              button={button}
              key={`widget-button-${kebabCase(button.title)}-${index}}`}
            />
          ))}
        </StyledBox>
      ))}
    </StyledTypography>
  );
};

export default Widget;
