import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
import { ContractType, Contract } from '../../schemas';
import { theme } from '@konecorp/ui-library';

export type ContractFormProps = {
  contractToEdit?: Contract;
  onClose: () => void;
  onSubmit: (state: Contract) => Promise<void>;
  resetForm?: () => void; // Add this line to accept resetForm as a prop
};

const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textTransform: 'uppercase',
  borderBottom: '1px solid lightgrey',
}));

const FieldsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 5),
  borderBottom: '1px solid lightgrey',
  '& .MuiFormControl-root': {
    margin: theme.spacing(1, 0),
  },
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 4, 2),
  '& .MuiButton-root:first-child': {
    marginRight: theme.spacing(2),
  },
}));

const ContractForm = ({
  contractToEdit,
  onClose,
  onSubmit,
}: ContractFormProps): JSX.Element => {
  const { t } = useTranslation();
  // Create a ref to track if the component is mounted
  const isMounted = useRef(true);

  // Cleanup: set the ref to false when the component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const initialContract: Contract = {
    sk: '',
    title:
      contractToEdit?.title !== undefined && contractToEdit?.title !== ''
        ? contractToEdit.title
        : contractToEdit?.contract
        ? ContractType.CONTRACT
        : '',
    url: contractToEdit?.value ?? '',
    contract: true,
    type: 'link',
  };

  const validationSchema = yup.object({
    title: yup.string().trim().required(t('contractForm.requiredHelperText')),
    url: yup
      .string()
      .trim()
      .required(t('contractForm.requiredHelperText'))
      .matches(
        /^https:\/\/[a-zA-Z0-9-]+/,
        t('contractForm.invalidUrl') // Custom message for invalid URL format
      ),
  });

  const handleSubmit = async (values: Contract, helpers: FormikHelpers<Contract>) => {
    try {
      await onSubmit({
        ...values,
        sk: contractToEdit?.sk,
      });
      // Only reset and close the form if the component is still mounted
      if (isMounted.current) {
        helpers.resetForm();
        onClose();
      }
    } catch (error) {
      console.error('Error submitting contract:', error);
    }
  };

  const formik = useFormik({
    initialValues: contractToEdit ?? initialContract,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}
    >
      <Title align="center">
        {contractToEdit?.contract
          ? contractToEdit?.sk
            ? t('contractForm.editContract')
            : t('contractForm.addContract')
          : contractToEdit?.sk
          ? t('contractForm.editLink')
          : t('contractForm.addLink')}
      </Title>
      <FieldsContainer display="flex" flexDirection="column">
        <Typography paddingLeft={theme.spacing(1)}>Title</Typography>
        <TextField
          inputProps={{
            'data-testid': 'contract-title',
          }}
          fullWidth
          variant="outlined"
          margin="dense"
          type="text"
          name="title"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          className="no-asterisk"
          placeholder={!contractToEdit?.contract ? 'Enter title for link' : ''}
        />
        <Typography paddingLeft={theme.spacing(1)}>URL</Typography>
        <TextField
          inputProps={{
            'data-testid': 'contract-url',
          }}
          fullWidth
          variant="outlined"
          margin="dense"
          type="text"
          name="url"
          value={formik.values.url || formik.values.value || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.url && Boolean(formik.errors.url)}
          helperText={formik.touched.url && formik.errors.url}
          placeholder="https://"
        />
      </FieldsContainer>
      <ButtonGroup
        display="flex"
        flexDirection="row"
        flex="1"
        justifyItems="space-between"
      >
        <Button fullWidth variant="outlined" onClick={onClose} color="primary">
          {t('contractForm.cancel')}
        </Button>
        <Button
          fullWidth
          disableElevation
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.isValid || !formik.dirty}
          data-testid="contract-form-save-button"
        >
          {t('contractForm.save')}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ContractForm;
