import React, { useState, useContext, PropsWithChildren, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { theme } from '@konecorp/ui-library';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  Installation,
  PlumbingMeasurementCategory,
  PlumbingNominalQuestion,
} from '../../schemas';
import Context from '../../context';
import { API_TYPE, put, get } from '../../helpers/fetch';
import NavigationButtons from '../NavigationButtons';
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow,
} from '../PlumbingSummaryTable';
import {
  getQuestionListWithUpdatedActualValue,
  extractPlumbingQuestionFromInstallationData,
  getPlumbingMeasurements,
  getDefaultNominalValues,
} from '../../helpers/plumbingSummary';
import { addHandoverStep } from '../../helpers/question';
import QDQuestionImage from '../QDQuestionImage';
import Empty from '../../components/Empty';
import { useGetToken } from '../../hooks/useGetToken';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';
import { updateInstallationDataWithMerge } from '../../helpers/updateMergedData';

const StyledTableContainer = styled(TableContainer)(() => ({
  maxWidth: '100%',
}));

const StyledTable = styled(Table)(() => ({
  maxWidth: '100%',
}));

const TableCellFirstColumn = styled(StyledTableCell)(() => ({
  color: theme.palette.secondary.main,
  width: theme.spacing(10),
  borderBottom: theme.spacing(0),
}));

const StyledTextfield = styled(TextField)(() => ({
  width: '100px',
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1),
  },
}));

const TableCellInput = styled(StyledTableCell)(() => ({
  padding: 0,
}));

const ImageContainer = styled(Box)(() => ({
  minHeight: theme.spacing(35),
}));

export type PlumbingNominalValueCorrectionProps = {
  onClickForward: () => void;
  onNavigateBack: () => void;
  installationData: Installation;
  imageLink: string | null;
};

export type NominalValues = {
  A1ActualValue: number;
  A2ActualValue: number;
  A3ActualValue: number;
  A4ActualValue: number;
  A5ActualValue?: number;
  A6ActualValue?: number;
};

enum NominalView {
  Table,
  Image,
}

type TabPanelProps = {
  children: JSX.Element | string;
  currentSelectedTabValue: NominalView;
  tabName: NominalView;
};

const CustomTab = styled(Tab)`
  text-transform: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)}px;
  font-size: ${({ theme }) => theme.typography.fontSize * 1.6}px;
`;

const TabPanel = (props: PropsWithChildren<TabPanelProps>): JSX.Element => {
  const { children, currentSelectedTabValue, tabName } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentSelectedTabValue !== tabName}
      id={`simple-tabpanel-${tabName}`}
      aria-labelledby={`simple-tab-${tabName}`}
    >
      {currentSelectedTabValue === tabName && <Box pt={0.5}>{children}</Box>}
    </div>
  );
};

const getTabAriaControls = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const PlumbingNominalValueCorrection = (
  props: PlumbingNominalValueCorrectionProps
): JSX.Element => {
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();
  const [isReadOnlyMode] = useIfReadOnly();

  const { networkNumber, updateInstallationData, updateIsLoading } = useContext(Context);
  const { onNavigateBack, onClickForward, installationData, imageLink } = props;
  const plumbingMeasurementPoints: PlumbingMeasurementCategory =
    getPlumbingMeasurements(installationData);

  const [nominalValues, setNominalValues] = useState<NominalValues>(
    getDefaultNominalValues(plumbingMeasurementPoints)
  );
  const [currentSelectedTab, setCurrentSelectedTab] = useState<NominalView>(
    NominalView.Table
  );

  const onUpdateNominalValues = async (
    plumbingQuestions: PlumbingNominalQuestion[][]
  ) => {
    const changedQuestionsAsPayload = getQuestionListWithUpdatedActualValue(
      nominalValues,
      plumbingQuestions
    ).map((question) => ({
      questionSetId: question.questionSetId,
      questionSequenceNumber: Number(question.questionSequenceNumber),
      actualValue: Number(question.actualValue),
    }));

    updateIsLoading(true);
    try {
      if (changedQuestionsAsPayload.length) {
        const accessToken = await getTokenFunction();
        await put(
          `v1/installations/${networkNumber}/questions`,
          accessToken,
          API_TYPE.APPLICATION,
          { questions: changedQuestionsAsPayload }
        );
        const data: Installation | null = await get(
          `v1/installations/${networkNumber}`,
          accessToken
        );
        const installationDataWithHandover = data ? addHandoverStep(data) : null;
        updateInstallationDataWithMerge(
          installationDataWithHandover,
          updateInstallationData
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      updateIsLoading(false);
    }
    onClickForward();
  };

  const tableHeaders = [
    '',
    t('plumbingSummary.currentValue'),
    t('plumbingSummary.correction'),
  ];

  const onChangeInput = (indexToUpdate: number, newValue: number) => {
    const keyToUpdate = Object.keys(nominalValues).find(
      (_, index) => index === indexToUpdate
    );
    if (keyToUpdate) {
      const newNominalValues = {
        ...nominalValues,
        [keyToUpdate]: newValue,
      };
      setNominalValues(newNominalValues);
    } else {
      console.error(
        'Error while updating nominal values, indexing issue in table input fields'
      );
    }
  };

  const getNominalValue = (index: number) => {
    const value = Object.values(nominalValues).find((_, i) => i === index);
    return value ? value : '';
  };
  const plumbingQuestions = extractPlumbingQuestionFromInstallationData(
    installationData,
    plumbingMeasurementPoints.length
  );

  // checking first floor measurement to get first 6 readings (A1, A2....A6)
  const firstFloorQuestions = plumbingQuestions[0];
  const measurementCount = firstFloorQuestions?.length;

  if (measurementCount === 0) {
    console.error('Invalid installation data, floor (position) information missing');
    return <Box>{t('qdPage.noQuestion')}</Box>;
  }

  return (
    <Box
      data-testid="plumbing-nominal-values"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      {/* when user hasn't choose wire or not, we don't have/know a set of questions to display a nominal table, hence display an error message */}
      {plumbingQuestions.length === 0 && (
        <Empty displayIcon={false} message={t('plumbingSummary.noLaserOrWireAnswer')} />
      )}
      {/* here we have a set of question to display a nominal table */}
      {plumbingQuestions.length > 0 && (
        <Box flexGrow={1}>
          <Tabs
            TabIndicatorProps={{ style: { background: '#0071B9' } }}
            value={currentSelectedTab}
            onChange={(_, newValue: React.SetStateAction<NominalView>) => {
              setCurrentSelectedTab(newValue);
            }}
            aria-label="installation summary tabs"
          >
            <CustomTab
              data-testid="tab-table"
              label={t('plumbingSummary.titleNominal')}
              {...getTabAriaControls(NominalView.Table)}
            />
            <CustomTab
              data-testid="tab-image"
              label={t('plumbingSummary.titlePositions')}
              {...getTabAriaControls(NominalView.Image)}
            />
          </Tabs>

          <TabPanel
            currentSelectedTabValue={currentSelectedTab}
            tabName={NominalView.Table}
          >
            <Box>
              <StyledTableContainer data-testid="plumbing-nominal-values-table">
                <StyledTable aria-label="nominal value table">
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((header, index) => (
                        <StyledTableHeadCell
                          key={`nominal-table-th-${index}`}
                          align="center"
                        >
                          {header}
                        </StyledTableHeadCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {firstFloorQuestions.map((question, index) => {
                      const nominalValueKey = plumbingMeasurementPoints[index] || '';
                      return (
                        <StyledTableRow key={`plumbing-nominal-row-${index}`}>
                          <>
                            <TableCellFirstColumn
                              key={'nominal-table-cell-0'}
                              align="center"
                            >
                              {nominalValueKey}
                            </TableCellFirstColumn>
                            <StyledTableCell
                              key={`nominal-table-actualValue-cell-${index}`}
                              align="center"
                            >
                              {question.actualValue}
                            </StyledTableCell>
                            <TableCellInput
                              key={`nominal-table-input-cell-${index}`}
                              align="center"
                            >
                              <StyledTextfield
                                id="standard-multiline-static"
                                placeholder={t('plumbingSummary.inputPlaceholder')}
                                value={getNominalValue(index)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  onChangeInput(index, Number(event.target.value))
                                }
                                type="number"
                                variant="outlined"
                                inputProps={{
                                  'data-testid': `nominal-input-${index}`,
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                                disabled={isReadOnlyMode}
                              />
                            </TableCellInput>
                          </>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Box
                borderColor={theme.palette.secondary.main}
                borderRadius={1}
                m={2}
                mt={3}
                p={2}
                border={1}
              >
                <Typography>{t('plumbingSummary.nominalText')}</Typography>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel
            currentSelectedTabValue={currentSelectedTab}
            tabName={NominalView.Image}
          >
            <ImageContainer
              mb={4}
              data-testid="plumbing-laser-image"
              display="flex"
              justifyContent="center"
            >
              <QDQuestionImage imageLink={imageLink} />
            </ImageContainer>
          </TabPanel>
        </Box>
      )}

      <Box justifyContent="center" p={3} mt="auto" pb={11}>
        <NavigationButtons
          onClickBackward={onNavigateBack}
          onClickForward={() => onUpdateNominalValues(plumbingQuestions)}
          backwardButtonText={t('plumbingSummary.backButton')}
          forwardButtonText={t('plumbingSummary.confirmButton')}
        />
      </Box>
    </Box>
  );
};

export default PlumbingNominalValueCorrection;
