import React, { JSX, useContext, useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Card,
  CardContent,
  TableRow,
  TableCell,
  TableBody,
  Table,
  styled,
  Grid2,
} from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { theme } from '@konecorp/ui-library';
import {
  ActivityDifferentiator,
  Assignment,
  InstallationSyncData,
  SubcontractorRecord,
} from '../../schemas';
import { useTranslation } from 'react-i18next';
import Context from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import { API_TYPE, get, put } from '../../helpers/fetch';
import { Installation } from '../../schemas';
import InstallationPlanQuestionsList from '../../containers/InstallationPlanQuestionsList';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { formatDate } from '../../helpers/formating';
import { updateInstallationDataWithMerge } from '../../helpers/updateMergedData';
import {
  deleteIndexedDBObject,
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
} from '../../helpers/indexedDB';
import { DeviationToSync, manualSyncFromUI } from '../../helpers/deviationOffline';
import dayjs from 'dayjs';
import { PickerWithButtonField } from '../DatePickerWithIcon';

const StyledTableCell = styled(TableCell)(() => ({
  color: theme.palette.primary.main,
  paddingTop: theme.spacing(4),
  textTransform: 'uppercase',
}));

const StyledGrid = styled(Grid2)(() => ({
  margin: theme.spacing(5),
  maxWidth: '100vw',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const InstallationPlanTasks = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { networkNumber } = useParams<Record<'networkNumber', string>>();
  const [currentUserRole] = useGetCurrentUserRole();
  const [isSubcontractor] = useIfSubcontractor();
  const [getTokenFunction] = useGetToken();
  const { installationData, updateInstallationData, updateIsLoading } =
    useContext(Context);
  const location = useLocation();
  const [inspectDate, setInspectDate] = useState(dayjs());
  const isInstallationPlanPath = location.pathname.includes('installation-plan');
  async function syncData(networkNumber: string, accessToken: string) {
    const isMounted = true;
    try {
      const syncDataKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.SYNC_DATA
      );

      const deviationToSyncKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
      );

      const syncDataFromIndexedDb = await getIndexedDBObject<InstallationSyncData>(
        syncDataKey
      );

      const deviationSyncDataFromIndexedDB = await getIndexedDBObject<DeviationToSync[]>(
        deviationToSyncKey
      );
      if (syncDataFromIndexedDb) {
        await put(
          `v1/installations/${networkNumber}/sync`,
          accessToken,
          API_TYPE.APPLICATION,
          { ...syncDataFromIndexedDb, currentUserRole }
        );
      }
      if (deviationSyncDataFromIndexedDB?.length) {
        await manualSyncFromUI(networkNumber, accessToken);
      }
    } catch (e) {
      console.error('Error while navigating: ', e);
    } finally {
      if (isMounted) {
        await deleteIndexedDBObject(
          generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
        );
        await deleteIndexedDBObject(
          generateIndexedDBKey(
            networkNumber,
            storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
          )
        );
      }
    }
  }

  const getSubcontractors = (
    role: ActivityDifferentiator,
    subcontractorRecords?: SubcontractorRecord[]
  ) => {
    const subcontractors = subcontractorRecords?.filter(
      (subcontractor) => subcontractor.activityDifferentiator === role
    );
    return subcontractors;
  };

  const getAssigees = (role: ActivityDifferentiator, assignees?: Assignment[]) => {
    const currentAssignees = assignees?.filter(
      (assignee) => assignee.activityDifferentiator === role
    );
    return currentAssignees;
  };

  const getStartAndTargetDate = (
    subcontractorRecords: SubcontractorRecord[],
    role: ActivityDifferentiator,
    assignees: Assignment[]
  ): (string | null)[] => {
    const subcontractors = getSubcontractors(role, subcontractorRecords);
    const currentAssignees = getAssigees(role, assignees);

    // Combine the two arrays
    const combinedArray = [
      ...(subcontractors || []).map((subcontractor) => ({
        startDate: subcontractor.plannedStartDate ? subcontractor.plannedStartDate : '',
        targetDate: subcontractor.plannedEndDate ? subcontractor.plannedEndDate : '',
      })),
      ...(currentAssignees || []).map((assignee) => ({
        startDate: assignee.assignmentStartDate ? assignee.assignmentStartDate : '',
        targetDate: assignee.assignmentEndDate ? assignee.assignmentEndDate : '',
      })),
    ];

    // Find the smallest start date
    const smallestStartDate = combinedArray.reduce<string | null>((earliest, item) => {
      const currentStartDate = new Date(item.startDate);
      return earliest === null || currentStartDate < new Date(earliest)
        ? item.startDate
        : earliest;
    }, null);

    //Find the largest target date
    const largestTargetDate = combinedArray.reduce<string | null>((largest, item) => {
      const currentTargetDate = item.targetDate
        ? new Date(item.targetDate)
        : new Date('1970-01-01T00:00:00.000Z');
      return largest === null || currentTargetDate > new Date(largest)
        ? item.targetDate
        : largest;
    }, null);

    return [smallestStartDate, largestTargetDate];
  };

  const [smallestStartDate, largestTargetDate] = getStartAndTargetDate(
    installationData?.subcontractors || [],
    ActivityDifferentiator.INST,
    installationData?.assignees || []
  );

  // Format the smallest and largest dates
  const formattedSmallestStartDate = smallestStartDate
    ? formatDate(smallestStartDate)
    : null;

  const formattedLargestTargetDate = largestTargetDate
    ? formatDate(largestTargetDate)
    : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (networkNumber) {
          isInstallationPlanPath && updateIsLoading(true);
          const accessToken = await getTokenFunction();
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );

          if (data) {
            updateInstallationDataWithMerge(data, updateInstallationData);
            setInspectDate(dayjs(data.inspectionDate));
          }
        }
      } catch (e) {
        console.error('Error while fetching installation data', e);
      } finally {
        isInstallationPlanPath && updateIsLoading(false);
      }
    };

    fetchData();
  }, [networkNumber]);

  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const isMounted = true;
    try {
      const token = await getTokenFunction();
      if (isMounted) {
        await put(
          `v1/installations/${networkNumber}/insDateSoldHours`,
          token,
          API_TYPE.APPLICATION,
          { type: 'inspectionDate', value: inspectionDate }
        );
      }
    } catch (error) {
      console.error('Error while updating inspection date', error);
    }
  };

  const handleDateChange = async (ISOFormattedDate: any) => {
    if (installationData) {
      installationData.inspectionDate = ISOFormattedDate;
    }
    if (ISOFormattedDate) {
      setInspectDate(dayjs(ISOFormattedDate));
      await putInsDateSoldHrs(ISOFormattedDate);
    }
  };

  const handleInstallationComplete = async (): Promise<void> => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();
      if (networkNumber) await syncData(networkNumber, accessToken);
      navigate(
        `${rootPath}/${networkNumber}/installation-plan/handover?questionSequence=0`
      );
    } catch (error) {
      console.error('error while navigating', error);
    } finally {
      updateIsLoading(false);
    }
  };

  return (
    <>
      <Card
        style={{
          maxWidth: '100%',
          margin: '0px auto',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
          border: '1px solid #ddd',
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid2 container spacing={1} style={{ paddingTop: 2 }}>
            {/* Target Date */}
            <Grid2 size={{ xs: 5 }} style={{ textAlign: 'left' }}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 4 }}>
                  <Typography variant="subtitle1">{'Target'}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 8 }} style={{ padding: '2px' }}>
                  <Typography variant="body1">{formattedLargestTargetDate}</Typography>
                </Grid2>
              </Grid2>
            </Grid2>

            {/* Start Date */}
            <Grid2 size={{ xs: 7 }} style={{ textAlign: 'left' }}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 5 }}>
                  <Typography variant="subtitle1">
                    {t('installationPlanList.startDate')}
                  </Typography>
                </Grid2>
                <Grid2 size={{ xs: 5 }} style={{ padding: '2px' }}>
                  <Typography variant="body1">{formattedSmallestStartDate}</Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            {/* Forcast Date */}
            <Grid2 size={{ xs: 5 }} style={{ textAlign: 'left' }}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 4 }}></Grid2>
                <Grid2 size={{ xs: 8 }} style={{ padding: '2px' }}></Grid2>
              </Grid2>
            </Grid2>
            {/* Inspection Date */}
            <Grid2 size={{ xs: 7 }} style={{ textAlign: 'left' }}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 5 }}>
                  <Typography variant="subtitle1">
                    {t('installationPlanList.inspection')}
                  </Typography>
                </Grid2>
                <Grid2 size={{ xs: 7 }} style={{ padding: '2px' }}>
                  <PickerWithButtonField
                    inspectDate={inspectDate}
                    handleDateChange={handleDateChange}
                    calendarIcon={false}
                    data-testid="date-picker-input"
                  />
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell
              sx={{
                borderBottom: 'none',
              }}
            >
              <Typography variant="h6">
                {t('installationPlanList.installationTaskHeading')}
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      <InstallationPlanQuestionsList
        questionSets={installationData?.installationPlanQuestions}
        answers={installationData?.installationPlanAnswers}
        startDate={formattedSmallestStartDate}
        targetDate={formattedLargestTargetDate}
        networkNumber={networkNumber as string}
        inspectDate={inspectDate}
        handleDateChange={handleDateChange}
      />
      <StyledGrid data-testid="pre-checklist">
        <Button
          size="large"
          data-testid="installer-installation-task-button"
          variant="contained"
          color="primary"
          disabled={
            // Check if all questions with deleted=false are answered
            (installationData?.installationPlanAnswers?.[0]?.answers?.filter(
              (answer) => answer !== null
            ).length || 0) !==
            (installationData?.installationPlanQuestions?.[0]?.questions?.filter(
              (question) => !question.deleted
            ).length || 0)
          }
          onClick={handleInstallationComplete}
        >
          <Typography> {t('installationStarting.installationComplete')} </Typography>
        </Button>
      </StyledGrid>
    </>
  );
};

export default InstallationPlanTasks;
