import React, { JSX, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { theme } from '@konecorp/ui-library';
import { Box, Grid2, styled, Typography } from '@mui/material';
import {
  ActivityDifferentiator,
  Handover,
  SpecialUserIds,
  NetworkTags,
} from '../../schemas';
import { fetchEmployee } from '../../helpers/fetch';
import { formatDate } from '../../helpers/formating';
import UploadWidget from '../UploadWidget';
import { useGetToken } from '../../hooks/useGetToken';

import HandoverNotifications from '../HandoverNotifications';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import Context, { InstallationContext } from '../../context';

export type HandoverSummaryProps = {
  handover: Handover | null;
  className?: string;
};

const HandoverSummary = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  minHeight: '150px',
}));

const StyledGrid = styled(Grid2)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(() => ({
  wordBreak: 'break-all',
}));

const HandoverSummaryPreview = (props: HandoverSummaryProps): JSX.Element => {
  const { installationData: installation } = useContext(Context);
  const { deviations } = useContext(InstallationContext);
  const [creatorName, setCreatorName] = useState<string | null>(null);
  const [getTokenFunction] = useGetToken();
  const [role] = useGetCurrentUserRole();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const userRole = searchQuery.get('role') ?? role;

  const { handover } = props;
  const createdAt = formatDate(handover?.modifiedAt);

  useEffect(() => {
    const fetchCreatorName = async (employeeId?: string): Promise<string | undefined> => {
      if (!employeeId) {
        return undefined;
      }

      try {
        const accessToken = await getTokenFunction();

        const subcontractors = uniqBy(
          installation?.subcontractors || [],
          'activityDifferentiator'
        );

        const getSubcontractor = (role: ActivityDifferentiator) =>
          subcontractors.find(
            (subcontractor) => subcontractor.activityDifferentiator === role
          );

        switch (employeeId) {
          case SpecialUserIds.SUBCONTRACTOR_INSTALLER: {
            const subcontractor = getSubcontractor(ActivityDifferentiator.INST);
            return subcontractor?.subcontractor.name;
          }
          case SpecialUserIds.SUBCONTRACTOR_TESTER: {
            const subcontractor = getSubcontractor(ActivityDifferentiator.CMSN);
            return subcontractor?.subcontractor.name;
          }
          default: {
            const fetchedEmployee = await fetchEmployee(employeeId, accessToken);
            return fetchedEmployee
              ? `${fetchedEmployee.legalFirstName} ${fetchedEmployee.legalLastName}`
              : undefined;
          }
        }
      } catch (error) {
        console.error('error while fetching handover data', error);
      }
    };

    (async () => {
      const creatorName = await fetchCreatorName(props.handover?.creator);
      if (creatorName) setCreatorName(creatorName);
    })();
  }, [props.handover]);

  const isTester = userRole === ActivityDifferentiator.CMSN;
  const isSupervisor = userRole === ActivityDifferentiator.SPV;
  const displayHandoverNotifications =
    installation?.networkTag !== NetworkTags.MOD && (isTester || isSupervisor);

  return (
    <div className={props.className}>
      <Box mb={3}>
        {displayHandoverNotifications && (
          <HandoverNotifications
            deviations={deviations}
            installation={installation}
            showQdPlusQuestionsWarning={true}
            showQualityReviewQuestionsWarning={isSupervisor}
          />
        )}
      </Box>
      <HandoverSummary>
        <StyledGrid container>
          <Grid2 size={{ xs: 7 }}>
            <Typography align="left" data-testid="creatorName">
              {creatorName}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 5 }}>
            <Typography align="right" data-testid="createdAt">
              {createdAt}
            </Typography>
          </Grid2>
        </StyledGrid>
        <Grid2 container padding={theme.spacing(1)}>
          <Grid2 size={{ xs: 12 }}>
            <StyledTypography data-testid="comment">
              {props.handover?.comment}
            </StyledTypography>
          </Grid2>
        </Grid2>
      </HandoverSummary>
      {handover?.attachments && <UploadWidget selectedFiles={handover.attachments} />}
    </div>
  );
};

export default HandoverSummaryPreview;
