import React, { JSX, PropsWithChildren } from 'react';
import { ArrowLeft, ArrowRight, theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  OutlinedInputProps,
  TextField,
  Typography,
  Box,
  styled,
  OutlinedInput,
  Grid2,
} from '@mui/material';
import { theme as koneTheme } from '@konecorp/ui-library';

export const StyledInvalidTextField = styled(TextField)(({ theme }) => ({
  maxWidth: theme.spacing(6),
  '& .MuiOutlinedInput-inputMarginDense': {
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.error.light,
    padding: '6px 0',
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: theme.spacing(6),
  '& .MuiOutlinedInput-inputMarginDense': {
    padding: '6px 0',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  marginLeft: theme.spacing(1),
  padding: '6px 0',
  border: '2px solid',
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.primary.light,
  '&.Mui-disabled': {
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
  },
  '&.Mui-disabled path': {
    fill: theme.palette.secondary.light,
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
  },
}));

const GridContainer = styled(Grid2)(() => ({
  padding: '0 16px',
}));

const StyledTypography = styled(Typography)(() => ({
  lineHeight: '0.9',
  display: 'inline-block',
}));

const ResetButton = styled(Button)(() => ({
  border: '2px solid',
  cursor: 'pointer',
  lineHeight: '16px',
  maxWidth: '100%',
}));

export type PlumbingXYAdjustmentsProps = {
  adjustments: AdjustmentsState[];
  onChangeAdjustments: (param: AdjustmentsState) => void;
  onResetCoordinates: () => void;
  readOnly?: boolean;
};

type PlusMinusIconButtonProps = {
  onIncrement: () => void;
  onDecrement: () => void;
  disabled: boolean;
  dataTestKey?: string;
  incrementIcon: JSX.Element;
  decrementIcon: JSX.Element;
};

export enum PlumbingAdjustmentAction {
  INCREMENT,
  DECREMENT,
}

export enum AdjustmentCoordinates {
  X = 'X',
  Y = 'Y',
}

export const defaultXYAdjustmentsState: AdjustmentsState[] = [
  {
    error: false,
    value: 0,
    key: AdjustmentCoordinates.X,
  },
  {
    error: false,
    value: 0,
    key: AdjustmentCoordinates.Y,
  },
];

export type AdjustmentsState = {
  key: AdjustmentCoordinates;
  value: number;
  error?: boolean;
  thresholdText?: string;
  actionType?: PlumbingAdjustmentAction;
};

const PlusMinusIconButton = (props: PropsWithChildren<PlusMinusIconButtonProps>) => {
  const {
    onIncrement,
    onDecrement,
    disabled,
    dataTestKey,
    incrementIcon,
    decrementIcon,
  } = props;
  return (
    <>
      <StyledIconButton
        onClick={onIncrement}
        data-testid={`plumbing-${dataTestKey}-increment-button`}
        disabled={disabled}
        aria-label={`${dataTestKey}-value-increment-button`}
        size="small"
        color={'primary'}
      >
        {incrementIcon}
      </StyledIconButton>
      <StyledIconButton
        onClick={onDecrement}
        disabled={disabled}
        data-testid={`plumbing-${dataTestKey}-decrement-button`}
        size="small"
        aria-label={`${dataTestKey}-value-decrement-button`}
        color={'primary'}
      >
        {decrementIcon}
      </StyledIconButton>
    </>
  );
};

const PlumbingXYAdjustments = (
  props: PropsWithChildren<PlumbingXYAdjustmentsProps>
): JSX.Element => {
  const { t } = useTranslation();
  const InputProps: Partial<OutlinedInputProps> = {
    inputMode: 'numeric',
  };
  const { adjustments, onChangeAdjustments, onResetCoordinates, readOnly } = props;

  const getIcons = (key: AdjustmentCoordinates, type: PlumbingAdjustmentAction) => {
    const xIncrementIcon = <ArrowLeft />;
    const xDecrementIcon = <ArrowRight />;
    const yIncrementIcon = <ArrowLeft transform="rotate(90)" />;
    const yDecrementIcon = <ArrowRight transform="rotate(90)" />;
    if (key === AdjustmentCoordinates.X && type === PlumbingAdjustmentAction.INCREMENT) {
      return xIncrementIcon;
    } else if (
      key === AdjustmentCoordinates.X &&
      type === PlumbingAdjustmentAction.DECREMENT
    ) {
      return xDecrementIcon;
    } else if (
      key === AdjustmentCoordinates.Y &&
      type === PlumbingAdjustmentAction.INCREMENT
    ) {
      return yIncrementIcon;
    } else {
      return yDecrementIcon;
    }
  };
  return (
    <GridContainer container>
      <Grid2 size={{ xs: 9, sm: 9 }}>
        <Box mt={2}>
          {adjustments.map(({ key, value, error = false, thresholdText }, index) => {
            const plusMinusButtonProps: PlusMinusIconButtonProps = {
              onIncrement: () =>
                onChangeAdjustments({
                  key,
                  value,
                  actionType: PlumbingAdjustmentAction.INCREMENT,
                }),
              incrementIcon: getIcons(key, PlumbingAdjustmentAction.INCREMENT),
              onDecrement: () =>
                onChangeAdjustments({
                  key,
                  value,
                  actionType: PlumbingAdjustmentAction.DECREMENT,
                }),
              decrementIcon: getIcons(key, PlumbingAdjustmentAction.DECREMENT),
              disabled: error || !!readOnly,
              dataTestKey: key,
            };
            return (
              <Box
                p={0.5}
                key={`plumbing-summary-${key}-${index}`}
                display="flex"
                justifyContent="in-line"
              >
                <Typography color="textSecondary" variant="h6" display="inline">
                  {key}&nbsp;
                </Typography>
                <TextField
                  classes={{ root: error ? 'error' : 'default-input' }}
                  sx={
                    error
                      ? {
                          maxWidth: theme.spacing(8),
                          '& .MuiOutlinedInput-root': {
                            borderColor: theme.palette.secondary.main,
                            borderRadius: theme.spacing(0.5),
                            backgroundColor: theme.palette.error.light,
                          },
                          '& .MuiOutlinedInput-input': {
                            backgroundColor: theme.palette.error.light,
                          },
                        }
                      : {
                          maxWidth: theme.spacing(6),
                        }
                  }
                  margin="none"
                  size="small"
                  variant="outlined"
                  disabled={true}
                  InputProps={InputProps}
                  type="number"
                  value={value.toString()}
                  inputProps={{
                    'data-testid': `summary-${key}-input`,
                    style: { textAlign: 'center' },
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
                <PlusMinusIconButton {...plusMinusButtonProps} />

                <Box p={0.5}>
                  {error ? (
                    <StyledTypography
                      data-testid={`${key}-adjustment-text-placeholder`}
                      color="error"
                    >
                      {t('plumbingSummary.errorAdjustments')}
                    </StyledTypography>
                  ) : (
                    <StyledTypography color="secondary">{thresholdText}</StyledTypography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid2>
      <Grid2 size={{ xs: 3, sm: 3 }}>
        <Box
          height={koneTheme.spacing(12)}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <ResetButton
            onClick={onResetCoordinates}
            size="large"
            variant="outlined"
            color="primary"
            data-testid="plumbing-previous-button"
          >
            {t('plumbingSummary.resetValues')}
          </ResetButton>
        </Box>
      </Grid2>
    </GridContainer>
  );
};

export default PlumbingXYAdjustments;
