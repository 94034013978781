import React, { JSX, useContext, useEffect, useState } from 'react';
import { CircleCancel, theme } from '@konecorp/ui-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid2,
  styled,
  Typography,
} from '@mui/material';

import SubHeader from '../SubHeader';
import InstallationCard from '../InstallationCard';
import Context from '../../context';
import { InstallationCardData, Installation, NetworkTags } from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';
import { FilterList } from '@mui/icons-material';
import { DialogType, Filters, defaultFilters } from '../OngoingInstallationsList';
import NetworkListFilters from '../NetworkListFilters';

const StyledGrid = styled(Grid2)(() => ({
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

const StyledContainer = styled('div')(() => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  marginTop: theme.spacing(3),
  overflow: 'auto',
}));

const CircleCancelIcon = styled(CircleCancel)(() => ({
  width: 30,
  height: 30,
  position: 'absolute',
  right: '10px',
}));

const CompletedInstallationsList = (): JSX.Element => {
  const { t } = useTranslation();

  const { completedInstallations, updateIsLoading } = useContext(Context);

  const navigate = useNavigate();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();
  const [openedDialogNetwork, setOpenedDialogNetwork] = useState<DialogType>(
    DialogType.NONE
  );

  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);
  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
  });
  const [showDiaglog, setShowDiaglog] = useState<boolean>(false);
  const filtersCloseAction = (reset?: boolean) => {
    if (reset) setFilters(defaultFilters);
    setOpenedDialogNetwork(DialogType.NONE);
  };
  useEffect(() => {
    (async () => {
      if (!completedInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, [completedInstallations]);

  useEffect(() => {
    (async () => {
      if (completedInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = completedInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const completedInstallationCardData = await Promise.all(promises);
        setInstallationCardData(
          completedInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [completedInstallations]);

  const handleCardClick = (installationCardData: InstallationCardData) => {
    const { network } = installationCardData;
    window.sessionStorage.setItem('previousPageInternalUrl', '/completed');

    navigate(`/${network.networkNumber}/deviations`);
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.completedInstallations')}
        handleGoBackClick={() => {
          navigate('/');
        }}
      />
      {true && (
        <StyledGrid container>
          <Grid2 size={{ xs: 11 }}>
            <Typography variant={'h5'}>{t('installationNetworkListTitle')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 1 }}>
            <FilterList
              data-testid="icon-filters"
              onClick={() => setOpenedDialogNetwork(DialogType.FILTERS)}
            />
          </Grid2>
        </StyledGrid>
      )}
      <Dialog open={openedDialogNetwork !== DialogType.NONE} fullWidth maxWidth="sm">
        <DialogContent>
          <CircleCancelIcon
            data-testid="dialog-close-icon"
            onClick={() => setOpenedDialogNetwork(DialogType.NONE)}
          />
          <Box mt={1} padding={theme.spacing(0.5)}>
            {openedDialogNetwork === DialogType.FILTERS && (
              <NetworkListFilters
                filters={filters}
                setFilters={setFilters}
                onClose={filtersCloseAction}
              />
            )}
          </Box>{' '}
        </DialogContent>
      </Dialog>
      <Dialog data-testid="not-allowed" open={showDiaglog}>
        <>
          <DialogContent>
            <DialogContentText data-testid="alert-dialog-description">
              {t('myInstallation.noAccess')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              data-testid="button-alert-ok"
              onClick={() => setShowDiaglog(false)}
            >
              {t('myInstallation.ok')}
            </Button>
          </DialogActions>
        </>
      </Dialog>

      {installationCardData && (
        <StyledContainer aria-label="all-installations">
          {installationCardData
            .filter((cardData) => {
              const { networkTag } = cardData.network || {};
              if (cardData.network.isVariationOrder) return false;
              if (filters.NBS && (networkTag === NetworkTags.NBS || networkTag === ''))
                return true;
              if (filters.MOD && networkTag === NetworkTags.MOD) return true;
              if (
                filters &&
                networkTag !== NetworkTags.NBS &&
                networkTag !== NetworkTags.MOD &&
                networkTag !== ''
              )
                return true;
              return false;
            })
            .map((installationCardData: InstallationCardData, index: number) => {
              const { network, deviations, installation } = installationCardData;

              return (
                <InstallationCard
                  key={`all-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => handleCardClick(installationCardData)}
                />
              );
            })}
        </StyledContainer>
      )}
    </>
  );
};

export default CompletedInstallationsList;
