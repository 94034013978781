import React, { JSX, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ServiceWorkerRegister = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const [openNewVersionModal, setOpenNewVersionModal] = useState<boolean>(false);
  const [serviceWorker, setServiceWorker] = useState<ServiceWorker | undefined>(
    undefined
  );
  const [isSWRegistered, setIsSWRegistered] = useState<boolean>(false);

  const updateServiceWorker = () => {
    if (!serviceWorker) return;

    serviceWorker.postMessage({ type: 'SKIP_WAITING' });
    setOpenNewVersionModal(false);
  };

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    if (registration && registration.waiting) {
      setServiceWorker(registration.waiting);
      setOpenNewVersionModal(true);
    }
  };

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    });
    setIsSWRegistered(true);
  }, []);

  useEffect(() => {
    if (!serviceWorker) {
      return;
    }

    serviceWorker.onstatechange = () => {
      // the later part of the if else statement means if there is already a service worker registered
      if (serviceWorker.state === 'activated' && navigator.serviceWorker.controller) {
        window.location.reload();
      }
    };
  }, [serviceWorker]);

  useEffect(() => {
    (async () => {
      if ('serviceWorker' in navigator && isSWRegistered) {
        const registration = await window.navigator.serviceWorker.ready;
        //manually trigger to check if there is a new version everytime user change the url
        await registration.update();
      }
    })();
  }, [location]);

  return (
    <Dialog open={openNewVersionModal}>
      <Box pt={2} pb={2}>
        <DialogContent>
          <Typography>{t('version.newVersionMessage')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateServiceWorker} variant="contained" color="primary">
            {t('version.refresh')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ServiceWorkerRegister;
