import React, { ChangeEvent, JSX, MouseEvent, useRef, useState } from 'react';
import { TextField, IconButton, Typography } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

export enum SearchInputType {
  TEXT = 'text',
  NUMERIC = 'numeric',
}

export type SearchProps = {
  label?: string;
  initialSearchText?: string;
  searchLabel?: string;
  searchInputType?: SearchInputType;
  minLength?: number;
  maxLength?: number;
  errorMessage?: string;
  onSubmit: (searchText: string) => void;
  disabled?: boolean;
};

const Search = ({
  searchInputType = SearchInputType.TEXT,
  label = `A ${searchInputType} search field`,
  initialSearchText = '',
  minLength,
  maxLength,
  errorMessage,
  onSubmit,
  disabled = false,
  searchLabel,
}: SearchProps): JSX.Element => {
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const inputRef = useRef<HTMLInputElement>(null);

  const Container = styled('div')(({ theme }) => ({
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  }));

  const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      paddingRight: theme.spacing(0),
    },
    '& .MuiIconButton-root': {
      borderRadius: '0px',
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.dark,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
  }));

  const handleOnChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTextValue = event.target.value;

    // Allow only numbers for NUMERIC input
    if (searchInputType === SearchInputType.NUMERIC && !/^\d*$/.test(searchTextValue)) {
      return;
    }

    setSearchText(searchTextValue);

    // Maintain focus after re-render
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleOnClickSearchButton = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSubmit(searchText);
  };

  const hasErrorMessage = Boolean(errorMessage && errorMessage.length > 0);

  return (
    <Container>
      <Typography variant="subtitle2" gutterBottom>
        {searchLabel}
      </Typography>
      <StyledTextField
        label={label}
        variant="outlined"
        fullWidth
        error={hasErrorMessage}
        helperText={errorMessage}
        value={searchText}
        onChange={handleOnChangeSearchText}
        inputRef={inputRef}
        inputProps={{
          'aria-label': 'search-input',
          inputMode: searchInputType,
          pattern: searchInputType === SearchInputType.NUMERIC ? '[0-9]*' : '(.*?)',
          minLength,
          maxLength,
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="search-icon"
              onClick={handleOnClickSearchButton}
              disabled={disabled}
            >
              <SearchIcon fontSize="large" />
            </IconButton>
          ),
        }}
      />
    </Container>
  );
};
export default Search;
