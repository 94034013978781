import React, { JSX, useContext, useEffect, useState } from 'react';
import {
  Button,
  Typography,
  TableRow,
  TableCell,
  Divider,
  TableBody,
  Table,
  styled,
  Grid2,
  Stack,
  Dialog,
  DialogContent,
} from '@mui/material';
import SubHeader from '../SubHeader';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { CircleCancel, theme } from '@konecorp/ui-library';
import { ActivityDifferentiator, Assignment, SubcontractorRecord } from '../../schemas';
import { useTranslation } from 'react-i18next';
import Context from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import { API_TYPE, get, post, put } from '../../helpers/fetch';
import { Installation } from '../../schemas';
import { formatDate } from '../../helpers/formating';
import { updateInstallationDataWithMerge } from '../../helpers/updateMergedData';
import InstallationPlanList from '../InstallationPlanList';
import { Add, ArrowBack, ArrowForward } from '@mui/icons-material';
import InstallationPlanForm from '../InstallationPlanForm';
import dayjs, { Dayjs } from 'dayjs';

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: theme.spacing(4),
}));

const StyledGrid = styled(Grid2)(() => ({
  margin: theme.spacing(8),
  marginLeft: '50px !important',
  maxWidth: '100vw',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const CloseDialogIcon = styled(CircleCancel)(() => ({
  width: 30,
  height: 30,
  position: 'absolute',
  zIndex: 1,
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
}));

const InstallationPlan = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { networkNumber } = useParams<Record<'networkNumber', string>>();
  const [getTokenFunction] = useGetToken();
  const { installationData, updateInstallationData, updateIsLoading } =
    useContext(Context);
  const location = useLocation();
  const [inspectDate, setInspectDate] = useState<Dayjs>(
    dayjs(installationData?.inspectionDate) ?? dayjs()
  );
  const [secondScreen, setIsSecondScreen] = useState(false);
  const [needChange, setNeedChange] = useState(false);
  const [installationPlanQuestions, setInstallationPlanQuestions] = useState<any>(
    installationData?.installationPlanQuestions || []
  );
  const [questionsList, setQuestionsList] = useState<any[]>([]);
  const isInstallationPlanPath = location.pathname.includes('installation-plan');

  const handleInstallationPlanForSupervisor = () => {
    try {
      navigate(`/${networkNumber}/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };

  const getSubcontractors = (
    role: ActivityDifferentiator,
    subcontractorRecords?: SubcontractorRecord[]
  ) => {
    const subcontractors = subcontractorRecords?.filter(
      (subcontractor) => subcontractor.activityDifferentiator === role
    );
    return subcontractors;
  };

  const getAssigees = (role: ActivityDifferentiator, assignees?: Assignment[]) => {
    const currentAssignees = assignees?.filter(
      (assignee) => assignee.activityDifferentiator === role
    );
    return currentAssignees;
  };

  const getStartAndTargetDate = (
    subcontractorRecords: SubcontractorRecord[],
    role: ActivityDifferentiator,
    assignees: Assignment[]
  ): (string | null)[] => {
    const subcontractors = getSubcontractors(role, subcontractorRecords);
    const currentAssignees = getAssigees(role, assignees);

    const combinedArray = [
      ...(subcontractors || []).map((subcontractor) => ({
        startDate: subcontractor.plannedStartDate ? subcontractor.plannedStartDate : '',
        targetDate: subcontractor.plannedEndDate ? subcontractor.plannedEndDate : '',
      })),
      ...(currentAssignees || []).map((assignee) => ({
        startDate: assignee.assignmentStartDate ? assignee.assignmentStartDate : '',
        targetDate: assignee.assignmentEndDate ? assignee.assignmentEndDate : '',
      })),
    ];

    // Find the smallest start date
    const smallestStartDate = combinedArray.reduce<string | null>((earliest, item) => {
      const currentStartDate = new Date(item.startDate);
      return earliest === null || currentStartDate < new Date(earliest)
        ? item.startDate
        : earliest;
    }, null);

    //Find the largest target date
    const largestTargetDate = combinedArray.reduce<string | null>((largest, item) => {
      const currentTargetDate = item.targetDate
        ? new Date(item.targetDate)
        : new Date('1970-01-01T00:00:00.000Z');
      return largest === null || currentTargetDate > new Date(largest)
        ? item.targetDate
        : largest;
    }, null);

    return [smallestStartDate, largestTargetDate];
  };

  const [smallestStartDate, largestTargetDate] = getStartAndTargetDate(
    installationData?.subcontractors || [],
    ActivityDifferentiator.INST,
    installationData?.assignees || []
  );

  // Format the smallest and largest dates
  const formattedSmallestStartDate = smallestStartDate
    ? formatDate(smallestStartDate)
    : null;

  const formattedLargestTargetDate = largestTargetDate
    ? formatDate(largestTargetDate)
    : null;

  useEffect(() => {
    if (installationData?.installationPlanQuestions) {
      setInstallationPlanQuestions(installationData.installationPlanQuestions);
    }
  }, [installationData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (networkNumber) {
          isInstallationPlanPath && updateIsLoading(true);
          const accessToken = await getTokenFunction();
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );

          if (data) {
            updateInstallationDataWithMerge(data, updateInstallationData);
            setInspectDate(dayjs(data.inspectionDate));
          }
        }
      } catch (e) {
        console.error('Error while fetching installation data', e);
      } finally {
        isInstallationPlanPath && updateIsLoading(false);
      }
    };

    fetchData();
  }, [networkNumber]);
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const isMounted = true;
    try {
      const token = await getTokenFunction();
      if (isMounted) {
        await put(
          `v1/installations/${networkNumber}/insDateSoldHours`,
          token,
          API_TYPE.APPLICATION,
          { type: 'inspectionDate', value: inspectionDate }
        );
      }
    } catch (error) {
      console.error('Error while updating inspection date', error);
    }
  };
  const handleDateChange = async (newValue: Dayjs | null) => {
    if (installationData) {
      installationData.inspectionDate = newValue?.toISOString() ?? null;
    }
    if (newValue) {
      setInspectDate(newValue);
      updateIsLoading(true);
      await putInsDateSoldHrs(newValue.toISOString());
      updateIsLoading(false);
    }
  };

  const addPlan = (values: {
    itemType: string;
    description: string;
    additionalInfo?: string;
  }) => {
    const newItem = {
      id: generateId(values.description, questionsList.length), // Generate a unique ID
      text: [{ code: 'en', text: values.description }],
      description: [{ code: 'en', text: values.additionalInfo || '' }],
      type: values.itemType,
      deleted: false,
      position: 0,
    };

    // Merge the new item with the existing questionsList while preserving the deleted status
    const updatedQuestionsList = [
      newItem,
      ...questionsList.map((question) => ({
        ...question,
        deleted: question.deleted || false, // Ensure deleted status is preserved
      })),
    ];

    setQuestionsList(updatedQuestionsList);
    setNeedChange(true);
  };

  // Function to generate unique IDs
  const generateId = (text: string, index: number) => `${text}-${index}`;

  const handleSaveNewInstallationplans = async (): Promise<void> => {
    if (needChange) {
      try {
        updateIsLoading(true);
        const accessToken = await getTokenFunction();
        const formatQuestions = (questionsList: any[]) => {
          return questionsList.map(
            (question: any, index: number) =>
              question.sk
                ? {
                    text: question.text,
                    description: question.additionalInfo || [{ code: 'en', text: '' }],
                    type: question.type,
                    deleted: question.deleted || false,
                    position: index, // Assign position here
                  }
                : { ...question, position: index } // Keep other objects unchanged but add position
          );
        };

        const installationPLanQuestions = formatQuestions(questionsList);

        const response = await post(
          `v1/installations/${networkNumber}/plan`,
          accessToken,
          API_TYPE.APPLICATION,
          {
            plans: installationPLanQuestions,
          }
        );
        if (response) {
          setIsSecondScreen(true);
          setNeedChange(false);
          setQuestionsList(response.plans[0].questions);
          setInstallationPlanQuestions(response.plans);
        }
      } catch (error) {
        console.error('error while adding installation plan', error);
      } finally {
        updateIsLoading(false);
      }
    } else {
      setIsSecondScreen(true);
      setNeedChange(false);
    }
  };

  // Check if all non-deleted questions have both startDate and endDate
  const isContinueDisabled = questionsList.some(
    (question) => !question.deleted && (!question.startDate || !question.endDate)
  );

  return (
    <>
      <SubHeader
        title={networkNumber ? networkNumber : ''}
        handleGoBackClick={() => {
          navigate(-1);
        }}
      />
      <Table>
        <TableBody>
          <TableRow>
            {secondScreen ? (
              <StyledTableCell>
                <Typography variant="h6" fontWeight="bold">
                  {t('installationPlanList.installationPlanHeadingSecondScreen')}
                </Typography>
                <Typography>
                  {t('installationPlanList.installationPlanSubHeadingSecondScreen')}
                </Typography>
              </StyledTableCell>
            ) : (
              <StyledTableCell>
                <Typography variant="h6" fontWeight="bold">
                  {t('installationPlanList.installationPlanHeading')}
                </Typography>
                <Typography>
                  {t('installationPlanList.installationPlanSubHeading')}
                </Typography>
              </StyledTableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
      <Divider />
      <InstallationPlanList
        questionsList={questionsList}
        setQuestionsList={setQuestionsList}
        questionSets={installationPlanQuestions}
        secondScreen={secondScreen}
        startDate={formattedSmallestStartDate}
        targetDate={formattedLargestTargetDate}
        inspectionDate={inspectDate}
        handleDateChange={handleDateChange}
        setNeedChange={setNeedChange}
      />
      {!secondScreen ? (
        <StyledGrid data-testid="installation-plan">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={() => setShowDialog(true)}
          >
            {t('installationPlanList.addInstallationPlan')}
          </Button>
          <Button
            size="large"
            data-testid="supervisor-installation-plan-button"
            variant="contained"
            color="primary"
            onClick={handleSaveNewInstallationplans}
            disabled={questionsList.every((question) => question.deleted)}
          >
            <Typography>
              {' '}
              {t('supervisorNewInstallation.saveInstallationPlanAndContinue')}{' '}
            </Typography>
          </Button>
        </StyledGrid>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          margin={theme.spacing(5)}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setIsSecondScreen(false)}
            startIcon={<ArrowBack />}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Typography>{t('installationPlanList.goBack')}</Typography>
          </Button>

          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleInstallationPlanForSupervisor}
            endIcon={<ArrowForward />}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            disabled={isContinueDisabled}
          >
            <Typography>{t('installationPlanList.continue')}</Typography>
          </Button>
        </Stack>
      )}
      <Dialog
        data-testid="dialog-form"
        maxWidth="sm"
        fullWidth
        open={showDialog}
        scroll="paper"
      >
        <CloseDialogIcon
          onClick={() => {
            setShowDialog(false);
          }}
        />
        <DialogContent style={{ padding: 0 }}>
          <InstallationPlanForm onSubmit={addPlan} onClose={() => setShowDialog(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InstallationPlan;
