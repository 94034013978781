import React, { JSX, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import Context from '../../context';
import {
  ActivityDifferentiator,
  Attachment,
  Deviation,
  Installation,
  NetworkTags,
} from '../../schemas';
import { getUnansweredQuestionsCount } from '../../helpers/question';
import HandoverNotifications from '../HandoverNotifications';
import UploadWidget from '../UploadWidget';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';

const StyledParagraph = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '0.875rem',
  '& .handoverAssignee': {
    display: 'block',
    width: '100%',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
  },
}));

const StyledTextfield = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const CheckboxContainer = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '& h6': {
    paddingTop: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
  },
}));

export type NonSupervisorSignoffProps = {
  commentText: string;
  deviations?: Deviation[] | undefined;
  handoverAssignee: string;
  selectedFiles: File[];
  installation: Installation | null;
  setCommentText: (comment: string) => void;
  deleteAttachmentsLocally: (file: File | Attachment) => void;
  handleUploadButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setHandoverAllowed: (allowed: boolean) => void;
};

const NonSupervisorSignoff = (props: NonSupervisorSignoffProps): JSX.Element => {
  const {
    commentText,
    deleteAttachmentsLocally,
    deviations,
    handleUploadButton,
    handoverAssignee,
    installation,
    selectedFiles,
    setCommentText,
    setHandoverAllowed,
  } = props;
  const { installationData } = useContext(Context);
  const { t } = useTranslation();
  const [userRole] = useGetCurrentUserRole();

  const [proceedChecked, setProceedChecked] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setHandoverAllowed(true);
    };
  }, []);

  const settingsForCurrentStatus = installationData?.status
    ? installationData.settings.handover?.[installationData.status]
    : undefined;

  const unansweredQuestionsCount = getUnansweredQuestionsCount({
    questions:
      (installationData?.mergedQuestions?.installerQuestions.length
        ? installationData.mergedQuestions.installerQuestions
        : installationData?.installerQuestions) ?? [],
    answers:
      (installationData?.mergedAnswers?.installerAnswers.length
        ? installationData.mergedAnswers.installerAnswers
        : installationData?.installerAnswers) ?? [],
  });
  const isModelData = installationData?.isModelData ?? false;
  const hasInstallerUnansweredQuestions =
    userRole === ActivityDifferentiator.INST && unansweredQuestionsCount > 0;

  const acknowledgedUnansweredInstallerQuestions =
    commentText.trim().length > 0 && proceedChecked;

  const requiredQuestionsActions =
    !hasInstallerUnansweredQuestions ||
    (hasInstallerUnansweredQuestions && acknowledgedUnansweredInstallerQuestions);

  const requiredAttachmentsAdded =
    selectedFiles.length >= (settingsForCurrentStatus?.totalMandatoryAttachments || 0);

  const isHandoverAllowed =
    installationData?.networkTag === NetworkTags.MOD
      ? commentText.trim().length > 0
      : (isModelData || commentText.trim().length > 0) &&
        requiredQuestionsActions &&
        requiredAttachmentsAdded;

  useEffect(() => {
    setHandoverAllowed(isHandoverAllowed);
  }, [installationData, commentText, selectedFiles, proceedChecked]);

  const uploadWidgetLabel =
    installationData?.networkTag === NetworkTags.MOD
      ? t('handoverSignoffPage.uploadButtonInfoText')
      : t(
          settingsForCurrentStatus?.attachmentQuestionTranslationKey ||
            'handoverSignoffPage.uploadButtonInfoText'
        ) + (requiredAttachmentsAdded ? '' : ' *');

  return (
    <Box component="section" data-testid="handover-signoff">
      <StyledParagraph role="note">
        <Trans
          i18nKey="handoverSignoffPage.pleaseReady"
          values={{ handoverAssignee }}
          components={{
            span: <span className="handoverAssignee" />,
          }}
        />
      </StyledParagraph>
      {installation?.networkTag !== NetworkTags.MOD && (
        <Box mb={1}>
          <HandoverNotifications
            deviations={deviations}
            installation={installation}
            showQdPlusQuestionsWarning={true}
            showQualityReviewQuestionsWarning={userRole !== ActivityDifferentiator.INST}
          />
        </Box>
      )}
      <Box mt={1} mb={1}>
        {hasInstallerUnansweredQuestions && installation?.networkTag !== NetworkTags.MOD && (
          <FormControl>
            <CheckboxContainer
              value="proceed"
              control={
                <Checkbox
                  data-testid="proceed-checkbox"
                  checked={proceedChecked}
                  color="primary"
                  name="proceed"
                  onChange={(_, checked: boolean | ((prevState: boolean) => boolean)) =>
                    setProceedChecked(checked)
                  }
                />
              }
              label={
                installation?.networkTag !== NetworkTags.MOD ? (
                  <Typography variant="h6">
                    {t('handoverSignoffPage.proceedWithUnansweredQuestions')}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    {t('handoverSignoffPage.proceedWithAnsweredQuestions')}
                  </Typography>
                )
              }
            />
          </FormControl>
        )}
      </Box>
      <Box mt={1} mb={1} sx={{ fontSize: '0.875rem' }}>
        {installation?.networkTag !== NetworkTags.MOD
          ? hasInstallerUnansweredQuestions
            ? t('handoverSignoffPage.whyUnansweredQuestions')
            : t('handoverSignoffPage.canDocument')
          : t('handoverSignoffPage.installerHandoverTextPartialMod')}
      </Box>
      <StyledTextfield
        placeholder={t('handoverSignoffPage.commentPlaceholder')}
        id="standard-multiline-static"
        value={commentText}
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCommentText(event.target.value)
        }
        multiline
        rows={4}
        inputProps={{
          'data-testid': 'comment-input',
        }}
      />

      <UploadWidget
        handleUploadButton={handleUploadButton}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        selectedFiles={selectedFiles}
        label={uploadWidgetLabel}
      />
    </Box>
  );
};
export default NonSupervisorSignoff;
