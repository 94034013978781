import React, { JSX, useState } from 'react';
import { Collapse, IconButton, Typography, styled, Grid2 } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

export type ContactInformationProps = {
  supervisor?: string;
  customerContact?: string;
  testers: string[];
  installers: string[];
};

const RowLevel1 = styled(Grid2)(() => ({
  backgroundColor: '#005A93',
  color: '#FFFFFF',
  '& p': {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  '& .MuiIconButton-root': {
    paddingTop: theme.spacing(1),
    color: '#FFFFFF',
  },
  height: theme.typography.fontSize * 3,
}));

const RowOdd = styled(Grid2)(() => ({
  backgroundColor: '#F5F6F7',
  minHeight: theme.typography.fontSize * 3,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));

const RowEven = styled(Grid2)(() => ({
  backgroundColor: '#FFFFFF',
  minHeight: theme.typography.fontSize * 3,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));

const ContactInformation = (props: ContactInformationProps): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  return (
    <>
      <RowLevel1
        data-testid="contact-info-header"
        container
        onClick={() => setOpen(!open)}
      >
        <Grid2 size={{ xs: 11 }}>
          <Typography>{t('contactInformation.contactInformation')}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 1 }}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid2>
      </RowLevel1>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <RowOdd container>
          <Grid2 size={{ xs: 7 }}>
            <Typography>{t('contactInformation.supervisor')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 5 }}>
            <Typography>{props.supervisor}</Typography>
          </Grid2>
        </RowOdd>

        <RowEven container>
          <Grid2 size={{ xs: 7 }}>
            <Typography>{t('contactInformation.customerContact')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 5 }}>
            <Typography>{props.customerContact}</Typography>
          </Grid2>
        </RowEven>

        <RowOdd container>
          <Grid2 size={{ xs: 7 }}>
            <Typography>{t('contactInformation.installer')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 5 }}>
            {props.installers?.map((name, index) => (
              <Typography key={index}>{name}</Typography>
            ))}
          </Grid2>
        </RowOdd>

        <RowEven container>
          <Grid2 size={{ xs: 7 }}>
            <Typography>{t('contactInformation.tester')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 5 }}>
            {props.testers?.map((name, index) => (
              <Typography key={index}>{name}</Typography>
            ))}
          </Grid2>
        </RowEven>
      </Collapse>
    </>
  );
};

export default ContactInformation;
