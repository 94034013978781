import React, { useEffect, useState, useContext, JSX } from 'react';
import { matchPath, useLocation, useParams, useNavigate } from 'react-router-dom';
import Context, { InstallationContext } from '../../context';
import QuestionsSequence from '../QuestionsSequence';
import {
  fetchContacts,
  fetchContracts,
  fetchSiteInfos,
  get,
  getEmployeesDataFromInstallation,
  getFormBuilder,
  post,
} from '../../helpers/fetch';
import InstallationTaskView from '../../components/InstallationTask';
import SubHeader from '../../components/SubHeader';
import QuestionsList from '../QuestionsList';
import { useTranslation } from 'react-i18next';
import NetworkDetailsModal from '../NetworkDetailsModal';
import { addHandoverStep, getQuestionSetDescription } from '../../helpers/question';
import NavigationStepper, { NavigationStep } from '../../components/NavigationStepper';
import CustomizedProgressBar from '../../components/CustomizedProgressBar';
import { chunk } from 'lodash';
import {
  Installation,
  ActivityDifferentiator,
  AnswerSet,
  Answer,
  FormId,
  QuestionSet,
  InstallationStatus,
  ScoreData,
  MergedQuestions,
  MergedAnswers,
  QuestionSetInfo,
  MergedQuestionSequences,
  Contact,
  Contract,
  NetworkTags,
  SiteInfo,
} from '../../schemas';
import { useGetUserData } from '../../hooks/useGetUserData';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import {
  getInstallationStatusIndex,
  installationStatuses,
} from '../../helpers/getInstallationLists';
import ProgressView from '../ProgressView';
import DeviationsView from '../DeviationsView';
import Empty from '../../components/Empty';
import InstallationStarting from '../InstallationStarting';

import {
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
} from '../../helpers/indexedDB';
import { cacheNetworkWithInstallationData } from '../../helpers/syncHelper';
import { cacheDeviationsData } from '../../helpers/deviationOffline';
import { extractImageLink } from '../../helpers/tip-images-helper';
import {
  getKoneEmployeeUserRole,
  getSubcontractorUserRole,
} from '../../helpers/getUserRole';
import { useGetToken } from '../../hooks/useGetToken';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { getDeviationsData } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import FloatingSyncButton from '../../components/FloatingSyncButton';
import PreInstallationChecklist from '../../components/Pre-Checklist';
import { updateInstallationDataWithMerge } from '../../helpers/updateMergedData';
import {
  initializeMergedAnswers,
  initializeMergedQuestionSequences,
  initializeMergedQuestions,
  mergeData,
} from '../../helpers/formUtils';
import InstallationPlanHandover from '../InstallationPlanHandover';
import InstallationPlanTasks from '../../components/InstallationPlanTasks';

export type QueryParams = {
  networkNumber: string;
};

export enum NetworkInstallationPaths {
  DEVIATIONS = '/:networkNumber/deviations',
  EXECUTION = '/:networkNumber/execution',
  MYDAY = '/:networkNumber/myday',
  MYPLAN = '/:networkNumber/myplan',
  PROGRESS = '/:networkNumber/progress',
  STARTING = '/:networkNumber/starting',
  PREINSTALLCHECKLIST = '/:networkNumber/starting/pre-install-checklist',
  INSTALLATIONTASK = '/:networkNumber/installation-tasks',
  INSTALLATIONPLANHANDOVER = '/:networkNumber/installation-plan/handover',
}

export enum SubcontractorNetworkInstallationPaths {
  DEVIATIONS = '/subcontractor/:networkNumber/deviations',
  EXECUTION = '/subcontractor/:networkNumber/execution',
  MYDAY = '/subcontractor/:networkNumber/myday',
  STARTING = '/subcontractor/:networkNumber/starting',
  PREINSTALLCHECKLIST = '/subcontractor/:networkNumber/starting/pre-install-checklist',
  INSTALLATIONTASK = '/subcontractor/:networkNumber/installation-tasks',
  INSTALLATIONPLANHANDOVER = '/subcontractor/:networkNumber/installation-plan/handover',
}

const SUPPORTED_URLs = [
  ...Object.values(NetworkInstallationPaths),
  ...Object.values(SubcontractorNetworkInstallationPaths),
];

export type NetworkInstallationProp = {
  redirectToReferrerUrl?: () => Promise<void>;
};

type ConvertedAnswer = {
  answer: Answer | null;
  questionSetId: string;
  questionNumberSequence: number;
};

const SCREEN_HEIGHT = '100vh';
const HEADER_AND_STEPPER_COMBINED_HEIGHT = '144px';
const FOOTER_HEIGHT = '86px';
export const CONTAINER_HEIGHT = `calc(${SCREEN_HEIGHT} - ${HEADER_AND_STEPPER_COMBINED_HEIGHT} - ${FOOTER_HEIGHT})`;

const getQuestionNextToFurthestAnsweredQuestion = (
  answers: AnswerSet[]
): { questionSetId: string; questionNumberSequence: number } | null => {
  //flatten an array of answer sets to an array of answers
  const allAnswers = answers.reduce(
    (accumulate: ConvertedAnswer[], answerSet: AnswerSet) => {
      const convertedAnswers = answerSet.answers.map((answer, answerIndex) => {
        return {
          answer,
          questionSetId: answerSet.questionSetId,
          questionNumberSequence: answerIndex,
        };
      });

      accumulate = [...accumulate, ...convertedAnswers];
      return accumulate;
    },
    []
  );

  if (allAnswers.length > 0) {
    for (let i = allAnswers.length - 1; i >= 0; i--) {
      if ((allAnswers[i].answer && allAnswers[i].answer?.value !== null) || i === 0) {
        if ([allAnswers.length - 1, 0].includes(i)) {
          // if user answered last question or haven't answered any question at all
          return {
            questionSetId: allAnswers[i].questionSetId,
            questionNumberSequence: allAnswers[i].questionNumberSequence,
          };
        } else {
          return {
            questionSetId: allAnswers[i + 1].questionSetId,
            questionNumberSequence: allAnswers[i + 1].questionNumberSequence,
          };
        }
      }
    }
  }

  return null;
};

const getIfCachingNeeded = (
  installationStatus: InstallationStatus | null,
  userRole?: ActivityDifferentiator
): boolean => {
  if (!installationStatus || !userRole) return false;

  const shouldCacheForInstaller =
    userRole === ActivityDifferentiator.INST &&
    [
      InstallationStatus.INSTALLER_ACCEPTED,
      InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
    ].includes(installationStatus);

  const shouldCacheForTester =
    userRole === ActivityDifferentiator.CMSN &&
    [
      InstallationStatus.FOR_TESTER_ACCEPTANCE,
      InstallationStatus.TESTER_ACCEPTED,
    ].includes(installationStatus);

  const shouldCacheForSupervisor =
    userRole === ActivityDifferentiator.SPV &&
    [
      InstallationStatus.FOR_FINAL_INSPECTION,
      InstallationStatus.SEB_ACCEPTED,
      InstallationStatus.SEB_REJECTED,
    ].includes(installationStatus);

  const shouldCacheForServiceEngineer =
    userRole === ActivityDifferentiator.SEEN &&
    installationStatus === InstallationStatus.FOR_SEB_ACCEPTANCE;

  return (
    shouldCacheForInstaller ||
    shouldCacheForTester ||
    shouldCacheForSupervisor ||
    shouldCacheForServiceEngineer
  );
};

const NetworkInstallation = (props: NetworkInstallationProp): JSX.Element => {
  const { redirectToReferrerUrl } = props;
  const { networkNumber: networkNumberParam } = useParams() as QueryParams;
  const {
    networkNumber,
    updateNetworkNumber,
    installationData,
    updateInstallationData,
    updateScoreForm,
  } = useContext(Context);

  const { dispatch } = useContext(InstallationContext);
  const [employeeId] = useGetUserData();
  const [userRole] = useGetCurrentUserRole();
  const [getTokenFunction] = useGetToken();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const location = useLocation();
  const [isSubcontractor] = useIfSubcontractor();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [siteInfos, setSiteInfos] = useState<SiteInfo[]>([]);
  const [itemToRemove, setItemToRemove] = useState<Contact | Contract | SiteInfo | null>(
    null
  );
  const [isMounted, setIsMounted] = useState(true);
  const matchedRoute = SUPPORTED_URLs.find((url: string) =>
    matchPath(url, location.pathname)
  );

  const isQuestionnaireUrl =
    matchedRoute &&
    [
      NetworkInstallationPaths.EXECUTION,
      SubcontractorNetworkInstallationPaths.EXECUTION,
    ].includes(matchedRoute);

  const navigate = useNavigate();
  const searchQuery = new URLSearchParams(location.search);
  const questionSetIdParam = searchQuery.get('questionSetId');

  const isUserSupervisor =
    userRole === ActivityDifferentiator.SEEN || userRole === ActivityDifferentiator.SPV;

  const installationStatus =
    installationData && installationData.status
      ? installationData.status
      : installationStatuses[0];

  const hasWorkflowPassedForFinalInspection =
    getInstallationStatusIndex(installationStatus) >=
    getInstallationStatusIndex(InstallationStatus.FOR_FINAL_INSPECTION);

  const sebInspectionDone =
    getInstallationStatusIndex(installationStatus) >
    getInstallationStatusIndex(InstallationStatus.FOR_SEB_ACCEPTANCE);

  const isEagerSupervisor =
    userRole === ActivityDifferentiator.SPV && !hasWorkflowPassedForFinalInspection;

  const { t, i18n } = useTranslation();

  type InstallationDataToUse = {
    answersToUse?: AnswerSet[];
    questionsToUse?: QuestionSet[];
    questionSetSequenceToUse?: string[] | null;
  };
  const getDataToUse = (mergedData: any, originalData: any) => {
    return mergedData?.length ? mergedData : originalData;
  };

  // the following variables is need to pick the correct questions for different roles
  const { answersToUse, questionsToUse, questionSetSequenceToUse } =
    ((): InstallationDataToUse => {
      switch (userRole) {
        case ActivityDifferentiator.INST:
          return {
            answersToUse: getDataToUse(
              installationData?.mergedAnswers?.installerAnswers,
              installationData?.installerAnswers
            ),
            questionsToUse: getDataToUse(
              installationData?.mergedQuestions?.installerQuestions,
              installationData?.installerQuestions
            ),
            questionSetSequenceToUse: getDataToUse(
              installationData?.mergedQuestionSequences?.installerQuestionSetSequence,
              installationData?.installerQuestionSetSequence
            ),
          };
        case ActivityDifferentiator.CMSN:
          return {
            answersToUse: getDataToUse(
              installationData?.mergedAnswers?.testerAnswers,
              installationData?.testerAnswers
            ),
            questionsToUse: getDataToUse(
              installationData?.mergedQuestions?.testerQuestions,
              installationData?.testerQuestions
            ),
            questionSetSequenceToUse: getDataToUse(
              installationData?.mergedQuestionSequences?.testerQuestionSetSequence,
              installationData?.testerQuestionSetSequence
            ),
          };
        case ActivityDifferentiator.SPV:
          return {
            /*
              In some front line, there is a special case that NEB supervisor first see
              one sequence of question sets after the handover from SEB supervisor, the NEB supervisor must
              see what the SEB supervisor / service engineer sees which could include some extra (local) question sets
            */
            answersToUse: sebInspectionDone
              ? getDataToUse(
                  installationData?.mergedAnswers?.sebSupervisorAnswers,
                  installationData?.testerAnswers
                )
              : getDataToUse(
                  installationData?.mergedAnswers?.testerAnswers,
                  installationData?.testerAnswers
                ),
            questionsToUse: sebInspectionDone
              ? getDataToUse(
                  installationData?.mergedQuestions?.sebSupervisorQuestions,
                  installationData?.sebSupervisorQuestions
                )
              : getDataToUse(
                  installationData?.mergedQuestions?.nebSupervisorQuestions,
                  installationData?.nebSupervisorQuestions
                ),
            questionSetSequenceToUse: sebInspectionDone
              ? getDataToUse(
                  installationData?.mergedQuestionSequences
                    ?.sebSupervisorQuestionSetSequence,
                  installationData?.sebSupervisorQuestionSetSequence
                )
              : getDataToUse(
                  installationData?.mergedQuestionSequences
                    ?.nebSupervisorQuestionSetSequence,
                  installationData?.nebSupervisorQuestionSetSequence
                ),
          };
        case ActivityDifferentiator.SEEN:
          return {
            answersToUse: getDataToUse(
              installationData?.mergedAnswers?.sebSupervisorAnswers,
              installationData?.testerAnswers
            ),
            questionsToUse: getDataToUse(
              installationData?.mergedQuestions?.sebSupervisorQuestions,
              installationData?.sebSupervisorQuestions
            ),
            questionSetSequenceToUse: getDataToUse(
              installationData?.mergedQuestionSequences?.sebSupervisorQuestionSetSequence,
              installationData?.sebSupervisorQuestionSetSequence
            ),
          };
        default:
          return {};
      }
    })();

  /* this is used to set the network number on the first time user landed on the app */
  useEffect(() => {
    updateNetworkNumber(networkNumberParam);
  }, [networkNumberParam]);

  /* this is used to fetch and set the Installation data on the first time user landed on the app */
  /* TODO: this caching part could be on it's own hook/helper function */
  useEffect(() => {
    //helper functions for caching purpose
    const cacheInstallationDataAndImages = async (
      accessToken: string,
      installation: Installation,
      userRole: ActivityDifferentiator
    ): Promise<void> => {
      await cacheNetworkWithInstallationData(
        installation,
        networkNumber,
        accessToken,
        userRole
      );

      const questionSetToCacheImages: QuestionSet[] = (() => {
        switch (userRole) {
          case ActivityDifferentiator.INST:
            return getDataToUse(
              installation.mergedQuestions?.installerQuestions,
              installation.installerQuestions
            );
          case ActivityDifferentiator.CMSN:
            return getDataToUse(
              installation.mergedQuestions?.testerQuestions,
              installation.testerQuestions
            );
          case ActivityDifferentiator.SPV:
            return getDataToUse(
              installation.mergedQuestions?.nebSupervisorQuestions,
              installation.nebSupervisorQuestions
            );
          case ActivityDifferentiator.SEEN:
            return getDataToUse(
              installation.mergedQuestions?.sebSupervisorQuestions,
              installation.sebSupervisorQuestions
            );
          default:
            throw Error(
              'Cannot figure out which questions set to use for image caching purpose'
            );
        }
      })();

      const splitQuestions = chunk(questionSetToCacheImages, 2);
      const progressForEachChunk = 70 / splitQuestions.length;
      for (let index = 0; index < splitQuestions.length; index++) {
        await extractImageLink(splitQuestions[index], accessToken);
        setProgressPercentage((progress) => progress + progressForEachChunk);
      }
    };
    setIsMounted(true);
    if (networkNumber) {
      let scoreData: ScoreData;
      const fetchInstallationData = async () => {
        try {
          const accessToken = await getTokenFunction();
          setProgressPercentage(5);
          if (!isMounted) return;
          const fetchedContacts = await fetchContacts(networkNumber, accessToken);
          setContacts(fetchedContacts);
          const fetchedContracts = await fetchContracts(networkNumber, accessToken);
          setContracts(fetchedContracts);
          const fetchedSiteInfos = await fetchSiteInfos(networkNumber, accessToken);
          setSiteInfos(fetchedSiteInfos);
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );

          if (data) {
            setProgressPercentage(20);

            let mergedQuestions: MergedQuestions = initializeMergedQuestions();
            let mergedAnswers: MergedAnswers = initializeMergedAnswers();
            let mergedQuestionSequences: MergedQuestionSequences =
              initializeMergedQuestionSequences();
            let mergedQuestionSetInfo: QuestionSetInfo[] = [];

            if (data.forms) {
              const mergedFormData = mergeData(data);
              mergedQuestions = mergedFormData.mergedQuestions;
              mergedAnswers = mergedFormData.mergedAnswers;
              mergedQuestionSequences = mergedFormData.mergedQuestionSequences;
              mergedQuestionSetInfo = mergedFormData.mergedQuestionSetInfo;

              // Add merged data into the data object
              data.mergedQuestions = mergedQuestions;
              data.mergedAnswers = mergedAnswers;
              data.mergedQuestionSequences = mergedQuestionSequences;
              data.mergedQuestionSetInfo = mergedQuestionSetInfo;
            }

            const networkNumberKey = generateIndexedDBKey(
              networkNumber,
              storedIndexedDBObjectType.NETWORK
            );
            const isNetworkDataInIndexDb = await getIndexedDBObject(networkNumberKey);
            const role = isSubcontractor
              ? getSubcontractorUserRole(networkNumber)
              : getKoneEmployeeUserRole({ installationData: data, employeeId });
            const isCachingNeeded = getIfCachingNeeded(data.status, role);
            /* Caching start here....Only cache if the data is not cached yet
                  aka can't find network key in indexedDB  */

            const deviationDataKey = generateIndexedDBKey(
              networkNumber,
              storedIndexedDBObjectType.DEVIATIONS
            );
            const isDeviationsInIndexedDB = await getIndexedDBObject(deviationDataKey);
            const shouldCacheDeviationsIntoIndexDb =
              !isDeviationsInIndexedDB && isCachingNeeded;

            const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
            if (
              role === ActivityDifferentiator.INST &&
              data?.networkTag === NetworkTags.MOD
            ) {
              const filteredDeviations = fetchedDeviations.filter(
                (deviation) =>
                  deviation.installationWorkflowStatus ===
                  InstallationStatus.INSTALLER_ACCEPTED
              );
              dispatch({
                type: InstallationActionName.SET_DEVIATIONS,
                deviations: filteredDeviations,
              });
            } else {
              dispatch({
                type: InstallationActionName.SET_DEVIATIONS,
                deviations: fetchedDeviations,
              });
            }

            if (shouldCacheDeviationsIntoIndexDb) {
              await cacheDeviationsData(networkNumber, fetchedDeviations);
            }

            const shouldStartCachingIntoIndexDb =
              !isNetworkDataInIndexDb && isCachingNeeded;
            if (shouldStartCachingIntoIndexDb) {
              if (role) {
                await Promise.all([
                  getEmployeesDataFromInstallation(accessToken, data),
                  cacheInstallationDataAndImages(accessToken, data, role),
                ]);
              } else {
                throw Error('Cannot fingure out user role in order to create syncpoint');
              }
            }

            let part1 = '';
            let part2 = '';

            if (data?.forms) {
              const formWithReferenceId = data.forms.find(
                (form) => form.name === FormId.NEBSEB && form.referenceId
              );

              if (formWithReferenceId?.referenceId) {
                [part1, part2] = formWithReferenceId.referenceId.split('#');
                const accessToken = await getTokenFunction();
                scoreData = await getFormBuilder(
                  `forms/${part1}%23${part2}/score/${networkNumber}`,
                  accessToken
                );
                if (scoreData) {
                  updateScoreForm(scoreData?.isScoreForm || false);
                }
              }
            }

            const installationDataWithHandover = addHandoverStep(
              data,
              scoreData?.isScoreForm || false
            );
            updateInstallationDataWithMerge(
              installationDataWithHandover,
              updateInstallationData
            );
          }
        } catch (e) {
          console.error(
            'Error while fetching installationData, caching the data and adding Handover step',
            e
          );
        } finally {
          setProgressPercentage(100);
        }
      };
      fetchInstallationData();
    }
  }, [networkNumber]);

  // this is used to move the user to the question next to the furthers answered question
  useEffect(() => {
    if (answersToUse && isQuestionnaireUrl && !questionSetIdParam) {
      if (isUserSupervisor && questionsToUse) {
        navigate(`?questionSetId=${questionsToUse[0].questionSetId}&questionNumber=${0}`);
        return;
      }
      const nextQuestion = getQuestionNextToFurthestAnsweredQuestion(answersToUse);

      if (nextQuestion) {
        navigate(
          `?questionSetId=${nextQuestion.questionSetId}&questionNumber=${nextQuestion.questionNumberSequence}`
        );
      }
    }
  }, [matchedRoute, answersToUse]);

  // call open endpoint to indicate that SEB opened the link
  useEffect(() => {
    const callOpenEndpoint = async () => {
      const accessToken = await getTokenFunction();
      await post(`v1/installations/${networkNumber}/open`, accessToken);
    };

    if (userRole === ActivityDifferentiator.SEEN) {
      callOpenEndpoint();
    }
  }, [userRole, networkNumber]);

  const handleDialogClick = (shouldOpen: boolean) => {
    if (shouldOpen !== isDialogOpen) setIsDialogOpen(shouldOpen);
  };

  const isStartingInstallation =
    (userRole === ActivityDifferentiator.INST &&
      installationData?.status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE) ||
    (userRole === ActivityDifferentiator.CMSN &&
      installationData?.status === InstallationStatus.FOR_TESTER_ACCEPTANCE);

  const renderView = (matchedRoute?: string, formId?: string | null) => {
    switch (matchedRoute) {
      case SubcontractorNetworkInstallationPaths.DEVIATIONS:
      case NetworkInstallationPaths.DEVIATIONS:
        return <DeviationsView />;

      case NetworkInstallationPaths.PROGRESS:
        if (userRole === ActivityDifferentiator.SPV)
          return (
            <ProgressView installation={installationData} networkNumber={networkNumber} />
          );
        if (userRole !== undefined) window.location.replace('/');
        return null;

      case SubcontractorNetworkInstallationPaths.MYDAY:
      case NetworkInstallationPaths.MYDAY:
        if (
          userRole === ActivityDifferentiator.CMSN ||
          userRole === ActivityDifferentiator.INST ||
          userRole === ActivityDifferentiator.SEEN
        ) {
          const showMyPlan =
            (!installationData?.isModelData && !installationData?.isTacoDataQuest) ||
            (installationData?.mergedQuestionSequences?.installerQuestionSetSequence
              ? installationData.mergedQuestionSequences?.installerQuestionSetSequence
                  .length === 2
              : installationData?.installerQuestionSetSequence.length === 2);
          return <InstallationTaskView showMyPlan={showMyPlan} />;
        }
        if (userRole !== undefined) window.location.replace('/');
        return null;

      case NetworkInstallationPaths.STARTING:
      case SubcontractorNetworkInstallationPaths.STARTING:
        return (
          <InstallationStarting
            contracts={contracts}
            setContracts={setContracts}
            contacts={contacts}
            setContacts={setContacts}
            siteInfos={siteInfos}
            setSiteInfos={setSiteInfos}
            itemToRemove={itemToRemove}
            setItemToRemove={setItemToRemove}
          />
        );
      case SubcontractorNetworkInstallationPaths.PREINSTALLCHECKLIST:
      case NetworkInstallationPaths.PREINSTALLCHECKLIST:
        if (installationData && !isStartingInstallation) {
          window.location.replace('/');
          return null;
        }
        return <PreInstallationChecklist />;
      case SubcontractorNetworkInstallationPaths.INSTALLATIONTASK:
      case NetworkInstallationPaths.INSTALLATIONTASK:
        return <InstallationPlanTasks />;
      case SubcontractorNetworkInstallationPaths.INSTALLATIONPLANHANDOVER:
      case NetworkInstallationPaths.INSTALLATIONPLANHANDOVER:
        return <InstallationPlanHandover />;
      case NetworkInstallationPaths.EXECUTION:
      case SubcontractorNetworkInstallationPaths.EXECUTION:
        if (isStartingInstallation) {
          window.location.replace('/');
          return null;
        }

        if (isEagerSupervisor) {
          return (
            <Empty displayIcon={false} message={t('qdPage.notReadyForFinalInspection')} />
          );
        }

        switch (formId) {
          case FormId.QDPLUS:
            return (
              <QuestionsSequence questionSets={questionsToUse} answers={answersToUse} />
            );
          case FormId.NEBSEB:
          case FormId.SEBLOC:
            return <QuestionsList questionSets={questionsToUse} answers={answersToUse} />;
          default:
            return null;
        }
    }
  };

  const questionSetInfo = (
    installationData?.mergedQuestionSetInfo?.length
      ? installationData?.mergedQuestionSetInfo
      : installationData?.questionSetInfo
  )?.find((qSet) => qSet.setId === questionSetIdParam);
  const onChangeNavigation = (step: NavigationStep) => {
    const { id } = step;
    navigate(`?questionSetId=${id}&questionNumber=0`);
  };
  let currentQuestionsetIndex = 0;

  // The state management of stepper is done based on route
  const questionSetIndex = questionsToUse?.findIndex(
    (qSet) => qSet.questionSetId === questionSetIdParam
  );
  currentQuestionsetIndex =
    questionSetIndex && questionSetIndex !== -1 ? questionSetIndex : 0;

  const navigationSteps = questionSetSequenceToUse
    ?.map((questionSetId) =>
      getQuestionSetDescription(
        questionSetId,
        installationData?.mergedQuestionSetInfo?.length
          ? installationData?.mergedQuestionSetInfo
          : installationData?.questionSetInfo,
        i18n
      )
    )
    .map(([id, displayText]) => ({ id, displayText }));

  const navTitle = installationData?.equipmentNumber
    ? [
        networkNumber,
        `${t('installationCard.equipmentNumber')} ${installationData?.equipmentNumber}`,
      ]
    : networkNumber;

  return (
    <>
      <SubHeader
        title={navTitle}
        handleInfoClick={() => setIsDialogOpen(true)}
        handleGoBackClick={isSubcontractor ? undefined : redirectToReferrerUrl}
      />
      <CustomizedProgressBar progress={progressPercentage} />
      {isDialogOpen && (
        <NetworkDetailsModal handleCloseIconClick={() => handleDialogClick(false)} />
      )}
      {isQuestionnaireUrl && (
        <NavigationStepper
          activeStep={currentQuestionsetIndex}
          navigationSteps={navigationSteps}
          onClickStep={onChangeNavigation}
          questions={questionsToUse}
          answers={answersToUse}
        />
      )}
      {renderView(matchedRoute, questionSetInfo?.formId)}
      <FloatingSyncButton />
    </>
  );
};

export default NetworkInstallation;
