import React, { JSX, useContext, useState } from 'react';

import { startCase } from 'lodash';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Grid2,
} from '@mui/material';
import { green, grey, red, yellow } from '@mui/material/colors';
import { CheckOutlined, Warning, Close, Search } from '@mui/icons-material';
import { theme } from '@konecorp/ui-library';

import {
  ActivityDifferentiator,
  Attachment,
  InstallationStatus,
  UserSettings,
} from '../../schemas';
import { API_TYPE, get } from '../../helpers/fetch';
import Context from '../../context';
import { FileType } from '../../helpers/upload-download';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import { getSettings } from '../../helpers/configurationActions';
import SubHeader from '../SubHeader';
import Empty from '../Empty';
import ExportButton from '../DashboardExportButton';
import PreviewFile from '../PreviewFile';

const StatusListItemWrapper = styled(Box)(() => ({
  alignItems: 'baseline',
  display: 'flex',
  margin: '0 auto 0.6rem auto',
  maxWidth: '800px',
}));

const StatusListLeftBox = styled(Box)(() => ({
  alignItems: 'baseline',
  display: 'flex',
  margin: '0 auto 0.6rem auto',
  maxWidth: '800px',
}));

const StatusListItem = styled(ListItem)(() => ({
  backgroundColor: '#eef0f1',
  minHeight: '2.4rem',
  padding: '0 0.8rem',
}));

const StyledGrid = styled(Grid2)(() => ({
  padding: theme.spacing(1),
  textAlign: 'right',
}));

const StyledTextField = styled(TextField)(() => ({
  padding: theme.spacing(1),
  textAlign: 'right',
}));

const StyledTable = styled(Table)(() => ({
  width: '100%',
  '& .MuiTableCell-head': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.dark,
  },
  '& .MuiTableCell-head, & .MuiTableCell-body': {
    padding: '8px',
    border: '1px solid #dddddd',
  },
}));

const SearchIcon = styled(Search)(() => ({
  transform: 'scaleX(-1)',
  fontSize: '25px',
}));

type EmployeeData = {
  employeeId: string;
  name: string;
  activityDifferentiator: string;
};

type SubcontractorData = {
  name: string;
  activityDifferentiator: string;
};

export type Workers = {
  supervisor?: EmployeeData;
  delegatedSupervisor?: EmployeeData;
  assignees: EmployeeData[];
  subcontractors: SubcontractorData[];
};

export type ProcessSummary = {
  setId: string;
  descriptions: {
    code: string;
    text: string;
  }[];
  isStarted: boolean;
  isCompleted: boolean;
  hasDeviations: boolean;
};

export type HandoverSummary = {
  creator: string;
  comment: string;
  attachments: Attachment[];
  role: ActivityDifferentiator;
};

export type InstallationAnalyticsData = {
  networkNumber: number;
  milestone: string;
  salesOrderDescription: string;
  status: InstallationStatus;
  countryCode?: string;
  actualDates: object;
  workers: Workers;
  installerProcessSummary: ProcessSummary[];
  testerProcessSummary: ProcessSummary[];
  handovers: HandoverSummary[];
  handedToSeb: number;
  sebAccepted: number;
  completedState: number;
};

export type QueryParams = {
  countryCode: string;
};

const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateIsLoading } = useContext(Context);
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();

  const [settings, setSettings] = useState<UserSettings>();
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [installations, setInstallations] = useState<InstallationAnalyticsData[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [status, setStatus] = useState<InstallationStatus | ''>('');
  const [openAttachment, setOpenAttachment] = useState<Attachment>();
  const { countryCode } = useParams() as QueryParams;

  const handleSearch = () => {
    const getUserSettings = async (): Promise<UserSettings> => {
      const accessToken = await getTokenFunction();
      return await getSettings<UserSettings>(employeeId, accessToken);
    };

    const getInstallationsData = async (): Promise<InstallationAnalyticsData[]> => {
      const accessToken = await getTokenFunction();

      const searchParams = [];
      if (countryCode) {
        searchParams.push(`countryCode=${countryCode}`);
      }
      if (searchText) {
        searchParams.push(`searchText=${searchText}`);
      }
      if (status) {
        searchParams.push(`status=${status}`);
      }

      const url = 'v1/dashboard?' + searchParams.join('&');
      const result = await get(url, accessToken, API_TYPE.ANALYTICS);

      return (result || []) as InstallationAnalyticsData[];
    };

    (async () => {
      try {
        updateIsLoading(true);
        const settings = await getUserSettings();
        setSettings(settings);

        const installations = await getInstallationsData();
        const sortedInstallations = installations.sort(
          (a, b) => a.networkNumber - b.networkNumber
        );
        setSearchFlag(installations ? true : false);
        setInstallations(sortedInstallations);
      } catch (e) {
        console.error(e);
      } finally {
        updateIsLoading(false);
      }
    })();
  };
  const byRole =
    (role: ActivityDifferentiator) => (worker: EmployeeData | SubcontractorData) =>
      worker.activityDifferentiator === role;

  const InstallationEmployeeInfo = (props: { workers: Workers }): JSX.Element => {
    const { workers } = props;

    const getEmployeeNameAndId = (employee?: EmployeeData) =>
      employee ? `${employee.name} - ${employee.employeeId}` : '';

    return (
      <>
        <Typography fontWeight="bold">{t('dashboard.supervisor')}</Typography>
        <Typography>{getEmployeeNameAndId(workers.supervisor)}</Typography>
        <br />

        {workers.delegatedSupervisor && (
          <>
            <Typography fontWeight="bold">
              {t('dashboard.delegatedSupervisor')}
            </Typography>
            <Typography>{getEmployeeNameAndId(workers.delegatedSupervisor)}</Typography>
            <br />
          </>
        )}

        <Typography fontWeight="bold">{t('dashboard.installer')}</Typography>
        {workers.assignees.filter(byRole(ActivityDifferentiator.INST)).map((assignee) => (
          <Typography key={assignee.employeeId}>
            {getEmployeeNameAndId(assignee)}
          </Typography>
        ))}

        {workers.subcontractors
          .filter(byRole(ActivityDifferentiator.INST))
          .map((subcontractor) => (
            <Typography key={subcontractor.name}>{subcontractor.name}</Typography>
          ))}

        <br />
        <Typography fontWeight="bold">{t('dashboard.tester')}</Typography>
        {workers.assignees.filter(byRole(ActivityDifferentiator.CMSN)).map((assignee) => (
          <Typography key={assignee.employeeId}>
            {getEmployeeNameAndId(assignee)}
          </Typography>
        ))}

        {workers.subcontractors
          .filter(byRole(ActivityDifferentiator.CMSN))
          .map((subcontractor) => (
            <Typography key={subcontractor.name}>{subcontractor.name}</Typography>
          ))}
      </>
    );
  };

  const DashboardProcessSummary = (props: {
    processSummary: ProcessSummary[];
    role: ActivityDifferentiator;
  }) => {
    const { processSummary, role } = props;

    const language = settings?.userLanguage ? settings?.userLanguage.split('-')[0] : 'en';

    const getIconData = (summary: ProcessSummary): { color: string; label: string } => {
      if (summary.isCompleted) return { color: green[600], label: 'phase complete icon' };
      if (summary.isStarted) return { color: yellow[600], label: 'phase started icon' };
      return { color: grey[300], label: 'phase incomplete icon' };
    };

    const getDescription = (summary: ProcessSummary): string => {
      const description = summary.descriptions.find(
        (description) => description.code === language
      );
      return description?.text || 'n/a';
    };

    return (
      <List key={`phase-status-display-${role}`} sx={{ padding: 0 }}>
        {processSummary.map((summary) => (
          <StatusListItemWrapper key={`phase-status-${summary.setId}`}>
            <StatusListLeftBox>
              {summary.hasDeviations && (
                <Warning
                  fontSize="small"
                  htmlColor={red[600]}
                  aria-label="phase deviations icon"
                />
              )}
            </StatusListLeftBox>
            <StatusListItem>
              <CheckOutlined
                sx={{ marginRight: '1rem' }}
                htmlColor={getIconData(summary).color}
                aria-label={getIconData(summary).label}
              />
              <ListItemText secondary={getDescription(summary)} />
            </StatusListItem>
          </StatusListItemWrapper>
        ))}
      </List>
    );
  };

  const HandoverSummary = (props: { handovers: HandoverSummary[] }): JSX.Element => (
    <Box>
      {props.handovers.map((handover, index) => (
        <Box pb={2} key={`handover-${handover.role}-${index}`}>
          <Typography fontWeight="bold">
            {handover.creator} {handover.role}
          </Typography>
          <Typography>{handover.comment}</Typography>
          {handover.attachments.map((attachment) => (
            <Typography key={`handover-${handover.role}-${index}-${attachment.filename}`}>
              <Link onClick={() => setOpenAttachment(attachment)}>
                {attachment.filename}
              </Link>
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );

  const NebSebProcess = (props: { installation: InstallationAnalyticsData }) => {
    const { installation } = props;

    const getMessage = (value: number) =>
      value === 1 ? t('dashboard.yes') : t('dashboard.no');

    return (
      <>
        <Typography fontWeight="bold">{t('dashboard.handedToSeb')}</Typography>
        <Typography data-testid="handedToSeb">
          {getMessage(installation.handedToSeb)}
        </Typography>
        <br />

        <Typography fontWeight="bold">{t('dashboard.sebApproved')}</Typography>
        <Typography data-testid="sebApproved">
          {getMessage(installation.sebAccepted)}
        </Typography>
        <br />

        <Typography fontWeight="bold">{t('dashboard.networkedCompleted')}</Typography>
        <Typography data-testid="networkedCompleted">
          {getMessage(installation.completedState)}
        </Typography>
      </>
    );
  };

  const NetworkInstallationsStatusTable = (props: {
    installations: InstallationAnalyticsData[];
  }): JSX.Element => {
    const { installations } = props;

    return (
      <>
        {/* EDOS - 4218 - Export to Excel button will be available only when Table data is present */}
        {installations && (
          <StyledGrid>
            <ExportButton
              installationsData={installations}
              descriptionLanguage={settings?.userLanguage?.split('-')[0] || 'en'}
            />
          </StyledGrid>
        )}
        <TableContainer component={Paper}>
          <StyledTable size="small" aria-label="Network Status Table">
            <TableHead>
              <TableRow>
                <TableCell>{t('dashboard.networkNumber')}</TableCell>
                <TableCell>{t('dashboard.milestone')}</TableCell>
                <TableCell>{t('dashboard.salesOrderDescription')}</TableCell>
                <TableCell>{t('dashboard.status')}</TableCell>
                <TableCell>{t('dashboard.actualDateObjects')}</TableCell>
                <TableCell>{t('dashboard.supervisorAndAssignees')}</TableCell>
                <TableCell>{t('dashboard.installerProcess')}</TableCell>
                <TableCell>{t('dashboard.qualityReview')}</TableCell>
                <TableCell>{t('dashboard.handovers')}</TableCell>
                <TableCell>{t('dashboard.nebSebProcess')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {installations.map((installation: InstallationAnalyticsData) => (
                <TableRow key={installation.networkNumber} aria-label="Network Entry">
                  <TableCell>{installation.networkNumber}</TableCell>
                  <TableCell>{installation.milestone}</TableCell>
                  <TableCell>{installation.salesOrderDescription ?? 'n/a'}</TableCell>
                  <TableCell>{installation.status ?? 'n/a'}</TableCell>
                  <TableCell>
                    <pre>
                      {JSON.stringify(installation.actualDates, null, 0.5) ?? 'n/a'}
                    </pre>
                  </TableCell>
                  <TableCell>
                    <InstallationEmployeeInfo workers={installation.workers} />
                  </TableCell>
                  <TableCell>
                    <DashboardProcessSummary
                      processSummary={installation.installerProcessSummary}
                      role={ActivityDifferentiator.INST}
                    />
                  </TableCell>
                  <TableCell>
                    <DashboardProcessSummary
                      processSummary={installation.testerProcessSummary}
                      role={ActivityDifferentiator.CMSN}
                    />
                  </TableCell>
                  <TableCell>
                    <HandoverSummary handovers={installation.handovers} />
                  </TableCell>
                  <TableCell>
                    <NebSebProcess installation={installation} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </>
    );
  };

  return (
    <>
      <SubHeader
        title="Dashboard"
        handleGoBackClick={() => {
          navigate('/');
        }}
      />
      <Grid2 container paddingTop={theme.spacing(2)}>
        <Grid2>
          {/* EDOS-4218 */}
          <TextField
            focused
            fullWidth
            variant="outlined"
            size="small"
            color="primary"
            value={searchText}
            placeholder={t('dashboard.searchPlaceholder')}
            style={{ padding: theme.spacing(1), width: '470px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Close
                    data-testid="search-close-icon"
                    onClick={() => {
                      setSearchText('');
                      setSearchFlag(false);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setSearchText(event.target.value ?? '');
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <StyledTextField
            id="status"
            label={t('dashboard.status')}
            value={status}
            variant="outlined"
            size="small"
            inputProps={{ 'data-testid': 'status-input' }}
            onChange={(event) => {
              setStatus(event.target.value as InstallationStatus);
            }}
            fullWidth
            select
          >
            <MenuItem value="">All</MenuItem>
            {Object.values(InstallationStatus).map((value) => (
              <MenuItem value={value} key={value}>
                {startCase(value)}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid2>
        <StyledGrid>
          <Button
            color="primary"
            size="large"
            variant="contained"
            data-testid="search-button"
            startIcon={<SearchIcon />}
            disabled={status || searchText.trim().length > 2 ? false : true}
            onClick={() => {
              handleSearch();
            }}
          >
            <Typography textAlign="right" textTransform="capitalize">
              {t('dashboard.searchButton')}
            </Typography>
          </Button>
        </StyledGrid>
      </Grid2>
      <Box margin={theme.spacing(1)} overflow="auto">
        {searchFlag === false || installations.length === 0 ? (
          <Empty message={t('dashboard.noNetworksFound')} />
        ) : (
          <NetworkInstallationsStatusTable installations={installations} />
        )}
      </Box>
      {openAttachment && (
        <Dialog open={true} onClose={() => setOpenAttachment(undefined)}>
          <DialogContent>
            <DialogContentText data-testid="file-preview-description">
              {openAttachment.filename}
            </DialogContentText>
            <PreviewFile file={openAttachment} fileType={FileType.ATTACHMENT} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default Dashboard;
