import React, { JSX, PropsWithChildren } from 'react';
import {
  DeviationBlocker,
  DeviationMinor,
  IconRoleInstaller,
  IconRoleSupervisor,
  IconRoleTester,
  TabMyDeviations,
  theme,
} from '@konecorp/ui-library';
import { blue, grey, green, purple } from '@mui/material/colors';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid2,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ActivityDifferentiator,
  CompactNetwork,
  InstallationStatus,
  NetworkDeviationCount,
  NetworkTag,
} from '../../schemas';

export interface InstallationCardProps {
  network: CompactNetwork;
  deviations: NetworkDeviationCount | null;
  status?: InstallationStatus | null;
  role?: ActivityDifferentiator;
  firstAssignmentDate: string;
  latestAnsweredQdQuestionSet: string;
  hasQualityForm: boolean | null;
  headerColor?: 'blue' | 'grey';
  onClick?: () => void;
  tacoReleaseNeeded?: boolean;
}

const StyledCard = styled(Card)(() => ({
  flexGrow: 1,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: '100%',
  '& >*:last-child': {
    paddingBottom: theme.spacing(0.7),
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  // paddingBottom: theme.spacing(1),
  paddingTop: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const StyledCardActions = styled(CardActions)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const GridFooter = styled(Grid2)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledTypography = styled(Typography)(() => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(0.75),
  width: 30,
  height: 25,
  borderRadius: 5,
  textTransform: 'uppercase',
  fontSize: '0.875rem',
  padding: theme.spacing(0.1),
}));

const InstallationText = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
}));

const WarningIcon = styled(TabMyDeviations)(() => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(2),
}));

const DeviationBlockerIcon = styled(DeviationBlocker)(() => ({
  verticalAlign: 'middle',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}));

const DeviationMinorIcon = styled(DeviationMinor)(() => ({
  verticalAlign: 'middle',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}));

type FooterColors = 'grey' | 'green' | 'blue' | 'purple';

const statusColors: { [status in InstallationStatus]: FooterColors } = {
  [InstallationStatus.TO_BE_STARTED]: 'grey',
  [InstallationStatus.FOR_INSTALLER_ACCEPTANCE]: 'green',
  [InstallationStatus.INSTALLER_REJECTED]: 'green',
  [InstallationStatus.INSTALLER_ACCEPTED]: 'green',
  [InstallationStatus.FOR_INSTALLATION_INSPECTION]: 'green',
  [InstallationStatus.FOR_TESTER_ACCEPTANCE]: 'blue',
  [InstallationStatus.TESTER_REJECTED]: 'blue',
  [InstallationStatus.TESTER_ACCEPTED]: 'blue',
  [InstallationStatus.FOR_FINAL_INSPECTION]: 'grey',
  [InstallationStatus.FOR_SEB_ACCEPTANCE]: 'purple',
  [InstallationStatus.SEB_REJECTED]: 'grey',
  [InstallationStatus.SEB_ACCEPTED]: 'grey',
  [InstallationStatus.INSTALLATION_COMPLETE]: 'grey',
  [InstallationStatus.INSTALLATION_ARCHIVED]: 'grey',
};

const warningStatuses = [
  InstallationStatus.TO_BE_STARTED,
  InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
  InstallationStatus.FOR_TESTER_ACCEPTANCE,
];

const InstallationCard = (
  props: PropsWithChildren<InstallationCardProps>
): JSX.Element => {
  const { t } = useTranslation();

  const {
    onClick,
    network,
    firstAssignmentDate,
    latestAnsweredQdQuestionSet,
    deviations,
    status,
    role,
    // hasQualityForm,
    tacoReleaseNeeded,
    headerColor,
  } = props;

  const isSupervisor = role === ActivityDifferentiator.SPV;
  const isWarningStatus = !!status && warningStatuses.includes(status);

  const footerColor = (status && statusColors[status]) || 'grey';
  const showMsg = network.isModelData && !network.isTacoDataQuest;
  /*changes for EDOS-4402*/
  const getStyle = (): React.CSSProperties => {
    if (showMsg) return { color: 'red' };
    else return {};
  };
  const getText = (): string => {
    if (showMsg) return t('installationCard.noTacoDataMessage');
    else {
      return '';
    }
  };
  return (
    <StyledCard onClick={onClick} aria-label="installation-card">
      <StyledCardContent
        sx={{
          backgroundColor:
            headerColor === 'grey'
              ? theme.palette.secondary.main
              : theme.palette.primary.main,
        }}
      >
        <Grid2 container alignItems="center">
          <Grid2 size={{ xs: 9 }} marginTop={theme.spacing(1.25)}>
            <Grid2 container>
              <Grid2 size={{ xs: 12 }}>
                <Typography variant="body2">{network.description}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body2">
                  {t('installationCard.networkNumber')} {network.networkNumber || ''}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body2">
                  {t('installationCard.equipmentNumber')} {network.equipmentNumber || ''}
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 3 }}>
            <Grid2 container direction="column">
              <Grid2
                paddingTop={1}
                paddingLeft={theme.spacing(1.5)}
                container
                justifyContent="flex-end"
              >
                {role === ActivityDifferentiator.INST && (
                  <IconRoleInstaller
                    data-testid="role-icon-installer"
                    htmlColor={theme.palette.background.paper}
                  />
                )}
                {role === ActivityDifferentiator.CMSN && (
                  <IconRoleTester
                    data-testid="role-icon-tester"
                    htmlColor={theme.palette.background.paper}
                  />
                )}
                {role === ActivityDifferentiator.SPV && (
                  <IconRoleSupervisor
                    data-testid="role-icon-supervisor"
                    htmlColor={theme.palette.background.paper}
                  />
                )}
              </Grid2>
              <Grid2>
                <Grid2
                  container
                  direction="row"
                  justifyContent="flex-end"
                  marginTop={theme.spacing(1.25)}
                >
                  <Grid2>
                    <StyledTypography variant="body2" as="div" align="center">
                      {network.milestone || ''}
                    </StyledTypography>
                  </Grid2>
                  <Grid2>
                    <StyledTypography variant="body2" as="div" align="center">
                      {network.networkTag || NetworkTag.NBS}
                    </StyledTypography>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </StyledCardContent>
      <StyledCardActions>
        <div
          style={{
            [theme.breakpoints.up('sm')]: {
              width: 'auto',
            },
            padding: theme.spacing(2),
          }}
        >
          <Box>
            <Grid2 container width="100%" flexWrap="wrap">
              <Grid2 size={{ xs: 6, sm: 3 }} paddingBottom={theme.spacing(1)}>
                <InstallationText variant="body2">
                  {t('installationCard.start')}
                </InstallationText>
              </Grid2>
              <Grid2 size={{ xs: 6, sm: 3 }} paddingBottom={theme.spacing(1)}>
                <Typography variant="body2">
                  {firstAssignmentDate
                    ? firstAssignmentDate
                    : t('installationCard.assignmentMissing')}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6, sm: 3 }} paddingBottom={theme.spacing(1)}>
                <InstallationText variant="body2">
                  {t('installationCard.QD+')}
                </InstallationText>
              </Grid2>
              <Grid2 size={{ xs: 6, sm: 3 }}>
                <Typography variant="body2">
                  {latestAnsweredQdQuestionSet}
                  <span style={getStyle()}> {getText()} </span>{' '}
                  {/* changes for EDOS-4402 */}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6, sm: 3 }} paddingBottom={theme.spacing(1)}>
                <InstallationText variant="body2">
                  {t('installationCard.deviations')}
                </InstallationText>
              </Grid2>
              <Grid2 size={{ xs: 6, sm: 3 }} paddingBottom={theme.spacing(1)}>
                <Typography variant="body2">
                  {deviations?.normal}
                  <DeviationMinorIcon />
                  {deviations?.blockers}
                  <DeviationBlockerIcon />
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        </div>

        {/* Right Aligned Box */}
        {tacoReleaseNeeded && (
          <div
            style={{
              [theme.breakpoints.up('sm')]: {
                width: 'auto',
                textAlign: 'right',
              },
            }}
          >
            <Box paddingTop={theme.spacing(3)} paddingRight={theme.spacing(2)}>
              <Grid2>
                <GridFooter
                  sx={{
                    ...(isWarningStatus && {
                      color: (theme) => theme.palette.error.main,
                      '& #icon_snags': {
                        fill: (theme) => theme.palette.error.main,
                      },
                    }),
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isWarningStatus && <WarningIcon />}
                    <Typography variant="body2" fontSize="16px">
                      {t('installationCard.releaseTacoWarningMessage')}
                    </Typography>
                  </div>
                </GridFooter>
              </Grid2>
            </Box>
          </div>
        )}
      </StyledCardActions>

      {isSupervisor && status && (
        <Footer
          sx={{
            padding: theme.spacing(1.5),
            backgroundColor:
              footerColor === 'grey'
                ? grey[200]
                : footerColor === 'green'
                ? green[100]
                : footerColor === 'purple'
                ? purple[100]
                : blue[100], // Default to blue

            ...(isWarningStatus && {
              color: theme.palette.error.main,
              '& #icon_snags': {
                fill: theme.palette.error.main,
              },
            }),
          }}
        >
          {isWarningStatus && <WarningIcon />}
          <Typography variant="body2">
            {t(`installationCard.footerMessage.${status}`)}
          </Typography>
        </Footer>
      )}
    </StyledCard>
  );
};

export default InstallationCard;
