import axios from 'axios';

import type { QuestionSet } from '../schemas';
import { get } from './fetch';

export const extractImageLink = async (
  questionSets: QuestionSet[],
  jwtToken: string
): Promise<void> => {
  const imageLinkArr: string[] = [];
  try {
    questionSets.forEach((questionSet) => {
      questionSet.questions.forEach((question) => {
        if (question.imageLink) {
          imageLinkArr.push(question.imageLink);
        }
      });
    });
    await fetchAndProcessImage(imageLinkArr, jwtToken);
  } catch (error) {
    console.error(`Error in processing tip images ${error}`);
    throw new Error(`Error in processing tip images ${error}`);
  }
};

const fetchAndProcessImage = async (
  imageLinkArr: string[],
  jwtToken: string
): Promise<void> => {
  try {
    const imageLinkPromises = imageLinkArr.map((imageLink) => {
      if (imageLink && jwtToken) {
        return get(`v1/installationimages/${imageLink}`, jwtToken);
      }
    });
    const s3ImageUrls = await Promise.all(imageLinkPromises);

    await fetchImagesFromS3(s3ImageUrls.flat());
  } catch (error) {
    throw new Error(`Error while processing tip images ${error}`);
  }
};

const fetchImagesFromS3 = async (s3ImageUrls: string[]): Promise<void> => {
  try {
    const allImagePromises = (s3ImageUrls as string[]).map((url) => {
      return axios.get(url);
    });
    await Promise.all(allImagePromises);
  } catch (error) {
    throw new Error(`Error in fetching images from s3 ${error}`);
  }
};
