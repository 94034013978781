import React, { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, styled, Grid2 } from '@mui/material';
import { theme } from '@konecorp/ui-library';

import { ExtendedInstallation } from '../../schemas';
import { formatDate } from '../../helpers/formating';

export type SelfAssignSupervisorProps = {
  networkNumber: string;
  installation: ExtendedInstallation;
  currentSupervisor: string;
  onConfirm: (networkNumber: string) => Promise<void>;
  onCancel: () => void;
};

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const AssignSummary = styled(Box)(() => ({
  padding: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.primary.main,
}));

const SelfAssignSupervisor = (props: SelfAssignSupervisorProps): JSX.Element => {
  const { networkNumber, installation, currentSupervisor, onConfirm, onCancel } = props;
  const { t } = useTranslation();
  const { customer, firstAssignmentDate } = installation;

  return (
    <>
      <Container>
        <AssignSummary>
          <Grid2 container spacing={3}>
            <Grid2 size={{ xs: 8 }}>
              <Typography variant="subtitle2">{customer?.customerName1}</Typography>
              <Typography variant="subtitle2">{customer?.customerName2}</Typography>
              <Typography variant="subtitle2">
                {`${customer?.street || ''} ${customer?.houseNumber || ''}, 
                  ${customer?.cityPostalCode || ''} ${customer?.city || ''}`}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <Typography variant="subtitle2">{networkNumber}</Typography>
              <Typography variant="subtitle2">
                {formatDate(firstAssignmentDate)}
              </Typography>
            </Grid2>
          </Grid2>
        </AssignSummary>

        <Box pt={2}>
          <Typography>{t('selfAssignSupervisor.confirmMessage')}</Typography>
          <br />
          <b>{currentSupervisor}</b>
          <br />
          <Typography>{t('selfAssignSupervisor.confirmQuestion')}</Typography>
          <br />
          <Typography>{t('selfAssignSupervisor.emailMessage')}</Typography>
          <br />
        </Box>

        <Box pt={4} pb={2}>
          <Box display="inline" mr={1}>
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-to-me-cancel"
              onClick={() => onCancel()}
            >
              {t('selfAssignSupervisor.cancelButton')}
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-to-me"
              onClick={() => onConfirm(networkNumber)}
            >
              {t('selfAssignSupervisor.yesButton')}
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SelfAssignSupervisor;
