import React, { JSX, PropsWithChildren, useEffect, useState } from 'react';
import {
  AppBar,
  ButtonBase,
  IconButton,
  Typography,
  Toolbar,
  Slide,
  styled,
  Grid2,
} from '@mui/material';
import { NaviBack, NaviInfo } from '@konecorp/ui-library';
export interface SubHeaderTitleProps {
  title: string[];
}

const SubHeaderTitle = (props: PropsWithChildren<SubHeaderTitleProps>) => {
  const { title } = props;
  const TIMEOUT = 10000;
  const [index, setIndex] = useState(0);

  const tick = () => setIndex((i) => (i + 1) % title.length);

  useEffect(() => {
    const st = setTimeout(tick, TIMEOUT);
    return () => clearTimeout(st);
  }, [index]);

  if (title.length === 1) {
    return <Typography variant="h6">{title[0]}</Typography>;
  }

  const titleToRender = title[index];

  return (
    <Slide
      direction="down"
      in={true}
      timeout={1000}
      mountOnEnter
      unmountOnExit
      key={index}
    >
      <ButtonBase onClick={tick}>
        <Typography variant="h6">{titleToRender}</Typography>
      </ButtonBase>
    </Slide>
  );
};

export interface SubHeaderProps {
  handleGoBackClick?: () => void;
  handleInfoClick?: () => void;
  title: string | string[];
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const NaviBackIcon = styled(NaviBack)(({ theme }) => ({
  fontSize: '2.7rem',
  '& path': {
    fill: theme.palette.common.white,
  },
  '& #Ellipse_62': {
    stroke: theme.palette.common.white,
  },
}));

const NaviInfoIcon = styled(NaviInfo)(({ theme }) => ({
  fontSize: '2.7rem',
  '& path': {
    fill: theme.palette.common.white,
  },
  '& #Ellipse_62': {
    stroke: theme.palette.common.white,
  },
}));

const SubHeader = (props: PropsWithChildren<SubHeaderProps>): JSX.Element => {
  const { handleGoBackClick, handleInfoClick, title } = props;

  return (
    <StyledAppBar>
      <Toolbar>
        <Grid2
          container
          width="100%"
          flexDirection="row"
          minWidth={0}
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Grid2 container minWidth={0} size={{ xs: 2, sm: 2, md: 2 }} wrap="nowrap">
            {handleGoBackClick && (
              <IconButton
                color="inherit"
                data-testid="header__back-button"
                edge="start"
                onClick={handleGoBackClick}
              >
                <NaviBackIcon />
              </IconButton>
            )}
          </Grid2>
          <Grid2
            container
            wrap="nowrap"
            width="100%"
            minWidth={0}
            size={{ xs: 8, sm: 8, md: 8 }}
            alignItems="center"
            justifyContent="center"
          >
            {Array.isArray(title) ? (
              <SubHeaderTitle title={title} />
            ) : (
              <Typography
                variant="h6"
                noWrap
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  flexGrow: 1,
                  minWidth: 0,
                }}
              >
                {title}
              </Typography>
            )}
          </Grid2>
          <Grid2
            container
            width="100%"
            minWidth={0}
            size={{ xs: 2, sm: 2, md: 2 }}
            wrap="nowrap"
            alignItems="center"
            justifyContent="center"
          >
            {handleInfoClick && (
              <IconButton
                color="inherit"
                data-testid="header__info-button"
                edge="end"
                sx={{ padding: '4px' }}
                onClick={handleInfoClick}
              >
                <NaviInfoIcon />
              </IconButton>
            )}
          </Grid2>
        </Grid2>
      </Toolbar>
    </StyledAppBar>
  );
};

export default SubHeader;
