import React, { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonGroup, Dialog, DialogContent, styled } from '@mui/material';
import Context, { InstallationContext } from '../../context';
import { Deviation, DeviationHistory } from '../../schemas';
import { useGetToken } from '../../hooks/useGetToken';
import { API_TYPE, post } from '../../helpers/fetch';
import { isSyncDeviationsInProgress } from '../../helpers/deviationOffline';
import DeviationsList from '../../components/DeviationsList';
import DeviationForm, {
  EditDeviationPayload,
  CreateDeviationPayload,
} from '../../components/DeviationForm';
import {
  closeDeviation,
  createDeviation,
  editDeviation,
  getDeviationsData,
} from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { isInstallationCompleted } from '../../helpers/getInstallationLists';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetUserData } from '../../hooks/useGetUserData';

const StyledContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: 0,
  maxWidth: '100vw',
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  fontWeight: 'bold',
  justifyContent: 'center',
  padding: 0,
}));

const ListContainer = styled(Box)(() => ({
  maxHeight: 'calc(100vh - 250px)',
  overflow: 'auto',
}));

const StyledButtonGroup = styled(ButtonGroup)(() => ({
  position: 'absolute',
  bottom: 110,
  margin: '0 auto',
  width: '100%',
  textTransform: 'uppercase',
}));

const StyledButton = styled(Button)(() => ({
  margin: '0 auto',
}));

const DeviationsView = (): JSX.Element => {
  const { updateIsLoading, updateErrorMessage, networkNumber, installationData } =
    useContext(Context);
  const { deviations, dispatch } = useContext(InstallationContext);
  const [getToken] = useGetToken();
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();
  const [deviation, setDeviation] = useState<Deviation | undefined>(undefined);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guid = queryParams.get('guid') || undefined;
  const [deviationsLoaded, setDeviationsLoaded] = useState(false); // State to track loading state

  useEffect(() => {
    const fetchDeviations = async () => {
      const accessToken = await getTokenFunction();
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: Array.isArray(fetchedDeviations) ? fetchedDeviations : [],
      });
      setDeviationsLoaded(true); // Update the loading state
    };
    fetchDeviations();
  }, []);

  useEffect(() => {
    const deviation = deviations.find((deviation) => deviation.guid === guid);
    if (deviation) {
      setDeviation(deviation);
      setDialogOpen(true);
    } else {
      setDeviation(undefined);
      setDialogOpen(false);
    }
  }, [deviations, guid]);

  const showDialog = (deviation: Deviation) => {
    setDeviation(deviation);
    setDialogOpen(true);
  };

  const closeDeviationFormDialog = () => {
    setDeviation(undefined);
    setDialogOpen(false);
    if (guid) navigate(location.pathname);
  };

  const waitForDeviationsSync = async () => {
    while (await isSyncDeviationsInProgress(networkNumber)) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  const closeDeviationAction = async (deviation?: Deviation) => {
    if (!deviation) return;
    updateIsLoading(true);
    try {
      const token = await getToken();
      const patchedDeviation = await closeDeviation(token, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: patchedDeviation,
      });
      setDeviation(undefined);
    } catch (error) {
      updateErrorMessage({
        message: t('deviationsView.error.cannotCloseDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  const createDeviationAction = async (deviation: CreateDeviationPayload) => {
    updateIsLoading(true);
    try {
      await waitForDeviationsSync();
      const accessToken = await getToken();
      const createdDeviation = await createDeviation(
        accessToken,
        networkNumber,
        deviation
      );
      dispatch({
        type: InstallationActionName.ADD_DEVIATION,
        deviation: createdDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('deviationsView.error.cannotCreateDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  const editDeviationAction = async (deviation: EditDeviationPayload) => {
    updateIsLoading(true);
    try {
      await waitForDeviationsSync();
      const accessToken = await getToken();
      const patchedDeviation = await editDeviation(accessToken, networkNumber, deviation);

      // Add user comment to deviation history
      if (patchedDeviation.userComment) {
        const updatedDeviation = addCommentToDeviationHistory(
          patchedDeviation,
          patchedDeviation.userComment,
          employeeId
        );
        dispatch({
          type: InstallationActionName.EDIT_DEVIATION,
          deviation: updatedDeviation,
        });
      } else {
        dispatch({
          type: InstallationActionName.EDIT_DEVIATION,
          deviation: patchedDeviation,
        });
      }
    } catch (error) {
      updateErrorMessage({
        message: t('deviationsView.error.cannotUpdateDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  const addCommentToDeviationHistory = (
    deviation: Deviation,
    userComment: string,
    employeeId: string
  ): Deviation => {
    const newHistoryEntry: DeviationHistory = {
      employeeId,
      userComment,
      changes: [],
      modifiedAt: new Date().toISOString(),
      files: [],
    };

    return {
      ...deviation,
      history: [newHistoryEntry, ...(deviation.history || [])],
    };
  };

  const editDeviationsAction = async (deviations: EditDeviationPayload[]) => {
    updateIsLoading(true);
    try {
      const accessToken = await getToken();

      // NOTE: DO NOT call update deviations in parallel (with Promise.all)
      // due to the implementation of offline (deviation) feature, parallel calling won't work
      for (const payload of deviations) {
        await waitForDeviationsSync();

        const patchedDeviation = await editDeviation(accessToken, networkNumber, payload);

        dispatch({
          type: InstallationActionName.EDIT_DEVIATION,
          deviation: patchedDeviation,
        });
      }
    } catch (error) {
      updateErrorMessage({
        message: t('deviationsView.error.cannotMassUpdateDeviations'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  const sendDeviationNotification = async (employeeId: string) => {
    updateIsLoading(true);

    try {
      const accessToken = await getToken();

      await post(
        `v1/deviations/${networkNumber}/inform`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          assignees: [employeeId],
          includeMarkAsFixed: false,
        }
      );
    } catch (error) {
      updateErrorMessage({
        message: t('deviationsView.error.cannotSendDeviationNotification'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  return (
    <StyledContainer data-testid="deviation-view">
      <Dialog open={isDialogOpen} fullScreen>
        <StyledDialogContent>
          <DeviationForm
            initialDeviation={deviation}
            onCreate={createDeviationAction}
            onEdit={editDeviationAction}
            onClear={closeDeviationFormDialog}
          />
        </StyledDialogContent>
      </Dialog>

      <ListContainer>
        <DeviationsList
          deviations={deviations}
          showControls={true}
          deviationsLoaded={deviationsLoaded}
          deviationOpenClick={showDialog}
          deviationCloseClick={closeDeviationAction}
          deviationsEditAction={editDeviationsAction}
          deviationNotification={sendDeviationNotification}
        />
      </ListContainer>

      <StyledButtonGroup>
        <StyledButton
          data-testid="create-deviation-button"
          color="primary"
          variant="contained"
          onClick={() => setDialogOpen(true)}
          disabled={isInstallationCompleted(installationData?.status)}
        >
          {t('deviationForm.createNew')}
        </StyledButton>
      </StyledButtonGroup>
    </StyledContainer>
  );
};

export default DeviationsView;
