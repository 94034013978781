import React, { JSX, PropsWithChildren, useState } from 'react';
import { Button, Grid2, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExtendedInstallation, Installation, NetworkTags } from '../../schemas';
import SubHeader from '../../components/SubHeader';
import TeamList from '../../components/TeamList';
import { API_TYPE, put } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';
import { PickerWithButtonField } from '../DatePickerWithIcon';
import dayjs, { Dayjs } from 'dayjs';

export type SupervisorNewInstallationProps = {
  installation: Installation | ExtendedInstallation;
  networkNumber: string;
};

const GridContainer = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& > div': {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
}));

const Container = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  paddingTop: theme.spacing(3),
  height: '100%',
}));

const SupervisorNewInstallation = (
  props: PropsWithChildren<SupervisorNewInstallationProps>
): JSX.Element => {
  const { installation, networkNumber } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [inspectDate, setInspectDate] = useState<Dayjs | null>(
    dayjs(installation.inspectionDate)
  );
  const [getTokenFunction] = useGetToken();
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const token = await getTokenFunction();
    return await put(
      `v1/installations/${networkNumber}/insDateSoldHours`,
      token,
      API_TYPE.APPLICATION,
      { type: 'inspectionDate', value: inspectionDate }
    );
  };
  const handleChecklistButtonClick = async (): Promise<void> => {
    try {
      navigate(`/${networkNumber}/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };

  const handleInstallPlanButtonClick = async (): Promise<void> => {
    try {
      navigate(`/${networkNumber}/installation-plan`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };
  const handleDateChange = async (newValue: Dayjs | null) => {
    const ISOFormattedDate = newValue?.toISOString() ?? null;
    installation.inspectionDate = ISOFormattedDate;
    if (ISOFormattedDate) {
      setInspectDate(dayjs(ISOFormattedDate));
      await putInsDateSoldHrs(ISOFormattedDate);
    }
  };
  const renderCustomerInfo = (networkNumber: string): JSX.Element => {
    const {
      customerName1 = '',
      street = ' ',
      houseNumber = '',
      city = '',
      cityPostalCode = '',
      countryKey = '',
      partnerFunction = '',
    } = installation.customer ?? {};
    if (partnerFunction !== 'WE') {
      return <></>;
    }
    return (
      <>
        <Grid2 container flexDirection="column" spacing={2}>
          <Grid2 container flexDirection="row" width="100%">
            <Grid2 size={{ xs: 6, md: 6, lg: 6 }}>
              <Typography textTransform="uppercase">
                {t('deviationsTable.network')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6, lg: 6 }}>
              <Typography>{networkNumber}</Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 size={{ xs: 6, md: 6, lg: 6 }}>
              <Typography textTransform="uppercase">
                {t('supervisorNewInstallation.customer')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6, lg: 6 }}>
              <Typography>{customerName1}</Typography>
              <Typography>
                {street} {houseNumber}
              </Typography>
              <Typography>
                {cityPostalCode} {city}, {countryKey}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 size={{ xs: 6, md: 6, lg: 6 }}>
              <Typography textTransform="uppercase">
                {t('supervisorNewInstallation.inspectionDate')}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 6, md: 6, lg: 6 }}>
              <PickerWithButtonField
                inspectDate={inspectDate}
                handleDateChange={handleDateChange}
                calendarIcon={false}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </>
    );
  };

  const renderButton = (): JSX.Element => {
    return (
      <>
        <GridContainer>
          <Grid2 container justifyContent="center" alignItems="center" paddingLeft={0}>
            <Grid2>
              {installation?.networkTag === NetworkTags.MOD ? (
                <Button
                  data-testid="installation-button"
                  id="installation-button"
                  variant="contained"
                  color="primary"
                  onClick={handleInstallPlanButtonClick}
                >
                  <Typography>
                    {' '}
                    {t('supervisorNewInstallation.proceedToInstallationPlan')}{' '}
                  </Typography>
                </Button>
              ) : (
                <Button
                  data-testid="start-button"
                  id="start-button"
                  variant="contained"
                  color="primary"
                  onClick={handleChecklistButtonClick}
                >
                  <Typography>
                    {' '}
                    {t(
                      'supervisorNewInstallation.proceedToPreInstallationChecklist'
                    )}{' '}
                  </Typography>
                </Button>
              )}
            </Grid2>
          </Grid2>
        </GridContainer>
      </>
    );
  };
  return (
    <>
      <SubHeader
        title={t('myInstallation.toBeStartedInstallations')}
        handleGoBackClick={() => {
          navigate('/tobestarted');
        }}
      />
      <Container>
        <Grid2 container spacing={2}>
          {renderCustomerInfo(networkNumber)}
          <TeamList installation={installation} networkNumber={networkNumber} />
        </Grid2>
        {renderButton()}
      </Container>
    </>
  );
};
export default SupervisorNewInstallation;
