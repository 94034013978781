import React, { JSX, useState } from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  Dialog,
  IconButton,
  Box,
  styled,
  Grid2,
} from '@mui/material';
import { CircleCancel, theme } from '@konecorp/ui-library';

import {
  Answer,
  isNewDeviation,
  PutAnswerPayload,
  SupervisorInstallerCheckListQuestions,
} from '../../schemas';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { useTranslation } from 'react-i18next';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import CustomizedLocalizedQuestionText from '../../components/CustomizedLocalizedQuestionText';
import AnswerValueWithDeviation from '../AnswerValueWithDeviation';

export interface ChecklistQuestionProps {
  question: SupervisorInstallerCheckListQuestions;
  questionSequence: number;
  /*
    The index could be 0 if the question is type dummy or header of the group of questions.
    Otherwise the index could be either odd or even, which reflects on the different background color.
  */
  tableRowIndex: number;
  /**
   * This is required as part of the Info modal and NoAnswerForm Modal heading part
   */
  answer?: Answer | null;
  globalArr?: string[];
  saveAnswer: (newAnswer: PutAnswerPayload) => Promise<void>;
  onCreateDeviation: (deviation: CreateDeviationPayload) => Promise<void>;
  onEditDeviation: (deviation: EditDeviationPayload) => Promise<void>;
}

// Even row styling
export const EvenRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '4px 0 4px 12px',
}));

// Odd row styling
export const OddRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: '4px 0 4px 12px',
}));

export const StyledTableCell = styled(TableCell)(() => ({
  padding: '4px 0 4px 12px',
}));

// Header row styling
export const StyledHeaderRow = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingTop: theme.spacing(4),
}));

// Answer container styling
export const AnswerContainer = styled(Box)(({ theme }) => ({
  flexWrap: 'wrap',
  justifyContent: 'end',
  paddingRight: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(5),
  },
}));

// Close dialog icon styling
export const CloseDialogIcon = styled(CircleCancel)(({ theme }) => ({
  width: 30,
  height: 30,
  position: 'absolute',
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
  [theme.breakpoints.up('sm')]: {
    width: 50,
    height: 50,
    right: theme.spacing(4),
    top: theme.spacing(4),
  },
}));

// Modal styling
export const StyledModal = styled(Dialog)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  background: theme.palette.background.paper,
  justifyContent: 'center',
  '& .MuiDialog-container': {
    width: '100%',
  },
}));

const PreChecklistQuestion = (props: ChecklistQuestionProps): JSX.Element => {
  const { i18n } = useTranslation();

  const {
    question,
    questionSequence,
    tableRowIndex,
    answer,
    saveAnswer,
    onCreateDeviation,
    onEditDeviation,
  } = props;
  const localizedQuestionText = getLocalizedText(i18n, question.text)?.text || '';
  const localizedQuestionTextWithoutWhiteSpace = localizedQuestionText
    .replaceAll(' ', '-')
    .trim();
  const [modalState, setModalState] = useState({
    isOpen: false,
    content: <></>,
  });

  const onCloseModal = () => setModalState({ isOpen: false, content: <></> });

  if (tableRowIndex === 0) {
    return (
      <TableRow>
        <StyledHeaderRow
          data-testid={`header-question-row-${localizedQuestionTextWithoutWhiteSpace}`}
        >
          <Typography variant="h6">{localizedQuestionText}</Typography>
        </StyledHeaderRow>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <StyledTableCell
          sx={{
            backgroundColor: (theme) =>
              tableRowIndex % 2 === 0
                ? theme.palette.background.default
                : theme.palette.grey[100],
          }}
          data-testid={
            tableRowIndex % 2 === 0
              ? `even-question-row-${localizedQuestionTextWithoutWhiteSpace}`
              : `odd-question-row-${localizedQuestionTextWithoutWhiteSpace}`
          }
        >
          <StyledModal
            fullScreen
            fullWidth
            onClose={onCloseModal}
            open={modalState.isOpen}
          >
            <IconButton onClick={onCloseModal} data-testid="review-question-close-modal">
              <CloseDialogIcon htmlColor={theme.palette.grey[900]} />
            </IconButton>
            {modalState.content}
          </StyledModal>
          <Grid2 container alignItems="flex-start">
            <Grid2 size={{ xs: 7 }}>
              <CustomizedLocalizedQuestionText
                localaizedQuestionText={localizedQuestionText}
              />
            </Grid2>
            <Grid2 container size={{ xs: 5 }} justifyContent="flex-end">
              <AnswerContainer display="flex" alignItems="center">
                {question.valueType === 'ok_notok' && (
                  <AnswerValueWithDeviation
                    answer={answer?.value}
                    question={question}
                    questionSetId={'PRECHECK'}
                    questionSequence={questionSequence}
                    isReadOnly={false}
                    onAnswer={async (newAnswer, deviation) => {
                      if (deviation) {
                        if (isNewDeviation(deviation)) {
                          await onCreateDeviation(deviation as CreateDeviationPayload);
                        } else {
                          await onEditDeviation(deviation as EditDeviationPayload);
                        }
                      }

                      await saveAnswer(newAnswer);
                    }}
                  />
                )}
              </AnswerContainer>
            </Grid2>
          </Grid2>
        </StyledTableCell>
      </TableRow>
    );
  }
};

export default PreChecklistQuestion;
