import React, { JSX, useContext, useEffect } from 'react';
import Context, { InstallationContext } from '../../context';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, TableBody } from '@mui/material';

import Empty from '../../components/Empty';
import {
  QuestionType,
  AnswerSet,
  PutAnswerPayload,
  Attachment,
  SupervisorInstallerCheckListQuestions,
  InstallationStatus,
  ActivityDifferentiator,
  Deviation,
} from '../../schemas';
import {
  createDeviation,
  editDeviation,
  getDeviationsData,
} from '../../helpers/deviationActions';
import { API_TYPE, post } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import { InstallationActionName } from '../../reducers/installation';
import { uploadAttachments } from '../../helpers/upload-download';
import ChecklistQuestion from '../PreChecklistQuestion';
import { useUpdatePreChecklistAnswer } from '../../hooks/usePreChecklistAnswer';

type QuestionListProps = {
  questions?: SupervisorInstallerCheckListQuestions[];
  answers?: AnswerSet[] | null;
  role: ActivityDifferentiator;
};

type QueryParams = {
  networkNumber: string;
};

const PreCheckListQuestionsList = (props: QuestionListProps): JSX.Element => {
  const { updateIsLoading, updateErrorMessage } = useContext(Context);
  const { networkNumber } = useParams() as QueryParams;
  const { dispatch } = useContext(InstallationContext);
  const location = useLocation();
  const [getTokenFunction] = useGetToken();
  const { t } = useTranslation();
  const [updatePreChecklistAnswer] = useUpdatePreChecklistAnswer();

  const searchQuery = new URLSearchParams(location.search);
  const questionSetIdParam = searchQuery.get('questionSetId');

  const { questions, answers, role } = props;

  useEffect(() => {
    const fetchDeviations = async () => {
      const accessToken = await getTokenFunction();
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      const filteredDeviations = filterDeviations(fetchedDeviations, role);
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: filteredDeviations,
      });
    };

    fetchDeviations();
  }, []);

  const onSaveAnswer = async (
    newAnswer: PutAnswerPayload,
    questionSequenceNumber: number
  ) => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();
    const files = newAnswer.value || [];
    const uploadFiles: File[] = [];
    const dbFiles: Attachment[] = [];
    if (Array.isArray(files)) {
      files.forEach((file) => {
        if (file instanceof File) {
          uploadFiles.push(file);
        } else {
          dbFiles.push(file);
        }
      });
    }

    try {
      const actualQuestionSequenceNumber =
        questionSequenceNumber < 10
          ? `0${questionSequenceNumber}`
          : questionSequenceNumber;

      newAnswer.value = true;

      if (Array.isArray(uploadFiles) && uploadFiles.length) {
        const attachments = await uploadAttachments({
          files: uploadFiles,
          networkNumber,
          questionSequenceNumber,
          jwtToken: accessToken,
        });
        newAnswer.value = [...dbFiles, ...(attachments || [])];
      }

      const updatedAnswer = await post(
        `v1/installations/${networkNumber}/answers/${questionSetIdParam}/${actualQuestionSequenceNumber}`,
        accessToken,
        API_TYPE.APPLICATION,
        newAnswer
      );
      updatePreChecklistAnswer(updatedAnswer, questionSetIdParam, questionSequenceNumber);
    } catch (e) {
      console.error(e);
    }
    updateIsLoading(false);
  };

  const renderQuestionList = (questions: SupervisorInstallerCheckListQuestions[]) => {
    const answerSets = answers?.find(
      (answerSet) => answerSet.questionSetId === 'PRECHECK'
    );
    const sortedQuestions = [...questions].sort((a, b) => {
      if (a.questionType.toLowerCase() === QuestionType.DUMMY) return -1;
      if (b.questionType.toLowerCase() === QuestionType.DUMMY) return 1;
      return 0;
    });
    const globalArr: string[] = [];
    const answerValues = answerSets?.answers || [];
    const questionListRow: JSX.Element[] = [];

    for (let index = 0; index < sortedQuestions.length; index++) {
      questionListRow.push(
        <ChecklistQuestion
          key={`${sortedQuestions[index].questionId}-${index}`}
          answer={answerValues[index]}
          question={sortedQuestions[index]}
          questionSequence={Number(index < 10 ? `0${index}` : index)}
          tableRowIndex={index}
          saveAnswer={(newAnswer) => onSaveAnswer(newAnswer, index)}
          onCreateDeviation={handleCreateDeviation}
          onEditDeviation={handleEditDeviation}
          globalArr={globalArr}
        />
      );
    }
    return questionListRow;
  };

  const handleCreateDeviation = async (deviation: CreateDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);

    try {
      const createdDeviation = await createDeviation(
        accessToken,
        networkNumber,
        deviation
      );
      dispatch({
        type: InstallationActionName.ADD_DEVIATION,
        deviation: createdDeviation,
      });
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      const filteredDeviations = filterDeviations(fetchedDeviations, role);

      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: filteredDeviations,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotCreateDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const handleEditDeviation = async (deviation: EditDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);
    try {
      const updatedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: updatedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotEditDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  return (
    <>
      <div data-testid="pre-install-checklist">
        {questions && questions.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableBody>{renderQuestionList(questions)}</TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Empty displayIcon={false} message={t('')}></Empty>
        )}
      </div>
    </>
  );
};

const filterDeviations = (
  fetchedDeviations: Deviation[] | undefined,
  role: ActivityDifferentiator
): Deviation[] => {
  if (!Array.isArray(fetchedDeviations)) {
    return [];
  }
  if (role === ActivityDifferentiator.SPV) {
    return fetchedDeviations.filter(
      (deviation) =>
        deviation.installationWorkflowStatus === InstallationStatus.TO_BE_STARTED
    );
  } else {
    return fetchedDeviations.filter(
      (deviation) =>
        deviation.installationWorkflowStatus === InstallationStatus.TO_BE_STARTED ||
        deviation.installationWorkflowStatus ===
          InstallationStatus.FOR_INSTALLER_ACCEPTANCE
    );
  }
};

export default PreCheckListQuestionsList;
export { filterDeviations };
