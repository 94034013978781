import React, { JSX, useContext, useEffect, useState } from 'react';
import { CircleCancel, theme } from '@konecorp/ui-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SubHeader from '../../components/SubHeader';
import InstallationCard from '../InstallationCard';
import Context from '../../context';
import {
  ActivityDifferentiator,
  InstallationCardData,
  Installation,
  InstallationStatus,
  NetworkTags,
} from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';
import { Box, Dialog, DialogContent, Grid2, styled, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import NetworkListFilters from '../NetworkListFilters';

export enum DialogType {
  FILTERS,
  NONE,
}
export type Filters = {
  NBS: boolean;
  MOD: boolean;
};
export const defaultFilters: Filters = {
  NBS: true,
  MOD: false,
};

const CardContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  overflow: 'auto',
}));

const CloseIcon = styled(CircleCancel)(() => ({
  width: 30,
  height: 30,
  position: 'absolute',
  right: '10px',
}));

const StyledGrid = styled(Grid2)(() => ({
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

const OngoingInstallationsList = (): JSX.Element => {
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();
  const [openedDialog, setOpenedDialog] = useState<DialogType>(DialogType.NONE);
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);
  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
  });
  const { ongoingInstallations, updateIsLoading } = useContext(Context);

  const navigate = useNavigate();
  const filtersCloseAction = (reset?: boolean) => {
    if (reset) setFilters(defaultFilters);
    setOpenedDialog(DialogType.NONE);
  };
  useEffect(() => {
    (async () => {
      if (!ongoingInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (ongoingInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = ongoingInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const ongoinInstallationCardData = await Promise.all(promises);

        setInstallationCardData(
          ongoinInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [ongoingInstallations]);

  const handleCardClick = (installationCardData: InstallationCardData) => {
    const { network, installation } = installationCardData;
    const role = getKoneEmployeeUserRole({
      installationData: installation as Installation,
      employeeId,
    });

    window.sessionStorage.setItem('previousPageInternalUrl', '/ongoing');

    const path =
      role === ActivityDifferentiator.SPV
        ? 'progress'
        : !installation.isModelData
        ? 'myday'
        : installation?.networkTag === NetworkTags.MOD &&
          installation?.status === InstallationStatus.INSTALLER_ACCEPTED
        ? 'installation-tasks'
        : 'execution';
    navigate(`/${network.networkNumber}/${path}`);
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.ongoingInstallations')}
        handleGoBackClick={() => {
          navigate('/');
        }}
      />

      {true && (
        <StyledGrid container>
          <Grid2 size={{ xs: 11 }}>
            <Typography variant={'h5'}>{t('installationNetworkListTitle')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 1 }}>
            <FilterList
              data-testid="icon-filters"
              onClick={() => setOpenedDialog(DialogType.FILTERS)}
            />
          </Grid2>
        </StyledGrid>
      )}
      <Dialog open={openedDialog !== DialogType.NONE} fullWidth maxWidth="sm">
        <DialogContent>
          <CloseIcon
            data-testid="dialog-close-icon"
            onClick={() => setOpenedDialog(DialogType.NONE)}
          />
          <Box mt={1} padding={theme.spacing(0.5)}>
            {openedDialog === DialogType.FILTERS && (
              <NetworkListFilters
                filters={filters}
                setFilters={setFilters}
                onClose={filtersCloseAction}
              />
            )}
          </Box>{' '}
        </DialogContent>
      </Dialog>

      {installationCardData && (
        <CardContent>
          {installationCardData
            .filter((cardData) => {
              const { networkTag } = cardData.network || {};
              if (cardData.network.isVariationOrder) return false;
              if (filters.NBS && (networkTag === NetworkTags.NBS || networkTag === ''))
                return true;
              if (filters.MOD && networkTag === NetworkTags.MOD) return true;
              if (
                filters &&
                networkTag !== NetworkTags.NBS &&
                networkTag !== NetworkTags.MOD &&
                networkTag !== ''
              )
                return true;
              return false;
            })
            .map((installationCardData: InstallationCardData, index: number) => {
              const { network, deviations, installation } = installationCardData;
              return (
                <InstallationCard
                  key={`ongoing-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => handleCardClick(installationCardData)}
                />
              );
            })}
        </CardContent>
      )}
    </>
  );
};

export default OngoingInstallationsList;
