import React, { JSX } from 'react';
import { styled, Typography } from '@mui/material';

import RaccoonSign from './assets/raccoon_sign.png';

type WarningProps = {
  message: string;
};

const ImageRaccoonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const ImageRaccoon = styled('img')(() => ({
  maxWidth: '200px',
  width: '40%',
}));

const TextContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(1),
  border: `1px solid ${theme.palette.text.secondary}`,
  color: theme.palette.text.secondary,
  '& h6': {
    fontWeight: 'bold',
  },
}));

const Warning = (props: WarningProps): JSX.Element => {
  return (
    <div>
      <ImageRaccoonWrapper>
        <ImageRaccoon src={RaccoonSign} alt="image-raccoon" />
      </ImageRaccoonWrapper>
      <TextContainer>
        <Typography variant="h6">{props.message}</Typography>
      </TextContainer>
    </div>
  );
};

export default Warning;
