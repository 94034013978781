import {
  AuthenticatorProps,
  Navigation,
  theme,
  WithAuthenticator,
  WithAuthenticatorProps,
} from '@konecorp/ui-library';
import { Box, CssBaseline, styled } from '@mui/material';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { generatePath, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Context from '../../context';
import { ActivityDifferentiator, InstallationStatus, NetworkTags } from '../../schemas';
import { useSetLanguage } from '../../hooks/useSetLanguage';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useSetServiceWorkerMessageInterval } from '../../hooks/useSetServiceWorkerMessageInterval';
import getMenus from '../../menus';
import ConnectStatusBanner from '../../components/ConnectStatusBanner';
import Installations from '../Installations';
import MyInstallationsList from '../../components/MyInstallationsList';
import OngoingInstallationsList from '../../components/OngoingInstallationsList';
import ToBeStartedInstallationsList from '../../components/ToBeStartedInstallationsList';
import Newinstallation from '../NewInstallation';
import NetworkInstallation, { NetworkInstallationPaths } from '../NetworkInstallation';
import Attachment from '../Attachment';
import Dashboard from '../../components/Dashboard';
import DemoUtilitiesPage from '../../containers/DemoUtilitiesPage';
import Loading from '../../components/Loading';
import SelfAssign from '../../containers/SelfAssign';
import MaintenanceSelfAssign from '../MaintenanceSelfAssign';
import AdminPage from '../../containers/AdminPage';
import InstallationState from '../../context/installation';
import { compareStatus, CompareStatusResult } from '../../helpers/getInstallationLists';
import ErrorMessageDialog from '../../components/ErrorMessageDialog';
import SupervisorChecklist from '../../components/Pre-Checklist';
import InstallationPlan from '../../components/InstallationPlan';

const APP_URL = process.env.REACT_APP_IAPP_URL || '';

const StyledDiv = styled('div')(() => ({
  toolbar: theme.mixins.toolbar,
}));

const NavigationWrapper = styled(Box)(() => ({
  '& .MuiBottomNavigation-root': {
    height: '80px !important',
  },
}));

const KoneEmployeeApp = (props: PropsWithChildren<WithAuthenticatorProps>) => {
  const {
    authState: { isAuthenticated },
    installationData,
    networkNumber,
    updateAuthState,
    updateNetworkNumber,
    updateInstallationData,
    updateAcquireToken,
    updateIsLoading,
    isLoading,
  } = useContext(Context);

  useSetLanguage();

  //Setting up interval to send message to service worker every 5 mins to sync with backend
  useSetServiceWorkerMessageInterval();

  const navigate = useNavigate();
  const location = useLocation();
  const [userRole] = useGetCurrentUserRole();
  const redirectUrl = window.sessionStorage.getItem('redirectUrl');

  const [referrerUrl, setReferrerUrl] = useState(
    window.sessionStorage.getItem('referrerUrl')
  );

  /* This needed because Azure AD perform redirection and we need to know
     what was an URL user want to access
  */
  useEffect(() => {
    const { pathname, search } = location;

    if (pathname.length > 1 && !isAuthenticated) {
      window.sessionStorage.setItem('redirectUrl', `${pathname}${search}`);
    }
    if (pathname.length === 1 && isAuthenticated) {
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    }
    if (pathname.length > 1 && isAuthenticated) {
      window.sessionStorage.removeItem('redirectUrl');
    }
  }, [location, isAuthenticated, history]);

  const isDeviationsOnlyMode =
    userRole === ActivityDifferentiator.SEEN &&
    installationData?.status &&
    compareStatus(installationData.status, InstallationStatus.FOR_SEB_ACCEPTANCE) ===
      CompareStatusResult.AFTER;

  const isPreChecklistMode =
    userRole === ActivityDifferentiator.SPV &&
    installationData?.status &&
    compareStatus(installationData.status, InstallationStatus.TO_BE_STARTED) ===
      CompareStatusResult.SAME;

  useEffect(() => {
    if (isDeviationsOnlyMode)
      navigate(generatePath(NetworkInstallationPaths.DEVIATIONS, { networkNumber }));
  }, [networkNumber, isDeviationsOnlyMode]);

  useEffect(() => {
    const updateLoginData = async (): Promise<void> => {
      try {
        const authResponse = await props.acquireToken(true);
        updateAcquireToken(() => props.acquireToken); //store the function

        // The authResponse (try to call refresh token) can fail in slow network or offline mode
        // which is the reason of ? and passing in empty string. However, this following function, updateGraphData
        // is the one who set authState.isAuthenticated to true or false, if the user already logged-in and there is a cache
        // it will result in a true to isAuthenticated, if there is NO cache aka user never login before, it will force user to login like usual
        // read the updateGraphData detail implementation and service-worker.js for more information
        await props.updateGraphData(authResponse?.accessToken || '');

        const authState = props.getAuthState();
        updateAuthState(authState);
      } catch (error) {
        console.error('updateLoginData', error);
      }
    };

    if (
      !referrerUrl &&
      document.referrer &&
      process.env.REACT_APP_IAPP_URL !== document.referrer
    ) {
      const url = document.referrer;

      setReferrerUrl(url);
      window.sessionStorage.setItem('referrerUrl', url);
    }
    if (!isAuthenticated) updateLoginData();
  }, [isAuthenticated, referrerUrl, props, updateAuthState]);

  const redirectToReferrerUrl = async () => {
    /* DO NOT CHANGE the order of functions calling. All the function must be call after redirect
       otherwise it will cause the child component which use this function unnecessary re-render
       because of shared context
    */
    const currentPreviousPageInternalUrl =
      window.sessionStorage.getItem('previousPageInternalUrl') ?? '/';
    navigate(currentPreviousPageInternalUrl);

    updateIsLoading(true);
    updateNetworkNumber('');
    updateInstallationData(null);
    updateIsLoading(false);
  };

  const showMyPlan =
    (!installationData?.isModelData && !installationData?.isTacoDataQuest) ||
    (installationData?.mergedQuestionSequences?.installerQuestionSetSequence
      ? installationData.mergedQuestionSequences?.installerQuestionSetSequence.length ===
          2 ||
        installationData?.mergedQuestionSequences.installerQuestionSetSequence.length ===
          1
      : installationData?.installerQuestionSetSequence.length === 2 ||
        installationData?.installerQuestionSetSequence.length === 1);

  const selectedMenus = () =>
    getMenus(
      networkNumber,
      installationData?.status || InstallationStatus.TO_BE_STARTED,
      userRole,
      false,
      showMyPlan,
      installationData
    );

  const handleMenuChange = (menuTitle: string): void => {
    if (!menuTitle) {
      return;
    }

    const selectedMenu = selectedMenus().find(
      (item) => item.title === menuTitle.trimEnd()
    );

    if (selectedMenu?.url === location.pathname) {
      return;
    }

    navigate(selectedMenu?.url || '/');
  };

  const getCurrentMenuPageIndex = (url: string): number => {
    switch (true) {
      case url.includes('progress'):
      case url.includes('myday'):
      case url.includes('installation-tasks'):
      case url.includes('starting') &&
        installationData?.networkTag === NetworkTags.MOD &&
        showMyPlan &&
        userRole === ActivityDifferentiator.INST:
        return 0;
      case url.includes('starting') && installationData?.networkTag !== NetworkTags.MOD:
      case url.includes('starting') && installationData?.networkTag === NetworkTags.MOD:
      case url.includes('pre-install-checklist') && showMyPlan:
      case url.includes('execution'):
      case url.includes('deviations') &&
        installationData?.networkTag === NetworkTags.MOD &&
        showMyPlan &&
        userRole === ActivityDifferentiator.INST:
        return 1;
      case url.includes('deviations') &&
        installationData?.networkTag === NetworkTags.MOD &&
        !showMyPlan:
      case url.includes('deviations') &&
        installationData?.networkTag === NetworkTags.MOD &&
        showMyPlan:
      case url.includes('deviations') && installationData?.networkTag !== NetworkTags.MOD:
        return 2;
      default:
        return 0;
    }
  };

  const initialSelectedMenuIndex = getCurrentMenuPageIndex(
    redirectUrl || location.pathname
  );
  const [currentMenuIndex, setCurrentMenuIndex] = useState(
    initialSelectedMenuIndex ? initialSelectedMenuIndex : 0
  );

  const paths: string[] = [
    NetworkInstallationPaths.DEVIATIONS,
    NetworkInstallationPaths.EXECUTION,
    NetworkInstallationPaths.MYDAY,
    NetworkInstallationPaths.MYPLAN,
    NetworkInstallationPaths.PROGRESS,
    NetworkInstallationPaths.STARTING,
    NetworkInstallationPaths.PREINSTALLCHECKLIST,
    NetworkInstallationPaths.INSTALLATIONTASK,
    NetworkInstallationPaths.INSTALLATIONPLANHANDOVER,
  ];

  useEffect(() => {
    setCurrentMenuIndex(initialSelectedMenuIndex);
  }, [initialSelectedMenuIndex]);
  return (
    <>
      {(isLoading || !isAuthenticated) && <Loading backDropSx={{ zIndex: 1500 }} />}
      <ErrorMessageDialog />
      {isAuthenticated && (
        <>
          <Box display="flex" pb={11}>
            <CssBaseline />
            <main style={{ flexGrow: 1, marginTop: theme.spacing(7) }}>
              <StyledDiv />
              <ConnectStatusBanner />
              <Routes>
                <Route
                  path="/"
                  element={<Installations onSignOut={props.onSignOut} />}
                ></Route>
                <Route
                  path="/tobestarted"
                  element={<ToBeStartedInstallationsList />}
                ></Route>
                <Route path="/ongoing" element={<OngoingInstallationsList />}></Route>
                <Route path="/completed" element={<MyInstallationsList />}></Route>
                <Route path="/selfassign" element={<SelfAssign />}></Route>
                <Route
                  path="/maintenanceassign"
                  element={<MaintenanceSelfAssign />}
                ></Route>
                <Route
                  path="/:networkNumber/newinstallation"
                  element={<Newinstallation />}
                ></Route>
                <Route
                  path="/:networkNumber/installation-plan"
                  element={
                    <InstallationState>
                      <InstallationPlan />
                    </InstallationState>
                  }
                ></Route>
                <Route
                  path="/:networkNumber/pre-install-checklist"
                  element={
                    <InstallationState>
                      <SupervisorChecklist />
                    </InstallationState>
                  }
                ></Route>
                {paths.map((path, index) => (
                  <Route
                    path={path}
                    key={index}
                    element={
                      <InstallationState>
                        <NetworkInstallation
                          redirectToReferrerUrl={redirectToReferrerUrl}
                        />
                      </InstallationState>
                    }
                  ></Route>
                ))}

                <Route path="/attachment" element={<Attachment />}></Route>
                <Route path="/dashboard/:countryCode?" element={<Dashboard />}></Route>
                <Route path="/demo" element={<DemoUtilitiesPage />}></Route>
                <Route path="/admin" element={<AdminPage />}></Route>
              </Routes>
            </main>
          </Box>
          {networkNumber && !isDeviationsOnlyMode && !isPreChecklistMode && (
            <footer
              data-testid="footer"
              style={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                width: '100%',
              }}
            >
              <NavigationWrapper>
                <Navigation
                  key={currentMenuIndex}
                  data={selectedMenus()}
                  handleActionButtonClick={handleMenuChange}
                  initalSelectedMenuIndex={initialSelectedMenuIndex}
                  shadow
                />
              </NavigationWrapper>
            </footer>
          )}
        </>
      )}
    </>
  );
};
export { KoneEmployeeApp as PureComponent };

export default WithAuthenticator(KoneEmployeeApp, {
  clientId: 'c49d3833-ee3c-4bfa-8fa8-d7566b7ec0fd',
  authority: 'https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50',
  redirectUri: APP_URL,
  validateAuthority: true,
  postLogoutRedirectUri: APP_URL,
  navigateToLoginRequestUrl: false,
} as AuthenticatorProps);
