import React, { JSX, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Box, Dialog, DialogContent, styled } from '@mui/material';
import { InfoModal } from '@konecorp/ui-library';
import Context from '../../context';
import SubHeader from '../../components/SubHeader';
import InstallationCard from '../../components/InstallationCard';
import Empty from '../../components/Empty';
import Search, { SearchInputType } from '../../components/Search';
import SelfAssignForm from '../../components/SelfAssignForm';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import {
  getNetworkDeviationCount,
  getInstallationData,
  getInstallationStatusIndex,
} from '../../helpers/getInstallationLists';
import { API_TYPE, fetchEmployee, put } from '../../helpers/fetch';
import {
  InstallationCardData,
  CompactNetwork,
  ActivityDifferentiator,
  InstallationStatus,
} from '../../schemas';
import SelfAssignSupervisor from '../../components/SelfAssignSupervisor';

// This regular expects only numbers [0-9] of min-max length of 8 characters (no more, no less)
const SEARCH_NUMBER_REGEX = /^\d{8}$/;

enum DialogType {
  NONE,
  ASSIGN_TO_ME_FORM,
  ASSIGN_TO_ME_SUCCESS,
  ASSIGN_TO_SPV_VALIDATE,
}

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  overflow: 'auto',
}));

export const getAssignToMeSuccessText = (
  status: InstallationStatus | undefined | null,
  role: ActivityDifferentiator
): string => {
  const ASSIGN_SUCCESS = 'selfAssignPage.assignToMeSuccess';
  const ASSIGN_SUCCESS_TO_BE_STARTED = 'selfAssignPage.assignToMeSuccessToBeStarted';
  const ASSIGN_SUCCESS_ONGOING = 'selfAssignPage.assignToMeSuccessOngoing';

  if (!status) return ASSIGN_SUCCESS_TO_BE_STARTED;

  const currentStatusIndex = getInstallationStatusIndex(status);
  const installerAcceptedIndex = getInstallationStatusIndex(
    InstallationStatus.INSTALLER_ACCEPTED
  );
  const testerAcceptedIndex = getInstallationStatusIndex(
    InstallationStatus.TESTER_ACCEPTED
  );

  if (role === ActivityDifferentiator.INST) {
    if (currentStatusIndex < installerAcceptedIndex) return ASSIGN_SUCCESS_TO_BE_STARTED;
    if (currentStatusIndex === installerAcceptedIndex) return ASSIGN_SUCCESS_ONGOING;
    else return ASSIGN_SUCCESS;
  } else if (role === ActivityDifferentiator.CMSN) {
    if (currentStatusIndex < testerAcceptedIndex) return ASSIGN_SUCCESS_TO_BE_STARTED;
    if (currentStatusIndex === testerAcceptedIndex) return ASSIGN_SUCCESS_ONGOING;
    else return ASSIGN_SUCCESS;
  }

  return ASSIGN_SUCCESS;
};

const SelfAssign = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const { updateIsLoading } = useContext(Context);
  const [loggedInEmployeeId] = useGetUserData();
  const [isOnline] = useCheckConnection();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isValidSearchNumber, setIsValidSearchNumber] = useState<boolean>(true);
  const [searchInstallationResult, setSearchInstallationResult] = useState<
    InstallationCardData | null | undefined
  >(undefined);
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.NONE);
  const [assignToMeMessage, setAssignToMeMessage] = useState<string>('');

  const queryInstallation = async (searchNumber: string) => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      const installation = await getInstallationData(searchNumber, accessToken);

      const isSupervisor = installation?.supervisorNumber === loggedInEmployeeId;
      const isVariationOrder = installation?.isVariationOrder ?? false;
      let supervisorName = '';
      if (!installation?.networkNumber || isSupervisor || isVariationOrder) {
        setSearchInstallationResult(null);
        return;
      }
      const employeeData = await fetchEmployee(
        installation?.supervisorNumber,
        accessToken
      );
      if (employeeData?.employeeId) {
        supervisorName =
          `${employeeData.legalFirstName} ${employeeData.legalLastName}` || '';
      }
      const deviations = await getNetworkDeviationCount(
        installation.networkNumber,
        accessToken
      );

      const network: CompactNetwork = {
        networkNumber: installation.networkNumber,
        equipmentNumber: installation.equipmentNumber ?? '',
        description: installation.description ?? '',
        networkDescription: installation.networkDescription ?? '',
        salesOrderDescription: installation.salesOrderDescription ?? '',
        milestone: installation.milestone ?? '',
        supervisorNumber: installation.supervisorNumber ?? '',
        isModelData: installation.isModelData ?? true,
        isTacoDataQuest: installation.isTacoDataQuest ?? true,
        supervisorName,
      };

      const searchInstallationResultData = {
        deviations,
        installation,
        network,
      };

      setSearchInstallationResult(searchInstallationResultData);
    } catch (e) {
      console.error(e);
    } finally {
      updateIsLoading(false);
    }
  };

  const handleSearchOnSubmit = async (searchNumber: string) => {
    if (searchNumber !== '') {
      const isValid = SEARCH_NUMBER_REGEX.test(searchNumber);
      setIsValidSearchNumber(isValid);
      if (isValid) {
        await queryInstallation(searchNumber);
      }
    }
  };
  const confirmAssignment = async (
    networkNumber: string,
    newRole: ActivityDifferentiator,
    installationStatus?: InstallationStatus | null
  ) => {
    if (newRole === ActivityDifferentiator.SPV) {
      setDialogType(DialogType.ASSIGN_TO_SPV_VALIDATE);
      return;
    }
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/assignee`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          userRole: newRole,
        }
      );
      await updateInstallationListsInContext();
      setAssignToMeMessage(getAssignToMeSuccessText(installationStatus, newRole));
    } catch (error) {
      setAssignToMeMessage('selfAssignPage.unable');
      console.error('error while updating installation status', error);
    } finally {
      setDialogType(DialogType.NONE);
      updateIsLoading(false);
    }

    const shouldRedirectAsInstaller =
      installationStatus === InstallationStatus.FOR_INSTALLER_ACCEPTANCE &&
      newRole === ActivityDifferentiator.INST;

    const shouldRedirectAsTester =
      installationStatus === InstallationStatus.FOR_TESTER_ACCEPTANCE &&
      newRole === ActivityDifferentiator.CMSN;

    const shouldRedirectToNewInstallationPage =
      shouldRedirectAsInstaller || shouldRedirectAsTester;

    if (shouldRedirectToNewInstallationPage) {
      navigate(`/${networkNumber}/starting`);
      return;
    }

    setDialogType(DialogType.ASSIGN_TO_ME_SUCCESS);
  };
  const confirmSupervisorAssignment = async (networkNumber: string) => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/assignee`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          userRole: ActivityDifferentiator.SPV,
        }
      );
      await updateInstallationListsInContext();
      setAssignToMeMessage('selfAssignPage.assignToMeSuccess');
    } catch (error) {
      setAssignToMeMessage('selfAssignPage.unable');
      console.error('error while updating installation status', error);
    } finally {
      setDialogType(DialogType.NONE);
      updateIsLoading(false);
    }
    setDialogType(DialogType.ASSIGN_TO_ME_SUCCESS);
  };
  const renderAssignToMeDialog = (installationToAssign: InstallationCardData) => {
    const { network } = installationToAssign;
    const successMessage = assignToMeMessage ? t(assignToMeMessage) : '';
    return (
      <>
        <InfoModal
          closeButtonText={t('selfAssignPage.ok')}
          message={successMessage}
          open={dialogType === DialogType.ASSIGN_TO_ME_SUCCESS}
          onClose={() => {
            setDialogType(DialogType.NONE);
            setSearchInstallationResult(undefined);
          }}
          isCenteredMessage
        />
        <Dialog
          data-testid="assign-to-me"
          open={dialogType === DialogType.ASSIGN_TO_SPV_VALIDATE}
        >
          <DialogContent data-testid="modal-assign-to-me">
            <SelfAssignSupervisor
              networkNumber={network.networkNumber}
              installation={installationToAssign.installation}
              currentSupervisor={network.supervisorName || ''}
              onConfirm={confirmSupervisorAssignment}
              onCancel={() => {
                setDialogType(DialogType.NONE);
              }}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          data-testid="assign-to-me"
          open={dialogType === DialogType.ASSIGN_TO_ME_FORM}
        >
          <DialogContent data-testid="modal-assign-to-me">
            <SelfAssignForm
              networkNumber={network.networkNumber}
              installation={installationToAssign.installation}
              onConfirm={confirmAssignment}
              onCancel={() => {
                setDialogType(DialogType.NONE);
              }}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const renderSearchResultCard = (data?: InstallationCardData | null) => {
    if (!data) return null;

    const { network, deviations, installation } = data;
    const { status, firstAssignmentDate, latestAnsweredQdQuestionSet, hasQualityForm } =
      installation;

    return (
      <StyledBox aria-label="searched-installation-found">
        {renderAssignToMeDialog(data)}

        <InstallationCard
          network={network}
          deviations={deviations}
          status={status}
          firstAssignmentDate={firstAssignmentDate}
          latestAnsweredQdQuestionSet={latestAnsweredQdQuestionSet}
          headerColor="grey"
          hasQualityForm={hasQualityForm}
          onClick={() => setDialogType(DialogType.ASSIGN_TO_ME_FORM)}
        />
      </StyledBox>
    );
  };

  useEffect(() => {
    if (!isOnline) {
      setErrorMessage(t('selfAssignPage.unable'));
    } else {
      if (searchInstallationResult === null) {
        setErrorMessage(t('selfAssignPage.installationNotFound'));
      } else {
        setErrorMessage('');
      }
    }
  }, [isOnline, searchInstallationResult]);

  return (
    <Box p={1}>
      <SubHeader
        title={t('selfAssignPage.title')}
        handleGoBackClick={() => {
          navigate('/');
        }}
      />

      <Search
        searchInputType={SearchInputType.NUMERIC}
        label={t('selfAssignPage.label')}
        searchLabel={t('selfAssignPage.searchLabel')}
        maxLength={8}
        errorMessage={!isValidSearchNumber ? t('selfAssignPage.invalidNumber') : ''}
        onSubmit={handleSearchOnSubmit}
        disabled={!isOnline}
      />

      {errorMessage && <Empty message={errorMessage} />}

      {isOnline && renderSearchResultCard(searchInstallationResult)}
    </Box>
  );
};

export default SelfAssign;
