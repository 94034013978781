/* istanbul ignore file */
import React, { JSX, useContext } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Box, styled } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { theme } from '@konecorp/ui-library';
import SubHeader from '../../components/SubHeader';
import Context from '../../context';
import { post, get, API_TYPE } from '../../helpers/fetch';
import GenerateNetworkForm, {
  FormState as GenerateNetworkFormState,
} from '../../components/DemoGenerateNetworkForm';
import { CompactNetwork } from '../../schemas';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';

const ButtonGroup = styled('div')(() => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(5),
}));

const DemoUtilitiesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [getTokenFunction] = useGetToken();
  const { updateIsLoading } = useContext(Context);
  const [employeeId] = useGetUserData();

  const refreshDemoInstallaionOnClick = async () => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await post('v1/demo/refreshNetworks', accessToken, API_TYPE.APPLICATION, {});
      alert('Refresh demo installations completed');
    } catch (e) {
      console.error('Error while refresh demo installation. Error: ', e);
      alert('Refresh demo installations failed');
    } finally {
      updateIsLoading(false);
    }
  };

  const disconnectInstallationOnClick = async () => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      const allNetworks: CompactNetwork[] | null = await get(
        `v1/installations/employee/${employeeId}`,
        accessToken,
        API_TYPE.APPLICATION
      );

      const networkNumberOnly = allNetworks?.map((network) => network.networkNumber);

      await post('v1/demo/disconnectNetwork', accessToken, API_TYPE.APPLICATION, {
        networkNumbers: networkNumberOnly,
      });

      alert('Disconnect installation(s) completed');
    } catch (e) {
      console.error('Error while disconnected installation. Error: ', e);
      alert('Disconnect installation(s) failed');
    } finally {
      updateIsLoading(false);
    }
  };

  const genereteNewNetwork = async (
    formObject: GenerateNetworkFormState
  ): Promise<string> => {
    try {
      updateIsLoading(true);

      const accessToken = await getTokenFunction();
      const networkNumber = await post(
        'v1/demo/generateNetwork',
        accessToken,
        API_TYPE.APPLICATION,
        formObject
      );

      return networkNumber;
    } catch (e) {
      console.error('Error while trying to generate new network for demo. Error: ', e);
      return '';
    } finally {
      updateIsLoading(false);
    }
  };

  if (process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === 'PROD') {
    return <Navigate to="/" replace />;
  } else {
    return (
      <>
        <SubHeader
          title="Demo Utilities"
          handleGoBackClick={() => {
            navigate('/');
          }}
        />
        <ButtonGroup>
          <Button
            variant="outlined"
            endIcon={<RefreshIcon />}
            size="large"
            color="primary"
            onClick={refreshDemoInstallaionOnClick}
            data-testid="refresh-demo-installation-button"
          >
            Refresh demo installation(s)
          </Button>
          <Box pt={3}>
            <Button
              variant="outlined"
              endIcon={<DeleteForeverIcon />}
              size="large"
              color="primary"
              onClick={disconnectInstallationOnClick}
            >
              Disconnect all assigned installation(s)
            </Button>
          </Box>

          <GenerateNetworkForm onSubmit={genereteNewNetwork} />
        </ButtonGroup>
      </>
    );
  }
};

export default DemoUtilitiesPage;
