import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

export type InstallationFormProps = {
  onClose: () => void;
  onSubmit: (values: {
    itemType: string;
    description: string;
    additionalInfo?: string;
  }) => void;
};

const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textTransform: 'uppercase',
  borderBottom: '1px solid lightgrey',
}));

const FieldsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 5),
  borderBottom: '1px solid lightgrey',
  '& .MuiFormControl-root': {
    margin: theme.spacing(1, 0),
  },
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 4, 2),
  '& .MuiButton-root:first-child': {
    marginRight: theme.spacing(2),
  },
}));

const InstallationPlanForm = ({
  onClose,
  onSubmit,
}: InstallationFormProps): JSX.Element => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    itemType: yup.string().required('Item type is required'),
    description: yup.string().trim().required('Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      itemType: 'TASK',
      description: '',
      additionalInfo: '',
    },
    validationSchema,
    onSubmit: async (
      values,
      helpers: FormikHelpers<{
        itemType: string;
        description: string;
        additionalInfo?: string;
      }>
    ) => {
      try {
        onSubmit(values);
        helpers.resetForm();
        onClose();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Title align="center">{t('installationPlanList.addInstallationPlan')}</Title>
      <FieldsContainer display="flex" flexDirection="column">
        <Typography mb={1}>{t('installationPlanList.itemTypeTitle')}</Typography>
        <Select
          fullWidth
          name="itemType"
          value={formik.values.itemType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          sx={{ mb: 2 }}
        >
          <MenuItem value="TASK">{t('installationPlanList.itemType')}</MenuItem>
        </Select>

        <Typography mb={1}>{t('installationPlanList.description')}</Typography>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          name="description"
          multiline
          rows={3}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          placeholder="Enter description"
        />

        <Typography mb={1} mt={2}>
          {t('installationPlanList.additionalInfo')}
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          name="additionalInfo"
          multiline
          rows={3}
          value={formik.values.additionalInfo}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter additional info"
          sx={{ mb: 2 }}
        />
      </FieldsContainer>
      <ButtonGroup
        display="flex"
        flexDirection="row"
        flex="1"
        justifyItems="space-between"
      >
        <Button fullWidth variant="outlined" onClick={onClose} color="primary">
          {t('installationPlanList.cancel')}
        </Button>
        <Button
          fullWidth
          disableElevation
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.isValid || !formik.dirty}
        >
          {t('installationPlanList.save')}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default InstallationPlanForm;
