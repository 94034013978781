import React, { PropsWithChildren, useState, useContext, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';

import {
  Installation,
  PlumbingMeasurementCategory,
  PlumbingAdjustmentType,
} from '../../schemas';
import Context from '../../context';
import QDQuestionImage from '../QDQuestionImage';
import { API_TYPE, post, get } from '../../helpers/fetch';
import PlumbingSummaryTable, { PlumbingSummaryAnswer } from '../PlumbingSummaryTable';
import PlumbingXYAdjustments, {
  PlumbingAdjustmentAction,
  AdjustmentsState,
  defaultXYAdjustmentsState,
  AdjustmentCoordinates,
} from '../PlumbmingXYAdjustments';
import {
  calculatePlumbingThreshold,
  parseDataForSummaryTableRows,
  getUpdatedAnswersOnChangeXYValues,
  extractChangedAnswersInPlumbingSummary,
  cannotMakeAdjustments,
  getPlumbingMeasurements,
} from '../../helpers/plumbingSummary';
import NavigationButtons from '../NavigationButtons';
import SendToDeviationsButton from '../SendToDeviationsButton';
import { CONTAINER_HEIGHT } from '../../containers/NetworkInstallation';
import { addHandoverStep } from '../../helpers/question';
import { useGetToken } from '../../hooks/useGetToken';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';
import { styled } from '@mui/material';
import { updateInstallationDataWithMerge } from '../../helpers/updateMergedData';

const PlumbingContainer = styled(Box)(({ theme }) => ({
  minHeight: CONTAINER_HEIGHT,
  paddingBottom: theme.spacing(3),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  minHeight: theme.spacing(35),
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  padding: `0 ${theme.spacing(1.5)}`,
  fontSize: theme.typography.fontSize * 1.6,
}));

type TabPanelProps = {
  children: JSX.Element | string;
  value: number;
  index: number;
};
const TabPanel = (props: PropsWithChildren<TabPanelProps>): JSX.Element => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box pt={0.5}>{children}</Box>}
    </div>
  );
};

const getTabAriaControls = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export type PlumbingSummaryPageProps = {
  onCompletePlumbing: () => void;
  onNavigateBack: () => void;
  imageLink: string | null;
  installationData: Installation;
};

const PlumbingSummaryPage = (
  props: PropsWithChildren<PlumbingSummaryPageProps>
): JSX.Element => {
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();
  const [isReadOnlyMode] = useIfReadOnly();

  const { onNavigateBack, onCompletePlumbing, imageLink, installationData } = props;

  const getUpdatedThresholdValues = (
    adjustmentStateToUpdate: AdjustmentsState[],
    summaryTableData: PlumbingSummaryAnswer[][]
  ): AdjustmentsState[] => {
    const { xMaxToNegativeX, xMaxToPositiveX, yMaxToNegativeY, yMaxToPositiveY, error } =
      calculatePlumbingThreshold(summaryTableData);
    const newAdjustmentState = adjustmentStateToUpdate.map(
      (adjustment: AdjustmentsState): AdjustmentsState => {
        if (error) return { ...adjustment, error };

        switch (adjustment.key) {
          case AdjustmentCoordinates.X:
            return {
              ...adjustment,
              error: cannotMakeAdjustments(xMaxToNegativeX, xMaxToPositiveX),
              thresholdText: t('plumbingSummary.thresholdText', {
                min: xMaxToNegativeX,
                max: xMaxToPositiveX,
              }),
            };

          case AdjustmentCoordinates.Y:
            return {
              ...adjustment,
              error: cannotMakeAdjustments(yMaxToNegativeY, yMaxToPositiveY),
              thresholdText: t('plumbingSummary.thresholdText', {
                min: yMaxToNegativeY,
                max: yMaxToPositiveY,
              }),
            };
        }
      }
    );
    return newAdjustmentState;
  };

  const { networkNumber, updateInstallationData, updateIsLoading } = useContext(Context);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const onTabSwitch = (
    event: React.ChangeEvent<unknown>,
    newTabIndexToSelect: number
  ) => {
    setSelectedTabIndex(newTabIndexToSelect);
  };
  const timeout = 1000;
  const plumbingMeasurementPoints: PlumbingMeasurementCategory =
    getPlumbingMeasurements(installationData);
  const parsedAnswersFromProps = parseDataForSummaryTableRows(
    installationData,
    plumbingMeasurementPoints.length
  );
  const [summaryTableData, setSummaryTableData] = useState<PlumbingSummaryAnswer[][]>(
    parsedAnswersFromProps || [[]]
  );
  const { plumbingAdjustment } = installationData;
  //Parsing the plumbingAdjustment data as per the frontend requirements
  const getDefaultAdjustments = (
    plumbingAdjustment: PlumbingAdjustmentType | undefined
  ): AdjustmentsState[] => {
    const defaultAdjustments = plumbingAdjustment
      ? [
          {
            value: plumbingAdjustment.x,
            key: AdjustmentCoordinates.X,
          },
          {
            value: plumbingAdjustment.y,
            key: AdjustmentCoordinates.Y,
          },
        ]
      : defaultXYAdjustmentsState;

    return getUpdatedThresholdValues(defaultAdjustments, parsedAnswersFromProps);
  };

  const [adjustments, setAdjustments] = useState(
    getDefaultAdjustments(plumbingAdjustment)
  );

  const resetSummaryTable = () => {
    // reset the whole table and XYCoordinates
    setSummaryTableData(parsedAnswersFromProps);
    setAdjustments(getDefaultAdjustments(plumbingAdjustment));
  };
  /** casting plumbing data from backend type to frond end type
   */
  const castPlumbingAdjustmentToBackendSchema = (
    updatedPlumbingAdjustments: AdjustmentsState[]
  ) => {
    const data = updatedPlumbingAdjustments.reduce((modifiedAdj, adjustment) => {
      return { ...modifiedAdj, [adjustment.key.toLowerCase()]: adjustment.value };
    }, {});
    return data;
  };
  const onClickCompletePlumbing = async () => {
    const changedAnswersAsPayload = extractChangedAnswersInPlumbingSummary(
      parsedAnswersFromProps,
      summaryTableData
    ).map((answer) => ({
      questionSetId: answer.questionSetId,
      questionSequenceNumber: answer.questionSequenceNumber,
      value: answer.value,
      tag: answer.tag,
      timestamp: Date.now(),
    }));

    updateIsLoading(true);
    try {
      if (changedAnswersAsPayload.length) {
        const accessToken = await getTokenFunction();

        await post(
          `v1/installations/${networkNumber}/answers`,
          accessToken,
          API_TYPE.APPLICATION,
          {
            answers: changedAnswersAsPayload,
            plumbingAdjustment: castPlumbingAdjustmentToBackendSchema(adjustments),
          }
        );

        const data: Installation | null = await get(
          `v1/installations/${networkNumber}`,
          accessToken
        );
        const installationDataWithHandover = data ? addHandoverStep(data) : null;
        updateInstallationDataWithMerge(
          installationDataWithHandover,
          updateInstallationData
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      updateIsLoading(false);
    }
    onCompletePlumbing();
  };

  const updateAdjustmentsState = (updatedAdjustment: AdjustmentsState) => {
    const { key, actionType } = updatedAdjustment;
    const updatedSummaryTableData = getUpdatedAnswersOnChangeXYValues(
      summaryTableData,
      updatedAdjustment
    );
    const newAdjustmentValues = adjustments.map((adjustment) => {
      if (key === adjustment.key) {
        switch (actionType) {
          case PlumbingAdjustmentAction.INCREMENT:
            adjustment.value = adjustment.value + 1;
            break;
          case PlumbingAdjustmentAction.DECREMENT:
            adjustment.value = adjustment.value - 1;
            break;
          default:
            break;
        }
      }
      return adjustment;
    });
    setAdjustments(newAdjustmentValues);
    setSummaryTableData(updatedSummaryTableData);
  };

  return (
    <PlumbingContainer
      data-testid="plumbing-summary"
      display="flex"
      flexDirection="column"
    >
      <Tabs
        TabIndicatorProps={{ style: { background: '#0071B9' } }}
        value={selectedTabIndex}
        onChange={onTabSwitch}
        aria-label="installation summary tabs"
      >
        <CustomTab
          data-testid="tab-summary"
          label={t('plumbingSummary.titleSummary')}
          {...getTabAriaControls(0)}
        />
        <CustomTab
          data-testid="tab-lasers"
          label={t('plumbingSummary.titlePositions')}
          {...getTabAriaControls(1)}
        />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <Fade in={selectedTabIndex === 0} timeout={timeout}>
          <div>
            {summaryTableData && plumbingMeasurementPoints ? (
              <PlumbingSummaryTable
                summaryTableData={summaryTableData}
                plumbingMeasurementPoints={plumbingMeasurementPoints}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </Fade>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <Fade in={selectedTabIndex === 1} timeout={timeout}>
          <ImageContainer
            mb={4}
            data-testid="plumbing-laser-image"
            display="flex"
            justifyContent="center"
          >
            <QDQuestionImage imageLink={imageLink} />
          </ImageContainer>
        </Fade>
      </TabPanel>
      <Box>
        <PlumbingXYAdjustments
          adjustments={adjustments}
          onChangeAdjustments={updateAdjustmentsState}
          onResetCoordinates={resetSummaryTable}
          readOnly={isReadOnlyMode}
        />
      </Box>
      <Box justifyContent="center" p={3} mt="auto">
        <NavigationButtons
          onClickBackward={onNavigateBack}
          onClickForward={onClickCompletePlumbing}
          backwardButtonText={t('plumbingSummary.backButton')}
          forwardButtonText={t('plumbingSummary.confirmButton')}
        />
        <Box mt={2} display="flex" justifyContent="center">
          <SendToDeviationsButton />
        </Box>
      </Box>
    </PlumbingContainer>
  );
};

export default PlumbingSummaryPage;
