import React, { JSX } from 'react';

import {
  IconFiletypeAudio,
  IconFiletypeOther,
  IconFiletypePhoto,
  IconFiletypeVideo,
} from '@konecorp/ui-library';

import { isAudioFile, isImageFile, isVideoFile } from '../../helpers/allowedFileTypes';

type FileIconProps = {
  fileName: string;
};

const FileIcon = (props: FileIconProps): JSX.Element => {
  const { fileName } = props;

  if (isImageFile(fileName)) return <IconFiletypePhoto />;
  if (isVideoFile(fileName)) return <IconFiletypeVideo />;
  if (isAudioFile(fileName)) return <IconFiletypeAudio />;

  return <IconFiletypeOther />;
};

export default FileIcon;
