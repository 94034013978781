import React, { JSX, useContext, useState } from 'react';
import { Fab, Badge, Box, styled } from '@mui/material';
import { Sync } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { InfoModal } from '@konecorp/ui-library';
import Context from '../../context';
import { useCheckDataSync } from '../../hooks/useCheckDataSync';
import { useGetToken } from '../../hooks/useGetToken';
import { syncWithBackend as manualSyncAnswers } from '../../helpers/syncHelper';
import { manualSyncFromUI as manualSyncDeviation } from '../../helpers/deviationOffline';
import { useCheckConnection } from '../../hooks/useCheckConnection';

const FloatingSyncButton = (): JSX.Element => {
  const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isLoading, updateIsLoading, networkNumber } = useContext(Context);
  const { hasToSync, setPending } = useCheckDataSync();
  const [getTokenFunction] = useGetToken();
  const [isOnline] = useCheckConnection();

  const FloatingBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    left: 'calc(100vw - 75px)',
    bottom: '95px',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      left: 'calc(100vw - 95px)',
    },
  }));

  const handleSync = async () => {
    if (!isOnline) {
      setShowOfflineModal(true);
      return;
    }

    try {
      const accessToken = await getTokenFunction();

      if (hasToSync) {
        updateIsLoading(true);

        await Promise.all([
          manualSyncAnswers(networkNumber, accessToken),
          manualSyncDeviation(networkNumber, accessToken),
        ]);

        setPending(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      updateIsLoading(false);
    }
  };

  if (isLoading || !hasToSync) {
    return <></>;
  }

  return (
    <>
      <FloatingBox onClick={handleSync} data-testid="floating-sync-button">
        <Badge color="primary" badgeContent="sync" variant="standard">
          <Fab size="large" sx={{ backgroundColor: 'white' }}>
            <Sync />
          </Fab>
        </Badge>
      </FloatingBox>

      {showOfflineModal && (
        <InfoModal
          open={true}
          message={t('connection.offlineAlert')}
          onClose={(): void => setShowOfflineModal(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </>
  );
};

export default FloatingSyncButton;
