import React, { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  styled,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getDayCount, formatDuration } from '../../helpers/formating';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

export interface DescriptionProps {
  code: string;
  text: string;
}

export interface EndObjectAttributeValueProps {
  description: DescriptionProps[];
  tacoStartHour: string;
  tacoEndHour: string;
  tacoPlannedHours: string;
}

export interface WorkFlowTaskProps {
  endObjectAttributeValues: EndObjectAttributeValueProps;
  endObjectSpecification: string;
  relationshipAttributeValues: object;
  relationshipId: string;
  workFlowTasks: WorkFlowTaskProps[];
  classname?: string;
  level?: number;
}

export interface WorkFlowTasksProps {
  data: WorkFlowTaskProps[];
  dayLengthInHours: number;
}

export type RowProps = Pick<
  WorkFlowTaskProps,
  'endObjectAttributeValues' | 'workFlowTasks' | 'level'
> &
  Pick<WorkFlowTasksProps, 'dayLengthInHours'> & { index: number };

const StyledTableRow = styled(TableRow)(() => ({
  '&.row-level-1': {
    backgroundColor: '#005A94',
    '& .MuiTableCell-body, & .MuiIconButton-root': {
      color: '#FFFFFF',
    },
  },
  '&.row-level-2': {
    backgroundColor: '#338DC7',
    '& .MuiTableCell-body, & .MuiIconButton-root': {
      color: '#FFFFFF',
    },
  },
  '&.row-odd': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #F5F6F7',
  },
  '&.row-even': {
    backgroundColor: '#F5F6F7',
    '& .MuiTableCell-body, & .MuiIconButton-root': {
      color: '#262E33',
    },
  },
}));

const WorkFlowTasks = ({ data, dayLengthInHours }: WorkFlowTasksProps): JSX.Element => {
  const { i18n } = useTranslation();
  const [isSubcontractor] = useIfSubcontractor();

  const Row = ({
    level = 1,
    index,
    endObjectAttributeValues,
    workFlowTasks,
    dayLengthInHours,
  }: RowProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const { description, tacoStartHour, tacoEndHour } = endObjectAttributeValues;
    const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

    const localizedDescription = getLocalizedText(i18n, description);

    let taskWidth = '400';

    if (level > 2) {
      taskWidth = '400';
    }

    return (
      <>
        <TableRow
          component={StyledTableRow}
          sx={{
            ...(level === 1 && {
              backgroundColor: '#005A94',
              '& .MuiTableCell-body, & .MuiIconButton-root': { color: '#FFFFFF' },
            }),
            ...(level === 2 && {
              backgroundColor: '#338DC7',
              '& .MuiTableCell-body, & .MuiIconButton-root': { color: '#FFFFFF' },
            }),
            ...(level > 2 &&
              index % 2 === 0 && {
                backgroundColor: '#F5F6F7',
                '& .MuiTableCell-body, & .MuiIconButton-root': { color: '#262E33' },
              }),
            ...(level > 2 &&
              index % 2 !== 0 && {
                backgroundColor: '#FFFFFF',
                border: '1px solid #F5F6F7',
              }),
          }}
        >
          <TableCell width={taskWidth}>{localizedDescription?.text}</TableCell>
          {!isSubcontractor && (
            <TableCell width="50" aria-label="day-counter-cell">
              {getDayCount(tacoStartHour, dayLengthInHours)}
            </TableCell>
          )}
          {!isSubcontractor && (
            <TableCell width="200" aria-label="target-hours-cell" align="right">
              {formatDuration(
                Number(tacoEndHour) - Number(tacoStartHour),
                dayLengthInHours
              )}
            </TableCell>
          )}
          <TableCell width="50">
            <IconButton
              aria-label="expand-or-collapse-row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {workFlowTasks.length > 0 && chevron}
            </IconButton>
          </TableCell>
        </TableRow>
        {workFlowTasks.length > 0 && (
          <TableRow>
            <TableCell padding="none" colSpan={24} style={{ paddingLeft: '12px' }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>
                    {workFlowTasks.map((row, index) => (
                      <Row
                        key={`${index}--${row.endObjectSpecification}`}
                        level={level + 1}
                        index={index}
                        dayLengthInHours={dayLengthInHours}
                        {...row}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <Paper elevation={0}>
      <Table aria-label="collapsible table">
        <TableBody>
          {data.map((row, index) => (
            <Row
              key={`${index}--${row.endObjectSpecification}`}
              index={index}
              dayLengthInHours={dayLengthInHours}
              {...row}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default WorkFlowTasks;
