import React, { JSX, PropsWithChildren } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
} from '@mui/material';
import { theme } from '@konecorp/ui-library';

import { useTranslation } from 'react-i18next';
import { Answer, PlumbingMeasurementCategory, Question } from '../../schemas';
import {
  useGetFloorOrientation,
  FloorOrientation,
} from '../../hooks/useGetFloorOrientation';

const PLUMBING_QUESTIONSET_ID = 'TACO01001';

export const StyledTableHeadCell = styled(TableCell)(() => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1),
}));

// Caution! Do not change. This is always in this order,
export const PLUMBING_MEASUREMENTS = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6'];

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderBottom: 0,
  color: theme.palette.primary.dark,
}));

export const StyledTableErrorCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: `2px solid ${theme.palette.error.dark}`,
  color: theme.palette.primary.dark,
}));

export const StyledTableWarningCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: `2px solid ${theme.palette.warning.main}`,
  color: theme.palette.primary.dark,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledTable = styled(Table)(() => ({
  maxWidth: '100%',
}));

const StyledTableContainer = styled(TableContainer)(() => ({
  maxWidth: '100%',
  padding: theme.spacing(0.2),
}));

const TableCellFirstColumn = styled(StyledTableCell)(() => ({
  color: theme.palette.secondary.main,
  width: theme.spacing(10),
  borderBottom: theme.spacing(0),
}));

enum CellType {
  ERROR = 'error',
  WARNING = 'warning',
}

/**
 *
 * @param answer
 * @returns true when value is out of the range
 */
const checkIsErrorOrWarning = (answer: PlumbingSummaryAnswer): CellType | undefined => {
  if (answer) {
    // 3mm plus/minus range #TASK 124695
    const WARNING_THRESHOLD = 3;
    const { value, actualValue, negativeTolerance, positiveTolerance } = answer;

    const referenceValue = actualValue ?? '';
    const answerValue = typeof value === 'number' ? value : 0;
    const minPossibleValue: number =
      parseFloat(referenceValue) - parseFloat(negativeTolerance || '0');
    const maxPossibleValue: number =
      parseFloat(referenceValue) + parseFloat(positiveTolerance || '0');

    const isValueOutOfRange =
      answerValue < minPossibleValue || answerValue > maxPossibleValue;

    const isValueAboutToGoOutOfRange =
      !isValueOutOfRange &&
      (answerValue < minPossibleValue + WARNING_THRESHOLD ||
        answerValue > maxPossibleValue - WARNING_THRESHOLD);

    // when answer/value/referenceValue is not available.
    const isError: boolean = isValueOutOfRange || !value || !referenceValue;
    const isWarning: boolean = isValueAboutToGoOutOfRange;

    if (isError) return CellType.ERROR;
    else if (isWarning) return CellType.WARNING;
    else return;
  }
  return;
};

/**
 * Below function will contain the logic to check for miscalculations and
 * @returns style specific component
 */
const renderTableCell = (answer: PlumbingSummaryAnswer, index: number): JSX.Element => {
  const { value, gsi1, actualValue } = answer;
  const displayValue =
    (value && typeof value === 'number' ? value : 0) - parseFloat(actualValue || '0');
  const isErrorOrWarning = checkIsErrorOrWarning(answer);
  if (isErrorOrWarning) {
    const HighlightedCell =
      isErrorOrWarning === CellType.WARNING
        ? StyledTableWarningCell
        : StyledTableErrorCell;
    return (
      <HighlightedCell
        data-testid={`plumbing-summary-table-cell-${gsi1}`}
        key={`summary-table-cell-${index}-${index + 1}`}
        align="center"
      >
        {value ? displayValue : '—'}
      </HighlightedCell>
    );
  }
  return (
    <StyledTableCell
      data-testid={`plumbing-summary-table-cell-${gsi1}`}
      key={`summary-table-cell-${index}-${index + 1}`}
      align="center"
    >
      {displayValue}
    </StyledTableCell>
  );
};

// Special type to contain both answers and question related fields.
export interface PlumbingSummaryAnswer
  extends Answer,
    Partial<Omit<Question, 'position' | 'modifiedAt'>> {}

export type PlumbingSummaryTableProps = {
  summaryTableData: PlumbingSummaryAnswer[][];
  plumbingMeasurementPoints: PlumbingMeasurementCategory;
};

const PlumbingSummaryTable = (
  props: PropsWithChildren<PlumbingSummaryTableProps>
): JSX.Element => {
  /**
   * For now we are having hardcoded values for the Summary table column headers (1 + 6 columns)
   * Later when we have this data available, we can make this dynamic
   */
  const { t } = useTranslation();
  const [getFloorOrientation] = useGetFloorOrientation();

  const { summaryTableData, plumbingMeasurementPoints } = props;
  const tableHeaders = [t('plumbingSummary.tableHeader'), ...plumbingMeasurementPoints];

  const floorOrientation = getFloorOrientation(PLUMBING_QUESTIONSET_ID);

  return (
    <StyledTableContainer data-testid="plumbing-summary-table">
      <StyledTable aria-label="summary table">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <StyledTableHeadCell key={`summary-table-th-${index}`} align="center">
                {header}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryTableData.map((floor, index, summaryData) => {
            return (
              <StyledTableRow key={`plumbing-row-${index}`}>
                <>
                  <TableCellFirstColumn
                    key={`summary-table-cell-${index}-0`}
                    data-testid={`summary-table-floor-info-cell-${index}`}
                    align="center"
                  >
                    {floorOrientation === FloorOrientation.TOP_TO_BOTTOM
                      ? summaryData.length - index
                      : index + 1}
                  </TableCellFirstColumn>
                  {floor.map((answer, floorIndex) =>
                    renderTableCell(answer as Answer, floorIndex)
                  )}
                </>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default PlumbingSummaryTable;
