import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon, SvgIconComponent } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import UserMenu from '../UserMenu';

export interface HeaderBarProps {
  handleSidebarToggle: () => void;
  isAuthenticated: boolean;
  logo?: string;
  onSignOut: () => void;
  sidemenuWidth: number;
  title?: string;
  titleIcon?: SvgIconComponent;
  userMenuTitle?: string;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'sidemenuWidth',
})<{ sidemenuWidth: number }>(({ theme, sidemenuWidth }) => ({
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${sidemenuWidth}px - '10px')`,
    marginLeft: sidemenuWidth,
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  display: 'none', // Hide by default
  // Uncomment these lines to make it visible on mobile devices
  // [theme.breakpoints.down('sm')]: {
  //   display: 'block',
  // },
}));

const HeaderBar = (props: HeaderBarProps): JSX.Element => {
  const getIcon = (Icon?: SvgIconComponent) =>
    Icon ? (
      <Icon
        className={`headerbar-icon ${props.title?.toLowerCase()}-headerbar-icon`}
        sx={{ marginRight: '1rem' }}
      />
    ) : null;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isLargeScreen = useMediaQuery('(min-width:601px)');
  const renderUserMenu = () => (
    <>
      {isLargeScreen && (
        <UserMenu onSignOut={props.onSignOut} title={props.userMenuTitle} />
      )}
      {isSmallScreen && <UserMenu onSignOut={props.onSignOut} />}
    </>
  );

  return (
    <StyledAppBar position="fixed" sidemenuWidth={props.sidemenuWidth}>
      <Toolbar>
        <StyledIconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleSidebarToggle}
        >
          <MenuIcon />
        </StyledIconButton>
        {getIcon(props.titleIcon)}
        <Typography
          variant="h6"
          noWrap
          className={`headerbar-title ${props.title?.toLowerCase()}-headerbar-title`}
          sx={{ flexGrow: 1 }}
        >
          {props.title}
        </Typography>
        {props.isAuthenticated && renderUserMenu()}
        {props.logo && <img src={props.logo} alt="logo" />}
      </Toolbar>
    </StyledAppBar>
  );
};

export default HeaderBar;
