import React, { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid2,
  Link,
  styled,
  Typography,
} from '@mui/material';
import { CircleCancel, InfoModal, theme } from '@konecorp/ui-library';

import Context from '../../context';
import { API_TYPE, fetchEmployeeFullName, get, put, remove } from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Assignment,
  Attachment,
  Contact,
  Contract,
  ContractType,
  Handover,
  InstallationStatus,
  SiteInfo,
  SiteInfoType,
  SubcontractorRecord,
} from '../../schemas';
import { truncateNetworkFromIndexedDB } from '../../helpers/indexedDB';
import { useGetToken } from '../../hooks/useGetToken';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetUserData } from '../../hooks/useGetUserData';
import InstallationStartingTester from '../../components/InstallationStartingTester';
import { formatDate } from '../../helpers/formating';
import { useNavigate } from 'react-router-dom';
import AddButton from '../../components/AddButton';
import { DialogType } from '../../components/TeamList';
import ContactForm from '../../components/ContactForm';
import ContactInfoDetails from '../../components/ContactInfo';
import { grey } from '@mui/material/colors';
import ErrorMessageDialog from '../../components/ErrorMessageDialog';

import ContractInfoDetails from '../../components/ContractInfoDetails';
import ContractForm from '../../components/ContractForm';
import SiteInfoDetails from '../../components/SiteInfoDetails';
import SiteInfoForm from '../../components/SiteInfoForm';
import { uploadAttachments } from '../../helpers/upload-download';
import { PickerWithButtonField } from '../../components/DatePickerWithIcon';
import dayjs, { Dayjs } from 'dayjs';

enum InfoModalType {
  NONE,
  IS_OFFLINE,
  CONFIRMATION,
}

const GreyHeadline = styled(Typography)(() => ({
  backgroundColor: grey[200],
  padding: theme.spacing(1),
  textTransform: 'uppercase',
}));

const StyledGrid = styled(Grid2)(() => ({
  width: 150,
  height: 30,
  marginTop: '20px',
  right: theme.spacing(3),
  top: theme.spacing(10),
}));

const StyledDivider = styled(Divider)(() => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
const StyledTypography = styled(Typography)(() => ({
  padding: theme.spacing(1),
  textTransform: 'uppercase',
}));
const StyledContainer = styled(Box)(() => ({
  margin: theme.spacing(5),
  maxWidth: '100vw',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '& button': {
    marginTop: theme.spacing(5),
  },
}));
const CancelButton = styled(Button)(() => ({
  color: 'black',
  backgroundColor: 'rgb(224, 224, 224)',
  '&:hover': {
    backgroundColor: 'rgb(200, 200, 200)',
  },
}));
const CircleCancelIcon = styled(CircleCancel)(() => ({
  width: 30,
  height: 30,
  position: 'absolute',
  zIndex: 1,
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  textDecoration: 'underline',
}));

type InstallationStartingProps = {
  contacts: Contact[];
  contracts: Contract[];
  siteInfos: SiteInfo[];
  setContracts: (contracts: Contract[]) => void;
  setContacts: (contacts: Contact[]) => void;
  setSiteInfos: (siteInfos: SiteInfo[]) => void;
  itemToRemove: Contact | Contract | SiteInfo | null;
  setItemToRemove: (item: Contact | Contract | SiteInfo) => void;
};
const InstallationStarting = (props: InstallationStartingProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();
  const [role] = useGetCurrentUserRole();
  const { updateIsLoading, networkNumber, installationData, updateErrorMessage } =
    useContext(Context);
  const [showDialog, setShowDialog] = useState<DialogType>(DialogType.NONE);
  const [selectedItem, setSelectedItem] = useState<Contact | Contract | SiteInfo | null>(
    null
  );
  const [dialogType, setDialogType] = useState<InfoModalType>(InfoModalType.NONE);
  const [allowStart, setAllowStart] = useState<boolean>(false);
  const [supervisorFullName, setSupervisorFullName] = useState<string>('');
  const [handover, setHandover] = useState<Handover | null>(null);
  const [isSubcontractor] = useIfSubcontractor();
  const [employeeId] = useGetUserData();
  const [errorMsg, setErrorMsg] = useState(false);
  const navigate = useNavigate();
  const { itemToRemove, setItemToRemove } = props;
  const [inspectDate, setInspectDate] = useState<Dayjs | null>(
    dayjs(installationData?.inspectionDate) ?? dayjs()
  );
  const [errorType, setErrorType] = useState<string>('');
  const [getTokenFunction] = useGetToken();

  const nextStatus = (() => {
    switch (role) {
      case ActivityDifferentiator.INST:
        return InstallationStatus.INSTALLER_ACCEPTED;
      case ActivityDifferentiator.CMSN:
        return InstallationStatus.TESTER_ACCEPTED;
      default:
        return undefined;
    }
  })();

  const isInstaller = role === ActivityDifferentiator.INST;
  const isTester = role === ActivityDifferentiator.CMSN;

  useEffect(() => {
    if (installationData?.inspectionDate && installationData?.inspectionDate !== null) {
      setInspectDate(dayjs(installationData.inspectionDate));
    }
    const fetchSupervisorFullName = async (accessToken: string): Promise<string> => {
      return installationData?.supervisorNumber
        ? await fetchEmployeeFullName(installationData.supervisorNumber, accessToken)
        : '';
    };

    const fetchHandoverData = async (accessToken: string): Promise<Handover | null> => {
      try {
        return await get(
          `v1/installations/${networkNumber}/handovers/latest`,
          accessToken
        );
      } catch (error) {
        console.error('error while fetching handover data', error);
      }
      return null;
    };

    (async () => {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      if (isInstaller || isTester) {
        const supervisorFullName = await fetchSupervisorFullName(accessToken);
        setSupervisorFullName(supervisorFullName);
      }

      if (isTester) {
        const handover = await fetchHandoverData(accessToken);
        setHandover(handover);
      }

      updateIsLoading(false);
    })();
  }, [installationData]);
  const sendContact = async (
    contactToSend: Contact,
    setContacts: (contacts: Contact[]) => void,
    contacts: Contact[]
  ) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      const returnedContact: Contact = await put(
        `v1/installations/${networkNumber}/contacts`,
        token,
        API_TYPE.APPLICATION,
        contactToSend
      );

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);

      const updatedContacts =
        contactToSend.guid !== returnedContact.guid
          ? [...contacts, returnedContact]
          : contacts.map((contact) =>
              contact.guid === returnedContact.guid ? returnedContact : contact
            );
      setContacts(updatedContacts);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const sendContract = async (contractToSend: Contract) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      if (contractToSend.value) {
        delete contractToSend.value;
        contractToSend = { ...contractToSend, type: 'link' };
      }
      const returnedContract: Contract = await put(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        contractToSend
      );
      const updatedContract = {
        ...contractToSend,
        sk: returnedContract.sk,
      };

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);
      const updatedContracts =
        contractToSend.sk !== updatedContract.sk
          ? [...props.contracts, updatedContract]
          : props.contracts.map((contract) =>
              contract.sk === updatedContract.sk ? updatedContract : contract
            );
      props.setContracts(updatedContracts);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const sendSiteInfo = async (siteInfoToSend: SiteInfo) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      const uploadFiles: File[] = [];
      const dbFiles: Attachment[] = [];
      if (Array.isArray(siteInfoToSend.attachments)) {
        siteInfoToSend.attachments.forEach((selectedFile: any) => {
          if (selectedFile instanceof File) {
            uploadFiles.push(selectedFile);
          } else {
            dbFiles.push(selectedFile);
          }
        });
      }

      if (uploadFiles.length > 0) {
        try {
          const attachments = await uploadAttachments({
            files: uploadFiles,
            networkNumber,
            questionSequenceNumber: 0,
            questionSetIdParam: siteInfoToSend.type,
            jwtToken: token,
          });

          siteInfoToSend.attachments = [...dbFiles, ...(attachments || [])];
        } catch (error) {
          console.error('Error uploading files:', error);
          return;
        }
      }
      siteInfoToSend.type = 'siteInfo';
      const returnedContract: SiteInfo = await put(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        siteInfoToSend
      );
      const updatedSiteInfo = {
        ...siteInfoToSend,
        sk: returnedContract.sk,
      };

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);
      const updatedSiteInfos =
        siteInfoToSend.sk !== updatedSiteInfo.sk
          ? [...props.siteInfos, updatedSiteInfo]
          : props.siteInfos.map((siteinfo) =>
              siteinfo.sk === updatedSiteInfo.sk ? updatedSiteInfo : siteinfo
            );
      props.setSiteInfos(updatedSiteInfos);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const openInstallationExecution = () => {
    // hard reload the app so when the app go to /execution it has a clean context
    // and trigger the caching again
    if (isSubcontractor) {
      window.location.replace(`/subcontractor/${networkNumber}/execution`);
    } else {
      window.location.replace(`/${networkNumber}/execution`);
    }
  };

  const onStartingInstallation = async () => {
    try {
      if (!isOnline) {
        setDialogType(InfoModalType.IS_OFFLINE);
        return;
      }

      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        { status: nextStatus }
      );

      await truncateNetworkFromIndexedDB(networkNumber);

      switch (role) {
        case ActivityDifferentiator.INST:
          setDialogType(InfoModalType.CONFIRMATION);
          break;
        case ActivityDifferentiator.CMSN:
          openInstallationExecution();
          break;
      }
    } catch (e) {
      console.error('Error when trying to start an installation, error: ', e);
    } finally {
      updateIsLoading(false);
    }
  };
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const token = await getTokenFunction();
    return await put(
      `v1/installations/${networkNumber}/insDateSoldHours`,
      token,
      API_TYPE.APPLICATION,
      { type: 'inspectionDate', value: inspectionDate }
    );
  };
  const handleDateChange = async (newValue: Dayjs | null) => {
    if (installationData) {
      installationData.inspectionDate = newValue?.toISOString() ?? null;
    }
    if (newValue) {
      setInspectDate(newValue);
      await putInsDateSoldHrs(newValue.toISOString());
    }
  };
  const handleChecklistButtonClick = (): void => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    try {
      navigate(`${rootPath}/${networkNumber}/starting/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };

  const getDialogMessage = (): string => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      return t('installationStarting.onlyOnline');
    } else {
      return t('installationStarting.networkStartedMessageWithSupervisorFullName', {
        supervisorFullName: supervisorFullName,
      });
    }
  };

  const onDialogClose = (): void => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      setDialogType(InfoModalType.NONE);
    } else {
      openInstallationExecution();
    }
  };

  if (!installationData || !role) return <></>;

  const { assignees, subcontractors } = installationData;

  const ContactInfo = () => {
    const { customer } = installationData;

    return (
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6 }}>
          <Typography textTransform="uppercase">
            {t('installationStarting.network')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography>{networkNumber}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography textTransform="uppercase">
            {t('installationStarting.supervisor')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography>{supervisorFullName}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography textTransform="uppercase">
            {t('installationStarting.location')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Typography>{customer?.customerName1}</Typography>
          <Typography>{customer?.customerName2}</Typography>
          <Typography>
            {customer?.street} {customer?.houseNumber}
          </Typography>
          <Typography>
            {customer?.cityPostalCode} {customer?.city}, {customer?.countryKey}
          </Typography>
        </Grid2>
      </Grid2>
    );
  };

  const getSubcontractorDates = (
    subcontractorRecords: SubcontractorRecord[],
    role: ActivityDifferentiator
  ) => {
    const subcontractor = subcontractorRecords.find(
      (subcontractor) => subcontractor.activityDifferentiator === role
    );

    const startDate = formatDate(subcontractor?.plannedStartDate);
    const targetDate = formatDate(subcontractor?.plannedEndDate);
    return [startDate, targetDate];
  };

  const getAssignmentDates = (
    currentEmployeeId: string,
    role: ActivityDifferentiator,
    assignees?: Assignment[]
  ) => {
    const currentAssignee = assignees?.find(
      (assignee) =>
        assignee.koneResourcePersonalNumber === currentEmployeeId &&
        assignee.activityDifferentiator === role
    );
    const startDate = formatDate(currentAssignee?.assignmentStartDate);
    const targetDate = formatDate(currentAssignee?.assignmentEndDate);
    return [startDate, targetDate];
  };
  const handleAddContact = () => {
    if (props.contacts.length >= 10) {
      setErrorMsg(true);
      setErrorType('contact');
      return;
    }
    setSelectedItem(null);
    setShowDialog(DialogType.CONTACT);
  };

  const handleAddContract = (contract: Contract, link?: boolean) => {
    if (props.contracts.length >= 10) {
      setErrorMsg(true);
      setErrorType('contract');
      return;
    }
    if (link) {
      contract.title = '';
      contract.contract = false;
    }
    setSelectedItem(contract);
    setShowDialog(DialogType.LINKS);
  };
  const initialContract: Contract = {
    title: ContractType.CONTRACT,
    url: '',
    contract: true,
    type: 'link',
  };
  const intialSiteInfo: SiteInfo = {
    type: 'siteInfo',
    note: '',
    attachments: [],
    name: '' as SiteInfoType,
  };
  const siteInfoButtons = [
    {
      label: `+ ${t('teamList.siteInfo.addSiteAccsessInfo')}`,
      dialogType: DialogType.SITEINFO,
      intialSiteInfo: { ...intialSiteInfo, name: SiteInfoType.SITEACCESSINFO },
    },
    {
      label: `+ ${t('teamList.siteInfo.addBathroomsInfo')}`,
      dialogType: DialogType.SITEINFO,
      intialSiteInfo: {
        ...intialSiteInfo,
        name: SiteInfoType.BATHROOMSINFO,
      },
    },
    {
      label: `+ ${t('teamList.siteInfo.addOtherNoteInfo')}`,
      dialogType: DialogType.SITEINFO,
      intialSiteInfo: { ...intialSiteInfo, name: SiteInfoType.OTHERNOTE },
    },
  ];
  const handleAddSiteAccessInfo = (siteInfo: SiteInfo) => {
    setSelectedItem(siteInfo);
    setShowDialog(DialogType.SITEINFO);
  };
  const SiteInfoButton = () => {
    return (
      <>
        <StyledTypography sx={{ backgroundColor: grey[200] }} as="div">
          {t('teamList.siteInfo.title')}
        </StyledTypography>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            {(props.siteInfos as SiteInfo[])?.map((siteInfo, index) => (
              <SiteInfoDetails
                setItemToRemove={setItemToRemove}
                siteInfo={siteInfo}
                key={index}
                setSelectedItem={setSelectedItem}
                setShowDialog={setShowDialog}
              />
            ))}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            {siteInfoButtons
              .filter(
                (button) =>
                  !props.siteInfos.some(
                    (info) => info.sk && info.name === button.intialSiteInfo.name
                  )
              )
              .map((button, index) => (
                <AddButton
                  key={index}
                  label={button.label}
                  style={{ paddingLeft: 0 }}
                  onClick={() => {
                    handleAddSiteAccessInfo(button.intialSiteInfo);
                  }}
                />
              ))}
          </Grid2>
        </Grid2>
      </>
    );
  };
  const ContactInfoButton = () => {
    return (
      <>
        <GreyHeadline>{t('teamList.contacts')}</GreyHeadline>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            {(props.contacts as Contact[])?.map((contact, index) => (
              <ContactInfoDetails
                setItemToRemove={setItemToRemove}
                contact={contact}
                key={index}
                setSelectedItem={setSelectedItem}
                setShowDialog={setShowDialog}
              />
            ))}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <AddButton
              label={`+ ${t('teamList.addNew')}`}
              onClick={handleAddContact}
              style={{ paddingLeft: 0 }}
            />
          </Grid2>
        </Grid2>
      </>
    );
  };

  const ContractInfoButton = () => {
    return (
      <>
        <GreyHeadline as="div">{t('teamList.links')}</GreyHeadline>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Typography style={{ paddingTop: '5px' }}>
              <Link
                href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${networkNumber}`}
                style={{ textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('teamList.tracePDM')}
              </Link>
            </Typography>
            <Typography style={{ padding: '1px' }}>
              <Link
                href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/site_surveys/${networkNumber}`}
                style={{ textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('teamList.siteSurvey')}
              </Link>
            </Typography>
            <Typography style={{ padding: '1px' }}>
              <Link
                href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/bom/${networkNumber}`}
                style={{ textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('teamList.bom')}
              </Link>
            </Typography>
            {(props.contracts as Contract[])?.map((contract, index) => (
              <ContractInfoDetails
                setItemToRemove={setItemToRemove}
                contract={contract}
                key={index}
                setSelectedItem={setSelectedItem}
                setShowDialog={setShowDialog}
              />
            ))}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <AddButton
              label={'+ Add Contracts'}
              onClick={() => handleAddContract(initialContract)}
              style={{ padding: '5px' }}
            ></AddButton>
            <AddButton
              label={'+ Add Other Link'}
              onClick={() => handleAddContract(initialContract, true)}
              style={{ padding: '5px' }}
            />
          </Grid2>
        </Grid2>
      </>
    );
  };
  const deleteSiteInfo = async (siteInf: SiteInfo) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        { sk: siteInf.sk }
      );
      const updatedSiteInfo = props.siteInfos.filter((c) => c.sk !== siteInf.sk);
      props.setSiteInfos(updatedSiteInfo);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const deleteContact = async (contact: Contact) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/contacts/${contact.guid}`,
        token,
        API_TYPE.APPLICATION
      );
      const updatedContacts = props.contacts.filter((c) => c.guid !== contact.guid);
      props.setContacts(updatedContacts);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const deleteContract = async (contract: Contract) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        { sk: contract.sk }
      );
      const updatedContracts = props.contracts.filter((c) => c.sk !== contract.sk);
      props.setContracts(updatedContracts);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const RemoveDialog = (props: {
    item: Contact | Contract | SiteInfo;
    onCancel: () => void;
  }): JSX.Element => {
    const { item, onCancel } = props;
    const isContract = (
      item: Worker | Contact | Contract | SiteInfo
    ): item is Contract => {
      return (item as Contract).contract !== undefined;
    };
    const isSiteInfo = (
      item: Worker | Contact | Contract | SiteInfo
    ): item is SiteInfo => {
      return (item as SiteInfo).name !== undefined;
    };
    return (
      <>
        <DialogContent
          style={{ paddingTop: theme.spacing(6), paddingBottom: theme.spacing(2) }}
        >
          <Typography align="center" style={{ fontWeight: 'bold' }} gutterBottom>
            {isContract(item as Contract)
              ? t('teamList.remove.titleContract', {
                  title: (item as Contract).title || 'Contract',
                })
              : isSiteInfo(item)
              ? t('teamList.remove.titleSiteInfo', {
                  name:
                    (item as SiteInfo).name === SiteInfoType.SITEACCESSINFO
                      ? 'Site access'
                      : (item as SiteInfo).name === SiteInfoType.BATHROOMSINFO
                      ? 'Bathrooms'
                      : 'Other notes',
                })
              : t('teamList.remove.titleContact', {
                  name:
                    `${(item as Contact).firstName || ''} ${
                      (item as Contact).lastName || ''
                    }`.trim() || '-',
                })}
          </Typography>
          <Typography align="center" style={{ fontWeight: 'bold' }}>
            {t('teamList.remove.question')}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ padding: theme.spacing(2), paddingBottom: theme.spacing(3) }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              isContract(item)
                ? deleteContract(item as Contract)
                : isSiteInfo(item)
                ? deleteSiteInfo(item as SiteInfo)
                : deleteContact(item as Contact)
            }
            fullWidth
          >
            {t('teamList.remove.confirm')}
          </Button>
          <CancelButton
            variant="contained"
            onClick={() => onCancel()}
            autoFocus
            fullWidth
          >
            {t('teamList.remove.cancel')}
          </CancelButton>
        </DialogActions>
      </>
    );
  };
  const InstallationInfo = () => {
    const [startDate, targetDate] =
      role && isSubcontractor
        ? getSubcontractorDates(subcontractors ?? [], role)
        : getAssignmentDates(employeeId, role, assignees);

    const installerTotalHours = {
      message: t('installationStarting.installationHours'),
      value: Math.ceil(Number(installationData.totalInstallationHours)),
    };

    const testerTotalHours = {
      message: t('installationStarting.testingHours'),
      value: Math.ceil(Number(installationData.testerHours)),
    };

    const totalHours = isInstaller ? installerTotalHours : testerTotalHours;

    return (
      <>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6 }}>
            <Typography textTransform="uppercase">
              {t('installationStarting.startDate')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Typography data-testid="start-date"> {startDate ?? ''}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Typography textTransform="uppercase">
              {t('installationStarting.targetDate')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Typography data-testid="target-date"> {targetDate ?? ''}</Typography>
          </Grid2>
          {!isSubcontractor && (
            <>
              <Grid2 size={{ xs: 6 }}>
                <Typography textTransform="uppercase">{totalHours.message}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography data-testid="total-hours">{totalHours.value}</Typography>
              </Grid2>
            </>
          )}
          <Grid2 size={{ xs: 6 }}>
            <Typography textTransform="uppercase">
              {t('supervisorNewInstallation.inspectionDate')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <PickerWithButtonField
              inspectDate={inspectDate}
              handleDateChange={handleDateChange}
              calendarIcon={false}
            />
          </Grid2>
        </Grid2>
        <StyledGrid container flexDirection="row">
          <Grid2 size={{ xs: 12 }}>
            {!isSubcontractor && (
              <Typography>
                <Link
                  target="_blank"
                  href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${installationData.equipmentNumber}`}
                >
                  {t('installationStarting.openTracePDM')}
                </Link>
              </Typography>
            )}
          </Grid2>
        </StyledGrid>
      </>
    );
  };

  return (
    <StyledContainer>
      {(isInstaller || isTester) && (
        <>
          <ContactInfo />
          <StyledDivider />
          <InstallationInfo />
          <StyledDivider />
          <ContractInfoButton />
          <StyledDivider />
          <ContactInfoButton />
          <StyledDivider />
          <SiteInfoButton />
          <StyledDivider />
        </>
      )}
      {isTester && (
        <InstallationStartingTester handover={handover} setAllowStart={setAllowStart} />
      )}
      <ErrorMessageDialog
        editValue={errorMsg}
        handleEditChange={setErrorMsg}
        typeOfError={errorType}
      />
      {isInstaller ? (
        <Button
          variant="contained"
          color="primary"
          data-testid="proceed-to-checklist-button"
          onClick={handleChecklistButtonClick}
        >
          {t('installationStarting.proceedToPreInstallationChecklist')}
        </Button>
      ) : (
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={!allowStart}
          onClick={onStartingInstallation}
          data-testid="start-button"
        >
          {t('installationStarting.start')}
        </Button>
      )}
      <Dialog
        data-testid="dialog-form"
        maxWidth="sm"
        fullWidth
        open={showDialog !== DialogType.NONE}
        scroll="paper"
      >
        <CircleCancelIcon
          onClick={() => {
            setShowDialog(DialogType.NONE);
            setSelectedItem(null);
          }}
        />
        {showDialog === DialogType.CONTACT && (
          <DialogContent style={{ padding: 0 }}>
            <ContactForm
              contactToEdit={(selectedItem || undefined) as Contact | undefined}
              onSubmit={(contact) =>
                sendContact(contact, props.setContacts, props.contacts)
              }
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.SITEINFO && (
          <DialogContent style={{ padding: 0 }}>
            <SiteInfoForm
              siteInfoToEdit={(selectedItem || undefined) as SiteInfo | undefined}
              onSubmit={sendSiteInfo}
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.LINKS && (
          <DialogContent style={{ padding: 0 }}>
            <ContractForm
              contractToEdit={(selectedItem || undefined) as Contract | undefined}
              onSubmit={sendContract}
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.REMOVE && itemToRemove && (
          <RemoveDialog
            item={itemToRemove}
            onCancel={() => setShowDialog(DialogType.NONE)}
          />
        )}
      </Dialog>
      <InfoModal
        closeButtonText={t('installationStarting.ok')}
        message={getDialogMessage()}
        open={
          dialogType === InfoModalType.CONFIRMATION ||
          dialogType === InfoModalType.IS_OFFLINE
        }
        onClose={onDialogClose}
        isCenteredMessage
      />
    </StyledContainer>
  );
};

export default InstallationStarting;
