import React, { useEffect, useState, useContext, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  styled,
} from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { InfoModal, NaviClose } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import NetworkContactInformation from '../../components/NetworkContactInformation';
import NetworkTechnicalInformation from '../../components/NetworkTechnicalInformation';
import NetworkSummary from '../../components/NetworkSummary';
import OtherTools from '../../components/OtherTools';
import ConnectStatusBanner from '../../components/ConnectStatusBanner';

import {
  Employee,
  ActivityDifferentiator,
  QuestionSet,
  AnswerSet,
  InstallationSyncData,
  DeviationStatus,
} from '../../schemas';
import Context, { InstallationContext } from '../../context';
import { getEmployeesDataFromInstallation, remove } from '../../helpers/fetch';
import { getOpenDeviationsCount } from '../../helpers/question';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import {
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
  truncateNetworkFromIndexedDB,
} from '../../helpers/indexedDB';
import {
  formatDate,
  DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT,
} from '../../helpers/formating';
import { ReviewFormModal } from '../ReviewFormModal';
import { useGetToken } from '../../hooks/useGetToken';
import { syncWithBackend } from '../../helpers/syncHelper';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { getAllowToViewDeviationListOnly } from '../SubcontractorApp'; //TODO: having helper function from this component is a temporary solution for now
import { useGetUserData } from '../../hooks/useGetUserData';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { manualSyncFromUI, DeviationToSync } from '../../helpers/deviationOffline';
import PrintoutForms from '../../components/PrintoutForms';

export interface NetworkDetailsModalProps {
  handleCloseIconClick: () => void;
}

type AssignmentData = {
  assigmentEndDate: string;
  installers: string[];
  testers: string[];
} | null;

type NetworkData = {
  customerName: string;
  equipmentNumber: string;
  targetHour: number;
} | null;

type syncPointRecord = {
  employeeId: string;
  employeeName: string;
  modifiedAt: string;
  userRole: ActivityDifferentiator;
};

type ReviewFormModalData = {
  questions: QuestionSet[];
  answers: AnswerSet[];
};

const syncInterval = process.env.REACT_APP_DATA_SYNC_INTERVAL || '5';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const StyledToolbar = styled(Toolbar)({
  textAlign: 'center',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
  flexGrow: 1,
}));

const CloseIcon = styled(NaviClose)({
  fontSize: '2.7rem',
});

const ContactInformation = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const StyledContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2, 0, 2),
  marginTop: '17px',
  columnGap: '10px',
}));

export const LastSyncPointDateTimeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'flex-end',
  '& p': {
    padding: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
}));

const NetworkDetailsModal = (props: NetworkDetailsModalProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    networkNumber,
    installationData,
    updateIsLoading,
    updateInstallationData,
    updateNetworkNumber,
  } = useContext(Context);
  const { deviations } = useContext(InstallationContext);
  const [getTokenFunction] = useGetToken();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [employeeId] = useGetUserData();

  const { handleCloseIconClick } = props;

  const [assignmentEndDate, setAssignmentEndDate] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [installers, setInstallers] = useState<string[]>([]);
  const [testers, setTesters] = useState<string[]>([]);
  const [equipmentNumber, setEquipmentNumber] = useState<string>('');
  const [targetHour, setTargetHour] = useState(0);
  const [openReloadDialog, setOpenReloadDialog] = useState<boolean>(false);
  const [openUnassignDialog, setOpenUnassignDialog] = useState<boolean>(false);
  const [openDeviationsCount, setOpenDeviationsCount] = useState<number>(0);
  const [employeesData, setEmployeesData] = useState<(Employee | null)[]>([]);
  const [offlineMode, setOfflineMode] = useState<boolean>(false);
  const [isOnline] = useCheckConnection();
  const [lastSyncPointDateTime, setLastSyncPointDateTime] = useState<string>('');
  const [syncDataAvailability, setSyncDataAvailability] = useState<{
    network: boolean;
    deviations: boolean;
  }>({ deviations: false, network: false });
  const [showSyncSuccessModal, setShowSyncSuccessModal] = useState<boolean>(false);
  const [isSubcontractor] = useIfSubcontractor();
  const [userRole] = useGetCurrentUserRole();

  const handleReloadClickOpen = (): void => {
    if (isOnline) setOpenReloadDialog(true);
    else setOfflineMode(true);
  };

  const handleReloadAction = async (): Promise<void> => {
    setOpenReloadDialog(false);

    try {
      await truncateNetworkFromIndexedDB(networkNumber);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleReloadCloseCancel = (): void => {
    setOpenReloadDialog(false);
  };

  const handleUnassignAction = async () => {
    updateIsLoading(true);

    try {
      const accessToken = await getTokenFunction();
      await remove(
        `v1/installations/${networkNumber}/assignees/${employeeId}`,
        accessToken
      );

      await truncateNetworkFromIndexedDB(networkNumber);
      await updateInstallationListsInContext();

      setOpenUnassignDialog(false);
      navigate('/');
      updateNetworkNumber('');
      updateInstallationData(null);
    } catch (error) {
      alert(error);
    }

    updateIsLoading(false);
  };

  const supervisor = employeesData.find(
    (emp) => emp?.employeeId === installationData?.supervisorNumber
  );

  const getEmployeeName = (employeeData: Employee | null | undefined): string => {
    return employeeData
      ? `${employeeData.legalFirstName} ${employeeData.legalLastName}`
      : '';
  };

  const isAllowToViewDeviationListOnly = getAllowToViewDeviationListOnly(
    userRole,
    installationData?.status
  );

  const shouldHideSyncButtons = isAllowToViewDeviationListOnly && isSubcontractor;
  const shouldHideUnassignButton =
    installationData?.supervisorNumber === employeeId || isSubcontractor;

  useEffect(() => {
    const getemployeesData = async (accessToken: string) => {
      if (installationData) {
        const employeesData = await getEmployeesDataFromInstallation(
          accessToken,
          installationData
        );
        if (employeesData) return employeesData;
      }
      return [];
    };

    const getAssignmentData = (empDatas: (Employee | null)[]): AssignmentData => {
      if (installationData) {
        const installers: string[] = [];
        const testers: string[] = [];

        for (const assignee of installationData.assignees) {
          const employeeData = empDatas.find(
            (employee) =>
              employee !== null &&
              employee.employeeId === assignee.koneResourcePersonalNumber
          );
          const name = (employeeData && getEmployeeName(employeeData)) || '';

          if (assignee.activityDifferentiator === ActivityDifferentiator.INST) {
            installers.push(name);
          }
          if (assignee.activityDifferentiator === ActivityDifferentiator.CMSN) {
            testers.push(name);
          }
        }

        installationData.subcontractors.forEach((data) => {
          if (data.activityDifferentiator === ActivityDifferentiator.INST)
            installers.push(data.subcontractor.name);
          if (data.activityDifferentiator === ActivityDifferentiator.CMSN)
            testers.push(data.subcontractor.name);
        });

        const loggedInAssignee = installationData.assignees.find(
          ({ koneResourcePersonalNumber }) => koneResourcePersonalNumber === employeeId
        );

        const loggedInSubcontractor = installationData.subcontractors.find(
          ({ activityDifferentiator }) => activityDifferentiator === userRole
        );

        const assigmentEndDate = isSubcontractor
          ? loggedInSubcontractor?.plannedEndDate
          : loggedInAssignee?.assignmentEndDate;

        return {
          assigmentEndDate: assigmentEndDate ?? '',
          installers,
          testers,
        };
      }
      return null;
    };

    const getNetworkData = (): NetworkData => {
      if (installationData) {
        const customerName = installationData?.customer?.customerName1 ?? '';
        const equipmentNumber = installationData.equipmentNumber || '';
        const installerTargetHours = Math.ceil(Number(installationData?.installerHours));
        const installationTargetHours = Math.ceil(
          Number(installationData?.totalInstallationHours)
        );
        const targetHour =
          employeeId === installationData.supervisorNumber
            ? installationTargetHours
            : installerTargetHours;
        return { customerName, equipmentNumber, targetHour };
      }
      return null;
    };

    const getLastSyncPointDateTime = async (): Promise<string | null> => {
      const syncPointKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.SYNC_POINT
      );

      const syncPointData: syncPointRecord | undefined = await getIndexedDBObject(
        syncPointKey
      );

      if (syncPointData) {
        return syncPointData.modifiedAt;
      }
      return null;
    };

    const getNetworkSyncDataAvailablityFromIndexedDb = async (): Promise<boolean> => {
      const syncDataKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.SYNC_DATA
      );

      const syncDataFromIndexDb = await getIndexedDBObject<InstallationSyncData>(
        syncDataKey
      );

      return Boolean(syncDataFromIndexDb);
    };

    const getDeviationToSyncAvailabilityFromIndexedDb = async (): Promise<boolean> => {
      const syncDataKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
      );

      const deviationToSyncFromIndexDB = await getIndexedDBObject<DeviationToSync[]>(
        syncDataKey
      );

      return Boolean(deviationToSyncFromIndexDB?.length);
    };

    const getAndSetAllRequiresData = async (accessToken: string): Promise<void> => {
      const empData = await getemployeesData(accessToken);
      setEmployeesData(empData);
      const assigmentData = getAssignmentData(empData);
      if (assigmentData) {
        setAssignmentEndDate(assigmentData.assigmentEndDate);
        setTesters(assigmentData.testers);
        setInstallers(assigmentData.installers);
      }

      const networkData = getNetworkData();
      if (networkData) {
        setCustomerName(networkData.customerName);
        setEquipmentNumber(networkData.equipmentNumber);
        setTargetHour(networkData.targetHour);
      }

      if (installationData) {
        const openDeviationsCount = getOpenDeviationsCount(deviations);
        setOpenDeviationsCount(openDeviationsCount);
      }

      const lastSyncPointDateTime = await getLastSyncPointDateTime();
      if (lastSyncPointDateTime) {
        setLastSyncPointDateTime(lastSyncPointDateTime);
      }

      const isNetworkSyncDataAvailableInIndexDb =
        await getNetworkSyncDataAvailablityFromIndexedDb();

      const isDeviationSyncDataAvailableInIndexDb =
        await getDeviationToSyncAvailabilityFromIndexedDb();

      setSyncDataAvailability({
        network: isNetworkSyncDataAvailableInIndexDb,
        deviations: isDeviationSyncDataAvailableInIndexDb,
      });
    };

    (async () => {
      try {
        updateIsLoading(true);
        const accessToken = await getTokenFunction();
        await getAndSetAllRequiresData(accessToken);
      } catch (err) {
        console.error(err);
      } finally {
        updateIsLoading(false);
      }
    })();
  }, [networkNumber, employeeId]);

  const [selectedReviewFormModalData, setSelectedReviewFormModalData] =
    useState<null | ReviewFormModalData>(null);

  const handleReviewFormWithData = (
    questionSet: ReviewFormModalData['questions'],
    answerSet: ReviewFormModalData['answers']
  ) => {
    setSelectedReviewFormModalData({
      questions: questionSet,
      answers: answerSet,
    });
  };

  const handleCloseReviewForm = () => {
    setSelectedReviewFormModalData(null);
  };

  const handleManualSynchronization = async () => {
    const accessToken = await getTokenFunction();

    try {
      updateIsLoading(true);

      if (syncDataAvailability.network) await syncWithBackend(networkNumber, accessToken);
      if (syncDataAvailability.deviations)
        await manualSyncFromUI(networkNumber, accessToken);

      setShowSyncSuccessModal(true);
    } catch (err) {
      console.error(err);
    } finally {
      updateIsLoading(false);
    }
  };

  return (
    <Dialog fullScreen open={true}>
      <StyledAppBar elevation={0}>
        <StyledToolbar>
          <StyledTypography variant="h6">{t('networkDetails')}</StyledTypography>
          <IconButton
            aria-label="close"
            color="inherit"
            data-testid="close-button"
            edge="end"
            onClick={handleCloseIconClick}
          >
            <CloseIcon />
          </IconButton>
        </StyledToolbar>
      </StyledAppBar>
      <ConnectStatusBanner />
      <NetworkSummary
        targetHours={targetHour}
        assignmentEndDate={assignmentEndDate}
        openDeviationCount={openDeviationsCount}
      />

      {!shouldHideSyncButtons && (
        <ActionButtonsContainer>
          <Button
            color="primary"
            data-testid="reload-button"
            onClick={handleReloadClickOpen}
            variant="contained"
          >
            <Typography>{t('reloadNetwork.buttonTitle')}</Typography>
          </Button>

          <Button
            color="primary"
            data-testid="sync-data-button"
            onClick={handleManualSynchronization}
            variant="contained"
            disabled={
              !isOnline ||
              (!syncDataAvailability.deviations && !syncDataAvailability.network)
            }
          >
            <Typography>{t('syncNetworkData')}</Typography>
          </Button>
        </ActionButtonsContainer>
      )}

      {!shouldHideUnassignButton && (
        <ActionButtonsContainer>
          <Button
            color="primary"
            data-testid="unassign-button"
            onClick={() => setOpenUnassignDialog(true)}
            variant="contained"
            startIcon={<DeleteForever />}
            fullWidth
          >
            <Typography>{t('unassignNetwork.buttonTitle')}</Typography>
          </Button>
        </ActionButtonsContainer>
      )}

      {/* Reload dialog */}
      <Dialog
        open={openReloadDialog}
        onClose={handleReloadCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ justifyContent: 'center' }}>
          <Box alignItems="justifyContent" pt="25px" pb="5px">
            <DialogContentText
              id="alert-dialog-description"
              align="center"
              style={{ fontWeight: 'bold' }}
            >
              {t('reloadNetwork.confirmationTitle')}
              <br></br> <br></br>
              {t('reloadNetwork.confirmationDescription')}{' '}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-evenly' }}>
          <Box pb="15px">
            <Button
              style={{ minWidth: '120px' }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleReloadCloseCancel}
            >
              {t('reloadNetwork.cancelButton')}
            </Button>
          </Box>
          <Box pb="15px">
            <Button
              fullWidth
              style={{ minWidth: '120px' }}
              variant="contained"
              color="primary"
              data-testid="reload-confirm"
              onClick={handleReloadAction}
              autoFocus
            >
              {t('reloadNetwork.reloadButton')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Unassign dialog */}
      <Dialog
        open={openUnassignDialog}
        onClose={() => setOpenUnassignDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ justifyContent: 'center' }}>
          <Box alignItems="justifyContent" pt="25px" pb="5px">
            <DialogContentText
              id="alert-dialog-description"
              align="center"
              style={{ fontWeight: 'bold' }}
            >
              {t('unassignNetwork.confirmationTitle')}
              <br></br> <br></br>
              {t('unassignNetwork.confirmationDescription')}{' '}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-evenly' }}>
          <Box pb="15px">
            <Button
              style={{ minWidth: '120px' }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setOpenUnassignDialog(false)}
            >
              {t('unassignNetwork.cancelButton')}
            </Button>
          </Box>
          <Box pb="15px">
            <Button
              fullWidth
              style={{ minWidth: '120px' }}
              variant="contained"
              color="primary"
              data-testid="unassign-confirm"
              onClick={handleUnassignAction}
              autoFocus
            >
              {t('unassignNetwork.unassignButton')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ContactInformation data-testid="contact-info">
        <NetworkContactInformation
          supervisor={getEmployeeName(supervisor)}
          customerContact={customerName}
          testers={testers}
          installers={installers}
        />
      </ContactInformation>
      <StyledContainer>
        <NetworkTechnicalInformation
          networkNumber={networkNumber}
          equipmentNumber={equipmentNumber}
        />
      </StyledContainer>
      <StyledContainer>
        <PrintoutForms
          installationData={installationData}
          onShowReviewFormWithData={handleReviewFormWithData}
        />
      </StyledContainer>
      {!isSubcontractor && (
        <StyledContainer>
          <OtherTools equipmentNumber={equipmentNumber} />
        </StyledContainer>
      )}
      <LastSyncPointDateTimeContainer role="last-sync-point-date-time">
        <Typography>
          {lastSyncPointDateTime
            ? t('technicalInformation.lastSyncPointDateTime', {
                dateTime: formatDate(
                  lastSyncPointDateTime,
                  DEFAULT_DATE_WITH_HOURS_DISPLAY_FORMAT
                ),
                syncInterval,
              })
            : t('technicalInformation.noDataForSync')}
        </Typography>
      </LastSyncPointDateTimeContainer>
      {offlineMode && (
        <InfoModal
          open={true}
          message={t('connection.offlineAlert')}
          onClose={(): void => setOfflineMode(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
      {selectedReviewFormModalData !== null && (
        <ReviewFormModal
          questions={selectedReviewFormModalData.questions}
          answers={selectedReviewFormModalData.answers}
          supervisorName={getEmployeeName(supervisor)}
          networkNumber={networkNumber}
          installationData={installationData}
          openDeviations={deviations.filter(
            ({ status }) => status === DeviationStatus.OPEN
          )}
          handleCloseOnClick={handleCloseReviewForm}
        />
      )}
      <InfoModal
        open={showSyncSuccessModal}
        message={t('technicalInformation.syncSuccessful')}
        onClose={(): void => setShowSyncSuccessModal(false)}
        closeButtonText={t('connection.ok')}
        isCenteredMessage
      />
    </Dialog>
  );
};

export default NetworkDetailsModal;
