import React, { JSX, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid2,
  Paper,
  styled,
  Typography,
} from '@mui/material';

import Context from '../../context';
import {
  ActivityDifferentiator,
  CompactNetwork,
  ExtendedInstallation,
  InstallationCardData,
  InstallationStatus,
} from '../../schemas';
import { API_TYPE, fetchEmployeeFullName, put } from '../../helpers/fetch';
import { formatDate } from '../../helpers/formating';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import {
  getInstallationData,
  getNetworkDeviationCount,
  NumberType,
} from '../../helpers/getInstallationLists';

import Empty from '../../components/Empty';
import SubHeader from '../../components/SubHeader';
import Search, { SearchInputType } from '../../components/Search';
import InstallationCard from '../../components/InstallationCard';

// This regular expects only numbers [0-9] of min-max length of 8 characters (no more, no less)
const EQUIPMENT_NUMBER_REGEX = /^\d{8}$/;

enum DialogType {
  NONE,
  ASSIGN_DIALOG,
}

const SearchContent = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  overflow: 'auto',
}));

const AssignSummary = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.primary.main,
}));

const AssignMessage = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const MaintenanceSelfAssign = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();
  const { updateIsLoading } = useContext(Context);
  const [employeeId] = useGetUserData();
  const [isOnline] = useCheckConnection();

  const searchQuery = new URLSearchParams(useLocation().search);
  const equipmentNumber = searchQuery.get('equipmentNumber') || '';

  const [searchedInstallation, setSearchedInstallation] = useState<
    InstallationCardData | undefined
  >(undefined);
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.NONE);

  const [isValidEquipmentNumber, setValidEquipmentNumber] = useState<boolean>(
    !equipmentNumber || EQUIPMENT_NUMBER_REGEX.test(equipmentNumber)
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const queryInstallation = async (
    equipmentNumber: string,
    employeeId: string,
    accessToken: string
  ): Promise<InstallationCardData> => {
    const installation = await getInstallationData(
      equipmentNumber,
      accessToken,
      false,
      NumberType.EQUIPMENT_NUMBER
    );
    const isVariationOrder = installation?.isVariationOrder ?? false;
    if (!installation || !installation?.networkNumber || isVariationOrder) {
      setErrorMessage(t('maintenanceSelfAssignPage.installationNotFound'));
      throw new Error(
        `Cannot find installation with equipment number: ${equipmentNumber}`
      );
    }

    const deviations = await getNetworkDeviationCount(
      installation.networkNumber,
      accessToken
    );

    const network: CompactNetwork = {
      networkNumber: installation.networkNumber,
      equipmentNumber: installation.equipmentNumber ?? '',
      description: installation.description ?? '',
      networkDescription: installation.networkDescription ?? '',
      salesOrderDescription: installation?.salesOrderDescription ?? '',
      milestone: installation?.milestone ?? '',
      supervisorNumber: installation?.supervisorNumber ?? '',
    };

    return { network, installation, deviations };
  };

  const handleSearchOnSubmit = async (equipmentNumber: string) => {
    setSearchedInstallation(undefined);
    setErrorMessage('');

    const isValid = EQUIPMENT_NUMBER_REGEX.test(equipmentNumber);
    setValidEquipmentNumber(isValid);

    if (!isValid) return;
    updateIsLoading(true);
    navigate(`/maintenanceassign?equipmentNumber=${equipmentNumber}`);

    try {
      const accessToken = await getTokenFunction();
      const installation = await queryInstallation(
        equipmentNumber,
        employeeId,
        accessToken
      );

      if (installation.installation.status === InstallationStatus.FOR_SEB_ACCEPTANCE) {
        setSearchedInstallation(installation);
      } else {
        const employeeId = installation.installation.supervisorNumber || '';
        const supervisorName = await fetchEmployeeFullName(employeeId, accessToken);
        setErrorMessage(
          t('maintenanceSelfAssignPage.installationNotReady', { supervisorName })
        );
      }
    } catch (error) {
      console.error(error);
    }

    updateIsLoading(false);
  };

  const handleAssignOperation = async (networkNumber: string) => {
    updateIsLoading(true);

    try {
      const accessToken = await getTokenFunction();
      await put(
        `v1/installations/${networkNumber}/assignee`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          userRole: ActivityDifferentiator.SEEN,
        }
      );
      navigate(`/${networkNumber}/execution`);
    } catch (error) {
      console.error(error);
    }

    updateIsLoading(false);
  };

  useEffect(() => {
    if (EQUIPMENT_NUMBER_REGEX.test(equipmentNumber)) {
      handleSearchOnSubmit(equipmentNumber);
    }
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setErrorMessage(t('maintenanceSelfAssignPage.unable'));
    } else {
      setErrorMessage('');
    }
  }, [isOnline]);

  const SearchResultCard = (props: { data: InstallationCardData }): JSX.Element => {
    const { network, deviations, installation } = props.data;
    const { status, firstAssignmentDate, latestAnsweredQdQuestionSet, hasQualityForm } =
      installation;

    network.isModelData = installation.isModelData ?? true;
    network.isTacoDataQuest = installation.isTacoDataQuest ?? true;

    return (
      <SearchContent aria-label="searched-installation-found">
        <InstallationCard
          network={network}
          deviations={deviations}
          status={status}
          firstAssignmentDate={firstAssignmentDate}
          latestAnsweredQdQuestionSet={latestAnsweredQdQuestionSet}
          headerColor="grey"
          hasQualityForm={hasQualityForm}
          onClick={() => setDialogType(DialogType.ASSIGN_DIALOG)}
        />
      </SearchContent>
    );
  };

  const AssignDialog = (props: { installation: ExtendedInstallation }): JSX.Element => {
    const { installation } = props;
    const { customer, firstAssignmentDate, networkNumber } = installation;

    return (
      <Box>
        <AssignSummary elevation={0}>
          <Grid2 container spacing={3}>
            <Grid2 size={{ xs: 8 }}>
              <Typography variant="subtitle2">{customer?.customerName1}</Typography>
              <Typography variant="subtitle2">{customer?.customerName2}</Typography>
              <Typography variant="subtitle2">
                {`${customer?.street || ''} ${customer?.houseNumber || ''}, 
                  ${customer?.cityPostalCode || ''} ${customer?.city || ''}`}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <Typography variant="subtitle2">{networkNumber}</Typography>
              <Typography variant="subtitle2">
                {formatDate(firstAssignmentDate)}
              </Typography>
            </Grid2>
          </Grid2>
        </AssignSummary>

        <AssignMessage>
          <Typography>{t('maintenanceSelfAssignPage.assignMessage')}</Typography>
        </AssignMessage>

        <Grid2 container spacing={1}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-cancel"
              fullWidth
              onClick={() => setDialogType(DialogType.NONE)}
            >
              {t('maintenanceSelfAssignPage.cancelButton')}
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-confirm"
              fullWidth
              onClick={() => handleAssignOperation(installation.networkNumber || '')}
            >
              {t('maintenanceSelfAssignPage.assignButton')}
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    );
  };

  return (
    <Box p={1}>
      <Dialog open={isOnline && dialogType !== DialogType.NONE}>
        <StyledDialogContent>
          {dialogType === DialogType.ASSIGN_DIALOG &&
            searchedInstallation?.installation && (
              <AssignDialog installation={searchedInstallation.installation} />
            )}
        </StyledDialogContent>
      </Dialog>

      <SubHeader
        title={t('maintenanceSelfAssignPage.title')}
        handleGoBackClick={() => navigate('/')}
      />

      <Search
        searchInputType={SearchInputType.NUMERIC}
        label={t('maintenanceSelfAssignPage.equipmentNumber')}
        searchLabel={t('maintenanceSelfAssignPage.searchLabel')}
        initialSearchText={equipmentNumber}
        maxLength={8}
        errorMessage={
          !isValidEquipmentNumber
            ? t('maintenanceSelfAssignPage.invalidEquipmentNumber')
            : ''
        }
        onSubmit={handleSearchOnSubmit}
        disabled={!isOnline}
      />

      {isOnline && searchedInstallation && (
        <SearchResultCard data={searchedInstallation} />
      )}

      {errorMessage && <Empty message={errorMessage} />}
    </Box>
  );
};

export default MaintenanceSelfAssign;
