import { useContext } from 'react';
import Context from '../context/index';
import { useGetCurrentUserRole } from './useGetCurrentUserRole';
import { ActivityDifferentiator, Answer, Installation } from '../schemas';
import { updateInstallationDataWithMerge } from '../helpers/updateMergedData';

type updateAnswerInContextFunction = (
  newAnswer: Answer,
  questionSetIdParam: string | null,
  questionSequenceNumber: number | null
) => void;

export const useUpdateAnswerInContext = (): [updateAnswerInContextFunction] => {
  const { installationData, updateInstallationData } = useContext(Context);
  const [userRole] = useGetCurrentUserRole();

  const updateAnswerInContext = (
    newAnswer: Answer,
    questionSetIdParam: string | null,
    questionSequenceNumber: number | null
  ) => {
    if (newAnswer && questionSequenceNumber !== null && questionSetIdParam) {
      switch (userRole) {
        case ActivityDifferentiator.INST: {
          // Update installationData.installerAnswers
          const newAnswerSets = installationData?.installerAnswers.map((answerSet) => {
            if (answerSet.questionSetId === questionSetIdParam) {
              const newAnswers = answerSet.answers.map((ans, i) =>
                i === questionSequenceNumber ? newAnswer : ans
              );
              return {
                questionSetId: answerSet.questionSetId,
                answers: newAnswers,
              };
            }
            return answerSet;
          });

          // Update installationData.forms.installerAnswers
          const newForms =
            installationData?.forms?.map((form) => {
              if (form.installerAnswers && form.installerAnswers.length > 0) {
                const newFormInstallerAnswers = form.installerAnswers.map((answerSet) => {
                  if (answerSet.questionSetId === questionSetIdParam) {
                    const newAnswers = answerSet.answers.map((ans, i) =>
                      i === questionSequenceNumber ? newAnswer : ans
                    );
                    return {
                      questionSetId: answerSet.questionSetId,
                      answers: newAnswers,
                    };
                  }
                  return answerSet;
                });
                return {
                  ...form,
                  installerAnswers: newFormInstallerAnswers,
                };
              }
              return form;
            }) || [];

          updateInstallationDataWithMerge(
            {
              ...installationData,
              installerAnswers: newAnswerSets,
              forms: newForms,
            } as Installation,
            updateInstallationData
          );
          break;
        }

        case ActivityDifferentiator.CMSN:
        case ActivityDifferentiator.SPV:
        case ActivityDifferentiator.SEEN: {
          // Update installationData.testerAnswers
          const newAnswerSets = installationData?.testerAnswers.map((answerSet) => {
            if (answerSet.questionSetId === questionSetIdParam) {
              const newAnswers = answerSet.answers.map((ans, i) =>
                i === questionSequenceNumber ? newAnswer : ans
              );
              return {
                questionSetId: answerSet.questionSetId,
                answers: newAnswers,
              };
            }
            return answerSet;
          });

          // Update installationData.forms.testerAnswers
          const newForms =
            installationData?.forms?.map((form) => {
              let updatedForm = { ...form };

              if (form.testerAnswers && form.testerAnswers.length > 0) {
                const newFormTesterAnswers = form.testerAnswers.map((answerSet) => {
                  if (answerSet.questionSetId === questionSetIdParam) {
                    const newAnswers = answerSet.answers.map((ans, i) =>
                      i === questionSequenceNumber ? newAnswer : ans
                    );
                    return {
                      questionSetId: answerSet.questionSetId,
                      answers: newAnswers,
                    };
                  }
                  return answerSet;
                });
                updatedForm = {
                  ...updatedForm,
                  testerAnswers: newFormTesterAnswers,
                };
              }

              if (form.sebSupervisorAnswers && form.sebSupervisorAnswers.length > 0) {
                const newFormSebSupervisorAnswers = form.sebSupervisorAnswers.map(
                  (answerSet) => {
                    if (answerSet.questionSetId === questionSetIdParam) {
                      const newAnswers = answerSet.answers.map((ans, i) =>
                        i === questionSequenceNumber ? newAnswer : ans
                      );
                      return {
                        questionSetId: answerSet.questionSetId,
                        answers: newAnswers,
                      };
                    }
                    return answerSet;
                  }
                );
                updatedForm = {
                  ...updatedForm,
                  sebSupervisorAnswers: newFormSebSupervisorAnswers,
                };
              }

              return updatedForm;
            }) || [];
          updateInstallationDataWithMerge(
            {
              ...installationData,
              testerAnswers: newAnswerSets,
              forms: newForms,
            } as Installation,
            updateInstallationData
          );
          break;
        }
      }
    }
  };
  return [updateAnswerInContext];
};
