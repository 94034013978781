import React, { useEffect, useContext, useState, JSX } from 'react';
import { CircleCancel, theme } from '@konecorp/ui-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InstallationCard from '../InstallationCard';
import SubHeader from '../../components/SubHeader';
import Context from '../../context';
import {
  ActivityDifferentiator,
  InstallationCardData,
  Installation,
  InstallationStatus,
  NetworkTags,
} from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetToken } from '../../hooks/useGetToken';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';
import { useGetUserData } from '../../hooks/useGetUserData';
import { Box, Dialog, DialogContent, Grid2, styled, Typography } from '@mui/material';
import { DialogType, Filters, defaultFilters } from '../OngoingInstallationsList';
import { FilterList } from '@mui/icons-material';
import NetworkListFilters from '../NetworkListFilters';

const StyledGrid = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

const CircleCancelIcon = styled(CircleCancel)(() => ({
  width: 30,
  height: 30,
  position: 'absolute',
  right: '10px',
}));

const CardContent = styled('div')(() => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  overflow: 'auto',
}));

const ToBeStartedInstallationsList = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [isSubcontractor] = useIfSubcontractor();
  const [employeeId] = useGetUserData();
  const [getTokenFunction] = useGetToken();
  const [openedDialog, setOpenedDialog] = useState<DialogType>(DialogType.NONE);
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);
  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
  });
  const { toBeStartedInstallations, updateIsLoading } = useContext(Context);
  const filtersCloseAction = (reset?: boolean) => {
    if (reset) setFilters(defaultFilters);
    setOpenedDialog(DialogType.NONE);
  };
  useEffect(() => {
    (async () => {
      if (!toBeStartedInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (toBeStartedInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = toBeStartedInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const toBeStartedInstallationCardData = await Promise.all(promises);
        setInstallationCardData(
          toBeStartedInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [toBeStartedInstallations]);

  const onInstallationCardClick = (installationData: InstallationCardData) => {
    const releaseNetwork =
      !(
        installationData.network.isModelData && !installationData.network.isTacoDataQuest
      ) &&
      !(
        installationData.installation.status === InstallationStatus.TO_BE_STARTED &&
        installationData.installation.forms?.length === 0 &&
        installationData.installation.customer?.countryKey === 'GB' &&
        (!!installationData.installation.installerQuestionSetSequence?.length ||
          !!installationData.installation.testerQuestionSetSequence?.length ||
          !!installationData.installation.sebSupervisorQuestionSetSequence?.length)
      );

    if (releaseNetwork) {
      // If no taco data no need to start installation
      window.sessionStorage.setItem('previousPageInternalUrl', '/tobestarted');

      const userRole = getKoneEmployeeUserRole({
        installationData: installationData.installation as Installation,
        employeeId,
      });

      if (userRole === ActivityDifferentiator.SPV) {
        navigate(
          `/${installationData.network.networkNumber}/newinstallation?role=${ActivityDifferentiator.SPV}`
        );
        return;
      }

      const rootPath = isSubcontractor ? '/subcontractor' : '';
      navigate(`${rootPath}/${installationData.network.networkNumber}/starting`);
    }
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.toBeStartedInstallations')}
        handleGoBackClick={() => {
          navigate('/');
        }}
      />
      {true && (
        <StyledGrid container>
          <Grid2 size={{ xs: 11 }}>
            <Typography variant="h5">{t('installationNetworkListTitle')}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 1 }}>
            <FilterList
              data-testid="icon-filters"
              onClick={() => setOpenedDialog(DialogType.FILTERS)}
            />
          </Grid2>
        </StyledGrid>
      )}
      <Dialog open={openedDialog !== DialogType.NONE} fullWidth maxWidth="sm">
        <DialogContent>
          <CircleCancelIcon
            data-testid="dialog-close-icon"
            onClick={() => setOpenedDialog(DialogType.NONE)}
          />
          <Box mt={1} padding={theme.spacing(0.5)}>
            {openedDialog === DialogType.FILTERS && (
              <NetworkListFilters
                filters={filters}
                setFilters={setFilters}
                onClose={filtersCloseAction}
              />
            )}
          </Box>{' '}
        </DialogContent>
      </Dialog>
      <CardContent>
        {installationCardData &&
          installationCardData
            .filter((cardData) => {
              const { networkTag } = cardData.network || {};
              if (cardData.network.isVariationOrder) return false;
              if (filters.NBS && (networkTag === NetworkTags.NBS || networkTag === ''))
                return true;
              if (filters.MOD && networkTag === NetworkTags.MOD) return true;
              if (
                filters &&
                networkTag !== NetworkTags.NBS &&
                networkTag !== NetworkTags.MOD &&
                networkTag !== ''
              )
                return true;
              return false;
            })
            .map((installationData: InstallationCardData, index: number) => {
              const { network, installation, deviations } = installationData;

              return (
                <InstallationCard
                  key={`tobestarted-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => onInstallationCardClick(installationData)}
                  tacoReleaseNeeded={
                    installation.status === InstallationStatus.TO_BE_STARTED &&
                    installation.forms?.length === 0 &&
                    installation.customer?.countryKey === 'GB' &&
                    (!!installation.installerQuestionSetSequence?.length ||
                      !!installation.testerQuestionSetSequence?.length ||
                      !!installation.sebSupervisorQuestionSetSequence?.length)
                  }
                />
              );
            })}
      </CardContent>
    </>
  );
};

export default ToBeStartedInstallationsList;
