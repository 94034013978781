import React from 'react';
import { Grid2, IconButton, Link, styled, Typography } from '@mui/material';
import { DialogType } from '../TeamList';
import { SiteInfo } from '../../schemas';
import { DeleteForever } from '@mui/icons-material';

const StyledGrid = styled(Grid2)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  '& p': {
    cursor: 'pointer',
  },
  textTransform: 'uppercase',
}));
export type AddButtonProps = {
  siteInfo: SiteInfo;
  setSelectedItem: (item: SiteInfo) => void;
  setShowDialog: (dialog: DialogType) => void;
  setItemToRemove: (item: SiteInfo) => void;
};
const SiteInfoDetails = (props: AddButtonProps): JSX.Element => {
  const { siteInfo } = props;
  const { setSelectedItem, setShowDialog, setItemToRemove } = props;
  return (
    <StyledGrid container>
      <Grid2 size={{ xs: 10 }}>
        <Typography>
          <Link
            style={{ textDecoration: 'underline' }}
            onClick={() => {
              setSelectedItem(siteInfo);
              setShowDialog(DialogType.SITEINFO);
            }}
          >
            {siteInfo.name}
          </Link>
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 2 }} container justifyContent="flex-end" alignContent="flex-end">
        <IconButton
          data-testid={`remove-icon-contact-${siteInfo.sk}`}
          style={{ padding: 0, margin: 0 }}
          onClick={() => {
            setItemToRemove(siteInfo);
            setShowDialog(DialogType.REMOVE);
          }}
        >
          <DeleteForever />
        </IconButton>
      </Grid2>
    </StyledGrid>
  );
};
export default SiteInfoDetails;
