import { theme as koneTheme } from '@konecorp/ui-library';
import { CircularProgress, Modal, styled } from '@mui/material';
import React, { JSX, useEffect, useState } from 'react';
import { get } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';
import { CALCULATED_IMAGE_CONTAINER_HEIGHT } from '../../containers/QDQuestion';

export interface QDQuestionImageProps {
  imageLink: string | null;
}

const StyledImage = styled('img')(() => ({
  width: '100%',
  maxWidth: '600px',
  height: `calc(${CALCULATED_IMAGE_CONTAINER_HEIGHT} - 20px)`,
}));

const StyledModal = styled(Modal)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

const ImagePopup = styled('img')(() => ({
  margin: koneTheme.spacing(1),
  width: '100%',
  maxHeight: '90%',
  backgroundColor: koneTheme.palette.background.default,
  borderRadius: koneTheme.shape.borderRadius * 4,
}));

const QDQuestionImage = (props: QDQuestionImageProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLinks, setimageLinks] = useState<string[]>([]);
  const [openSecondImageModal, setOpenSecondImageModal] = useState<boolean>(false);
  const [getTokenFunction] = useGetToken();

  useEffect(() => {
    let isComponentMounted = true;
    if (props.imageLink) {
      const fetchInstallationImages = async () => {
        setIsLoading(true);
        const accessToken = await getTokenFunction();
        const imageLinks: string[] | null = await get(
          `v1/installationimages/${props.imageLink}`,
          accessToken
        );

        if (isComponentMounted) {
          if (Array.isArray(imageLinks)) setimageLinks(imageLinks);
          setIsLoading(false);
        }
      };
      setOpenSecondImageModal(false);
      fetchInstallationImages();
    }
    return () => {
      setimageLinks([]);
      isComponentMounted = false;
    };
  }, [props.imageLink]);

  const handlePictureOnClick = () => {
    if (imageLinks.length > 1) setOpenSecondImageModal(true);
  };

  if (!props.imageLink) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div style={{ height: '50vmin' }}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div>
      <StyledImage
        onClick={handlePictureOnClick}
        data-testid="question-image"
        src={imageLinks && imageLinks[0]}
      />
      {imageLinks.length > 1 && (
        <StyledModal
          open={openSecondImageModal}
          onClose={() => {
            setOpenSecondImageModal(false);
          }}
          onClick={() => {
            setOpenSecondImageModal(false);
          }}
        >
          <ImagePopup src={imageLinks[1]} data-testid="second-question-image" />
        </StyledModal>
      )}
    </div>
  );
};

export default QDQuestionImage;
