import React, { useState, useEffect, JSX } from 'react';
import {
  Box,
  FormHelperText,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  CancelRounded,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from '@mui/icons-material';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import UploadWidget from '../UploadWidget';
import { Attachment } from '../../schemas';

export type SebSupervisorSignoffProps = {
  rejectionsCount: number;
  commentText: string;
  selectedFiles: File[];
  deleteAttachmentsLocally: (file: File | Attachment) => void;
  handleUploadButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setCommentText: (comment: string) => void;
  setIsSebSupervisorAccepted: (isAccepted: boolean | undefined) => void;
  acceptedBySeb?: boolean;
};

const SebSupervisorSignoff = (props: SebSupervisorSignoffProps): JSX.Element => {
  const {
    commentText,
    deleteAttachmentsLocally,
    handleUploadButton,
    rejectionsCount,
    selectedFiles,
    setCommentText,
    setIsSebSupervisorAccepted,
    acceptedBySeb,
  } = props;

  enum ToggleButtonState {
    REJECTED,
    ACCEPTED,
  }

  const getToggleButtonState = (): ToggleButtonState => {
    if (acceptedBySeb !== undefined) {
      return acceptedBySeb ? ToggleButtonState.ACCEPTED : ToggleButtonState.REJECTED;
    }

    return rejectionsCount === 0
      ? ToggleButtonState.ACCEPTED
      : ToggleButtonState.REJECTED;
  };

  const [toggleButtonState, setToggleButtonState] = useState<ToggleButtonState>(
    getToggleButtonState()
  );

  enum Status {
    SYSTEM_ACCEPTED, // rejections == 0 && ACCEPTED
    SYSTEM_REJECTED, // rejections > 0 && REJECTED
    REJECTED_WITH_COMMENT, // rejections == 0 && REJECTED && comment is filled
    ERROR_ACCEPTED_WITH_REJECTIONS, // rejections > 0 && ACCEPTED
    ERROR_REJECTED_WITHOUT_COMMENT, // rejections == 0 && REJECTED && comment is empty
  }

  const HandoverContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: 0,
      marginBottom: theme.spacing(2),
    },
  }));

  const StyledTextfield = styled(TextField)(() => ({
    width: '100%',
    marginBottom: theme.spacing(2),
  }));

  const TextBox = styled(Box)(() => ({
    border: '1px solid grey',
    padding: theme.spacing(3, 5, 1),
    textAlign: 'right',
    textTransform: 'uppercase',
    width: '100%',
  }));

  const ResultBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '1rem',
    width: '100%',
    '& p': { margin: 0 },
    '& >p': {
      textTransform: 'uppercase',
      marginTop: '1rem',
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const { t } = useTranslation();

  const [status, setStatus] = useState<Status>(Status.SYSTEM_ACCEPTED);

  const getStatus = (): Status => {
    const pressedAccept = toggleButtonState === ToggleButtonState.ACCEPTED;
    const pressedReject = !pressedAccept;
    const hasComment = commentText.trim().length;

    if (pressedAccept && rejectionsCount === 0) return Status.SYSTEM_ACCEPTED;
    if (pressedAccept && rejectionsCount > 0)
      return Status.ERROR_ACCEPTED_WITH_REJECTIONS;
    if (pressedReject && rejectionsCount > 0) return Status.SYSTEM_REJECTED;
    if (pressedReject && rejectionsCount === 0) {
      if (hasComment) return Status.REJECTED_WITH_COMMENT;
      else return Status.ERROR_REJECTED_WITHOUT_COMMENT;
    }

    throw new Error(
      `Ui state indeterminate pressedAccept:${toggleButtonState}, rejections:${rejectionsCount}, hasComments:${hasComment}`
    );
  };

  useEffect(() => {
    setToggleButtonState(getToggleButtonState());
  }, [acceptedBySeb]);

  useEffect(() => {
    setStatus(getStatus());

    if (status === Status.ERROR_ACCEPTED_WITH_REJECTIONS)
      setIsSebSupervisorAccepted(undefined);
    else if (status === Status.ERROR_REJECTED_WITHOUT_COMMENT)
      setIsSebSupervisorAccepted(undefined);
    else {
      setIsSebSupervisorAccepted(toggleButtonState === ToggleButtonState.ACCEPTED);
    }
  }, [rejectionsCount, toggleButtonState, status, commentText]);

  const onClickAccept = (isAccept: boolean) => () => {
    setToggleButtonState(
      isAccept ? ToggleButtonState.ACCEPTED : ToggleButtonState.REJECTED
    );
    setStatus(getStatus());
  };

  const getStatusMessage = (status: Status) => {
    switch (status) {
      case Status.ERROR_ACCEPTED_WITH_REJECTIONS:
        return t('sebSupervisorSignoff.status.extantRejections');
      case Status.ERROR_REJECTED_WITHOUT_COMMENT:
        return t('sebSupervisorSignoff.status.blankCommentReject');
      default:
        return '';
    }
  };

  const isError = () =>
    [Status.ERROR_ACCEPTED_WITH_REJECTIONS, Status.ERROR_REJECTED_WITHOUT_COMMENT].some(
      (errorStatus) => errorStatus === status
    );

  const isAcceptActive = toggleButtonState === ToggleButtonState.ACCEPTED;
  const isRejectActive = !isAcceptActive;

  return (
    <HandoverContainer as="section">
      <Typography component="h1" variant="h6">
        {t('sebSupervisorSignoff.title')}
      </Typography>
      <TextBox>
        <Typography>
          {t('sebSupervisorSignoff.rejections', { rejections: rejectionsCount })}
        </Typography>
      </TextBox>
      <ResultBox>
        <Typography>{t('sebSupervisorSignoff.result')}</Typography>
        <ul>
          <li>
            <IconButton data-testid="accept-button" onClick={onClickAccept(true)}>
              {isAcceptActive ? (
                <CheckCircleRounded
                  htmlColor={theme.palette.success.main}
                  sx={{ height: '2.5rem', width: '2.5rem', marginRight: '1rem' }}
                />
              ) : (
                <CheckCircleOutlineRounded
                  sx={{ height: '2.5rem', width: '2.5rem', marginRight: '1rem' }}
                />
              )}
              <Typography>{t('sebSupervisorSignoff.acceptHandover')}</Typography>
            </IconButton>
          </li>
          <li>
            <IconButton data-testid="reject-button" onClick={onClickAccept(false)}>
              {isRejectActive ? (
                <CancelRounded
                  htmlColor={theme.palette.error.main}
                  sx={{ height: '2.5rem', width: '2.5rem', marginRight: '1rem' }}
                />
              ) : (
                <CancelRounded
                  sx={{ height: '2.5rem', width: '2.5rem', marginRight: '1rem' }}
                />
              )}
              <Typography>{t('sebSupervisorSignoff.rejectHandover')}</Typography>
            </IconButton>
          </li>
        </ul>
      </ResultBox>

      {isError() && (
        <FormHelperText error={true}>{getStatusMessage(status)}</FormHelperText>
      )}

      <StyledTextfield
        variant="outlined"
        placeholder={t('sebSupervisorSignoff.commentPlaceholder')}
        id="standard-multiline-static"
        value={commentText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setCommentText(event.target.value);
        }}
        multiline
        rows={4}
        inputProps={{
          'data-testid': 'comment-input',
        }}
      />

      <UploadWidget
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        label={t('handoverSignoffPage.uploadButtonInfoText')}
        selectedFiles={selectedFiles}
      />
    </HandoverContainer>
  );
};

export default SebSupervisorSignoff;
