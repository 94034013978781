import React, { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NaviForward } from '@konecorp/ui-library';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Container,
  Dialog,
  DialogContent,
  styled,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import axios, { AxiosResponse } from 'axios';

import logo from '../../logo_inverted.svg';
import backgroundImage from './subcontractor-background.jpg';
import Context from '../../context';
import {
  subcontractorLoginPost,
  fetchContacts,
  put,
  API_TYPE,
  get,
} from '../../helpers/fetch';
import {
  generateTokenLocalStorageKey,
  generateUserRoleLocalStorageKey,
} from '../../helpers/subContractorAuthentication';
import {
  ActivityDifferentiator,
  InstallationStatus,
  Contact,
  ContactRole,
  Installation,
  NetworkTags,
} from '../../schemas';
import { getAllowToViewDeviationListOnly } from '../SubcontractorApp'; //TODO: having helper function from this component is a temporary solution for now
import SubcontractorPersonalInfoForm from '../../components/SubcontractorPersonalInfoForm';
import { useGetToken } from '../../hooks/useGetToken';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  color: 'white',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 100%',
  backgroundImage: `linear-gradient(
    rgba(0, 89, 148, 0.6),
    rgba(0, 89, 148, 0.6)
  ),url(${backgroundImage})`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
  background: 'rgba(28, 41, 49, 0.8)',
  padding: '0px 30px',
}));

const TextFieldStyled = styled(TextField)({
  color: 'white',
  '& .MuiFilledInput-root': {
    background: 'rgba(28, 41, 49, 0.8)',
    color: 'white',
    fontSize: 30,
    '&:hover': {
      background: 'rgba(28, 41, 49, 0.8)', // same color on hover
      color: 'white',
    },
    '&.Mui-focused': {
      background: 'rgba(28, 41, 49, 0.8)', // same color on focus
      color: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    height: '1.5em',
    color: 'white',
  },
});

const IconStyled = styled(NaviForward)(({ theme }) => ({
  fontSize: '4.7rem',
  '& path': {
    fill: theme.palette.common.white,
  },
  '& #Ellipse_62': {
    stroke: theme.palette.common.white,
  },
}));

type QueryParams = {
  networkNumber: string;
  guid: string;
  hash: string;
  role: string;
};

enum errorResponse {
  networkNotReleased = 'Network not yet released',
  networkNotReady = 'Network not yet ready for testing',
  networkFinished = 'Network finished',
}

const SubcontractorLogin = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { networkNumber, guid, hash, role } = useParams() as QueryParams;
  const { updateIsLoading, installationData, updateInstallationData } =
    useContext(Context);

  const [errMessage, setErrMessage] = useState('');
  const [subcontractorKey, setSubcontractorKey] = useState('');
  const [currentInstallationStatus, setCurrentInstallationStatus] = useState<
    InstallationStatus | undefined
  >(undefined);

  const [contactInfo, setContactInfo] = useState<Contact | undefined>(undefined);
  //NOTE: this function and the token is only available after login api call is finish within the submit function
  const [getTokenFunction] = useGetToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (networkNumber) {
          updateIsLoading(true);
          const accessToken = await getTokenFunction();
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );

          if (data) {
            updateInstallationData(data);
          }
        }
      } catch (e) {
        console.error('Error while fetching installation data', e);
      } finally {
        updateIsLoading(false);
      }
    };

    fetchData();
  }, [networkNumber, history]);

  const correspondingContactRole =
    role === ActivityDifferentiator.INST
      ? ContactRole.SUBCONTRACTOR_INSTALLER
      : ContactRole.SUBCONTRACTOR_TESTER;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSubcontractorKey(e.target.value);
    setErrMessage('');
  };

  const redirectUserToPage = (): void => {
    if (currentInstallationStatus) {
      const isStartingNewInstallation =
        (role === ActivityDifferentiator.INST &&
          currentInstallationStatus === InstallationStatus.FOR_INSTALLER_ACCEPTANCE) ||
        (role === ActivityDifferentiator.CMSN &&
          currentInstallationStatus === InstallationStatus.FOR_TESTER_ACCEPTANCE);

      const isOngoingInstallation =
        (role === ActivityDifferentiator.INST &&
          currentInstallationStatus === InstallationStatus.INSTALLER_ACCEPTED) ||
        (role === ActivityDifferentiator.CMSN &&
          currentInstallationStatus === InstallationStatus.TESTER_ACCEPTED);

      if (isStartingNewInstallation) {
        navigate(`/subcontractor/${networkNumber}/starting`);
        return;
      }

      if (isOngoingInstallation) {
        if (
          installationData?.networkTag === NetworkTags.MOD &&
          installationData?.status === InstallationStatus.INSTALLER_ACCEPTED
        )
          navigate(`/subcontractor/${networkNumber}/installation-tasks`);
        else navigate(`/subcontractor/${networkNumber}/execution`);
        return;
      }

      const isAllowToViewDeviationListOnly = getAllowToViewDeviationListOnly(
        role as ActivityDifferentiator,
        currentInstallationStatus
      );

      if (isAllowToViewDeviationListOnly) {
        navigate(`/subcontractor/${networkNumber}/deviations`);
        return;
      }
    }

    setSubcontractorKey('');
    setErrMessage(t('subcontractorLogin.somethingWentWrong'));
  };

  const upsertContact = async (contact: Contact) => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();

    await put(
      `v1/installations/${networkNumber}/contacts`,
      accessToken,
      API_TYPE.APPLICATION,
      contact
    );

    updateIsLoading(false);
  };

  const submit = async () => {
    setErrMessage('');
    updateIsLoading(true);
    try {
      const response = await subcontractorLoginPost(
        `v1/subcontractor/${networkNumber}/access/login`,
        {
          guid: guid,
          hash: hash,
          activityDifferentiator: role,
          pincode: subcontractorKey,
        }
      );

      const tokenLocalStorageKey = generateTokenLocalStorageKey(networkNumber);
      window.localStorage.setItem(tokenLocalStorageKey, response.token);

      const userRoleLocalStorageKey = generateUserRoleLocalStorageKey(networkNumber);
      window.localStorage.setItem(userRoleLocalStorageKey, role);

      const token = await getTokenFunction();
      const contacts = await fetchContacts(networkNumber, token);

      //For now, we assume that there will always be one subcontractor for each role
      const contact = contacts.find((c) => {
        return c.role === correspondingContactRole;
      });

      setContactInfo(contact);
      setCurrentInstallationStatus(response.status);
      updateIsLoading(false);
    } catch (error) {
      updateIsLoading(false);
      setSubcontractorKey('');

      if (!axios.isAxiosError(error)) {
        console.error(error);
        return;
      }

      const { status, data } = error.response as AxiosResponse<string>;

      switch (true) {
        case status === 410:
          setErrMessage(t('subcontractorLogin.accessRevoked'));
          return;
        case status === 401:
          setErrMessage(t('subcontractorLogin.incorrectCredentials'));
          return;
        case status === 412 && data === errorResponse.networkFinished:
          setErrMessage(t('subcontractorLogin.networkFinished'));
          return;
        case status === 412 && data === errorResponse.networkNotReady:
          setErrMessage(t('subcontractorLogin.networkNotReady'));
          return;
        case status === 412 && data === errorResponse.networkNotReleased:
          setErrMessage(t('subcontractorLogin.networkNotReleased'));
          return;
        default:
          setErrMessage(t('subcontractorLogin.somethingWentWrong'));
          return;
      }
    }
  };

  return (
    <StyledBox pt={15} marginTop={0}>
      <Container>
        <Typography component="div" align="center">
          {logo && <img src={logo} />}
          <Typography variant="h4">{t('ies')}</Typography>
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" pt={10}>
          <StyledTypography variant="h6">{errMessage}</StyledTypography>
          <br />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="15vh"
          pb={1}
        >
          <TextFieldStyled
            data-testid="subcontractor-key"
            id="subcontractorkey"
            label={t('subcontractorLogin.subcontractorKey')}
            variant="filled"
            // color="primary"
            value={subcontractorKey}
            onChange={handleInputChange}
            error={errMessage.length > 0}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="25vh"
          pt={20}
          pb={20}
        >
          <IconButton
            disabled={subcontractorKey.length < 1}
            color="inherit"
            edge="end"
            data-testid="subcontractor-login"
            onClick={submit}
          >
            <IconStyled />
          </IconButton>
        </Box>
      </Container>
      {currentInstallationStatus && (
        <Dialog open={true}>
          <DialogContent>
            <SubcontractorPersonalInfoForm
              dbInfo={contactInfo}
              contactRole={correspondingContactRole}
              onContinue={redirectUserToPage}
              upsertContact={upsertContact}
            />
          </DialogContent>
        </Dialog>
      )}
    </StyledBox>
  );
};

export default SubcontractorLogin;
