import {
  IconRoleInstaller,
  IconFiletypeOther,
  IconRoleTester,
} from '@konecorp/ui-library';
import {
  Typography,
  IconButton,
  Collapse,
  Box,
  Button,
  styled,
  Grid2,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import React, { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Installation, QuestionSet, AnswerSet } from '../../schemas';
import { green, blue } from '@mui/material/colors';

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  color: theme.palette.primary.main,
  textTransform: 'initial',
  textDecoration: 'underline',
}));

const StyledGrid = styled(Grid2)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  backgroundColor: '#005A93',
  color: '#FFFFFF',
  height: theme.typography.fontSize * 3,
  width: '100%',
  '& p': {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  '& .MuiIconButton-root': {
    paddingTop: theme.spacing(1),
    color: '#FFFFFF',
  },
}));

type FormProps = {
  text: string;
  questions: QuestionSet[];
  answers: AnswerSet[];
  icon: JSX.Element;
  color: string;
  buttonKey: string;
  onShowForm: (questionSet: QuestionSet[], answerSet: AnswerSet[]) => void;
};

const ReviewForm = (props: FormProps) => {
  const { color, text, icon, questions, answers, buttonKey, onShowForm } = props;
  const { t } = useTranslation();
  return (
    <Box mb={2} width="100%">
      <Box
        p={0.5}
        display="flex"
        alignItems="center"
        width="100%"
        style={{ backgroundColor: color }}
      >
        {icon}
        <Typography component="div" style={{ marginLeft: '4px' }}>
          {text}
        </Typography>
      </Box>
      <Button onClick={() => onShowForm(questions, answers)} aria-label={buttonKey}>
        <IconFiletypeOther fontSize="large" />
        <StyledTypography>{t('reviewForm.openForm')}</StyledTypography>
      </Button>
    </Box>
  );
};

export type PrintoutFormsProps = {
  installationData: Installation | null;
  onShowReviewFormWithData: (questionSet: QuestionSet[], answerSet: AnswerSet[]) => void;
};

const PrintoutForms = (props: PrintoutFormsProps): JSX.Element => {
  const { installationData, onShowReviewFormWithData } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
  return (
    <>
      <StyledGrid
        data-testid="printout-forms-header"
        container
        onClick={() => setOpen(!open)}
      >
        <Grid2 size={{ xs: 11 }}>
          <Typography>{t('reviewForm.forms')}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 1 }}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid2>
      </StyledGrid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box m={2} display="flex" flexDirection="column" alignItems="flex-start">
          <ReviewForm
            text={t('reviewForm.qdPlusForm')}
            color={green[100]}
            icon={<IconRoleInstaller fontSize="small" />}
            questions={
              (installationData?.mergedQuestions?.installerQuestions.length
                ? installationData?.mergedQuestions?.installerQuestions
                : installationData?.installerQuestions) ?? []
            }
            answers={
              (installationData?.mergedAnswers?.installerAnswers.length
                ? installationData?.mergedAnswers?.installerAnswers
                : installationData?.installerAnswers) ?? []
            }
            buttonKey="qd-plus-form-button"
            onShowForm={onShowReviewFormWithData}
          />
          <ReviewForm
            text={t('reviewForm.qualityReviewForm')}
            color={blue[100]}
            icon={<IconRoleTester fontSize="small" />}
            questions={
              (installationData?.mergedQuestions?.testerQuestions.length
                ? installationData?.mergedQuestions?.testerQuestions
                : installationData?.testerQuestions) ?? []
            }
            answers={
              (installationData?.mergedAnswers?.testerAnswers.length
                ? installationData?.mergedAnswers?.testerAnswers
                : installationData?.testerAnswers) ?? []
            }
            buttonKey="quality-review-form-button"
            onShowForm={onShowReviewFormWithData}
          />
        </Box>
      </Collapse>
    </>
  );
};

export default PrintoutForms;
