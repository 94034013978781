import { ArrowLeft, ArrowRight, theme } from '@konecorp/ui-library';
import { Box, Button, ButtonProps, Grid2, styled } from '@mui/material';
import React, { JSX, PropsWithChildren } from 'react';

const ArrowLeftIcon = styled(ArrowLeft)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(5),
  '& path': {
    fill: theme.palette.common.white,
  },
}));

const ArrowRightIcon = styled(ArrowRight)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(5),
  '& path': {
    fill: theme.palette.common.white,
  },
}));

const StyledGrid = styled(Grid2)(({ theme }) => ({
  flexBasis: 'auto',
  width: theme.spacing(24),
}));

const StyledNavigationButton = styled(Button)(() => ({
  height: theme.spacing(5),
  lineHeight: '16px',
  '&.MuiButton-root': {
    minWidth: '40px',
    padding: 0,
  },
  '& .MuiButton-endIcon, .MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const StyledNavigationButtonBackward = styled(Button)(() => ({
  height: theme.spacing(5),
  lineHeight: '16px',
  justifyContent: 'flex-start',
  '&.MuiButton-root': {
    width: '100%',
  },
  '& .MuiButton-label': {
    padding: theme.spacing(1),
    justifyContent: 'flex-start',
  },
}));

const StyledNavigationButtonForward = styled(Button)(() => ({
  height: theme.spacing(5),
  lineHeight: '16px',
  justifyContent: 'flex-end',
  '&.MuiButton-root': {
    width: '100%',
  },
  '& .MuiButton-label': {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}));

export interface NavigationButtonsProps {
  onClickForward?: () => void;
  onClickBackward?: () => void;
  onCreateDeviation?: () => void;
  backwardButtonText?: string;
  forwardButtonText?: string;
  content?: JSX.Element;
  className?: string;
  forwardDisabled?: boolean;
}

const NavigationButton = (props: {
  isBackButton?: boolean;
  isDeviationButton?: boolean;
  onClick: () => void;
  text?: string;
  disabled?: boolean;
}) => {
  const { isBackButton, isDeviationButton, onClick, text } = props;

  const buttonProps = {
    size: 'small',
    variant: 'contained',
    onClick,
    color: 'primary',
    disabled: props.disabled,
    'data-testid': isBackButton ? 'go-backward' : 'go-forward',
    endIcon: !isBackButton && !isDeviationButton ? <ArrowRightIcon /> : undefined,
    startIcon: isBackButton ? <ArrowLeftIcon /> : undefined,
  } as ButtonProps;
  if (text) {
    if (isBackButton) {
      return (
        <StyledNavigationButtonBackward {...buttonProps}>
          {text}
        </StyledNavigationButtonBackward>
      );
    } else {
      return (
        <StyledNavigationButtonForward {...buttonProps}>
          {text}
        </StyledNavigationButtonForward>
      );
    }
  } else return <StyledNavigationButton {...buttonProps}>{text}</StyledNavigationButton>;
};

const NavigationButtons = (
  props: PropsWithChildren<NavigationButtonsProps>
): JSX.Element => {
  const {
    onClickBackward,
    onClickForward,
    onCreateDeviation,
    backwardButtonText,
    forwardButtonText,
    content,
    forwardDisabled,
  } = props;
  const isSmallButton = !(backwardButtonText && forwardButtonText);
  return (
    <Box flexGrow={1}>
      <Grid2 container justifyContent="center" wrap="nowrap">
        <Grid2
          size={{ xs: isSmallButton ? 2 : 6 }}
          sx={{ paddingRight: isSmallButton ? 0 : (theme) => theme.spacing(1.5) }}
        >
          {onClickBackward && (
            <NavigationButton
              text={backwardButtonText}
              isBackButton
              onClick={onClickBackward}
            />
          )}
        </Grid2>
        {isSmallButton && <StyledGrid size={{ xs: 8 }}>{content}</StyledGrid>}
        <Grid2
          size={{ xs: isSmallButton ? 2 : 6 }}
          sx={{ paddingLeft: isSmallButton ? 0 : (theme) => theme.spacing(1.5) }}
        >
          {onClickForward && (
            <NavigationButton
              text={forwardButtonText}
              onClick={onClickForward}
              disabled={forwardDisabled}
            />
          )}
          {onCreateDeviation && (
            <NavigationButton
              text={forwardButtonText}
              onClick={onCreateDeviation}
              disabled={forwardDisabled}
              isDeviationButton
            />
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default NavigationButtons;
