import React, { ChangeEvent } from 'react';
import { AnswerValue, Question, QuestionValueType, isNewDeviation } from '../../schemas';
import { styled, TextField } from '@mui/material';
import AnswerValueWithDeviation from '../../containers/AnswerValueWithDeviation';
import { CreateDeviationPayload, EditDeviationPayload } from '../DeviationForm';
import { theme } from '@konecorp/ui-library';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';
import { QDQuestionProps } from '../../containers/QDQuestion';

export interface AnswerValueTypeProps {
  parentProp: QDQuestionProps;
  question: Question;
  answer: AnswerValue;
  isInputValid: boolean;
  handleNumberInputOnChange: (
    e: ChangeEvent<HTMLInputElement>,
    question: Question
  ) => void;
  questionSetId: string;
  questionSequence: number;
  setAnswer: React.Dispatch<React.SetStateAction<AnswerValue>>;
}

const StyledContainer = styled('div')({
  display: 'inline-block',
  verticalAlign: 'middle',
});
/**
 * AnswerValueType component accepts props which helps to render UI options like yes_no,ok_not
 * @param props - props for AnswerValueType component
 */
const AnswerValueType = (props: AnswerValueTypeProps) => {
  const [isReadOnlyMode] = useIfReadOnly();

  const {
    parentProp,
    question,
    answer,
    isInputValid,
    handleNumberInputOnChange,
    questionSetId,
    questionSequence,
    setAnswer,
  } = props;
  if (question.valueType === QuestionValueType.NUMBER) {
    return (
      <TextField
        margin="none"
        size="small"
        variant="outlined"
        type="number"
        value={answer?.toString() || ''}
        sx={isInputValid ? {} : { backgroundColor: theme.palette.error.light }}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleNumberInputOnChange(e, question)
        }
        inputProps={{
          'data-testid': 'number-answer-input',
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        data-testid="number-answer-text-field"
        disabled={isReadOnlyMode}
      />
    );
  } else if (
    question.valueType === QuestionValueType.YES_NO ||
    question.valueType === QuestionValueType.OK_NOTOK ||
    question.valueType === QuestionValueType.OK_NOTOK_NA
  ) {
    return (
      <StyledContainer data-testid="answer-with-deviation">
        <AnswerValueWithDeviation
          answer={answer}
          question={question}
          questionSetId={questionSetId}
          questionSequence={questionSequence}
          isReadOnly={isReadOnlyMode}
          onAnswer={async (newAnswer, deviation) => {
            if (deviation) {
              if (isNewDeviation(deviation)) {
                await parentProp.onCreateDeviation(deviation as CreateDeviationPayload);
              } else {
                await parentProp.onEditDeviation(deviation as EditDeviationPayload);
              }
              // store answer immediately only when deviation is created for data consistency
              parentProp.saveAnswer(newAnswer);
            }

            setAnswer(newAnswer.value);
          }}
        />
      </StyledContainer>
    );
  }
  return <></>;
};
export default AnswerValueType;
