import React, { JSX } from 'react';
import { Box, Typography, styled, Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import { useCheckDataSync } from '../../hooks/useCheckDataSync';

// Styled Components
const StyledBanner = styled(Box)(({ theme }) => ({
  background: 'white',
  width: '100%',
  height: 'fit-content',
  textAlign: 'center',
  paddingTop: theme.spacing(2.5),
  color: theme.palette.background.paper,
  visibility: 'visible',
  fontSize: '20px',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}));

export const ConnectStatusBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const [isOnline] = useCheckConnection();
  const { hasToSync } = useCheckDataSync();

  if (isOnline) {
    return <></>;
  }

  return (
    <Grow in={!isOnline} {...(!isOnline ? { timeout: 1000 } : {})}>
      <StyledBanner role="alert" data-testid="connection-banner">
        <StyledText>
          {hasToSync
            ? t('connection.offlineModeDataToSync')
            : t('connection.offlineModeNoDataToSync')}
        </StyledText>
      </StyledBanner>
    </Grow>
  );
};

export default ConnectStatusBanner;
