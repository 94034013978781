import React, { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InfoModal, NaviClose, theme } from '@konecorp/ui-library';
import { ActivityDifferentiator, Employee, UserSettings } from '../../schemas';
import Context from '../../context';
import { locales, getLocale } from '../../localization';
import { useGetUserData } from '../../hooks/useGetUserData';
import { useGetToken } from '../../hooks/useGetToken';
import { getSettings, putSettings } from '../../helpers/configurationActions';
import { clearIndexedDB } from '../../helpers/indexedDB';
import { API_TYPE, get } from '../../helpers/fetch';
import CustomAutocomplete, { CustomAutocompleteData } from '../CustomAutocomplete';

enum DialogType {
  CLEAR_CACHE,
  CLEAR_CACHE_MESSAGE,
  NONE,
}

const CacheClearButton = styled(Button)(() => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[500],
  '&:hover': {
    backgroundColor: red[700],
  },
}));

const CloseIcon = styled(NaviClose)(() => ({
  position: 'absolute',
  right: '10px',
  width: 40,
  height: 40,
}));

export type ClearCacheDialogProps = {
  open: boolean;
  onClose: () => void;
  onClear: () => void;
};

const ClearCacheDialog = (props: ClearCacheDialogProps): JSX.Element => {
  const { open, onClose, onClear } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>{t('settings.warning')}!</DialogTitle>
      <DialogContent>
        <Typography>{t('settings.warningMessage')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {t('settings.cancel')}
        </Button>
        <CacheClearButton onClick={onClear} color="primary">
          {t('settings.clear')}
        </CacheClearButton>
      </DialogActions>
    </Dialog>
  );
};

type DelegateAutoCompleteProps = {
  delegates: Employee[];
  settings: UserSettings;
  setDelegate: (delegate?: string) => void;
};

const DelegateAutoComplete = ({
  delegates,
  settings,
  setDelegate,
}: DelegateAutoCompleteProps) => {
  const { t } = useTranslation();

  const DELEGATE_NONE = 'NoDelegate';

  const noDelegateOption = {
    id: DELEGATE_NONE as string,
    label: t('settings.noDelegate'),
  };

  const delegateOptions = delegates.map((employee) => ({
    id: employee.employeeId,
    label: `${employee.legalFirstName} ${employee.legalLastName}`,
  }));

  const handleChange = (value: CustomAutocompleteData) => {
    const delegate = value.id === DELEGATE_NONE ? undefined : value.id;
    setDelegate(delegate);
  };

  return (
    <CustomAutocomplete
      id="delegate"
      label="Delegate"
      loading={delegates?.length === 0}
      data={[noDelegateOption].concat(delegateOptions)}
      selectedValue={settings.delegate || DELEGATE_NONE}
      onChange={handleChange}
    />
  );
};

export type SettingsProps = {
  open: boolean;
  onClose: () => void;
};

const defaultSettings: UserSettings = { userLanguage: getLocale().tag };

const Settings = (props: SettingsProps): JSX.Element => {
  const { updateIsLoading } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [getToken] = useGetToken();
  const [currentEmployeeId] = useGetUserData();
  const [currentEmployee, setCurrentEmployee] = useState<Employee>();
  const [delegates, setDelegates] = useState<Employee[]>([]);
  const { open, onClose } = props;

  const [settings, setSettings] = useState<UserSettings>(defaultSettings);
  const [initialSettings, setInitialSettings] = useState<UserSettings | null>(null);
  const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState<DialogType>(DialogType.NONE);

  useEffect(() => {
    (async () => {
      updateIsLoading(true);
      const token = await getToken();
      const savedSettings = await getSettings<UserSettings>(currentEmployeeId, token);
      if (savedSettings) {
        setSettings({ ...defaultSettings, ...savedSettings });
        setInitialSettings({ ...defaultSettings, ...savedSettings });
      }

      const employees: Employee[] | undefined = await get(
        `v1/employees?employeeId=${currentEmployeeId}`,
        token,
        API_TYPE.APPLICATION
      );

      const employeeResponse = employees?.[0];
      setCurrentEmployee(employeeResponse);
      updateIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (currentEmployee) {
      (async () => {
        const token = await getToken();
        const employeesData: Employee[] | undefined = await get(
          `v1/employees?role=${ActivityDifferentiator.SPV}&companyCode=${currentEmployee.companyCode}`,
          token,
          API_TYPE.APPLICATION
        );

        if (employeesData)
          setDelegates(
            employeesData.filter((employee) => employee.employeeId !== currentEmployeeId)
          );
      })();
    }
  }, [currentEmployee]);

  const saveSettings = async () => {
    const token = await getToken();

    if (!initialSettings || !isEqual(initialSettings, settings)) {
      updateIsLoading(true);
      await putSettings(currentEmployeeId, settings, token);
      setInitialSettings(settings);
      i18n.changeLanguage(settings.userLanguage as string);
      updateIsLoading(false);

      // make sure all translations will be reloaded
      window.location.reload();
    }

    onClose();
  };

  const handleClearCache = async () => {
    setOpenDialog(DialogType.NONE);
    try {
      updateIsLoading(true);
      await clearIndexedDB();
      const keys = await window.caches.keys();
      await Promise.all(keys.map((key) => window.caches.delete(key)));
      setOpenDialog(DialogType.CLEAR_CACHE_MESSAGE);
    } catch (e) {
      console.error(e);
    } finally {
      updateIsLoading(false);
    }
  };

  const closeDialog = () => setOpenDialog(DialogType.NONE);

  const isSupervisor = currentEmployee?.role === ActivityDifferentiator.SPV;

  if (!currentEmployee) return <></>;

  return (
    <Box>
      <Dialog
        open={open && openDialog === DialogType.NONE}
        sx={{
          '.MuiPaper-root': {
            height: '80%',
          },
        }}
        fullWidth
      >
        <DialogTitle
          sx={{
            '& + .MuiDialogContent-root': {
              paddingTop: '10px !important',
            },
          }}
        >
          {t('settings.title')}
          <CloseIcon onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              name="language"
              label={t('settings.language')}
              variant="outlined"
              value={settings.userLanguage as string}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSettings({ ...settings, userLanguage: event.target.value })
              }
              inputProps={{ 'data-testid': 'language-input' }}
              fullWidth
              select
            >
              {locales.map((locale) => (
                <MenuItem value={locale.tag} key={locale.tag}>
                  {locale.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box mt="1.5em">
            {isSupervisor && (
              <DelegateAutoComplete
                delegates={delegates}
                settings={settings}
                setDelegate={(delegate) => setSettings({ ...settings, delegate })}
              />
            )}
          </Box>
          <Box mt="1.5em">
            <Accordion
              expanded={isAdvancedSettingsOpen}
              onChange={() => setAdvancedSettingsOpen((isOpen) => !isOpen)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>{t('settings.advanced')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CacheClearButton
                  fullWidth
                  variant="contained"
                  data-testid="clear-cache"
                  onClick={() => setOpenDialog(DialogType.CLEAR_CACHE)}
                >
                  {t('settings.clearCache')}
                </CacheClearButton>
              </AccordionDetails>
            </Accordion>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box width="100%" p={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              data-testid="save-button"
              onClick={saveSettings}
            >
              {t('settings.save')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ClearCacheDialog
        open={openDialog === DialogType.CLEAR_CACHE}
        onClose={closeDialog}
        onClear={handleClearCache}
      />
      <InfoModal
        open={openDialog === DialogType.CLEAR_CACHE_MESSAGE}
        aria-label="clear-cache-message"
        closeButtonText={t('settings.OK')}
        message={t('settings.cleared')}
        onClose={closeDialog}
        isCenteredMessage
      />
    </Box>
  );
};

export default Settings;
