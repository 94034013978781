import { NominalValues } from '../components/PlumbingNominalValueCorrection';
import { PlumbingSummaryAnswer } from '../components/PlumbingSummaryTable';
import {
  AdjustmentCoordinates,
  AdjustmentsState,
  PlumbingAdjustmentAction,
} from '../components/PlumbmingXYAdjustments';
import { PLUMBING_QUESTIONSET_ID } from '../containers/QuestionsSequence';
import {
  AnswerSet,
  Installation,
  Question,
  QuestionSet,
  QuestionValueType,
  PlumbingMeasurementTag,
  PlumbingMeasurementCategory,
  PlumbingNominalQuestion,
  LocalizedText,
  MergedQuestions,
  MergedAnswers,
} from '../schemas';
import { skipQuestion } from './question';

/**
 * Returns an array with arrays of the given size.
 *
 * @param array {Array} Array to split
 * @param chunkSize {Integer} > 0 Size of every group
 */
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  if (chunkSize <= 0)
    throw new Error(
      'The chunk size cannot be zero or less, otherwise it will kick infinite loop'
    );
  const results: T[][] = [];
  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }
  return results;
};

const [INDEX_A1, INDEX_A2, INDEX_A3, INDEX_A4, INDEX_A5, INDEX_A6] = [0, 1, 2, 3, 4, 5];

const parseToFloatValue = (value?: string | null): number => parseFloat(value || '0');

/**
 * @param installationQuestionsAndAnswers - Installation meta data
 * @returns floor wise array of Plumbing answers
 */
export const parseDataForSummaryTableRows = (
  installationQuestionsAndAnswers: {
    installerAnswers: AnswerSet[];
    installerQuestions: QuestionSet[];
    mergedQuestions?: MergedQuestions;
    mergedAnswers?: MergedAnswers;
  } | null,
  plumbingMeasurementsLength: number
): PlumbingSummaryAnswer[][] => {
  if (installationQuestionsAndAnswers) {
    const { installerAnswers, installerQuestions, mergedQuestions, mergedAnswers } =
      installationQuestionsAndAnswers;

    const questionSet = mergedQuestions?.installerQuestions?.length
      ? mergedQuestions.installerQuestions
      : installerQuestions;

    const answerSet = mergedAnswers?.installerAnswers?.length
      ? mergedAnswers.installerAnswers
      : installerAnswers;

    const plumbingAnswers =
      answerSet.find((ans) => ans.questionSetId === PLUMBING_QUESTIONSET_ID)?.answers ||
      [];
    const plumbingQuestions =
      questionSet.find((ques) => ques.questionSetId === PLUMBING_QUESTIONSET_ID)
        ?.questions || [];

    const plumbingMeasurements: PlumbingSummaryAnswer[] = [];
    for (let key = 0; key < plumbingAnswers.length; key++) {
      const answer = plumbingAnswers[key];
      const { condition = '', valueType } = plumbingQuestions[key];
      const isQuestiontypeNumber =
        !skipQuestion(condition, answerSet) && valueType === QuestionValueType.NUMBER;
      if (answer && isQuestiontypeNumber) {
        plumbingMeasurements.push({
          ...answer, // As question/answer are in the same sequence
          actualValue: plumbingQuestions[key].actualValue,
          questionSequenceNumber: key,
          tag: plumbingQuestions[key].tag,
          negativeTolerance: plumbingQuestions[key].negativeTolerance,
          positiveTolerance: plumbingQuestions[key].positiveTolerance,
          additionalInfo: plumbingQuestions[key].additionalInfo,
        });
      }
    }

    const rowWiseAnswers = chunkArray<PlumbingSummaryAnswer>(
      plumbingMeasurements,
      plumbingMeasurementsLength
    );
    return rowWiseAnswers;
  }
  return [[]];
};

// Caution! Do not change. This is always in this order,
const PLUMBING_MEASUREMENTS_4_VALUES: PlumbingMeasurementCategory = [
  'A1',
  'A2',
  'A3',
  'A4',
];
const PLUMBING_MEASUREMENTS_WITH_A6: PlumbingMeasurementCategory = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A6',
];
const PLUMBING_MEASUREMENTS_WITH_A5: PlumbingMeasurementCategory = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
];
const PLUMBING_MEASUREMENTS_6_VALUES: PlumbingMeasurementCategory = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
];

export const getPlumbingMeasurements = (
  installationData: Installation
): PlumbingMeasurementCategory => {
  const { installerQuestions: questionSet } = installationData;
  const plumbingQuestions =
    questionSet.find((ques) => ques.questionSetId === PLUMBING_QUESTIONSET_ID)
      ?.questions || [];
  const plumbingNominalQuestionTag = plumbingQuestions[2].tag.toLowerCase(); // nominal question position always remains the same(2).

  switch (plumbingNominalQuestionTag) {
    case PlumbingMeasurementTag.VALUES_WITH_4:
      return PLUMBING_MEASUREMENTS_4_VALUES;
    case PlumbingMeasurementTag.VALUES_WITH_A5:
      return PLUMBING_MEASUREMENTS_WITH_A5;
    case PlumbingMeasurementTag.VALUES_WITH_A6:
      return PLUMBING_MEASUREMENTS_WITH_A6;
    default:
      return PLUMBING_MEASUREMENTS_6_VALUES;
  }
};

export const getDefaultNominalValues = (
  measurements: PlumbingMeasurementCategory
): NominalValues => {
  const with4Values: NominalValues = {
    A1ActualValue: 0,
    A2ActualValue: 0,
    A3ActualValue: 0,
    A4ActualValue: 0,
  };
  const withA5Value = {
    ...with4Values,
    A5ActualValue: 0,
  };
  const withA6Value = {
    ...with4Values,
    A6ActualValue: 0,
  };
  const withAllValues = {
    ...with4Values,
    A5ActualValue: 0,
    A6ActualValue: 0,
  };
  switch (measurements) {
    case PLUMBING_MEASUREMENTS_4_VALUES:
      return with4Values;
    case PLUMBING_MEASUREMENTS_WITH_A5:
      return withA5Value;
    case PLUMBING_MEASUREMENTS_WITH_A6:
      return withA6Value;
    default:
      return withAllValues;
  }
};

/**
 * @param installationData - Installation meta data
 * @returns floor wise array of Question.
 * This also filters out wire/laser plumbing questions depending on the answer
 */
export const extractPlumbingQuestionFromInstallationData = (
  installationData: Installation,
  numberOfMeasurements: number
): PlumbingNominalQuestion[][] => {
  const { installerQuestions, installerAnswers } = installationData;

  const plumbingQuestions: Question[] =
    installerQuestions.find((ques) => ques.questionSetId === PLUMBING_QUESTIONSET_ID)
      ?.questions || [];

  const filteredQuestions: PlumbingNominalQuestion[] = plumbingQuestions
    .map((question, index) => ({
      ...question,
      questionSetId: PLUMBING_QUESTIONSET_ID,
      questionSequenceNumber: index,
      actualValue: Number(question.actualValue),
    })) // Caution! Filter only after mapping the data, as index is questionSequenceNumber
    .filter((plumbingQuestion) => {
      const { condition = '', valueType } = plumbingQuestion;
      return (
        // skip either laser/wire plumbing depending on answer and condition.
        !skipQuestion(condition, installerAnswers) &&
        valueType === QuestionValueType.NUMBER
      );
    });

  const rowWiseQuestions = chunkArray(filteredQuestions, numberOfMeasurements);
  return rowWiseQuestions as PlumbingNominalQuestion[][];
};

type PlumbingThreshold = {
  xMaxToNegativeX?: number;
  xMaxToPositiveX?: number;
  yMaxToNegativeY?: number;
  yMaxToPositiveY?: number;
  error?: boolean;
};

enum PlumbingAnswerProperty {
  NEGATIVE_TOLLERANCE,
  POSITIVE_TOLLERANCE,
  REFERENCE_VALUE,
  VALUE,
}

enum AnswerCombination {
  A1_OR_A5,
  A2_OR_A6,
  A3_OR_A4,
}

type AddintionalInfoTextKey = 'a1' | 'a2' | 'a3' | 'a4' | 'a5' | 'a6';

const ifAnswerIsAmongThesePairOfMeasurements = (
  combination: AnswerCombination,
  answer: PlumbingSummaryAnswer,
  measurementsPerFloor: number,
  key: number
): boolean => {
  const { additionalInfo } = answer;
  // TODO - REMOVE THIS IF BELOW BLOCK WHEN ALL DATA IN PRODUCTION SUPPORTS 'additionalInfo' field
  if (measurementsPerFloor === 6) {
    switch (combination) {
      case AnswerCombination.A1_OR_A5:
        return [INDEX_A1, INDEX_A5].includes(key);
      case AnswerCombination.A2_OR_A6:
        return [INDEX_A2, INDEX_A6].includes(key);
      case AnswerCombination.A3_OR_A4:
        return [INDEX_A3, INDEX_A4].includes(key);
      default:
        return false;
    }
  }
  // ---ends here---

  switch (combination) {
    case AnswerCombination.A1_OR_A5:
      return (
        checkAdditionalInfoMatch('a1', additionalInfo) ||
        checkAdditionalInfoMatch('a5', additionalInfo)
      );
    case AnswerCombination.A2_OR_A6:
      return (
        checkAdditionalInfoMatch('a2', additionalInfo) ||
        checkAdditionalInfoMatch('a6', additionalInfo)
      );
    case AnswerCombination.A3_OR_A4:
      return (
        checkAdditionalInfoMatch('a3', additionalInfo) ||
        checkAdditionalInfoMatch('a4', additionalInfo)
      );
    default:
      return false;
  }
};
const getPlumbingAnswerProperty = (
  answer: PlumbingSummaryAnswer,
  property: PlumbingAnswerProperty
): number => {
  switch (property) {
    case PlumbingAnswerProperty.NEGATIVE_TOLLERANCE:
      return parseToFloatValue(answer.negativeTolerance);
    case PlumbingAnswerProperty.POSITIVE_TOLLERANCE:
      return parseToFloatValue(answer.positiveTolerance);
    case PlumbingAnswerProperty.REFERENCE_VALUE:
      return parseToFloatValue(answer.actualValue);
    case PlumbingAnswerProperty.VALUE:
      return parseToFloatValue(`${answer.value ?? ''}`);
  }
};

const checkAdditionalInfoMatch = (
  key: AddintionalInfoTextKey,
  additionalInfo?: LocalizedText[]
): boolean => {
  return Boolean(additionalInfo && additionalInfo[0].text.toLowerCase() === key);
};

/**
 * This is generic function to return a required property from Answer
 * @param answers
 * @param property
 * @returns {a1, a2, a3, a4, a5, a6} values based on the property passed.
 */
const getParsedPropertyFromAnswers = (
  answers: PlumbingSummaryAnswer[],
  property: PlumbingAnswerProperty
) => {
  /**
   * To support the old way that always has 6 measurements
   * TODO - REMOVE THIS BELOW BLOCK WHEN ALL DATA IN PRODUCTION SUPPORTS 'additionalInfo' field
   */
  if (answers.length === 6) {
    const [a1, a2, a3, a4, a5, a6] = answers.map((answer) =>
      getPlumbingAnswerProperty(answer, property)
    );
    return { a1, a2, a3, a4, a5, a6 };
  }
  /** --- Old implementation ends here --- */

  const referrenceValues: {
    a5?: number;
    a6?: number;
  } = {};
  const [a1, a2, a3, a4] = answers.map((answer) => {
    const { additionalInfo } = answer;
    if (checkAdditionalInfoMatch('a5', additionalInfo)) {
      referrenceValues.a5 = getPlumbingAnswerProperty(answer, property);
    } else if (checkAdditionalInfoMatch('a6', additionalInfo)) {
      referrenceValues.a6 = getPlumbingAnswerProperty(answer, property);
    }
    return getPlumbingAnswerProperty(answer, property);
  });

  return {
    a1,
    a2,
    a3,
    a4,
    ...referrenceValues,
  };
};

/**
 * This functions separates all the measurements answers in one array
 * @param answers
 * @returns separate list of each answers a1AllAnswers has all A1 values and similarly A2, A3, A4, A5, A6
 */
const getEachMeasurementValuesRespectively = (answers: PlumbingSummaryAnswer[][]) => {
  const a1AllAnswers: number[] = [];
  const a2AllAnswers: number[] = [];
  const a3AllAnswers: number[] = [];
  const a4AllAnswers: number[] = [];
  const a5AllAnswers: number[] = [];
  const a6AllAnswers: number[] = [];
  answers.map((floorWiseAnswer) => {
    const { a1, a2, a3, a4, a5, a6 } = getParsedPropertyFromAnswers(
      floorWiseAnswer,
      PlumbingAnswerProperty.VALUE
    );
    a1AllAnswers.push(a1);
    a2AllAnswers.push(a2);
    a3AllAnswers.push(a3);
    a4AllAnswers.push(a4);
    /**
     * Caution!! Checking value is very important for A5 and A6.
     * Do not push is value is undefined
     */
    if (a5 !== undefined) a5AllAnswers.push(a5);
    if (a6 !== undefined) a6AllAnswers.push(a6);
  });

  return {
    a1AllAnswers,
    a2AllAnswers,
    a3AllAnswers,
    a4AllAnswers,
    a5AllAnswers,
    a6AllAnswers,
  };
};

export const calculatePlumbingThreshold = (
  answers: PlumbingSummaryAnswer[][]
): PlumbingThreshold => {
  try {
    const isAnswerValueEmpty = answers.every((floor) =>
      floor.every((ans) => Boolean(!ans.value))
    );
    const isAnyAnswerActualValueEmpty = answers.some((floor) =>
      floor.some((ans) => Boolean(!ans.actualValue))
    );
    const isNotPossibleToFix = isAnswerValueEmpty || isAnyAnswerActualValueEmpty;
    if (isNotPossibleToFix) {
      return {
        error: true,
      };
    }

    // All reference values
    const {
      a1: a1ParsedReferenceValue,
      a2: a2ParsedReferenceValue,
      a3: a3ParsedReferenceValue,
      a4: a4ParsedReferenceValue,
      a5: a5ParsedReferenceValue,
      a6: a6ParsedReferenceValue,
    } = getParsedPropertyFromAnswers(answers[0], PlumbingAnswerProperty.REFERENCE_VALUE);

    // mininum and maximum A1, A2, A3, A4, A5, A6 reading of all floors.
    const {
      a1AllAnswers,
      a2AllAnswers,
      a3AllAnswers,
      a4AllAnswers,
      a5AllAnswers,
      a6AllAnswers,
    } = getEachMeasurementValuesRespectively(answers);

    const minA1AllFloors = Math.min(...a1AllAnswers);
    const maxA1AllFloors = Math.max(...a1AllAnswers);
    const minA2AllFloors = Math.min(...a2AllAnswers);
    const maxA2AllFloors = Math.max(...a2AllAnswers);
    const minA3AllFloors = Math.min(...a3AllAnswers);
    const maxA3AllFloors = Math.max(...a3AllAnswers);
    const minA4AllFloors = Math.min(...a4AllAnswers);
    const maxA4AllFloors = Math.max(...a4AllAnswers);

    // Make sure if the installation has any A5 measurements
    const minA5AllFloors =
      a5AllAnswers.length > 0 ? Math.min(...a5AllAnswers) : undefined;
    const maxA5AllFloors =
      a5AllAnswers.length > 0 ? Math.max(...a5AllAnswers) : undefined;

    // Make sure if the installation has any A6 measurements
    const minA6AllFloors =
      a6AllAnswers.length > 0 ? Math.min(...a6AllAnswers) : undefined;
    const maxA6AllFloors =
      a6AllAnswers.length > 0 ? Math.max(...a6AllAnswers) : undefined;

    //  negative/positive tolerances for A1, A2, A3, A4, A5
    const {
      a1: a1NegativeTolerance,
      a2: a2NegativeTolerance,
      a3: a3NegativeTolerance,
      a4: a4NegativeTolerance,
      a5: a5NegativeTolerance,
      a6: a6NegativeTolerance,
    } = getParsedPropertyFromAnswers(
      answers[0],
      PlumbingAnswerProperty.NEGATIVE_TOLLERANCE
    );

    const {
      a1: a1PositiveTolerance,
      a2: a2PositiveTolerance,
      a3: a3PositiveTolerance,
      a4: a4PositiveTolerance,
      a5: a5PositiveTolerance,
      a6: a6PositiveTolerance,
    } = getParsedPropertyFromAnswers(
      answers[0],
      PlumbingAnswerProperty.POSITIVE_TOLLERANCE
    );

    /**
     * Follow the wiki page for the formula from here
     * https://dev.azure.com/kone-cloud/kc-app-InstallationExecutionSystem/_wiki/wikis/kc-app-InstallationExecutionSystem.wiki/766/Plumbing-range-calculations
     */

    const a1MaxToNegativeX =
      minA1AllFloors - (a1ParsedReferenceValue - a1NegativeTolerance);

    const a2MaxToNegativeX =
      a2ParsedReferenceValue + a2PositiveTolerance - maxA2AllFloors;

    const a5MaxToNegativeX =
      minA5AllFloors !== undefined &&
      a5ParsedReferenceValue !== undefined &&
      a5NegativeTolerance !== undefined
        ? minA5AllFloors - (a5ParsedReferenceValue - a5NegativeTolerance)
        : undefined;

    const a6MaxToNegativeX =
      maxA6AllFloors !== undefined &&
      a6ParsedReferenceValue !== undefined &&
      a6PositiveTolerance !== undefined
        ? a6ParsedReferenceValue + a6PositiveTolerance - maxA6AllFloors
        : undefined;

    /**
     * This is the max negative(-ve) X threshold.
     */
    // filter undefined vaues
    const maxNegativeXValues = [
      a1MaxToNegativeX,
      a2MaxToNegativeX,
      a5MaxToNegativeX,
      a6MaxToNegativeX,
    ];
    const filteredMaxNegativeXValues = maxNegativeXValues.filter(
      (value) => value !== undefined
    ) as number[];
    const xMaxToNegativeX = Math.min(...filteredMaxNegativeXValues) * -1; // multiplying negative sign to showcase the direction;

    const a1MaxToPositiveX =
      a1ParsedReferenceValue + a1PositiveTolerance - maxA1AllFloors;
    const a2MaxToPositiveX =
      minA2AllFloors - (a2ParsedReferenceValue - a2NegativeTolerance);

    const a5MaxToPositiveX =
      maxA5AllFloors !== undefined &&
      a5ParsedReferenceValue !== undefined &&
      a5PositiveTolerance !== undefined
        ? a5ParsedReferenceValue + a5PositiveTolerance - maxA5AllFloors
        : undefined;

    const a6MaxToPositiveX =
      minA6AllFloors !== undefined &&
      a6ParsedReferenceValue !== undefined &&
      a6NegativeTolerance !== undefined
        ? minA6AllFloors - (a6ParsedReferenceValue - a6NegativeTolerance)
        : undefined;
    /**
     * This is the max positive(+ve) X threshold.
     */
    const maxPositiveXValues = [
      a1MaxToPositiveX,
      a2MaxToPositiveX,
      a5MaxToPositiveX,
      a6MaxToPositiveX,
    ];
    const filteredMaxPositiveXValues = maxPositiveXValues.filter(
      (value) => value !== undefined
    ) as number[];
    const xMaxToPositiveX = Math.min(...filteredMaxPositiveXValues);

    /* const tMaxToNegativeY = Math.min(a3NegativeTolerance, a4NegativeTolerance); */
    const a3MaxToNegativeY =
      minA3AllFloors - (a3ParsedReferenceValue - a3NegativeTolerance);
    const a4MaxToNegativeY =
      minA4AllFloors - (a4ParsedReferenceValue - a4NegativeTolerance);

    /**
     * This is the max negative(-ve) Y threshold.
     */
    const yMaxToNegativeY = Math.min(a3MaxToNegativeY, a4MaxToNegativeY) * -1;

    const a3MaxToPositiveY =
      a3ParsedReferenceValue + a3PositiveTolerance - maxA3AllFloors;
    const a4MaxToPositiveY =
      a4ParsedReferenceValue + a4PositiveTolerance - maxA4AllFloors;

    const yMaxToPositiveY = Math.min(a3MaxToPositiveY, a4MaxToPositiveY);

    return {
      xMaxToNegativeX,
      xMaxToPositiveX,
      yMaxToNegativeY,
      yMaxToPositiveY,
    };
  } catch (e) {
    console.error('Error while calculating threshold values', e);
    return {
      error: true,
    };
  }
};

export const getQuestionListWithUpdatedActualValue = (
  nominalValues: NominalValues,
  plumbingQuestions: PlumbingNominalQuestion[][]
): PlumbingNominalQuestion[] => {
  const {
    A1ActualValue,
    A2ActualValue,
    A3ActualValue,
    A4ActualValue,
    A5ActualValue,
    A6ActualValue,
  } = nominalValues;
  const questionListWithUpdatedActualValue: PlumbingNominalQuestion[] = [];
  plumbingQuestions.forEach((floors) => {
    floors.forEach((question, index) => {
      const is6Measurements = floors.length === 6;
      const { additionalInfo } = question;
      // TODO - REMOVE THIS IF BELOW BLOCK WHEN ALL DATA IN PRODUCTION SUPPORTS 'additionalInfo' field
      if (is6Measurements) {
        if (A1ActualValue && index === 0) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A1ActualValue,
          });
        } else if (A2ActualValue && index === 1) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A2ActualValue,
          });
        } else if (A3ActualValue && index === 2) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A3ActualValue,
          });
        } else if (A4ActualValue && index === 3) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A4ActualValue,
          });
        } else if (A5ActualValue && index === 4) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A5ActualValue,
          });
        } else if (A6ActualValue && index === 5) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A6ActualValue,
          });
        }
      } else {
        /** Old Implementation ends here. */
        if (A1ActualValue && checkAdditionalInfoMatch('a1', additionalInfo)) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A1ActualValue,
          });
        } else if (A2ActualValue && checkAdditionalInfoMatch('a2', additionalInfo)) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A2ActualValue,
          });
        } else if (A3ActualValue && checkAdditionalInfoMatch('a3', additionalInfo)) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A3ActualValue,
          });
        } else if (A4ActualValue && checkAdditionalInfoMatch('a4', additionalInfo)) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A4ActualValue,
          });
        } else if (A5ActualValue && checkAdditionalInfoMatch('a5', additionalInfo)) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A5ActualValue,
          });
        } else if (A6ActualValue && checkAdditionalInfoMatch('a6', additionalInfo)) {
          questionListWithUpdatedActualValue.push({
            ...question,
            actualValue: A6ActualValue,
          });
        }
      }
    });
  });
  return questionListWithUpdatedActualValue;
};

export const getUpdatedAnswersOnChangeXYValues = (
  answers: PlumbingSummaryAnswer[][],
  newAdjustment: AdjustmentsState
): PlumbingSummaryAnswer[][] => {
  try {
    const { key: adjustmentKey, actionType } = newAdjustment;

    return answers.map((floor) =>
      floor.map((answer, key) => {
        if (!answer.value) return answer;

        const stateAnswerValue = (answer.value as number) || 0;
        const measurementsPerFloor = floor.length;

        let newValue: number = stateAnswerValue;
        const isA1OrA5 = ifAnswerIsAmongThesePairOfMeasurements(
          AnswerCombination.A1_OR_A5,
          answer,
          measurementsPerFloor,
          key
        );
        const isA2OrA6 = ifAnswerIsAmongThesePairOfMeasurements(
          AnswerCombination.A2_OR_A6,
          answer,
          measurementsPerFloor,
          key
        );
        const isA3OrA4 = ifAnswerIsAmongThesePairOfMeasurements(
          AnswerCombination.A3_OR_A4,
          answer,
          measurementsPerFloor,
          key
        );

        switch (adjustmentKey) {
          case AdjustmentCoordinates.X:
            if (isA1OrA5) {
              switch (actionType) {
                case PlumbingAdjustmentAction.INCREMENT:
                  newValue = stateAnswerValue + 1;
                  break;
                case PlumbingAdjustmentAction.DECREMENT:
                  newValue = stateAnswerValue - 1;
                  break;
              }
            }
            if (isA2OrA6) {
              switch (actionType) {
                case PlumbingAdjustmentAction.INCREMENT:
                  newValue = stateAnswerValue - 1;
                  break;
                case PlumbingAdjustmentAction.DECREMENT:
                  newValue = stateAnswerValue + 1;
                  break;
              }
            }
            break;
          case AdjustmentCoordinates.Y:
            if (isA3OrA4) {
              switch (actionType) {
                case PlumbingAdjustmentAction.INCREMENT:
                  newValue = stateAnswerValue + 1;
                  break;
                case PlumbingAdjustmentAction.DECREMENT:
                  newValue = stateAnswerValue - 1;
                  break;
              }
            }
            break;
        }
        return {
          ...answer,
          value: newValue,
        };
      })
    ) as PlumbingSummaryAnswer[][];
  } catch (e) {
    console.error('Error while updating values in summary table', e);
  }
  return answers;
};

export const extractChangedAnswersInPlumbingSummary = (
  answersFromProps: PlumbingSummaryAnswer[][],
  answersFromState: PlumbingSummaryAnswer[][]
): PlumbingSummaryAnswer[] => {
  try {
    let changedAnswers: PlumbingSummaryAnswer[] = [];
    answersFromState.forEach((floors, position) => {
      const filteredAns = floors.filter(
        (answerProps, key) => answersFromProps[position][key].value !== answerProps.value
      );
      changedAnswers = [...changedAnswers, ...filteredAns];
    });
    return changedAnswers;
  } catch (e) {
    console.error('Error while finding difference in answers ', e);
  }
  return [];
};

export const cannotMakeAdjustments = (
  negativeReading?: number,
  positiveReading?: number
): boolean => {
  return Boolean(
    (negativeReading || negativeReading === 0) &&
      (positiveReading || positiveReading === 0) &&
      negativeReading > positiveReading
  );
};
