/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import App from './containers/App';
import ServiceWorkerRegister from './containers/ServiceWorkerRegister';

// Select the root element
const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <App />
      <ServiceWorkerRegister />
    </BrowserRouter>
  );
}
