import React, { JSX, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { Handover } from '../../schemas';
import HandoverSummaryPreview from '../HandoverSummaryPreview';

type InstallationStartingTesterProps = {
  handover: Handover | null;
  setAllowStart: (value: boolean) => void;
};

const StyledHandoverSummaryPreview = styled(HandoverSummaryPreview)(() => ({
  width: '100%',
}));

const InstallationStartingTester = (
  props: InstallationStartingTesterProps
): JSX.Element => {
  const { handover, setAllowStart } = props;

  useEffect(() => {
    setAllowStart(true);
  }, []);

  return (
    <Box data-testid="installation-starting-tester">
      <StyledHandoverSummaryPreview handover={handover} />
    </Box>
  );
};

export default InstallationStartingTester;
