import React, { JSX, useState } from 'react';
import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { InfoModal, theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import UploadWidget from '../UploadWidget';
import { Attachment } from '../../schemas';
import { SendHandoverParams } from '../../containers/Handover';
import { useCheckConnection } from '../../hooks/useCheckConnection';

export type NebSupervisorCompletionProps = {
  commentText: string;
  selectedFiles: File[];
  isAccepted: boolean;
  deleteAttachmentsLocally: (file: File | Attachment) => void;
  handleUploadButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendHandover: (params: SendHandoverParams) => Promise<void>;
  isReadOnly?: boolean;
  setCommentText: (comment: string) => void;
};

const NebSupervisorCompletion = (props: NebSupervisorCompletionProps): JSX.Element => {
  const {
    commentText,
    deleteAttachmentsLocally,
    handleUploadButton,
    isAccepted,
    selectedFiles,
    sendHandover,
    isReadOnly,
    setCommentText,
  } = props;

  const ConfirmCompleteButtonContainer = styled(Box)(() => ({
    alignSelf: 'center',
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    marginTop: '1.7rem',
    padding: '2px',
    width: 'fit-content',
  }));

  const StyledTextField = styled(TextField)(() => ({
    width: '100%',
    marginBottom: '0.5rem',
    '& > div': { padding: 0 },
    '& textarea': { padding: '0.7rem' },
  }));

  const CheckCircleRoundedIcon = styled(CheckCircleRounded)(() => ({
    height: '1.5rem',
    marginRight: '0.3rem',
    width: '1.5rem',
  }));

  const CancelRoundedIcon = styled(CancelRounded)(() => ({
    height: '1.5rem',
    marginRight: '0.3rem',
    width: '1.5rem',
  }));

  const StyledStatus = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    marginBottom: '2.6rem',
    textTransform: 'uppercase',
  }));

  const InspectionStatus = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  }));

  const { t } = useTranslation();
  const [offlineHandover, setOfflineHandover] = useState<boolean>(false);
  const [isOnline] = useCheckConnection();
  const handleSendHandover = () => {
    if (isOnline) {
      const params: SendHandoverParams = {
        comment: commentText,
        files: selectedFiles,
      };
      sendHandover(params);
    } else {
      setOfflineHandover(true);
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.25rem',
      }}
      component="section"
      data-testid="neb-supervisor-complete"
    >
      <Typography
        component="h1"
        variant="h6"
        align="center"
        sx={{ fontWeight: 700, marginBottom: '2.25rem' }}
      >
        {t('nebSupervisorCompletion.title')}
      </Typography>
      <InspectionStatus>
        <Typography variant="body2" width="7.7rem">
          {t('nebSupervisorCompletion.statusLabel')}
        </Typography>
        {isAccepted ? (
          <StyledStatus>
            <CheckCircleRoundedIcon htmlColor={theme.palette.success.main} />
            <Typography variant="body2">
              {t('nebSupervisorCompletion.statusAccepted')}
            </Typography>
          </StyledStatus>
        ) : (
          <StyledStatus>
            <CancelRoundedIcon htmlColor={theme.palette.error.main} />
            <Typography variant="body2">
              {t('nebSupervisorCompletion.statusRejected')}
            </Typography>
          </StyledStatus>
        )}
      </InspectionStatus>
      <Box width="17.4rem">
        <Typography variant="body2" marginBottom="0.5rem">
          {t('nebSupervisorCompletion.commentLabel')}
        </Typography>
        <StyledTextField
          variant="outlined"
          placeholder={t('nebSupervisorCompletion.commentPlaceholder')}
          id="standard-multiline-static"
          value={commentText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCommentText(event.target.value);
          }}
          multiline
          rows={3}
          inputProps={{
            'data-testid': 'comment-input',
          }}
        />
      </Box>
      <UploadWidget
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        handleUploadButton={handleUploadButton}
        selectedFiles={selectedFiles}
      />
      <ConfirmCompleteButtonContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendHandover}
          data-testid="confirm-complete-button"
          disableElevation
          disabled={isReadOnly}
        >
          {t('nebSupervisorCompletion.confirmComplete')}
        </Button>
      </ConfirmCompleteButtonContainer>
      {offlineHandover && (
        <InfoModal
          open={true}
          message={t('connection.offlineHandover')}
          onClose={(): void => setOfflineHandover(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </Box>
  );
};

export default NebSupervisorCompletion;
