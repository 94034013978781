import {
  enUS,
  itIT,
  plPL,
  roRO,
  svSE,
  Localization,
  frFR,
  esES,
  ptPT,
  daDK,
} from '@mui/material/locale';
import { createTheme, Theme } from '@mui/material';

export interface LocaleProps {
  name: string;
  locale: Localization;
  tag: string;
  short: string;
  fallback: boolean;
}

export const locales: LocaleProps[] = [
  {
    name: 'English',
    locale: enUS,
    tag: 'en-US',
    short: 'en',
    fallback: true,
  },
  {
    name: 'English (UK)',
    locale: enUS,
    tag: 'en-GB',
    short: 'en',
    fallback: false,
  },
  {
    name: 'Italian',
    locale: itIT,
    tag: 'it-IT',
    short: 'it',
    fallback: false,
  },
  {
    name: 'Polish',
    locale: plPL,
    tag: 'pl-PL',
    short: 'pl',
    fallback: false,
  },
  {
    name: 'Romanian',
    locale: roRO,
    tag: 'ro-RO',
    short: 'ro',
    fallback: false,
  },
  {
    name: 'Swedish',
    locale: svSE,
    tag: 'sv-SE',
    short: 'sv',
    fallback: false,
  },
  {
    name: 'French',
    locale: frFR,
    tag: 'fr-FR',
    short: 'fr',
    fallback: false,
  },
  {
    name: 'Spanish',
    locale: esES,
    tag: 'es-ES',
    short: 'es',
    fallback: false,
  },
  {
    name: 'Portuguese',
    locale: ptPT,
    tag: 'pt-PT',
    short: 'pt',
    fallback: false,
  },
  {
    name: 'Danish',
    locale: daDK,
    tag: 'da-DK',
    short: 'da',
    fallback: false,
  },
  /*{
    name: 'Chinese',
    locale: zhCN,
    tag: 'zh-CN',
    short: 'zh',
    fallback: false,
  },
  {
    name: 'Finnish',
    locale: fiFI,
    tag: 'fi-FI',
    short: 'fi',
    fallback: false,
  },
*/
];

export const getFallbackLocale = (): LocaleProps =>
  locales.find((locale) => locale.fallback) || locales[0];

export const getLocale = (name: string = navigator.language): LocaleProps =>
  locales.find((locale) => locale.short === name) || getFallbackLocale();

export const getLocalizedTheme = (
  theme: Theme,
  name: string = navigator.language
): Theme => {
  const data = !name ? getFallbackLocale() : getLocale(name);

  return createTheme(theme, data.locale);
};
