import React, { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { kebabCase } from 'lodash';

import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import { theme } from '@konecorp/ui-library';

import { WorkerData } from '../DeviationsList';
import AlertBox from '../AlertBox';
import { SpecialUserIds } from '../../schemas';

export type DeviationsListNotificationProps = {
  workers: WorkerData[];
  onSend: (employeeId: string) => void;
  onCancel: () => void;
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledAlertBox = styled(AlertBox)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const DeviationsListNotification = (
  props: DeviationsListNotificationProps
): JSX.Element => {
  const { t } = useTranslation();

  const { workers, onSend, onCancel } = props;

  const [employeeId, setEmployeeId] = useState<string>('');
  const [warning, setWarning] = useState<string>('');

  const isUpdateDisabled = !employeeId;

  useEffect(() => {
    setWarning(
      employeeId === SpecialUserIds.BUILDER
        ? 'deviationsListNotification.builderWarning'
        : ''
    );
  }, [employeeId]);

  return (
    <>
      <Box mb={2}>
        <Typography align="center">{t('deviationsListNotification.info')}</Typography>
      </Box>
      {warning && (
        <StyledAlertBox>
          <Typography align="center">{t(warning)}</Typography>
        </StyledAlertBox>
      )}
      <FormGroup>
        <StyledTextField
          name="assignee"
          label={t('deviationsListNotification.assignee')}
          variant="outlined"
          value={employeeId}
          onChange={(event) => setEmployeeId(event.target.value)}
          inputProps={{ 'data-testid': 'assignee-input' }}
          fullWidth
          select
        >
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </StyledTextField>
      </FormGroup>
      <Box display="flex" flexDirection="row" pt={1} pb={1}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          sx={{ marginRight: theme.spacing(1) }}
          disabled={isUpdateDisabled}
          onClick={() => onSend(employeeId)}
          fullWidth
        >
          {t('deviationsListNotification.send')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          onClick={onCancel}
          fullWidth
        >
          {t('deviationsListNotification.cancel')}
        </Button>
      </Box>
    </>
  );
};

export default DeviationsListNotification;
