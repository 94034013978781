import React, { JSX, useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  ActivityDifferentiator,
  Installation,
  InstallationStatus,
  isAssignment,
  Worker,
} from '../../schemas';
import InstallationProgress from '../../components/InstallationProgress';
import TeamList from '../../components/TeamList';
import HandoverStatusWithSummary from '../../components/HandoverStatusWithSummary';
import { InstallationContext } from '../../context';
import InstallationActions from '../../components/InstallationActions';

export type ProgressViewProps = {
  installation: Installation | null;
  networkNumber: string;
};

export const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '1rem',
  // fix the jumping issue while toggling
  '& .MuiIconButton-edgeEnd': {
    marginRight: 0,
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIcon': {
    color: theme.palette.common.white,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#005A93',
  color: theme.palette.common.white,
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  display: 'flex',
}));

const getInstallationDates = (installation: Installation | null) => {
  const byRole = (role: ActivityDifferentiator) => (worker: Worker) =>
    worker.activityDifferentiator === role;
  const toStartDate = (worker: Worker) =>
    isAssignment(worker) ? worker.assignmentStartDate : worker.plannedStartDate;
  const toEndDate = (worker: Worker) =>
    isAssignment(worker) ? worker.assignmentEndDate : worker.plannedEndDate;
  const getEarliest = (acc: string, date: string): string =>
    new Date(acc).getTime() < new Date(date).getTime() ? acc : date;
  const getLatest = (acc?: string, date?: string): string | undefined => {
    if (acc && date)
      return new Date(acc).getTime() > new Date(date).getTime() ? acc : date;
    else return acc;
  };

  const installers = [
    ...(installation?.assignees || []),
    ...(installation?.subcontractors || []),
  ].filter(byRole(ActivityDifferentiator.INST));

  if (installers.length === 0) return [undefined, undefined];

  const startDate = installers.map(toStartDate).reduce(getEarliest);
  const endDate = installers.map(toEndDate).reduce(getLatest);

  return [startDate, endDate];
};

const ProgressView = ({
  installation,
  networkNumber,
}: ProgressViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { deviations } = useContext(InstallationContext);

  const [plannedStartDate, plannedEndDate] = getInstallationDates(installation);

  const isForInstallationInspection =
    installation?.status === InstallationStatus.FOR_INSTALLATION_INSPECTION;

  return (
    <StyledContainer>
      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls="team-panel-content"
          >
            <Typography>{t('progressView.installationTeam')}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails aria-label="team-panel-content">
            {installation && (
              <TeamList installation={installation} networkNumber={networkNumber} />
            )}
          </StyledAccordionDetails>
        </Accordion>
      </Box>

      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls="progress-panel-content"
          >
            <Typography>
              {t('progressView.installationActions')}
              {isForInstallationInspection && ' (1)'}
            </Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {isForInstallationInspection && (
              <InstallationActions
                networkNumber={networkNumber}
                deviations={deviations}
              />
            )}
          </StyledAccordionDetails>
        </Accordion>
      </Box>

      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls="progress-panel-content"
          >
            <Typography>{t('progressView.installationProgress')}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails aria-label="progress-panel-content">
            <InstallationProgress
              installation={installation}
              networkNumber={networkNumber}
            />
          </StyledAccordionDetails>
        </Accordion>
      </Box>

      <Box pb={0.5}>
        <Accordion square={true} TransitionProps={{ unmountOnExit: true }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls="summary-panel-content"
          >
            <Typography>{t('progressView.installationSummary')}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails aria-label="summary-panel-content">
            <HandoverStatusWithSummary
              actualStartDate={installation?.actualDates?.actualInstallerStartDate}
              actualTargetDate={installation?.actualDates?.actualInstallerHandoverDate}
              plannedStartDate={plannedStartDate}
              plannedTargetDate={plannedEndDate}
              deviations={deviations}
            />
          </StyledAccordionDetails>
        </Accordion>
      </Box>
    </StyledContainer>
  );
};

export default ProgressView;
