import React, { JSX } from 'react';
import { Typography, Box } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

export type CustomizedLocalizedQuestionTextProps = {
  localaizedQuestionText: string;
  className?: string;
  typographyVariant?: Variant;
  hasAnswer?: boolean; // Make hasAnswer optional
};

/*
  This is a way to allow form builder to have question with bullet point, example of question from backend:

  Is all the signalisation working correctly & as per the building designated markings?
 <li>Car and landing pushes</li> <li>Voice annunciator</li> <li>Indicators</li>
*/
const CustomizedLocalizedQuestionText = (
  props: CustomizedLocalizedQuestionTextProps
): JSX.Element => {
  const { localaizedQuestionText, className, typographyVariant, hasAnswer } = props;

  const textColor = hasAnswer ? '#707070' : 'inherit'; // Change text color if hasAnswer is true

  if (localaizedQuestionText.includes('<li>')) {
    const regex = /<li>(.*?)<\/li>/g;
    const listItems = localaizedQuestionText.match(regex) || [];

    return (
      <Box className={className}>
        <Typography variant={typographyVariant ?? 'body1'} style={{ color: textColor }}>
          {localaizedQuestionText.replace(regex, '')}
        </Typography>
        <ul>
          {listItems.map((item, index) => (
            <li key={index}>
              <Typography
                variant={typographyVariant ?? 'body1'}
                style={{ color: textColor }}
              >
                {item.replace(/<\/?li>/g, '')}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    );
  } else {
    return (
      <Typography
        className={className}
        variant={typographyVariant ?? 'body1'}
        style={{ color: textColor }}
      >
        {localaizedQuestionText}
      </Typography>
    );
  }
};

export default CustomizedLocalizedQuestionText;
