import React, { JSX } from 'react';

import { Help } from '@mui/icons-material';

import {
  DeviationBlocker,
  DeviationClosed,
  DeviationCompliance,
  DeviationMinor,
  DeviationQrAccepted,
  DeviationQrApprovedWithActions,
  DeviationQrRejected,
} from '@konecorp/ui-library';

import { Deviation, DeviationStatus, DeviationVariation } from '../../schemas';

const DeviationIcon = (props: { deviation: Deviation }): JSX.Element => {
  const { deviation } = props;

  if (
    deviation.variation === DeviationVariation.DEVIATION ||
    deviation.variation === DeviationVariation.SNAG
  ) {
    if (deviation.status === DeviationStatus.OPEN) {
      if (deviation.compliance) {
        return <DeviationCompliance />;
      } else if (deviation.blocker) {
        return <DeviationBlocker />;
      } else {
        return <DeviationMinor />;
      }
    } else {
      return <DeviationClosed />;
    }
  }

  if (deviation.variation === DeviationVariation.REJECT) {
    if (deviation.status === DeviationStatus.OPEN) {
      return <DeviationQrRejected />;
    }
  }

  if (deviation.variation === DeviationVariation.ACCEPT_WITH_ACTIONS) {
    if (deviation.status === DeviationStatus.OPEN) {
      return <DeviationQrApprovedWithActions />;
    }
  }

  if (
    deviation.status === DeviationStatus.CLOSED ||
    deviation.status === DeviationStatus.CANCELLED
  ) {
    return <DeviationQrAccepted />;
  }

  return <Help />;
};

export default DeviationIcon;
