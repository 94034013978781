import i18next from 'i18next';

export enum DescriptionType {
  NETWORK_DESCRIPTION = 'networkDescription',
  SALES_ORDER_DESCRIPTION = 'salesOrderDescription',
}

export enum ApprovalSettings {
  NONE = 'none',
  SUBCONTRACTOR = 'subcontractor',
  SUBCONTRACTOR_AND_KONE_EMPLOYEE = 'subcontractorAndKoneEmployee',
}

export type HandoverSettings = {
  attachmentQuestionTranslationKey: string;
  totalMandatoryAttachments: number;
  requiresApproval: ApprovalSettings;
};

export type FrontlineSettings = {
  network?: {
    description: DescriptionType;
  };
  handover?: {
    [status in InstallationStatus]?: HandoverSettings;
  };
  vendors?: { countryCodes: string[] };
};

export type UserSettings = {
  userLanguage?: string;
  delegate?: string;
};

export type VersionInfo = {
  version: string;
  releaseDate: string;
  newFeatures: {
    title: string;
    description: string[];
  }[];
  instructions: string[];
};

export enum ActivityDifferentiator {
  INST = 'INST', // Installer
  CMSN = 'CMSN', // Tester
  SPV = 'SPV', // NEB Supervisor
  SEBSPV = 'SEBSPV', // SEB Supervisor
  SEEN = 'SEEN', // NEB Service Engineer
  OTHER = 'OTHER',
}

export enum NetworkTags {
  MOD = 'MOD',
  NBS = 'NBS',
}

export enum SpecialUserIds {
  SUBCONTRACTOR_INSTALLER = '00000000',
  SUBCONTRACTOR_TESTER = '00000002',
  BUILDER = '00000001',
}

export type DeviationStatus = 'OPEN' | 'CLOSED' | 'CANCELLED';

// Helper to express type values like an enum, e.g.: DeviationStatus.OPEN
export const DeviationStatus = {
  OPEN: 'OPEN' as const,
  CLOSED: 'CLOSED' as const,
  CANCELLED: 'CANCELLED' as const,
};

export enum DeviationVariation {
  DEVIATION = 'deviation',
  SNAG = 'snag',
  NOTE = 'note',
  REJECT = 'reject',
  ACCEPT_WITH_ACTIONS = 'acceptWithActions',
}

export enum FormId {
  QDPLUS = 'qdplus',
  NEBSEB = 'nebseb',
  SEBLOC = 'sebloc',
}

export enum InstallationStatus {
  // These should be in the order of workflow
  // q.v. https://dev.azure.com/kone-cloud/kc-app-InstallationExecutionSystem/_wiki/wikis/kc-app-InstallationExecutionSystem.wiki/707/Installation-workflow
  TO_BE_STARTED = 'toBeStarted',
  FOR_INSTALLER_ACCEPTANCE = 'forInstallerAcceptance',
  INSTALLER_ACCEPTED = 'installerAccepted',
  INSTALLER_REJECTED = 'installerRejected',
  FOR_INSTALLATION_INSPECTION = 'forInstallationInspection',
  FOR_TESTER_ACCEPTANCE = 'forTesterAcceptance',
  TESTER_ACCEPTED = 'testerAccepted',
  TESTER_REJECTED = 'testerRejected',
  FOR_FINAL_INSPECTION = 'forFinalInspection',
  FOR_SEB_ACCEPTANCE = 'forSebAcceptance',
  SEB_ACCEPTED = 'sebAccepted',
  SEB_REJECTED = 'sebRejected',
  INSTALLATION_COMPLETE = 'installationComplete',
  INSTALLATION_ARCHIVED = 'installationArchived',
}

export enum NoAnswerType {
  REJECTION = 'REJECTION',
  ACTION = 'ACTION',
}

export enum QuestionType {
  ROUTE = 'route',
  DUMMY = 'dummy',
  QUALITY_CHECK = 'quality_check',
}

export enum SupervisorChecklistQuestionType {
  PRE_CHECK = 'pre_check',
  DUMMY = 'dummy',
}

export enum QuestionValueType {
  NUMBER = 'number',
  YES_NO = 'yes_no',
  YES_NO_CHOICE = 'yes_no_choice',
  OK = 'ok',
  OK_NOTOK = 'ok_notok',
  OK_NOTOK_NA = 'ok_notok_na',
  WARNING_OK_NOTOK_NA = 'warning_ok_notok_na',
  STRING = 'string',
  PICTURE = 'picture',
  DEFAULT = 'default',
}

export interface ExtendedInstallation extends Partial<Installation> {
  firstAssignmentDate: string;
  hasQualityForm: boolean | null;
  latestAnsweredQdQuestionSet: string;
}

export type Answer = {
  answerer: string;
  gsi1: string;
  issue?: { type: NoAnswerType; text?: string; attachments?: Attachment[] };
  modifiedAt: string;
  position: string | null;
  value: AnswerValue;
  questionSetId?: string;
  questionSequenceNumber: number;
};

export type AnswerSet = { questionSetId: string; answers: (Answer | null)[] };

export type AnswerValue =
  | string
  | number
  | boolean
  | null
  | Attachment[]
  | Attachment
  | File[]
  | File;

export enum RoleSource {
  IRMA = 'IRMA',
  SELF = 'SELF',
  SUPERVISOR = 'SUPERVISOR',
}

export type Assignment = {
  createdBy?: string;
  modifiedAt: string;
  source: RoleSource;
  activityDifferentiator: string;
  koneResourcePersonalNumber: string;
  assignmentStartDate: string;
  assignmentEndDate?: string;
};

// Assignee type guard
export const isAssignment = (item: unknown | Assignment): item is Assignment =>
  !!item && (item as Assignment).koneResourcePersonalNumber !== undefined;

// Subcontractor type guard
export const isSubcontractor = (
  item: unknown | SubcontractorRecord
): item is SubcontractorRecord =>
  !!item && (item as SubcontractorRecord).subcontractor !== undefined;

export type Attachment = { filename: string; location: string };

export type CompactNetwork = {
  networkNumber: string;
  equipmentNumber: string;
  description: string;
  networkDescription: string;
  salesOrderDescription: string;
  milestone: string;
  supervisorNumber: string;
  isTacoDataQuest?: boolean;
  isModelData?: boolean;
  isVariationOrder?: boolean;
  supervisorName?: string;
  networkTag?: NetworkTag | string;
};

export enum NetworkTag {
  MOD = 'MOD',
  NBS = 'NBS',
}

export type DeviationFile = {
  filename: string;
  location: string;
};

export type DeviationHistoryChange = {
  key: string;
  oldValue: string | boolean | number | undefined;
  newValue: string | boolean | number | undefined;
};

export type DeviationHistory = {
  employeeId: string;
  userComment?: string;
  changes: DeviationHistoryChange[];
  modifiedAt: string;
  files?: DeviationFile[];
};

export type Deviation = {
  guid: string;
  networkNumber: string;
  assignee: string;
  blocker: boolean;
  compliance: boolean;
  createdAt: string; // timestamp
  createdBy: string;
  closedAt?: string; // timestamp
  closedBy?: string;
  delay: number;
  description: string;
  ken: string;
  modifiedAt: string; // timestamp
  lastViewedByAssigneeAt: string; // timestamp
  phase?: DeviationPhase;
  location?: DeviationLocation;
  questionSetId?: string;
  questionSequence?: number;
  installationWorkflowStatus: InstallationStatus;
  source: string; // DeviationSource
  variation: DeviationVariation;
  history?: DeviationHistory[]; //deviation created offline won't have history property
  status: DeviationStatus;
  type: DeviationType | undefined;
} & DeviationOfflineOnly;

type DeviationOfflineOnly = {
  userComment?: string;
  tempGuid?: string;
  files?: DeviationFile[]; //for uploading attachments, this will hang around before synchonization kicks in
};

export const isNewDeviation = (deviation: object): boolean => !('guid' in deviation);

export const isDeviationOpen = (deviation: Deviation): boolean =>
  deviation.status === DeviationStatus.OPEN;

export const isDeviationCompliance = (deviation: Deviation): boolean =>
  deviation.compliance;

export const isDeviationBlocker = (deviation: Deviation): boolean => deviation.blocker;

export enum PlumbingMeasurementTag {
  VALUES_WITH_A5 = 'tag_plumbing_nominal_w_a5',
  VALUES_WITH_A6 = 'tag_plumbing_nominal_w_a6',
  VALUES_WITH_4 = 'tag_plumbing_nominal_4pf',
  VALUES_WITH_6 = 'tag_plumbing_nominal_6pf',
}

/**
 * Plumbing Measurement type, DO NOT CHANGE THE ORDER and SEQUENCE OF THE TYPES
 */
export type PlumbingMeasurementA1 = 'A1';
export type PlumbingMeasurementA2 = 'A2';
export type PlumbingMeasurementA3 = 'A3';
export type PlumbingMeasurementA4 = 'A4';
export type PlumbingMeasurementA5 = 'A5';
export type PlumbingMeasurementA6 = 'A6';

export type MeasurementWith4Values = [
  PlumbingMeasurementA1,
  PlumbingMeasurementA2,
  PlumbingMeasurementA3,
  PlumbingMeasurementA4
];

export type MeasurementWithA5Value = [
  PlumbingMeasurementA1,
  PlumbingMeasurementA2,
  PlumbingMeasurementA3,
  PlumbingMeasurementA4,
  PlumbingMeasurementA5
];

export type MeasurementWithA6Value = [
  PlumbingMeasurementA1,
  PlumbingMeasurementA2,
  PlumbingMeasurementA3,
  PlumbingMeasurementA4,
  PlumbingMeasurementA6
];

export type MeasurementWith6Values = [
  PlumbingMeasurementA1,
  PlumbingMeasurementA2,
  PlumbingMeasurementA3,
  PlumbingMeasurementA4,
  PlumbingMeasurementA5,
  PlumbingMeasurementA6
];

export type PlumbingMeasurementCategory =
  | MeasurementWith4Values
  | MeasurementWithA5Value
  | MeasurementWithA6Value
  | MeasurementWith6Values;

/** ----Plumbing Measurement Types end here---- */

/* TODO: Rename DeviationPhase enum to Question Set enum and exclude
   the static phase "testing" and "neb_seb_handover"

   A deviation has 2 properties: phase and location
    * phase:
      - If user is an Installer, phase is determined by questionSetId, ex: TACO01001 - Plumbing
      - If user is a Tester, phase is a static string value: "Testing"
    * Location:
      - For every roles, the location is determined by questionSetId and mapping table
        ex: TACO000777 - TOP_OF_CAR_ELECTRICAL --> map location to CAR
 */
export enum DeviationPhase {
  START_OF_INSTALLATION = 'StartOfInstallation', // Start of installation
  PLUMBING = 'TACO01001', // plumbing
  PIT = 'TACO01007', // pit
  SHAFT_MECHANICS = 'TACO01002', // shaftMechanics
  LANDING_DOORS = 'TACO01003', // landingDoors
  ELECTRIFICATION_AND_SIGNALIZATION = 'TACO01004', // electrificationAndSignalization
  ROPING = 'TACO01005', // roping
  COMMISSIONING = 'TACO01006', // commissioning
  PIT_INSPECTION_MECHANICAL = 'TACO000771',
  AT_MAP_PANEL_MECHANICAL = 'TACO000772',
  TOP_OF_THE_SHAFT_MECHANICAL = 'TACO000773',
  INSIDE_THE_SHAFT_MACHANICAL = 'TACO000774',
  LANDING_MECHANICAL = 'TACO000775',
  AT_MAP_PANEL_ELECTRICAL = 'TACO000776',
  TOP_OF_CAR_ELECTRICAL = 'TACO000777',
  TOP_OF_THE_SHAFT_ELECTRICAL = 'TACO000778',
  INSIDE_THE_SHAFT_ELECTRICAL = 'TACO000779',
  INSIDE_THE_CAR_ELECTRICAL = 'TACO000780',

  TESTING = 'Testing', // testing
  NEB_SEB_HANDOVER = 'NebSebHandover',
  TO_BE_STARTED = 'To be started',
  INSTALLATION_PLAN = 'Installation Plan',
}

export enum DeviationType {
  MATERIAL = 'MATERIAL',
  SITE = 'SITE',
  TOOLS = 'TOOLS',
  PEOPLE = 'PEOPLE',
  KONE = 'KONE',
  BUILDER = 'BUILDER',
  DEFAULT = 'DEFAULT',
}

export enum DeviationLocation {
  PIT_INSPECTION = 'pitInspection',
  MAP_PANEL = 'mapPanel',
  TOP_OF_THE_SHAFT = 'topOfTheShaft',
  INSIDE_THE_SHAFT = 'insideTheShaft',
  LANDING = 'landing',
  TOP_OF_THE_CAR = 'topOfTheCar',
  INSIDE_THE_CAR = 'insideTheCar',
  NOT_SPECIFIED = 'notSpecified',
}

export const locationMapping = new Map([
  [DeviationPhase.PLUMBING, DeviationLocation.INSIDE_THE_SHAFT],
  [DeviationPhase.SHAFT_MECHANICS, DeviationLocation.INSIDE_THE_SHAFT],
  [DeviationPhase.PIT, DeviationLocation.PIT_INSPECTION],
  [DeviationPhase.LANDING_DOORS, DeviationLocation.LANDING],
  [DeviationPhase.ELECTRIFICATION_AND_SIGNALIZATION, DeviationLocation.MAP_PANEL],
  [DeviationPhase.COMMISSIONING, DeviationLocation.MAP_PANEL],
  [DeviationPhase.ROPING, DeviationLocation.INSIDE_THE_SHAFT],
  [DeviationPhase.PIT_INSPECTION_MECHANICAL, DeviationLocation.PIT_INSPECTION],
  [DeviationPhase.AT_MAP_PANEL_MECHANICAL, DeviationLocation.MAP_PANEL],
  [DeviationPhase.TOP_OF_THE_SHAFT_MECHANICAL, DeviationLocation.TOP_OF_THE_SHAFT],
  [DeviationPhase.INSIDE_THE_SHAFT_MACHANICAL, DeviationLocation.INSIDE_THE_SHAFT],
  [DeviationPhase.LANDING_MECHANICAL, DeviationLocation.LANDING],
  [DeviationPhase.AT_MAP_PANEL_ELECTRICAL, DeviationLocation.MAP_PANEL],
  [DeviationPhase.TOP_OF_CAR_ELECTRICAL, DeviationLocation.TOP_OF_THE_CAR],
  [DeviationPhase.TOP_OF_THE_SHAFT_ELECTRICAL, DeviationLocation.TOP_OF_THE_SHAFT],
  [DeviationPhase.INSIDE_THE_SHAFT_ELECTRICAL, DeviationLocation.INSIDE_THE_SHAFT],
  [DeviationPhase.INSIDE_THE_CAR_ELECTRICAL, DeviationLocation.INSIDE_THE_CAR],
  [undefined, undefined],
]);

type DeviationPhaseMapping = {
  id: DeviationPhase;
  label: string;
};

export const DEVIATION_PHASES: DeviationPhaseMapping[] = [
  {
    id: DeviationPhase.TO_BE_STARTED,
    label: i18next.t('deviationPhase.toBeStarted'),
  },
  {
    id: DeviationPhase.START_OF_INSTALLATION,
    label: i18next.t('deviationPhase.startOfInstallation'),
  },
  {
    id: DeviationPhase.PLUMBING,
    label: i18next.t('deviationPhase.plumbing'),
  },
  {
    id: DeviationPhase.SHAFT_MECHANICS,
    label: i18next.t('deviationPhase.shaftMechanics'),
  },
  {
    id: DeviationPhase.PIT,
    label: i18next.t('deviationPhase.pit'),
  },
  {
    id: DeviationPhase.LANDING_DOORS,
    label: i18next.t('deviationPhase.landingDoor'),
  },
  {
    id: DeviationPhase.ELECTRIFICATION_AND_SIGNALIZATION,
    label: i18next.t('deviationPhase.electrificationAndSignalization'),
  },
  {
    id: DeviationPhase.ROPING,
    label: i18next.t('deviationPhase.roping'),
  },
  {
    id: DeviationPhase.COMMISSIONING,
    label: i18next.t('deviationPhase.commissioning'),
  },
  {
    id: DeviationPhase.TESTING,
    label: i18next.t('deviationPhase.testing'),
  },
  {
    id: DeviationPhase.NEB_SEB_HANDOVER,
    label: i18next.t('deviationPhase.nebSebHandover'),
  },
  {
    id: DeviationPhase.INSTALLATION_PLAN,
    label: i18next.t('deviationPhase.installationPlan'),
  },
];

type DeviationStatusMapping = {
  id: DeviationStatus;
  label: string;
};

export const DEVIATION_STATUSES: DeviationStatusMapping[] = [
  {
    id: DeviationStatus.CANCELLED,
    label: i18next.t('deviationStatus.cancelled'),
  },
  {
    id: DeviationStatus.CLOSED,
    label: i18next.t('deviationStatus.closed'),
  },
  {
    id: DeviationStatus.OPEN,
    label: i18next.t('deviationStatus.open'),
  },
];

type DeviationTypeMapping = {
  id: DeviationType;
  label: string;
};

export const DEVIATION_TYPES: DeviationTypeMapping[] = [
  {
    id: DeviationType.MATERIAL,
    label: i18next.t('deviationType.material'),
  },
  {
    id: DeviationType.PEOPLE,
    label: i18next.t('deviationType.people'),
  },
  {
    id: DeviationType.SITE,
    label: i18next.t('deviationType.site'),
  },
  {
    id: DeviationType.TOOLS,
    label: i18next.t('deviationType.tools'),
  },
  {
    id: DeviationType.KONE,
    label: i18next.t('deviationType.kone'),
  },
  {
    id: DeviationType.BUILDER,
    label: i18next.t('deviationType.builder'),
  },
];

type DeviationLocationMapping = {
  id: DeviationLocation;
  label: string;
};

export const DEVIATION_LOCATIONS: DeviationLocationMapping[] = [
  {
    id: DeviationLocation.NOT_SPECIFIED,
    label: i18next.t('deviationLocation.notSpecified'),
  },
  {
    id: DeviationLocation.PIT_INSPECTION,
    label: i18next.t('deviationLocation.pitInspection'),
  },
  {
    id: DeviationLocation.MAP_PANEL,
    label: i18next.t('deviationLocation.mapPanel'),
  },
  {
    id: DeviationLocation.TOP_OF_THE_SHAFT,
    label: i18next.t('deviationLocation.topOfTheShaft'),
  },
  {
    id: DeviationLocation.INSIDE_THE_SHAFT,
    label: i18next.t('deviationLocation.insideTheShaft'),
  },
  {
    id: DeviationLocation.LANDING,
    label: i18next.t('deviationLocation.landing'),
  },
  {
    id: DeviationLocation.TOP_OF_THE_CAR,
    label: i18next.t('deviationLocation.topOfTheCar'),
  },
  {
    id: DeviationLocation.INSIDE_THE_CAR,
    label: i18next.t('deviationLocation.insideTheCar'),
  },
];

export type Employee = {
  legalFirstName?: string;
  legalLastName?: string;
  employeeId: string;
  title?: string;
  role?: ActivityDifferentiator;
  country: string;
  companyCode: string;
};

export type Handover = {
  comment: string;
  modifiedAt: string;
  creator: string;
  role: ActivityDifferentiator;
  attachments: Attachment[] | null;
};

export const isHandover = (item: unknown | Handover): item is Handover =>
  (item as Handover).creator !== undefined;

export type InstallationAttachment = { filename: string; id: string; location: string };

export type InstallationCardData = {
  network: CompactNetwork;
  installation: ExtendedInstallation;
  deviations: NetworkDeviationCount | null;
  /* This is needed in order to know which view to show when clicking on
                the card on to be started page. The reason is one 1 person could be
                both installer and supervisor for 1 network one 1 person could be
                both tester and supervisor for 1 network */
  viewInstallationAs?: ActivityDifferentiator;
};

export type InstallationCardDataList = {
  ongoingInstallations: InstallationCardData[];
  toBeStartedInstallations: InstallationCardData[];
  completedInstallations: InstallationCardData[];
};

export type LocalizedText = { code: string; text: string };

export type ActualDateKey =
  | 'actualInstallerStartDate'
  | 'actualInstallerHandoverDate'
  | 'actualTesterStartDate'
  | 'actualTesterHandoverDate'
  | 'actualSupervisorFinalInspectionStartDate'
  | 'actualSupervisorFinalInspectionHandoverDate'
  | 'actualSebSupervisorStartDate'
  | 'actualSebSupervisorHandoverDate'
  | 'actualSupervisorCompletionStartDate'
  | 'actualSupervisorCompletionEndDate';

export type ActualDates = { [key in ActualDateKey]?: string };

export const isInstallationData = (data: null | object): data is Installation =>
  !!data && 'installerQuestions' in data && 'testerQuestions' in data;

export enum Scenario {
  INSTALLER_TESTER_SUPERVISOR = 'installerTesterSupervisor',
  INSTALLER_SUPERVISOR = 'installerSupervisor',
}

export interface ScoreData {
  isScoreForm?: boolean;
}

export interface Installation {
  actualDates: ActualDates;
  assignees: Assignment[];
  customer: NetworkPartner | null;
  inspectionDate?: string | null;
  networkNumber: string; // use only when searching by equipment number
  equipmentNumber: string | null;
  installerAnswers: AnswerSet[];
  installerHours: string | null;
  installerQuestionSetSequence: string[];
  installerQuestions: QuestionSet[];
  nebSupervisorQuestionSetSequence: string[] | null;
  nebSupervisorQuestions: QuestionSet[];
  milestone: string;
  questionSetInfo: QuestionSetInfo[] | null;
  sebSupervisorQuestionSetSequence: string[];
  sebSupervisorQuestions: QuestionSet[];
  status: InstallationStatus | null;
  supervisorNumber: string | null;
  delegatedSupervisorNumber?: string;
  description: string | null;
  networkDescription: string | null;
  salesOrderDescription: string | null;
  testerAnswers: AnswerSet[];
  testerHours: string | null;
  testerQuestionSetSequence: string[];
  testerQuestions: QuestionSet[];
  totalInstallationHours: string | null;
  plumbingAdjustment?: PlumbingAdjustmentType;
  subcontractors: SubcontractorRecord[];
  settings: FrontlineSettings;
  statusHistory?: StatusHistory;
  scenario: Scenario;
  companyCode: string;
  isModelData?: boolean;
  isTacoDataQuest?: boolean;
  isVariationOrder?: boolean;
  preInstallCheckListAnswers?: AnswerSet[];
  installationPlanAnswers?: AnswerSet[];
  testerNebReferenceId?: string | null;
  networkTag?: string | null;
  installationPlanQuestions?: QuestionSet[];
  forms: InstallationFrm[];
  mergedQuestionSequences?: MergedQuestionSequences;
  mergedQuestions?: MergedQuestions;
  mergedAnswers?: MergedAnswers;
  mergedQuestionSetInfo?: QuestionSetInfo[];
}

export type InstallationFrm = DbInstallationFrm & {
  installerQuestions: QuestionSet[];
  installerAnswers: AnswerSet[];
  testerQuestions: QuestionSet[];
  testerAnswers: AnswerSet[];
  nebSupervisorQuestions: QuestionSet[];
  nebSupervisorAnswers: AnswerSet[];
  sebSupervisorQuestions: QuestionSet[];
  sebSupervisorAnswers: AnswerSet[];
  tasks: InstallationTask[];
};

export type InstallationTask = {
  text: LocalizedText[];
  setId: string;
  total: number;
  timeInMinutes: number;
  modifiedAt: string;
  entityType: InstallationEntityType;
};

export type DbInstallationFrm = {
  pk: string;
  sk: string;
  name: string;
  questionSetInfo: QuestionSetInfo[] | null;
  installerQuestionSetSequence: string[];
  testerQuestionSetSequence: string[];
  nebSupervisorQuestionSetSequence: string[];
  sebSupervisorQuestionSetSequence: string[];
  entityType: InstallationEntityType;
  referenceId?: string;
};

export enum InstallationEntityType {
  INSTALLATION = 'installation',
  NETWORK = 'network',
  QUESTION = 'question',
  ASSIGNEE = 'assignee',
  ANSWER = 'answer',
  ISSUE = 'issue',
  HANDOVER = 'handover_comment',
  RESOURCE = 'resource',
  CONTACT = 'contact',
  FORM = 'form',
  TASK = 'task',
}

export type StatusHistory = {
  [state in InstallationStatus]?: number;
};

export type PlumbingAdjustmentType = {
  x: number;
  y: number;
};
export type Network = {
  networkNumber: string;
  salesOrder?: string;
  currentMilestone?: string;
  applicant?: string;
  businessArea?: string;
  companyCode?: string;
  creationDate?: string;
  deletionFlag?: string;
  equipmentAmount?: number;
  equipmentNumber?: string;
  installationMaterial?: string;
  lastChangeDate?: string;
  networkDescription?: string;
  networkPlant?: string;
  partner?: NetworkPartner[];
  platformCode?: string;
  platformDesc?: string;
  poSubcontract?: string;
  projectType?: string;
  purchaseOrder?: string;
  purchaseRequisition?: string;
  rushCode?: string;
  salesMaterial?: string;
  salesOrderItem?: string;
  salesQuantity?: string;
  salesQuantityUnit?: string;
  site?: NetworkSite[];
  slSalesOrder?: string;
  splanScenario?: string;
  supervisorHRNumber?: string;
  supervisorName?: string;
  supervisorNumber?: string;
  TRBCategory?: string;
  TRBLisType?: string;
  wbsApplicantId?: string;
};

export type NetworkDeviationCount = {
  normal: number;
  blockers: number;
};

export type NetworkPartner = {
  customerNumber: string;
  customerName1?: string;
  customerName2?: string | null;
  city?: string;
  cityPostalCode?: string;
  countryKey?: string;
  houseNumber?: string;
  partnerFunction?: string;
  street?: string;
};

export type NetworkSite = {
  siteAbsoluteDescription: string;
  siteAbsoluteNumber: number;
  siteAbsoluteStatus: number;
};

export type Question = {
  actionIfDifferent: LocalizedText[];
  actualValue: string | null;
  additionalInfo: LocalizedText[];
  condition: string | null;
  imageLink: string | null;
  modelCondition?: string | null;
  modifiedAt?: string;
  negativeTolerance: string | null;
  position: string | null;
  reversedPosition: string | null;
  positiveTolerance: string | null;
  quantityRule: string;
  questionType: QuestionType | null;
  tag: string;
  text: LocalizedText[];
  translationKey?: string | null;
  valueRule?: string | null;
  valueType: QuestionValueType | null;
  startDate?: string | null;
  endDate?: string | null;
  deleted?: boolean;
};

export type SupervisorInstallerCheckListQuestions = {
  entityType: string | null;
  questionType: string;
  modifiedAt?: string;
  role: string[];
  userType: string[];
  questionId: string;
  questionStatus: string;
  createdAt?: string;
  text: LocalizedText[];
  valueType: QuestionValueType | null;
};

export interface PlumbingNominalQuestion extends Partial<Omit<Question, 'actualValue'>> {
  questionSetId: string;
  actualValue: number; // Omitting above as actualValue required as number here
  questionSequenceNumber: number;
}

export type QuestionSet = { questionSetId: string; questions: Question[] };

export type QuestionSetInfo = {
  setId: string;
  description: LocalizedText[];
  formId: FormId | null;
};

export type Syncpoint = {
  timeStamp: string;
  employeeId: string;
  employeeName: string;
  role: ActivityDifferentiator;
};

export type SyncAnswerPayload = Omit<Answer, 'answerer' | 'gsi1' | 'modifiedAt'> & {
  tag: string;
  timestamp: number;
};

export type InstallationSyncData = {
  answers?: SyncAnswerPayload[];
  questions?: PlumbingNominalQuestion[];
  plumbingAdjustment?: { x: number; y: number };
};

export type BulkAnswers = {
  answers: SyncAnswerPayload[];
  plumbingAdjustment: { x: number; y: number };
};

export type SubcontractorRecord = {
  createdAt: string;
  modifiedAt: string;
  expireAt: number;
  subcontractor: Subcontractor;
  access?: SubcontractorAccess;
  activityDifferentiator: Exclude<
    ActivityDifferentiator,
    | ActivityDifferentiator.SEBSPV
    | ActivityDifferentiator.SPV
    | ActivityDifferentiator.SEEN
  >;
  plannedStartDate: string;
  plannedEndDate: string;
};

export type Subcontractor = {
  vendorNumber: string;
  name: string;
  email: string;
  mobile: string;
};

type SubcontractorAccess = {
  failedLoginCounter: number;
  expireAt: number;
  failedTokenCounter: number;
};

export type Worker = Assignment | SubcontractorRecord;

export type Vendor = {
  vendorNumber: string;
  vendorCity: string;
  vendorName1: string;
  vendorName2: string;
  vendorStreet: string;
  vendorPostalCode: string;
};
export enum ContractType {
  CONTRACT = 'Contract',
  LINKS = 'links',
}
export enum ContactRole {
  BUILDER = 'builder',
  SEB_SPV = 'sebSpv',
  SUBCONTRACTOR_INSTALLER = 'subcontractorInstaller',
  SUBCONTRACTOR_TESTER = 'subcontractorTester',
  CUSTOMER = 'customer',
  CUSTOMER_MAINTENANCE_CONTACT = 'customerMaintenanceContact',
  CSE = 'cse',
}
export enum SiteInfoType {
  SITEACCESSINFO = 'site_access',
  BATHROOMSINFO = 'bathrooms',
  OTHERNOTE = 'other_notes',
}
export type Contract = {
  type: string;
  title: string;
  url: string;
  value?: string;
  contract: boolean;
  sk?: string;
};
export type SiteInfo = {
  type: string;
  name: SiteInfoType;
  note: string;
  attachments?: Attachment[];
  sk?: string;
};
export type Contact = {
  guid: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  role: ContactRole;
};

export enum ServiceWorkerEventMessageType {
  SYNC_WITH_BACKEND = 'synchronization-with-backend-to-be-done',
  NEW_DATA_TO_SYNC = 'synchronization-with-backend-is-pending',
  SYNCED_WITH_BACKEND = 'synchronization-with-backend-is-done',
  MANUAL_SYNC_DEVIATIONS = 'synchronization-deviations-with-backend-from-ui-thread',
}

export type ServiceWorkerEventMessagePayload = {
  messageType: ServiceWorkerEventMessageType;
  accessToken: string;
  networkNumber?: string;
};

export type PutAnswerPayload = Pick<Answer, 'value' | 'position' | 'issue'> & {
  tag: string;
  timestamp: number;
};

export type MergedQuestions = {
  installerQuestions: QuestionSet[];
  testerQuestions: QuestionSet[];
  sebSupervisorQuestions: QuestionSet[];
  nebSupervisorQuestions: QuestionSet[];
};

export type MergedAnswers = {
  installerAnswers: AnswerSet[];
  testerAnswers: AnswerSet[];
  sebSupervisorAnswers: AnswerSet[];
  nebSupervisorAnswers: AnswerSet[];
};

export type MergedQuestionSequences = {
  installerQuestionSetSequence: string[];
  testerQuestionSetSequence: string[];
  sebSupervisorQuestionSetSequence: string[];
  nebSupervisorQuestionSetSequence: string[];
};
