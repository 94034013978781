import React, { JSX, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, styled, Typography } from '@mui/material';
import Context, { InstallationContext } from '../../context';
import { Deviation, isDeviationOpen } from '../../schemas';
import { editDeviation } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { isSyncDeviationsInProgress } from '../../helpers/deviationOffline';
import { useGetToken } from '../../hooks/useGetToken';
import DeviationsList, { GroupType, SortType } from '../DeviationsList';
import DeviationForm, { EditDeviationPayload } from '../DeviationForm';

const Summary = styled(Box)(({ theme }) => ({
  lineHeight: theme.spacing(0.3),
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Headline = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const Instructions = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

const List = styled('ol')(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
}));

const HandoverSupervisorSummary = (): JSX.Element => {
  const { t } = useTranslation();
  const [getToken] = useGetToken();

  const { updateIsLoading, updateErrorMessage, networkNumber } = useContext(Context);
  const { deviations, dispatch } = useContext(InstallationContext);

  const [selectedDeviation, setSelectedDeviation] = useState<Deviation>();
  const [isDeviationFormOpen, setDeviationFormOpen] = useState<boolean>(false);

  const openDeviationFormDialog = (deviation: Deviation) => {
    setSelectedDeviation(deviation);
    setDeviationFormOpen(true);
  };

  const closeDeviationFormDialog = () => {
    setSelectedDeviation(undefined);
    setDeviationFormOpen(false);
  };

  const waitForDeviationsSync = async () => {
    while (await isSyncDeviationsInProgress(networkNumber)) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  const editDeviationAction = async (deviation: EditDeviationPayload) => {
    updateIsLoading(true);
    try {
      await waitForDeviationsSync();
      const accessToken = await getToken();
      const patchedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: patchedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('handoverSupervisorSummary.cannotUpdateDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
    }
  };

  const unsupportedAction = async () => await Promise.resolve();

  return (
    <>
      <Dialog open={isDeviationFormOpen} fullScreen>
        <DialogContent>
          <DeviationForm
            initialDeviation={selectedDeviation}
            onCreate={() => null}
            onEdit={editDeviationAction}
            onClear={closeDeviationFormDialog}
          />
        </DialogContent>
      </Dialog>
      <Summary>
        <Headline variant="h5">{t('handoverSupervisorSummary.title')}</Headline>
        <Instructions variant="body1">
          {t('handoverSupervisorSummary.instructions')}
        </Instructions>
        <List>
          <li>{t('handoverSupervisorSummary.step1')}</li>
          <li>{t('handoverSupervisorSummary.step2')}</li>
          <li>{t('handoverSupervisorSummary.step3')}</li>
          <li>{t('handoverSupervisorSummary.step4')}</li>
        </List>
      </Summary>
      {deviations.some(isDeviationOpen) && (
        <DeviationsList
          deviations={deviations}
          filters={{
            groupBy: GroupType.NONE,
            sortBy: SortType.PRIORITY,
            showFixed: false,
          }}
          showControls={false}
          deviationsLoaded={true}
          deviationOpenClick={openDeviationFormDialog}
          deviationCloseClick={unsupportedAction}
          deviationsEditAction={unsupportedAction}
          deviationNotification={unsupportedAction}
        />
      )}
    </>
  );
};

export default HandoverSupervisorSummary;
