import React, { JSX } from 'react';

import { ThemeProvider } from '@mui/material';

import { theme, WithAuthenticatorProps } from '@konecorp/ui-library';

import GlobalState from '../../context/globalState';
import { getLocalizedTheme } from '../../localization';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

import KoneEmployeeApp from '../KoneEmployeeApp';
import SubcontractorApp from '../SubcontractorApp';

const props = {} as WithAuthenticatorProps;

const App = (): JSX.Element => {
  const [isSubcontractor] = useIfSubcontractor();

  return (
    <GlobalState>
      <ThemeProvider theme={getLocalizedTheme(theme)}>
        {!isSubcontractor && <KoneEmployeeApp {...props}></KoneEmployeeApp>}
        {isSubcontractor && <SubcontractorApp />}
      </ThemeProvider>
    </GlobalState>
  );
};

export default App;
