import React, { useState, useContext, JSX } from 'react';
import { InfoModal } from '@konecorp/ui-library';
import { Button, ButtonGroup, Dialog, DialogContent, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeviationForm, { CreateDeviationPayload } from '../DeviationForm';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import Context, { InstallationContext } from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import { createDeviation } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { isInstallationCompleted } from '../../helpers/getInstallationLists';

export type SendToDeviationsButtonProps = {
  className?: string;
};

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: 0,
}));

const StyledButton = styled(Button)(() => ({
  lineHeight: '18px',
  border: '2px solid',
}));

const SendToDeviationsButton = (props: SendToDeviationsButtonProps): JSX.Element => {
  const { className } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [offlineDeviation, setOfflineDeviation] = useState<boolean>(false);
  const [isOnline] = useCheckConnection();
  const openDialog = () => {
    isOnline ? setIsDialogOpen(true) : setOfflineDeviation(true);
  };

  const { t } = useTranslation();
  const { updateIsLoading, networkNumber, installationData, updateErrorMessage } =
    useContext(Context);
  const { dispatch } = useContext(InstallationContext);
  const [getToken] = useGetToken();

  const createDeviationAction = async (
    deviation: CreateDeviationPayload
  ): Promise<void> => {
    try {
      updateIsLoading(true);
      const accessToken = await getToken();
      const newDeviation = await createDeviation(accessToken, networkNumber, deviation);
      dispatch({ type: InstallationActionName.ADD_DEVIATION, deviation: newDeviation });
    } catch (error) {
      updateErrorMessage({
        message: t('qdPage.cannotCreateDeviation'),
        error,
      });
    } finally {
      updateIsLoading(false);
      setIsDialogOpen(false);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} fullScreen={true}>
        <StyledDialogContent>
          <DeviationForm
            onCreate={createDeviationAction}
            onClear={() => setIsDialogOpen(false)}
          />
        </StyledDialogContent>
      </Dialog>
      <ButtonGroup className={className}>
        <StyledButton
          data-testid="create-deviation-button"
          color="primary"
          onClick={() => openDialog()}
          size="small"
          disabled={isInstallationCompleted(installationData?.status)}
        >
          {t('qdPage.createDeviation')}
        </StyledButton>
      </ButtonGroup>
      {offlineDeviation && (
        <InfoModal
          open={true}
          message={t('connection.offlineAlert')}
          onClose={(): void => setOfflineDeviation(false)}
          closeButtonText={t('connection.ok')}
          isCenteredMessage
        />
      )}
    </>
  );
};

export default SendToDeviationsButton;
