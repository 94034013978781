import React, { useEffect, useContext, useMemo, useState, JSX } from 'react';
import { Typography, CssBaseline, Box, styled } from '@mui/material';
import { theme, Navigation } from '@konecorp/ui-library';
import { Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../../logo.svg';
import { useSetServiceWorkerMessageInterval } from '../../hooks/useSetServiceWorkerMessageInterval';
import SubcontractorLogin from '../SubcontractorLogin';
import NetworkInstallation, {
  SubcontractorNetworkInstallationPaths,
} from '../NetworkInstallation';
import { subcontractorAcquireToken } from '../../helpers/subContractorAuthentication';
import { betweenStatus } from '../../helpers/getInstallationLists';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { ActivityDifferentiator, InstallationStatus, NetworkTags } from '../../schemas';
import getMenus from '../../menus';
import Loading from '../../components/Loading';
import Context from '../../context';
import ConnectStatusBanner from '../../components/ConnectStatusBanner';
import InstallationState from '../../context/installation';
import ErrorMessageDialog from '../../components/ErrorMessageDialog';

export type QueryParams = {
  networkNumber: string;
};

const StyledContainer = styled('div')(() => ({
  toolbar: theme.mixins.toolbar,
}));

const NavigationWrapper = styled(Box)(() => ({
  '& .MuiBottomNavigation-root': {
    height: '80px !important',
  },
}));

enum SubcontractorPublicPageType {
  Unauthorize,
  Completed,
}
type SubcontractorPublicInfoPageProps = {
  pageType: SubcontractorPublicPageType;
};

export const getAllowToViewDeviationListOnly = (
  userRole?: ActivityDifferentiator,
  status?: InstallationStatus | null
): boolean => {
  const deviationListOnlyForInstaller =
    userRole === ActivityDifferentiator.INST &&
    status &&
    betweenStatus(
      status,
      InstallationStatus.FOR_INSTALLATION_INSPECTION,
      InstallationStatus.INSTALLATION_COMPLETE
    );

  const deviationListOnlyForTester =
    userRole === ActivityDifferentiator.CMSN &&
    status &&
    betweenStatus(
      status,
      InstallationStatus.FOR_FINAL_INSPECTION,
      InstallationStatus.INSTALLATION_COMPLETE
    );

  if (deviationListOnlyForInstaller || deviationListOnlyForTester) return true;
  else return false;
};

const SubcontractorPublicPage = (props: SubcontractorPublicInfoPageProps) => {
  const { pageType } = props;
  const { networkNumber } = useParams<QueryParams>();
  const { t } = useTranslation();

  const title =
    pageType === SubcontractorPublicPageType.Unauthorize
      ? t('subcontractorUnauthorize.unauthorize', {
          networkNumber: networkNumber,
        })
      : t('subcontractorNetworkCompleted.completed');

  const message =
    pageType === SubcontractorPublicPageType.Unauthorize
      ? t('subcontractorUnauthorize.useURLToLogin')
      : t('subcontractorNetworkCompleted.canNowCloseTab', {
          networkNumber: networkNumber,
        });

  return (
    <Box display="flex" pl={3} pr={3} alignItems="center" height="100vh">
      <Box>
        <img src={logo} />
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6">{message}</Typography>
      </Box>
    </Box>
  );
};

const PublicSubcontractorPages = () => {
  return (
    <Routes>
      <Route
        path="/subcontractor/login/:networkNumber/:guid/:hash/:role"
        element={<SubcontractorLogin />}
      ></Route>
      <Route
        path="/subcontractor/unauthorize/:networkNumber"
        element={
          <SubcontractorPublicPage pageType={SubcontractorPublicPageType.Unauthorize} />
        }
      ></Route>
      <Route
        path="/subcontractor/completed/:networkNumber"
        element={
          <SubcontractorPublicPage pageType={SubcontractorPublicPageType.Completed} />
        }
      ></Route>
    </Routes>
  );
};

const PrivateSubcontractorPages = () => {
  //Seting up interval to send message to service worker every 5 mins to sync with backend
  useSetServiceWorkerMessageInterval();

  const { networkNumber } = useParams() as QueryParams;
  const { installationData } = useContext(Context);

  const [userRole] = useGetCurrentUserRole();
  const navigate = useNavigate();
  let showMyPlan = false;
  if (installationData) {
    showMyPlan =
      (!installationData.isModelData && !installationData.isTacoDataQuest) ||
      (installationData?.mergedQuestionSequences?.installerQuestionSetSequence
        ? installationData.mergedQuestionSequences?.installerQuestionSetSequence
            .length === 2 ||
          installationData.mergedQuestionSequences.installerQuestionSetSequence.length ===
            1
        : installationData?.installerQuestionSetSequence.length === 2 ||
          installationData.installerQuestionSetSequence.length === 1);
  }
  const getCurrentMenuPageIndex = (url: string): number => {
    if (url.includes('execution') && installationData?.networkTag !== NetworkTags.MOD)
      return 1;
    if (url.includes('starting') && installationData?.networkTag !== NetworkTags.MOD)
      return 1;
    if (
      url.includes('starting') &&
      installationData?.networkTag !== NetworkTags.MOD &&
      showMyPlan
    )
      return 0;
    if (
      url.includes('starting') &&
      installationData?.networkTag === NetworkTags.MOD &&
      !showMyPlan
    )
      return 1;
    if (
      url.includes('starting') &&
      installationData?.networkTag === NetworkTags.MOD &&
      showMyPlan &&
      userRole === ActivityDifferentiator.INST
    )
      return 0;
    if (
      url.includes('starting') &&
      installationData?.networkTag === NetworkTags.MOD &&
      showMyPlan &&
      userRole === ActivityDifferentiator.CMSN
    )
      return 1;

    if (
      url.includes('deviations') &&
      installationData?.networkTag === NetworkTags.MOD &&
      !showMyPlan
    )
      return 2;
    if (
      url.includes('deviations') &&
      installationData?.networkTag === NetworkTags.MOD &&
      showMyPlan &&
      userRole === ActivityDifferentiator.INST
    )
      return 1;
    if (
      url.includes('deviations') &&
      installationData?.networkTag === NetworkTags.MOD &&
      showMyPlan
    )
      return 2;
    if (
      url.includes('execution') &&
      installationData?.networkTag === NetworkTags.MOD &&
      showMyPlan &&
      userRole === ActivityDifferentiator.INST
    )
      return 0;
    if (url.includes('execution') && showMyPlan) return 1;
    if (url.includes('pre-install-checklist') && showMyPlan) return 1;

    if (url.includes('deviations') && installationData?.networkTag !== NetworkTags.MOD)
      return 2;
    if (
      url.includes('deviations') &&
      installationData?.networkTag !== NetworkTags.MOD &&
      showMyPlan
    )
      return 1;

    return 0;
  };

  const paths = Object.values(SubcontractorNetworkInstallationPaths);

  const isDeviationOnly = useMemo(() => {
    return getAllowToViewDeviationListOnly(userRole, installationData?.status);
  }, [userRole, installationData]);

  const initialSelectedMenuIndex = getCurrentMenuPageIndex(location.pathname);
  const [currentMenuIndex, setCurrentMenuIndex] = useState(
    initialSelectedMenuIndex ? initialSelectedMenuIndex : 1
  );

  useEffect(() => {
    setCurrentMenuIndex(initialSelectedMenuIndex);
  }, [initialSelectedMenuIndex]);

  const selectedMenus = () =>
    getMenus(
      networkNumber,
      installationData?.status || InstallationStatus.TO_BE_STARTED,
      userRole,
      true,
      showMyPlan,
      installationData
    );

  const handleMenuChange = (menuTitle: string): void => {
    if (!menuTitle) {
      return;
    }

    const selectedMenu = selectedMenus().find(
      (item) => item.title === menuTitle.trimEnd()
    );

    if (selectedMenu?.url === location.pathname) {
      return;
    }

    navigate(selectedMenu?.url || '/');
  };

  useEffect(() => {
    const key = subcontractorAcquireToken(networkNumber);

    if (!key) {
      navigate(`/subcontractor/unauthorize/${networkNumber}`, { replace: true });
    }
  }, []);

  return (
    <>
      <StyledContainer />
      <ConnectStatusBanner />
      <InstallationState>
        <Routes>
          {paths.map((path) => (
            <Route
              key={path}
              path={path.replace('/subcontractor/:networkNumber', '')}
              element={<NetworkInstallation />}
            />
          ))}
        </Routes>
      </InstallationState>

      {!isDeviationOnly && (
        <footer
          data-testid="footer"
          style={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
          }}
        >
          <NavigationWrapper>
            <Navigation
              key={currentMenuIndex}
              data={selectedMenus()}
              handleActionButtonClick={handleMenuChange}
              initalSelectedMenuIndex={initialSelectedMenuIndex}
              shadow
            />
          </NavigationWrapper>
        </footer>
      )}
    </>
  );
};

const SubcontractorApp = (): JSX.Element => {
  const location = useLocation();
  const { isLoading } = useContext(Context);

  const publicSubcontractorEndpoint = ['/login', '/unauthorize', '/completed'];

  const isPublicSubcontractorPage = useMemo(() => {
    return publicSubcontractorEndpoint.some((endpoint) =>
      location.pathname.includes(endpoint)
    );
  }, [location.pathname]);

  return (
    <>
      {isLoading && <Loading backDropSx={{ zIndex: 1500 }} />}
      <ErrorMessageDialog />

      <Box display="flex" pb={11}>
        <CssBaseline />
        {isPublicSubcontractorPage ? (
          <main style={{ flexGrow: 1 }}>
            <PublicSubcontractorPages />
          </main>
        ) : (
          <main style={{ flexGrow: 1, marginTop: '60px' }}>
            <Routes>
              <Route
                path="/subcontractor/:networkNumber/*"
                element={<PrivateSubcontractorPages />}
              >
                {/* Nested Route */}
                {/* <Route path="starting" element={<NetworkInstallation />} /> */}
              </Route>
            </Routes>
          </main>
        )}
      </Box>
    </>
  );
};
export default SubcontractorApp;
