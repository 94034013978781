import {
  Box,
  Button,
  Divider,
  Link,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  styled,
  Grid2,
} from '@mui/material';
import { CachedRounded } from '@mui/icons-material';
import React, { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { theme } from '@konecorp/ui-library';

import {
  ActivityDifferentiator,
  Installation,
  Employee,
  Vendor,
  Subcontractor,
  NetworkTags,
} from '../../schemas';

import CustomAutocomplete, {
  CustomAutocompleteData,
} from '../../components/CustomAutocomplete';
import { PickerWithButtonField } from '../../components/DatePickerWithIcon';
import dayjs from 'dayjs';

const DateError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  padding: theme.spacing(1, 2),
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
  '& .MuiButton-root:first-child': {
    marginRight: theme.spacing(2),
  },
}));

type TechnicianForm = {
  startDate: string;
  endDate: string;
  employmentType: EmploymentType;
  selectedKoneEmployeeId: string | undefined;
  vendorNumber: string;
  subcontractorEmail: string;
  subcontractorPhone: string;
  subcontractorPincode: string;
};

enum EmploymentType {
  KONE_EMPLOYEE = 'koneEmployee',
  SUBCONTRACTOR = 'subcontractor',
}

//#region component props types
type CommonDataProps = {
  role?: ActivityDifferentiator;
  installation: Installation;
  formik: FormikProps<TechnicianForm>;
};

type EmploymentTypeSelectionProps = {
  formik: FormikProps<TechnicianForm>;
};

type KoneEmployeeSearchProps = {
  employeeData: Employee[];
  formik: FormikProps<TechnicianForm>;
};

type SubcontractorFormProps = {
  vendorData: Vendor[];
  formik: FormikProps<TechnicianForm>;
};

export type AddTechnicianFormProps = {
  role: ActivityDifferentiator;
  installation: Installation;
  vendors: Vendor[];
  potentialKoneAssignees: Employee[];
  createSubcontractor: (
    subcontractor: Subcontractor,
    pincode: string,
    plannedStartDate: string,
    plannedEndDate: string
  ) => Promise<void>;
  createAssignee: (
    employeeId: string,
    plannedStartDate: string,
    plannedEndDate: string
  ) => Promise<void>;
  onClose: () => void;
};

//#endregion

//#region child components
const CommonData = ({ installation, role, formik }: CommonDataProps): JSX.Element => {
  const LINK_TO_TACO = process.env.REACT_APP_LINK_TO_TACO;

  const { t } = useTranslation();

  const totalHoursString =
    role === ActivityDifferentiator.INST
      ? installation.installerHours
      : installation.testerHours;

  const totalHours = Math.ceil(Number(totalHoursString));

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 7, sm: 9 }}>
        <Typography variant="body1">{t('addTechnicianForm.startDate')}</Typography>
      </Grid2>

      <Grid2 size={{ xs: 5, sm: 3 }}>
        <Box display="flex" flexDirection="row">
          <Typography variant="body1">
            {dayjs(formik.values.startDate).format('DD.MM.YYYY')}
          </Typography>
          <PickerWithButtonField
            inspectDate={dayjs(formik.values.startDate)}
            handleDateChange={(e) => formik.setFieldValue('startDate', e)}
            calendarIcon={true}
            planDate={true}
          />
        </Box>
      </Grid2>

      <Grid2 size={{ xs: 7, sm: 9 }}>
        <Typography variant="body1">{t('addTechnicianForm.endDate')}</Typography>
      </Grid2>

      <Grid2 size={{ xs: 5, sm: 3 }}>
        <Box display="flex" flexDirection="row">
          {/* due to the implementation of date picker (don't know the exact reason)
             justifyContent="space-between" doesn't work in IOS, hence using ugly margin right here
          */}
          <Typography variant="body1">
            {dayjs(formik.values.endDate).format('DD.MM.YYYY')}
          </Typography>
          <PickerWithButtonField
            inspectDate={dayjs(formik.values.endDate)}
            handleDateChange={(e) => formik.setFieldValue('endDate', e)}
            calendarIcon={true}
            planDate={true}
          />
        </Box>
      </Grid2>

      {Boolean(dayjs(formik.values.startDate).isAfter(dayjs(formik.values.endDate))) && (
        <Grid2 size={{ xs: 12 }}>
          <DateError>{t('addTechnicianForm.errors.invalidPlannedDate')}</DateError>
        </Grid2>
      )}
      {installation.networkTag !== NetworkTags.MOD && (
        <>
          <Grid2 size={{ xs: 7, sm: 9 }}>
            <Typography>{t('addTechnicianForm.totalHours')}</Typography>
          </Grid2>

          <Grid2 size={{ xs: 5, sm: 3 }}>
            <Box display="flex" flexDirection="row">
              <Typography variant="body1" style={{ marginRight: theme.spacing(2) }}>
                {totalHours}
              </Typography>
              <Link href={LINK_TO_TACO}>
                <Typography variant="body1">TACO</Typography>
              </Link>
            </Box>
          </Grid2>
        </>
      )}
    </Grid2>
  );
};

const EmploymentTypeSelection = (props: EmploymentTypeSelectionProps) => {
  const { t } = useTranslation();
  const { formik } = props;

  return (
    <>
      <Typography>{t('addTechnicianForm.employment')}</Typography>
      <FormControl>
        <RadioGroup
          aria-label="employmentType"
          name="employmentType"
          value={formik.values.employmentType}
          onChange={formik.handleChange}
          row={true}
        >
          <FormControlLabel
            key={EmploymentType.KONE_EMPLOYEE}
            value={EmploymentType.KONE_EMPLOYEE}
            control={<Radio color="primary" />}
            label={t('addTechnicianForm.koneInternal')}
          />

          <FormControlLabel
            key={EmploymentType.SUBCONTRACTOR}
            value={EmploymentType.SUBCONTRACTOR}
            control={<Radio color="primary" />}
            label={t('addTechnicianForm.subcontractor')}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

const KoneEmployeeSearch = (props: KoneEmployeeSearchProps) => {
  const { t } = useTranslation();
  const { formik, employeeData } = props;

  const onSelectedKoneEmployeeIdChanged = (value: CustomAutocompleteData) => {
    const employeeId = value.id;
    formik.setFieldValue('selectedKoneEmployeeId', employeeId);
  };

  return (
    <>
      <Typography gutterBottom>{t('addTechnicianForm.assignTo')}</Typography>
      <CustomAutocomplete
        label={t('addTechnicianForm.typeToSearch')}
        data={employeeData.map((employee) => ({
          label: `${employee.legalFirstName} ${employee.legalLastName}`,
          id: employee.employeeId,
        }))}
        id="kone-employee"
        selectedValue={formik.values.selectedKoneEmployeeId ?? ''}
        onChange={onSelectedKoneEmployeeIdChanged}
      ></CustomAutocomplete>
    </>
  );
};

const SubcontractorForm = (props: SubcontractorFormProps) => {
  const { t } = useTranslation();
  const { vendorData, formik } = props;

  const onVendorChanged = (value: CustomAutocompleteData) => {
    const vendorNumber = value.id;
    formik.setFieldValue('vendorNumber', vendorNumber);
  };

  const onGeneratingKeyClicked = () => {
    const characters = '23469ACDEFGHJKLMNPQRSTWXYZ';
    let randomString = '';

    while (randomString.length < 7) {
      randomString += characters[Math.floor(Math.random() * characters.length)];
    }

    formik.setFieldValue('subcontractorPincode', randomString);
  };

  return (
    <>
      <Typography gutterBottom>{t('addTechnicianForm.subcontractorContact')}</Typography>

      <CustomAutocomplete
        data={vendorData.map((vendor) => ({
          id: vendor.vendorNumber,
          label: `${vendor.vendorName1} (${vendor.vendorNumber})`,
        }))}
        id="subcontractor-name"
        label={t('addTechnicianForm.vendorName')}
        selectedValue={formik.values.vendorNumber}
        onChange={onVendorChanged}
        required
        data-testid="subcontractor-vendor-id-input"
      />

      <TextField
        sx={{ marginTop: theme.spacing(2) }}
        fullWidth
        type="text"
        label={t('addTechnicianForm.email')}
        variant="outlined"
        required
        size="medium"
        name="subcontractorEmail"
        value={formik.values.subcontractorEmail}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.subcontractorEmail && Boolean(formik.errors.subcontractorEmail)
        }
        helperText={formik.touched.subcontractorEmail && formik.errors.subcontractorEmail}
        inputProps={{
          'data-testid': 'subcontractor-email-input',
        }}
      />

      <TextField
        sx={{ marginTop: theme.spacing(2) }}
        fullWidth
        type="text"
        label={t('addTechnicianForm.phoneNumber')}
        variant="outlined"
        required
        size="medium"
        name="subcontractorPhone"
        value={formik.values.subcontractorPhone}
        error={
          formik.touched.subcontractorPhone && Boolean(formik.errors.subcontractorPhone)
        }
        helperText={formik.touched.subcontractorPhone && formik.errors.subcontractorPhone}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        inputProps={{
          'data-testid': 'subcontractor-phone-input',
        }}
      />

      <Typography sx={{ marginTop: theme.spacing(2) }}>
        {t('subContractorForm.subcontractorKey')}
      </Typography>

      <Box display="flex" alignItems="center">
        <TextField
          type="text"
          variant="outlined"
          disabled
          size="small"
          value={formik.values.subcontractorPincode}
          inputProps={{
            'data-testid': 'subcontractor-pincode-input',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          ml={2}
          sx={{ cursor: 'pointer' }}
          onClick={onGeneratingKeyClicked}
        >
          <CachedRounded aria-label="generate-key" />
          <Typography textTransform="uppercase">
            {t('subContractorForm.createNew')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
//#endregion

const AddTechnicianForm = ({
  installation,
  vendors,
  role,
  potentialKoneAssignees,
  createSubcontractor,
  createAssignee,
  onClose,
}: AddTechnicianFormProps): JSX.Element => {
  const { t } = useTranslation();

  const initialTechnicianForm: TechnicianForm = {
    startDate: new Date(Date.now()).toISOString(),
    endDate: new Date(Date.now()).toISOString(),
    employmentType: EmploymentType.KONE_EMPLOYEE,
    selectedKoneEmployeeId: undefined,
    vendorNumber: '',
    subcontractorEmail: '',
    subcontractorPhone: '',
    subcontractorPincode: '',
  };

  const validationSchema = yup.object({
    startDate: yup
      .string()
      .required()
      .test('is-valid-start-date', (startDate, testContext) => {
        const endDate = testContext.parent.endDate;
        return startDate && endDate && new Date(startDate) <= new Date(endDate);
      }),

    endDate: yup
      .string()
      .required()
      .test('is-valid-end-date', (endDate, testContext) => {
        const startDate = testContext.parent.startDate;
        return endDate && startDate && new Date(endDate) >= new Date(startDate);
      }),

    employmentType: yup.mixed().oneOf(Object.values(EmploymentType)),

    selectedKoneEmployeeId: yup.string().when('employmentType', {
      is: EmploymentType.KONE_EMPLOYEE,
      then: yup
        .string()
        .trim()
        .required(t('addTechnicianForm.errors.thisFieldIsRequired')),
    }),

    vendorNumber: yup.string().when('employmentType', {
      is: EmploymentType.SUBCONTRACTOR,
      then: yup.string().required(t('addTechnicianForm.errors.thisFieldIsRequired')),
    }),
    subcontractorEmail: yup.string().when('employmentType', {
      is: EmploymentType.SUBCONTRACTOR,
      then: yup
        .string()
        .email(t('addTechnicianForm.errors.invalidEmail'))
        .trim()
        .required(t('addTechnicianForm.errors.thisFieldIsRequired')),
    }),
    subcontractorPhone: yup.string().when('employmentType', {
      is: EmploymentType.SUBCONTRACTOR,
      then: yup
        .string()
        .test(
          'is-valid-phone',
          t('addTechnicianForm.errors.invalidPhoneNumber'),
          (phone) => !!phone && isValidPhoneNumber(phone)
        )
        .required(t('addTechnicianForm.errors.thisFieldIsRequired')),
    }),
    subcontractorPincode: yup.string().when('employmentType', {
      is: EmploymentType.SUBCONTRACTOR,
      then: yup
        .string()
        .trim()
        .required(t('addTechnicianForm.errors.thisFieldIsRequired')),
    }),
  });

  const handleSubmit = async (formValues: TechnicianForm) => {
    if (
      formValues.employmentType === EmploymentType.KONE_EMPLOYEE &&
      formValues.selectedKoneEmployeeId
    ) {
      await createAssignee(
        formValues.selectedKoneEmployeeId,
        formValues.startDate,
        formValues.endDate
      );
    }

    if (formValues.employmentType === EmploymentType.SUBCONTRACTOR) {
      const vendorName =
        vendors.find((vendor) => vendor.vendorNumber === formValues.vendorNumber)
          ?.vendorName1 || '';

      const subcontractorObject: Subcontractor = {
        name: vendorName,
        vendorNumber: formValues.vendorNumber,
        email: formValues.subcontractorEmail,
        mobile: formValues.subcontractorPhone,
      };

      await createSubcontractor(
        subcontractorObject,
        formValues.subcontractorPincode,
        formValues.startDate,
        formValues.endDate
      );
    }
  };

  const formik = useFormik({
    initialValues: initialTechnicianForm,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box padding={3}>
      <StyledTypography variant="h6" align="center" data-testid="dialogTitle">
        {role === ActivityDifferentiator.INST
          ? t('addTechnicianForm.addInstaller')
          : t('addTechnicianForm.addTester')}
      </StyledTypography>
      <Divider />

      <Box pt={3} pb={3}>
        <CommonData formik={formik} role={role} installation={installation} />
      </Box>

      <Divider />

      <Box pt={3} pb={3}>
        <EmploymentTypeSelection formik={formik} />

        {formik.values.employmentType === EmploymentType.KONE_EMPLOYEE && (
          <KoneEmployeeSearch
            formik={formik}
            employeeData={potentialKoneAssignees || []}
          />
        )}

        {formik.values.employmentType === EmploymentType.SUBCONTRACTOR && (
          <SubcontractorForm vendorData={vendors} formik={formik} />
        )}
      </Box>

      <ButtonGroup display="flex" flex="1" justifyItems="space-between">
        <Button
          color="primary"
          variant="contained"
          onClick={formik.submitForm}
          disabled={!formik.isValid || !formik.dirty}
          fullWidth
          data-testid="technical-form-save-button"
        >
          {t('addTechnicianForm.saveButton')}
        </Button>
        <Button color="primary" variant="outlined" onClick={onClose} fullWidth>
          {t('addTechnicianForm.cancelButton')}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default AddTechnicianForm;
