import React, { JSX } from 'react';
import { Grid2, Link, Typography, styled } from '@mui/material';

export type AddButtonProps = {
  label: string;
  onClick: () => void;
  style?: React.CSSProperties;
};

// Styled Grid component
const AssignmentInfo = styled(Grid2)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  textTransform: 'uppercase',
  '& p': {
    cursor: 'pointer',
  },
}));

const AddButton = (props: AddButtonProps): JSX.Element => {
  const { label, onClick, style } = props;

  return (
    <AssignmentInfo container style={style}>
      <Grid2 size={{ xs: 12 }}>
        <Typography>
          <Link
            sx={{
              textDecoration: 'none', // No underline by default
              '&:hover': {
                textDecoration: 'underline', // Underline on hover
              },
            }}
            onClick={onClick}
          >
            {label}
          </Link>
        </Typography>
      </Grid2>
    </AssignmentInfo>
  );
};

export default AddButton;
