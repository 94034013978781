import React, { JSX } from 'react';
import { Container, Typography, styled, Grid2 } from '@mui/material';
import { formatDate } from '../../helpers/formating';
import { theme } from '@konecorp/ui-library';
import i18next from 'i18next';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

export interface NetworkSummaryProps {
  targetHours: number;
  assignmentEndDate: string;
  openDeviationCount: number;
}

const StyledContainer = styled(Grid2)(({ theme }) => ({
  textAlign: 'center',
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: theme.shape.borderRadius,
  '& > div': {
    padding: theme.spacing(1),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 0.9,
  color: theme.palette.grey[500],
}));

const NetworkSummary = (props: NetworkSummaryProps): JSX.Element => {
  const { targetHours, assignmentEndDate, openDeviationCount } = props;
  const endDate = formatDate(assignmentEndDate);
  const [isSubcontractor] = useIfSubcontractor();

  return (
    <Container maxWidth={false} data-testid="network-summary">
      <StyledContainer container justifyContent="center">
        {!isSubcontractor && (
          <Grid2
            size={{ xs: 6 }}
            sx={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}
            aria-label="target-hours-gridItem"
          >
            <StyledTypography variant="subtitle1">
              {i18next.t('networkSummary.targetHoursLabel')}
            </StyledTypography>
            <Typography variant="h5">{targetHours}</Typography>
          </Grid2>
        )}
        <Grid2 size={{ xs: isSubcontractor ? 12 : 6 }}>
          <StyledTypography variant="subtitle1">
            {i18next.t('networkSummary.assignmentEndDateLabel')}
          </StyledTypography>
          <Typography variant="h5">{endDate ?? '—'}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <StyledTypography variant="subtitle1">
            {i18next.t('networkSummary.openDeviationCountLabel')}
          </StyledTypography>
          <Typography variant="h5">{openDeviationCount}</Typography>
        </Grid2>
      </StyledContainer>
    </Container>
  );
};

export default NetworkSummary;
